import React from 'react';
import { Card, CardBody, CardHeader, Col, Row, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';

const RoomList = (props) => {
  const { participants, rooms } = props;
  const [tCommon] = useTranslation();
  const [t] = useTranslation('back-office');

  return (
    <Card className="">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{t('Room list')}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Card
          style={{
            margin: '-1.5rem',
          }}
          className="mb-5"
        >
          <CardBody className="p-0">
            <Table className="align-items-center table-flush overflow-visible" responsive>
              <thead className="thead-light">
                <tr>
                  <th scope="col">{tCommon('Room')}</th>
                  <th scope="col">{t('Guests')}</th>
                  <th scope="col">{tCommon('Invitation')}</th>
                  <th scope="col" />
                </tr>
              </thead>
              <tbody>
                {rooms.map((room) => {
                  return (
                    <tr key={room.id}>
                      <td>N° {room.id}</td>
                      <td>
                        {_.filter(participants, (o) => {
                          /*
                            return o.role === 'animator' && parseInt(o.room_id.id) === room.id;
  */
                          return parseInt(o.room_id.id) === room.id;
                        }).map((p) => {
                          return (
                            <div>
                              -{' '}
                              {p.participant.role === 'animator' ? (
                                <b>{p.participant.name}</b>
                              ) : (
                                <span>{p.participant.name}</span>
                              )}
                            </div>
                          );
                        })}
                      </td>
                      <td>
                        <b>
                          {
                            _.filter(participants, (p) => {
                              return parseInt(p.room_id.id) === room.id;
                            }).length
                          }
                        </b>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          </CardBody>
        </Card>
      </CardBody>
    </Card>
  );
};

export default RoomList;
