import React from 'react';
import { Row } from 'reactstrap';

const UsersLayout = (props) => {
  const { children } = props;
  return (
    <>
      <Row className="mt-9 mb-4" />
      <Row>{children}</Row>
    </>
  );
};

export default UsersLayout;
