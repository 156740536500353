import React, { useCallback, useState } from 'react';
import { NavLink, Badge } from 'reactstrap';

import { useSessionsContext } from 'context/sessionContext';
import SharePanel from 'components/front/room/animator/SharePanel';
import icon from 'assets/img/icons/presentation-docs.svg';

const RoomNavbarButtons = () => {
  const sessionsContext = useSessionsContext();
  const [sharePanel, setSharePanel] = useState(false);

  const toggleSharePanel = useCallback(
    (e) => {
      e.stopPropagation();
      setSharePanel(!sharePanel);
    },
    [sharePanel, setSharePanel]
  );

  const { sharePanelNotification } = sessionsContext.state;

  return (
    <>
      <NavLink
        onClick={toggleSharePanel}
        style={{
          cursor: 'pointer',
        }}
      >
        {/*
          <img className="mr-1" src={icon} alt="" />
        */}
        <div className="mr-1">
          <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21">
            <g id="ungroup" transform="translate(0.5 0.5)">
              <path
                id="Path"
                d="M8,0h6V14H0V8"
                transform="translate(6 6)"
                fill="#698DCA"
                stroke="#fff"
                strokeLinecap="square"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
              <rect
                id="Rectangle"
                width="14"
                height="14"
                fill="#698DCA"
                stroke="#fff"
                strokeLinecap="square"
                strokeMiterlimit="10"
                strokeWidth="1"
              />
            </g>
          </svg>
        </div>
        {!!sharePanelNotification && (
          <Badge color="primary" pill>
            {sharePanelNotification}
          </Badge>
        )}
      </NavLink>
      <div
        style={{
          position: 'relative',
        }}
      >
        {sharePanel && <SharePanel close={toggleSharePanel} />}
      </div>
    </>
  );
};

export default RoomNavbarButtons;
