import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/authContext';

const EventEditLayout = (props) => {
  const { location, match, children } = props;
  const [t] = useTranslation('back-office');
  const currentPage = location.pathname.includes('participants') ? 'participants' : 'infos';
  const { id } = match.params;
  const authContext = useAuthContext();
  return (
    <Container fluid>
      <Link to={`/admin/events/${id}`} className="mr-2">
        <Button color={currentPage !== 'infos' ? 'outline-primary' : 'primary'}>
          {t('Event data')}
        </Button>
      </Link>

      <Link to={`/admin/events/${id}/participants`}>
        <Button
          disabled={id === 'create'}
          color={currentPage !== 'participants' ? 'outline-primary' : 'primary'}
        >
          {t('Participants data')}
        </Button>
      </Link>
      {/* authContext.state.user.roles.includes('user') && (
        <Link className="ml-2" to="/admin/events">
          <Button color="outline-primary">{t('Back to the list')}</Button>
        </Link>
      ) */}
      <Link to="/admin/events">
        <Button className="ml-2" color="outline-primary">
          {t('Back to the list')}
        </Button>
      </Link>
      <Row className="mt-3">
        <Col>{children}</Col>
      </Row>
    </Container>
  );
};

export default EventEditLayout;
