import React, { useCallback, useEffect, useState } from 'react';
import {
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  DropdownToggle,
  Media,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useAuthContext, logout } from 'context/authContext';
import { useThemeContext, actions } from 'context/themeContext';
import { useTranslation } from 'react-i18next';
import Avatar from 'components/admin/profile/Avatar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSignOutAlt, faArrowAltCircleLeft } from '@fortawesome/free-solid-svg-icons';
import { END_SESSION, useSessionsContext } from '../../context/sessionContext';
// import logoTP from 'assets/img/troisprime-logo-fr.png';

const UserBanner = (props) => {
  const { backHome, stopDevices } = props;
  const authContext = useAuthContext();
  const sessionsContext = useSessionsContext();
  const themeContext = useThemeContext();
  const history = useHistory();
  const [t] = useTranslation();

  const [isAdmin, setIsAdmin] = useState(false);

  const disconnect = useCallback(() => {
    const { dispatch } = themeContext;

    if (sessionsContext.state.helper) {
      sessionsContext.state.helper.disconnect();
      sessionsContext.dispatch({
        type: END_SESSION,
      });
    }

    try {
      stopDevices();
    } catch (e) {
      // Do nothing
    }

    dispatch({
      type: actions.LOG_OUT,
      payload: {},
    });

    logout(authContext);
    history.push('/');
  }, [themeContext, sessionsContext, authContext, history]);

  useEffect(() => {
    if (authContext?.state) {
      const admin = authContext?.state?.user?.roles || [];
      setIsAdmin(admin.indexOf('admin') !== -1);
    }
  }, [authContext]);

  return (
    <div className="mr-2" style={styles.wrapper}>
      {authContext?.state?.user && Object.keys(authContext.state.user).length !== 0 && (
        <>
          {backHome && isAdmin && (
            <div
              className="pr-3"
              tabIndex={0}
              role="button"
              style={{
                fontSize: '1em',
                whiteSpace: 'nowrap',
                cursor: `${authContext?.state?.isLogged === true ? 'pointer' : 'unset'}`,
              }}
              onClick={() => {
                stopDevices();
                if (authContext?.state?.isLogged === true) {
                  if (!localStorage.getItem('token').includes('user')) {
                    localStorage.removeItem('accessToken');
                    localStorage.removeItem('token');
                    localStorage.removeItem('themeData');
                    localStorage.removeItem('opentok_client_id');
                    localStorage.removeItem('debug');
                  }
                  history.push('/');
                }
              }}
            >
              <FontAwesomeIcon
                className="p-0 m-0 mr-1"
                icon={faArrowAltCircleLeft}
                onClick={stopDevices}
              />
              {t('Back to home')}
            </div>
          )}
          <Avatar />
          <UncontrolledDropdown nav>
            <DropdownToggle nav>
              <Media className="align-items-center">
                {/* <img src={logoTP} alt="" style={styles.avatarTP} /> */}
                {/* <span className='avatar avatar-sm rounded-circle bg-success' style={styles.avatar}/> */}
                <Media className="ml-3 d-none d-lg-block">
                  <span className="mb-0 text-sm font-weight-bold text-white">
                    <FontAwesomeIcon
                      style={styles.logout}
                      className="p-0 m-0"
                      icon={faSignOutAlt}
                    />
                  </span>
                </Media>
              </Media>
            </DropdownToggle>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem onClick={disconnect}>
                <i className="ni ni-user-run" />
                <span>{t('Logout')}</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </>
      )}
    </div>
  );
};

export default UserBanner;
const styles = {
  logout: {
    fontSize: '16px',
  },
  wrapper: {
    display: 'inline-flex',
    borderRadius: 0,
    borderWidth: 1,
    fontSize: 12,
    alignItems: 'center',
    color: '#FFF',
    alignSelf: 'flex-start',
  },
  avatar: {
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#098',
    minWidth: 36,
    minHeight: 36,
    width: 36,
    height: 36,
  },
  avatarTP: {
    // minWidth: 36,
    minHeight: 36,
    width: 'auto',
    height: 36,
  },
};
