import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Media,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Table,
  UncontrolledDropdown,
} from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';
import { Link, useHistory } from 'react-router-dom';
import RoomList from './RoomList';
import NodeApiHttp from '../../../utils/nodeApiHttp';

const EditEventParticipants = (props) => {
  const { match } = props;
  const { id } = match.params;
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const history = useHistory();
  const [participants, setParticipants] = useState([]);
  const [pagination, setPagination] = useState(0);
  const [rooms, setRooms] = useState(null);

  const removeParticipant = useCallback(
    async (participantID) => {
      const deletedParticipant = await NodeApiHttp.delete(`participants/${participantID}`);
      setParticipants(
        participants.filter((p) => {
          return p.participant.id !== deletedParticipant.id;
        })
      );
    },
    [participants]
  );

  useEffect(() => {
    if (!id || id === 'create') {
      history.replace('/admin/events');
      return;
    }
    NodeApiHttp.get(`event-invitations?event=${id}`).then((res) => {
      setParticipants(res);
    });
  }, [id, history]);

  useEffect(() => {
    http.get(`rooms?event_id=${id}`).then(setRooms);
  }, [id]);

  return (
    <div>
      <Card className="">
        <CardHeader className="bg-white border-0">
          <Row className="align-items-center">
            <Col xs="8">
              <h3 className="mb-0">{t('List of guests')}</h3>
            </Col>
            <Col className="text-right" xs="4">
              <Link to={`/admin/events/${id}/participants/create`}>
                <Button color="primary">{t('Add a participant')}</Button>
              </Link>
            </Col>
          </Row>
        </CardHeader>
        <CardBody>
          <Card
            style={{
              margin: '-1.5rem',
            }}
            className="mb-5"
          >
            <CardBody className="p-0">
              <Table className="align-items-center table-flush overflow-visible" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">{t('Guests')}</th>
                    <th scope="col">{t('Email address')}</th>
                    <th scope="col">{tCommon('Role')}</th>
                    <th scope="col">{tCommon('Room')}</th>
                    <th scope="col" />
                  </tr>
                </thead>
                <tbody>
                  {participants.slice(pagination * 4, 4 + pagination * 4).map((p, i) => (
                    <tr key={p.id}>
                      <th scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{p.participant.name}</span>
                          </Media>
                        </Media>
                      </th>
                      <td>{p.participant.email}</td>
                      <td>{tCommon(p.role)}</td>
                      {p.room_id ? <td>n° {p.room_id.id}</td> : <td> - </td>}
                      <td className="text-right">
                        <UncontrolledDropdown>
                          <DropdownToggle
                            className="btn-icon-only text-light"
                            href="#"
                            role="button"
                            size="sm"
                            color=""
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fas fa-ellipsis-v" />
                          </DropdownToggle>
                          <DropdownMenu className="dropdown-menu-arrow" right>
                            <DropdownItem
                              href="#"
                              onClick={() => removeParticipant(p.participant.id)}
                            >
                              {tCommon('Delete')}
                            </DropdownItem>
                          </DropdownMenu>
                        </UncontrolledDropdown>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </CardBody>
            <CardFooter className="py-4">
              <nav aria-label="...">
                <Pagination
                  className="pagination justify-content-end mb-0"
                  listClassName="justify-content-end mb-0"
                >
                  <PaginationItem
                    className={classnames({
                      disabled: pagination <= 0,
                    })}
                  >
                    <PaginationLink onClick={() => pagination > 0 && setPagination(pagination - 1)}>
                      <i className="fas fa-angle-left" />
                      <span className="sr-only">{tCommon('Previous')}</span>
                    </PaginationLink>
                  </PaginationItem>
                  {participants
                    .filter((_, i) => i % 4 === 0)
                    .map((_, i) => (
                      <PaginationItem key={i} className={pagination === i ? 'active' : ''}>
                        <PaginationLink onClick={() => setPagination(i)}>{i + 1}</PaginationLink>
                      </PaginationItem>
                    ))}
                  <PaginationItem
                    className={classnames({
                      disabled: pagination >= ((participants.length / 4) | 0),
                    })}
                  >
                    <PaginationLink
                      onClick={() =>
                        pagination < ((participants.length / 4) | 0) &&
                        setPagination(pagination + 1)
                      }
                    >
                      <i className="fas fa-angle-right" />
                      <span className="sr-only">{tCommon('Next')}</span>
                    </PaginationLink>
                  </PaginationItem>
                </Pagination>
              </nav>
            </CardFooter>
          </Card>
        </CardBody>
      </Card>

      {!!rooms && <RoomList participants={participants} rooms={rooms} />}
    </div>
  );
};

export default EditEventParticipants;
