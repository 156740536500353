import React, { useEffect, useCallback, useRef, useState } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PharmaReportList from 'components/admin/pharmacovigilance/PharmaReportList';
import Pagination from 'components/common/Pagination';
import { ListProvider, actions, useListContext } from 'context/ListContext';
import moment from 'moment';
import { Link } from 'react-router-dom';
import css from './emaillist.module.css';

require('moment/locale/fr.js');

moment.locale('fr');

const PharmaReport = () => {
  const Render = () => {
    const [t] = useTranslation('back-office');
    const [maxItem, setMaxItem] = useState(5);
    const [itemCount, setItemCount] = useState(5);
    const [pagination, setPagination] = useState(0);
    const [itemSubstitutes, setItemSubstitutes] = useState([]);

    const listContext = useListContext();
    // Pagination
    const load = useCallback(
      (paginate) => {
        const { dispatch } = listContext;
        dispatch({
          type: actions.LOAD_LIST,
          payload: {
            start: paginate * itemCount,
            count: itemCount,
          },
        });
      },
      [itemCount, listContext]
    );

    const onItemChange = useCallback(
      (value) => {
        setItemSubstitutes(Array(value).fill(0));
        const maxPagination = Math.ceil(value / maxItem);
        if (maxPagination === 0) {
          setPagination(0);
        } else if (maxPagination <= pagination) {
          setPagination(maxPagination - 1);
        }
      },
      [maxItem, pagination]
    );

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const previous = usePrevious({ pagination, listContext });
    useEffect(() => {
      if (previous === undefined || previous.pagination !== pagination) {
        load(pagination);
      }
    }, [pagination, previous, load]);

    return (
      <Container fluid>
        <Row>
          <Col lg="7" md="10">
            <h1 className="display-2 text-white">{t('Pharma report')}</h1>
            <p className="text-white mt-0 mb-5 w-100">
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className={css.defaultButtonNavigation}>
            <Link to="/admin/pharmacovigilance">
              <Button color="outline-primary">{t('E-mails')}</Button>
            </Link>
            <Button
              color="primary"
              style={{
                marginLeft: '8px',
              }}
            >
              {t('Reports')}
            </Button>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('Pharma vigilance report list')}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white">
                <PharmaReportList
                  onItemChange={onItemChange}
                  onItemCountChange={(value) => {
                    setMaxItem(value);
                    setItemCount(value);
                    setPagination(0);
                  }}
                >
                  <nav aria-label="...">
                    <Pagination
                      items={itemSubstitutes}
                      pagination={pagination}
                      setPagination={setPagination}
                      maxItem={maxItem}
                    />
                  </nav>
                </PharmaReportList>
              </CardBody>
            </Card>

            {/*
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <Row className="align-items-center">
                <Col xs="8">
                  <h3 className="mb-0">{t('Pharma vigilance report list')}</h3>
                </Col>
              </Row>
            </CardHeader>
            <CardBody className="bg-white">
              <Row>
                <Table>
                  <tbody>
                    {reportList.map((report, index) => (
                      <tr key={index}>
                        <td className="pl-1 pr-0">
                          <Input
                            addon
                            type="checkbox"
                            aria-label="Checkbox for following text input"
                          />
                        </td>
                        <td>
                          <div>
                            {report.doctor_firstname} {report.doctor_lastname}
                          </div>
                          <Link to={`/admin/pharma-report-detail/${report.id}`}>
                            <div>{report.drug_name}</div>
                          </Link>
                          <div>{report.drug_adverse_reaction}</div>
                        </td>
                        <td>
                          <span className="float-right opacity-4">
                            {moment(report.created_at, 'YYYYMMDD').fromNow()}
                          </span>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Row>
            </CardBody>
          </Card>
          */}
          </Col>
        </Row>
      </Container>
    );
  };

  return (
    <ListProvider
      resource={{
        routes: {
          ON_LOAD: 'pharmacovigilance-report',
          ON_DELETE: 'pharmacovigilance-report',
          ON_DELETE_MULTIPLE: 'pharmacovigilance-report',
          ON_CHANGE: 'pharmacovigilance-report',
        },
      }}
    >
      <Render />
    </ListProvider>
  );
};

export default PharmaReport;
