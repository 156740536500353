import React, { useReducer, useEffect, useContext, useState } from 'react';
// import ReactDOM from 'react-dom';
import { useLocation, useHistory } from 'react-router-dom';
import { useAuthContext } from 'context/authContext';
import http from 'utils/http';
import query from 'query-string';

export const actions = Object.freeze({
  LOG_OUT: 'LOG_OUT',
  LOAD_INITIAL: 'LOAD_INITIAL',
  SET_LAB: 'SET_LAB',
  LOAD: 'LOAD',
  LOAD_PRIVATE: 'LOAD_PRIVATE',
  LOAD_ON_EVENT: 'LOAD_ON_EVENT',
  ON_LOCATION_CHANGE: 'ON_LOCATION_CHANGE',
  UPDATE_SOME: 'UPDATE_SOME',
});

export const ThemeContext = React.createContext();

export const useThemeContext = () => {
  const context = useContext(ThemeContext);
  if (context === undefined) {
    throw new Error('useThemeContext must be used within a ThemeProvider');
  }
  return context;
};

const getColorsFromEvent = (id) =>
  http
    .get(`eventInfo/${id}`)
    .then((response) => {
      const { lab } = response;
      return {
        logo: lab.logo_image,
        colors: {
          primary: lab.primary_color,
          secondary: lab.background_color,
          tertiary: lab.third_color,
        },
      };
    })
    .catch((e) => console.log(e));

const initialState = {
  logo: null,
  location: {},
  loaded: false,
  enabled: false,
  colors: {
    primary: '#3571ff',
    secondary: '#f8f9fe',
    tertiary: '#191e4d',
  },
};

const storeThemeData = (data) => {
  localStorage.setItem('themeData', JSON.stringify(data));
};

const getThemeData = () => {
  const data = localStorage.getItem('themeData');
  if (data !== null) {
    return JSON.parse(data);
  }
  return null;
};

const reducer = async (state, action) => {
  const { payload, type } = action;
  switch (type) {
    case actions.LOG_OUT: {
      localStorage.removeItem('themeData');
      return { ...initialState };
    }
    case actions.LOAD_INITIAL: {
      return {
        ...initialState,
        enabled: true,
      };
    }
    case actions.LOAD: {
      const newState = await state;
      Object.assign(newState, payload, {
        enabled: true,
      });
      const data = getThemeData();
      if (data) {
        newState.logo = data.logo;
        newState.colors = data.colors;
      }
      return newState;
    }

    case actions.UPDATE_SOME: {
      const newState = await state;
      Object.assign(newState, payload);
      storeThemeData({
        logo: newState.logo,
        colors: newState.colors,
      });
      return newState;
    }

    case actions.LOAD_ON_EVENT: {
      const { id } = payload;
      const newState = await state;
      return getColorsFromEvent(id).then((data) => {
        Object.assign(newState, {
          logo: data?.logo || newState.logo,
          colors: {
            primary: data?.colors?.primary || newState.colors.primary,
            secondary: data?.colors?.secondary || newState.colors.secondary,
            tertiary: data?.colors?.tertiary || newState.colors.tertiary,
          },
          enabled: true,
        });
        storeThemeData({
          logo: newState.logo,
          colors: newState.colors,
        });
        return newState;
      });
    }
    case actions.LOAD_PRIVATE: {
      const { colors } = payload;
      const newState = await state;
      Object.assign(newState, {
        logo: null,
        colors: {
          primary: colors.primary || newState.colors.primary,
          secondary: colors.secondary || newState.colors.secondary,
          tertiary: colors.tertiary || newState.colors.tertiary,
        },
        enabled: true,
      });
      storeThemeData({
        logo: newState.logo,
        colors: newState.colors,
      });
      return newState;
    }
    /*
    case actions.ON_LOCATION_CHANGE: {
      const newState = {
        ...(await state),
      };
      newState.location = payload.location;
      return newState;
    }
    */
    case actions.SET_LAB: {
      const newState = await state;
      Object.assign(newState, {
        logo: payload.logo_image || newState.logo,
        colors: {
          primary: payload.primary_color || newState.colors.primary,
          secondary: payload.background_color || newState.colors.secondary,
          tertiary: payload.third_color || newState.colors.tertiary,
        },
        enabled: true,
      });
      storeThemeData({
        logo: newState.logo,
        colors: newState.colors,
      });
      return newState;
    }
    default:
      return state;
  }
};

export const ThemeProvider = ({ children }) => {
  const authContext = useAuthContext();
  const [state, dispatch] = useReducer(reducer, (async () => initialState)());
  const [logo, setLogo] = useState('');
  const location = useLocation();
  const history = useHistory();
  useEffect(() => {
    state.then((resolved) => {
      const { colors } = resolved;
      const root = document.documentElement;
      root.style.setProperty('--first-color', colors.primary);
      root.style.setProperty('--second-color', colors.secondary);
      root.style.setProperty('--third-color', colors.tertiary);
    });
  }, [state]);

  useEffect(() => {
    state.then((value) => {
      setLogo(/* `${process.env.REACT_APP_API_BASE_URI}/${value.logo}` */ value.logo);
      const id = authContext.state.eventId;
      if (!value.enabled) {
        if (id !== null) {
          dispatch({
            type: actions.LOAD_ON_EVENT,
            payload: {
              location,
              id,
            },
          });
        } else if (authContext.state.isLogged) {
          dispatch({
            type: actions.LOAD,
            payload: {
              location,
            },
          });
        }
      }
    });
  }, [authContext.state, location, state]);

  useEffect(() => {
    /*
    history.listen((value) => {
      dispatch({
        type: actions.ON_LOCATION_CHANGE,
        payload: {
          location: value,
        },
      });
    });
    */

    // Checks if it's a public link
    const match = /((?:(?!\/).)+)-([0-9]+)/g.exec(location.pathname);
    if (match != null) {
      const id = match.pop();
      dispatch({
        type: actions.LOAD_ON_EVENT,
        payload: {
          location,
          id,
        },
      });
    } else {
      // Checks if it's a private link
      const { href } = window.location;
      // eslint-disable-next-line no-shadow
      const match = /\/?invitation=([0-9a-z-]+)/g.exec(href);
      if (match != null) {
        const p = query.parse(href)?.p;
        const b = query.parse(href)?.b;
        const t = query.parse(href)?.t;
        const colors = {
          primary: p ? atob(p) : undefined,
          secondary: b ? atob(b) : undefined,
          tertiary: t ? atob(t) : undefined,
        };
        dispatch({
          type: actions.LOAD_PRIVATE,
          payload: {
            location,
            colors,
          },
        });
      } else if (!location.pathname || location.pathname === '/') {
        dispatch({
          type: actions.LOAD_INITIAL,
          payload: {},
        });
      } else if (location.pathname !== '/event') {
        dispatch({
          type: actions.LOAD,
          payload: {
            location,
          },
        });
      }
    }
  }, [history, location]);

  return (
    <ThemeContext.Provider
      value={{
        state,
        dispatch,
        logo,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
