import React, { useCallback, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Container,
  Button,
  Modal,
  Row,
  Col,
  ModalBody,
  ModalHeader,
  ModalFooter,
} from 'reactstrap';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
// import FilterBar from 'components/admin/polls/FilterBar';
import http from 'utils/http';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import css from 'components/common/common.module.scss';
import NodeServerHttp from '../../../utils/nodeApiHttp';
import EventList from './EventList';

import { useAuthContext } from '../../../context/authContext';

const ListEvents = () => {
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const authContext = useAuthContext();
  const [closingEvent, setClosingEvent] = useState('');
  const [deletingEvent, setDeletingEvent] = useState('');
  const [events, setEvents] = useState([]);
  const [plannedEvents, setPlannedEvents] = useState([]);
  const [currentEvents, setCurrentEvents] = useState([]);
  const [pastEvents, setPastEvents] = useState([]);
  const [loaded, setLoaded] = useState(false);

  /** Events */

  const deleteEvent = useCallback(
    async (id) => {
      await http.delete(`events/${id}`);
      setDeletingEvent('');
      setEvents(events.filter((event) => event.id !== id));
    },
    [events, setEvents, setDeletingEvent]
  );

  const updateEvent = useCallback(
    async (
      id,
      data,
      options = {
        modal: false,
      }
    ) => {
      if (options.modal) {
        setClosingEvent({
          id,
          data,
        });
      } else {
        try {
          const event = await http.put(`events/${id}`, data).then((res) => {
            NodeServerHttp.put(`events/${res.id}`, {
              active: res.active,
            });

            return res;
          });

          const index = events.findIndex((e) => e.id === id);
          setClosingEvent('');
          setEvents([...events.slice(0, index), event, ...events.slice(index + 1)]);
        } catch (error) {
          console.error(error);
        }
      }
    },
    [events, setEvents, setClosingEvent]
  );

  useEffect(() => {
    if (authContext.state.user.roles.includes('user')) {
      NodeServerHttp.get(`/events?owner_id=${authContext.state.user.id}`).then((res) => {
        setEvents(res);
        setLoaded(true);
      });
    } else {
      NodeServerHttp.get(`/events?lab_id=${authContext.state.user.lab_id}`).then((res) => {
        setEvents(res);
        setLoaded(true);
      });
    }
  }, [authContext]);

  /** Update events */
  useEffect(() => {
    setPlannedEvents(
      events.filter((s) => !s.active && !s.end_date && moment(s.planned_end_date).isAfter())
    );
    setCurrentEvents(
      events
        .filter(
          (s) =>
            s.active ||
            (moment(s.planned_end_date).isBefore() && moment(s.planned_end_date).isAfter())
        )
        .sort((a) => (a.active ? -1 : 1))
    );
    setPastEvents(events.filter((s) => !s.active && moment(s.end_date).isBefore()));
  }, [events]);

  return (
    <Container fluid>
      <Row
        style={{
          position: 'relative',
          zIndex: 1,
        }}
      >
        <Col className="text-right">
          <Link to="/admin/events/create">
            <Button color="primary" className="mr-2">
              <i className="fas fa-plus mr-2" />
              {t('New event')}
            </Button>
          </Link>
          <Button
            color="secondary"
            disabled
            style={{
              cursor: 'default',
              visibility: 'hidden',
            }}
          >
            <i className="fas fa-filter mr-2" />
            {t('Filter')}
          </Button>
          {/* <FilterBar /> */}
        </Col>
      </Row>
      {!loaded && (
        <Row
          style={{
            width: '100%',
            marginTop: '30vh',
          }}
        >
          <Col />
          <Col>
            <Row>
              <Col />
              <Col>
                <div className="text-center">{tCommon('Loading')}...</div>
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <div className="text-center">
                  <div className={`${css.comLoading}`}>
                    <div className={css.comSpinning}>
                      <FontAwesomeIcon className="pt-1 mb-1" icon={faCircleNotch} />
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Col>
          <Col />
        </Row>
      )}
      <Row className="m-0 mt--4">
        <EventList
          loaded={loaded}
          events={currentEvents}
          title={t('Current events')}
          titleIsWhite
          deleteEvent={setDeletingEvent}
          updateEvent={updateEvent}
          isCurrent
        />
        <EventList
          numberOfCurrentEvents={currentEvents?.length}
          loaded={loaded}
          events={plannedEvents}
          title={t('Planned events')}
          titleIsWhite={currentEvents.length === 0}
          deleteEvent={setDeletingEvent}
          updateEvent={updateEvent}
          isPlanned
        />
        <EventList
          loaded={loaded}
          events={pastEvents}
          title={t('Closed events')}
          titleIsWhite={currentEvents.length === 0 && plannedEvents.length === 0}
          deleteEvent={setDeletingEvent}
          updateEvent={updateEvent}
          isPast
        />
      </Row>
      <Modal
        isOpen={deletingEvent !== ''}
        toggle={() => setDeletingEvent('')}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={() => setDeletingEvent('')}>{t('Cancel event')}</ModalHeader>
        <ModalBody>
          {`${t('Are you sure you want to cancel')} '${
            deletingEvent && events.find((event) => event.id === deletingEvent).name
          }'?`}
          <br />
          {t('This action is irreversible')}.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => deleteEvent(deletingEvent)}>
            {t('Cancel event')}
          </Button>
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={closingEvent !== ''}
        toggle={() => setClosingEvent('')}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={() => setClosingEvent('')}>{t('Close event')}</ModalHeader>
        <ModalBody>
          {`${t('Are you sure you want to close')} '${
            closingEvent &&
            events.some((event) => event.id === closingEvent.id) &&
            events.find((event) => event.id === closingEvent.id).name
          }'?`}
          <br />
          {t('This action is irreversible')}.
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={() => updateEvent(closingEvent.id, closingEvent.data)}>
            {t('Close event')}
          </Button>
        </ModalFooter>
      </Modal>
    </Container>
  );
};

export default ListEvents;
