import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, useLocation, useHistory } from 'react-router-dom';
import { Spinner } from 'reactstrap';
import queryString from 'query-string';

import 'i18n';
import routes from 'routes';
import { useAuthContext, reconnect, login } from 'context/authContext';
import { NotificationProvider } from 'context/notificationContext';
import PrivateRoute from 'components/common/PrivateRoute';
import Notifications from 'components/common/Notifications';

import 'simplebar/dist/simplebar.min.css';
import 'App.css';
import { useSessionsContext } from 'context/sessionContext';
import { useThemeContext } from 'context/themeContext';

import { GoogleOAuthProvider, GoogleLogin } from '@react-oauth/google';
import { io as openSocket } from 'socket.io-client';

const displayPublicRoute = (route, key) => (
  <Route key={key} exact={route.exact} path={route.path} component={route.component} />
);

const displayPrivateRoute = (route, key) => (
  <PrivateRoute
    key={key}
    exact={route.exact}
    path={route.path}
    moduleName={route.moduleName}
    permissionsRequired={route.permissions}
    render={(props) =>
      route.layout ? (
        <route.layout {...props} route={route}>
          {
            /* eslint-disable no-use-before-define */
            route.routes.map(displayRoute)
            /* eslint-enable no-use-before-define */
          }
        </route.layout>
      ) : (
        <route.component {...props} />
      )
    }
  />
);

const displayRoute = (route, key) =>
  route.public ? displayPublicRoute(route, key) : displayPrivateRoute(route, key);

const App = () => {
  const themeContext = useThemeContext();
  const authContext = useAuthContext();
  const sessionContext = useSessionsContext();
  const location = useLocation();
  const history = useHistory();
  const [firstLoad, setFirstLoad] = useState(true);
  const [appRoutes, setAppRoutes] = useState(routes);
  const [themeEnabled, setThemeEnabled] = useState(false);
  const [socket, setSocket] = useState(null);

  useEffect(() => {
    const socketConnection = openSocket(process.env.REACT_APP_API_URI_NODE, {
      auth: {
        token: process.env.REACT_APP_NODE_API_TOKEN,
      },
    }).emit('connection');
    setSocket(socketConnection);
  }, []);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    const opentokSessionHelper = sessionContext.state.helper;
    const opentokSessionParticipantData = sessionContext.state.participant;

    if (!opentokSessionHelper) {
      return null;
    }

    opentokSessionHelper.on('sessionDisconnected', (event) => {
      if (opentokSessionParticipantData) {
        socket.emit('opentok-session-disconnected', {
          event: opentokSessionParticipantData.event_id,
          email: opentokSessionParticipantData.email,
          participantId: opentokSessionParticipantData.id,
          participantName: opentokSessionParticipantData.name,
          participantRole: opentokSessionParticipantData.role,
          roomId: opentokSessionParticipantData.room_id,
          disconnectReason: event.reason,
        });
      }
    });
  }, [sessionContext.state.helper, sessionContext.state.participant, socket]);

  useEffect(() => {
    themeContext.state.then((resolved) => {
      setThemeEnabled(resolved.enabled);
    });
  }, [themeContext.state]);

  useEffect(async () => {
    const invitation = queryString.parse(location.search)?.invitation;
    const calendar = queryString.parse(location.search)?.calendar;
    const eventId = queryString.parse(location.search)?.e;
    const guestParticipantId = queryString.parse(location.search)?.i;

    if (calendar) {
      history.push(`/my-calendar`, { eventID: eventId, participantId: guestParticipantId });
    }

    if (invitation) {
      login(
        authContext,
        {
          invitation,
        },
        eventId
      ).then(() => history.push('/event'));
    } else if (localStorage.getItem('token') && !authContext.state.isLogged) {
      reconnect(authContext);
    }
    setFirstLoad(false);
    // eslint-disable-next-line
  }, [history, location.search]);

  /**
   * Filter the routes according to the lab's modules
   */
  useEffect(() => {
    const filterSubRoutes = (route) => {
      if (route.routes) {
        route = {
          ...route,
          routes: route.routes.filter(filterSubRoutes),
        };
      }
      return !(
        typeof route.moduleName === 'string' &&
        !!sessionContext.state.lab &&
        !sessionContext.state.lab.modules.some((module) => module.name === route.moduleName)
      );
    };
    setAppRoutes(routes.filter(filterSubRoutes));
  }, [sessionContext.state.lab, setAppRoutes]);

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLOUD_CLIENT_ID}>
      <div className="App position-relative">
        <NotificationProvider>
          <Notifications />
          {!firstLoad && !authContext.state.reconnecting && themeEnabled && (
            <Switch>
              {appRoutes.map(displayRoute)}
              <Redirect from="*" to="/" />
            </Switch>
          )}
          {(firstLoad || !themeEnabled) && (
            <div className="w-100 d-flex justify-content-center pt-5">
              <Spinner
                style={{
                  color: '#6c757d',
                }}
              />
            </div>
          )}
        </NotificationProvider>
      </div>
    </GoogleOAuthProvider>
  );
};
export default App;
