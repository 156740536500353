import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import { useCookies } from 'react-cookie';
import '../../../assets/css/talking.css';

import '@opentok/client';
import {
  useSessionsContext,
  SET_VIEWER_SPEAKING,
  ADD_VIEWER,
  SET_OPENTOK_STREAM_ID,
  SET_PUBLISHER,
  SET_CURRENT_AUDIO_INPUT,
  SET_CURRENT_VIDEO_INPUT,
  SET_OT_PUBLISHER_HTML_ELEMENT,
} from 'context/sessionContext';

import 'assets/css/opentok-session.css';

const Viewer = (props) => {
  const [t] = useTranslation();
  const { dispatch, state } = useSessionsContext();
  const {
    isMe,
    id,
    name,
    height,
    tokSession,
    stream,
    activeAudioInputDevice,
    activeVideoInputDevice,
    participant,
  } = props;
  const [bg, setBg] = useState(null);
  const otPublisher = useRef();
  const otSubscriber = useRef();
  const [cookies] = useCookies(['publishAudio', 'publishVideo']);
  const [publisher, setPublisher] = useState(state.publisher);
  const [publisherCurrentAudioLevel, setPublisherCurrentAudioLevel] = useState(null);
  const [subscriberCurrentAudioLevel, setSubscriberCurrentAudioLevel] = useState(null);

  useEffect(() => {
    setPublisher(state.publisher);
  }, [state.publisher, tokSession]);

  const toggleAudio = useCallback(
    (force) => {
      if (isMe && publisher?.session) {
        const str = publisher.session.streams.get(publisher.streamId);
        if (!str) {
          return;
        }
        publisher.publishAudio(!str.hasAudio);
        tokSession.signal({
          type: 'user-toggle-audio',
          data: !str.hasAudio,
        });
        document.body.dispatchEvent(
          new CustomEvent('user-toggled-audio', {
            detail: {
              enabled: typeof force === 'boolean' ? force : !str.hasAudio,
              id,
            },
          })
        );
      }
    },
    [isMe, publisher, tokSession, id]
  );

  const toggleVideo = useCallback(
    (force) => {
      if (isMe && publisher?.session) {
        const str = publisher.session.streams.get(publisher.streamId);
        if (!str) {
          return;
        }
        publisher.publishVideo(!str.hasVideo);
        tokSession.signal({
          type: 'user-toggle-video',
          data: !str.hasVideo,
        });
        document.body.dispatchEvent(
          new CustomEvent('user-toggled-video', {
            detail: {
              enabled: typeof force === 'boolean' ? force : !str.hasVideo,
              id,
            },
          })
        );
      }
    },
    [isMe, publisher, tokSession, id]
  );

  const videoEnabled = useCallback(() => {
    document.body.dispatchEvent(
      new CustomEvent('user-toggled-video', {
        detail: {
          enabled: true,
          id,
        },
      })
    );
  }, [id]);

  const videoDisabled = useCallback(() => {
    document.body.dispatchEvent(
      new CustomEvent('user-toggled-video', {
        detail: {
          enabled: false,
          id,
        },
      })
    );
  }, [id]);

  const audioLevelUpdated = useCallback(
    ({ audioLevel }) => {
      dispatch({ type: SET_VIEWER_SPEAKING, payload: { id, speaking: audioLevel > 0.02 } });
    },
    [id, dispatch]
  );

  const accessDenied = useCallback(() => {
    document.body.dispatchEvent(
      new CustomEvent('user-toggled-video', {
        detail: {
          enabled: false,
          id,
        },
      })
    );
    document.body.dispatchEvent(
      new CustomEvent('user-toggled-audio', {
        detail: {
          enabled: false,
          id,
        },
      })
    );
  }, [id]);

  /**
   * Called on every user, when a user toggles their audio. Unlike for the video, OpenTOK doesn't have a "videoDisabled" callback, so we have to use this.
   */
  const onUserToggledAudio = useCallback(
    (e) => {
      if (e.from.id === id) {
        document.body.dispatchEvent(
          new CustomEvent('user-toggled-audio', {
            detail: {
              enabled: e.data,
              id,
            },
          })
        );
      }
    },
    [id]
  );

  const onUserToggledVideo = useCallback(
    (e) => {
      if (e.from.id === id) {
        document.body.dispatchEvent(
          new CustomEvent('user-toggled-video', {
            detail: {
              enabled: e.data,
              id,
            },
          })
        );
      }
    },
    [id]
  );

  const onAdminForceAudio = useCallback(
    (e) => {
      try {
        const str = publisher.session.streams.get(publisher.streamId);

        if (str.connection.id === e.data) {
          publisher.publishAudio(!str.hasAudio);
          toggleAudio(!str.hasAudio);
        }
      } catch (exception) {
        console.log(exception);
      }
    },
    [id, publisher, toggleAudio]
  );

  const onAdminForceVideo = useCallback(
    (e) => {
      try {
        const str = publisher.session.streams.get(publisher.streamId);

        if (str.connection.id === e.data) {
          publisher.publishVideo(!str.hasVideo);
          toggleVideo(!str.hasVideo);
        }
      } catch (exception) {
        console.log(exception);
      }
    },
    [id, publisher, toggleVideo]
  );

  useEffect(() => {
    tokSession.on('signal:user-toggle-audio', onUserToggledAudio);
    tokSession.on('signal:user-toggle-video', onUserToggledVideo);

    tokSession.on('signal:force-toggle-audio', onAdminForceAudio);
    tokSession.on('signal:force-toggle-video', onAdminForceVideo);
    document.body.addEventListener('toggle-audio', toggleAudio);
    document.body.addEventListener('toggle-video', toggleVideo);
    return () => {
      tokSession.off('signal:user-toggle-audio', onUserToggledAudio);
      tokSession.off('signal:force-toggle-audio', onAdminForceAudio);
      tokSession.off('signal:force-toggle-video', onAdminForceVideo);
      document.body.removeEventListener('toggle-audio', toggleAudio);
      document.body.removeEventListener('toggle-video', toggleVideo);
    };
  }, [
    setBg,
    name,
    toggleAudio,
    toggleVideo,
    tokSession,
    onAdminForceAudio,
    onAdminForceVideo,
    onUserToggledAudio,
    onUserToggledVideo,
  ]);

  useEffect(() => {
    if (otPublisher?.current && !publisher?.streamId) {
      console.log('je passe dans publishier');
      console.log(activeAudioInputDevice ? JSON.parse(activeAudioInputDevice) : null);
      console.log(activeVideoInputDevice ? JSON.parse(activeVideoInputDevice) : null);

      const pub = OT.initPublisher(otPublisher.current, {
        audioSource: activeAudioInputDevice ? JSON.parse(activeAudioInputDevice) : null,
        videoSource: activeVideoInputDevice ? JSON.parse(activeVideoInputDevice) : null,
        mirror: false,
        audioFallbackEnabled: false,
        audioVolume: 100,
        audioBitrate: 64000,
        videoBitrate: 200000,
        showControls: false,
        noiseSuppression: true,
        echoCancellation: true,
        publishVideo: cookies.publishVideo !== 'false',
        publishAudio: cookies.publishAudio !== 'false',
      });

      setPublisher(pub);
      tokSession.publish(pub, (error) => {
        if (error) {
          console.log(error);
        } else {
          dispatch({
            type: SET_OPENTOK_STREAM_ID,
            payload: pub.stream.id,
          });
          dispatch({
            type: SET_PUBLISHER,
            payload: pub,
          });
          dispatch({
            type: SET_CURRENT_AUDIO_INPUT,
            payload: JSON.parse(activeAudioInputDevice),
          });
          dispatch({
            type: SET_CURRENT_VIDEO_INPUT,
            payload: JSON.parse(activeVideoInputDevice),
          });
          dispatch({
            type: SET_OT_PUBLISHER_HTML_ELEMENT,
            payload: otPublisher,
          });
        }
      });
    }
  }, [
    tokSession,
    cookies.publishVideo,
    cookies.publishAudio,
    publisher,
    dispatch,
    activeVideoInputDevice,
    activeAudioInputDevice,
  ]);

  useEffect(() => {
    if (otSubscriber?.current && !!stream) {
      if (stream.videoType !== 'screen') {
        const subscribe = tokSession.subscribe(
          stream,
          `subscriber-${id}`,
          {
            insertMode: 'replace',
            width: '100%',
            height: '100%',
            audioFallbackEnabled: false,
            showControls: false,
            audioVolume: 100,
            noiseSuppression: true,
            echoCancellation: true,
            subscribeToAudio: true,
            subscribeToVideo: stream.videoType === 'camera',
          },
          (error) => {
            if (error) {
              console.log('subscriber error');
              console.log(error.message);
            } else {
              console.log(`Subscribed to stream: ${stream.id}`);
            }
          }
        );
        subscribe.setAudioVolume(100);
      }
    }
  }, [id, stream, tokSession]);

  useEffect(() => {
    if (publisher) {
      let movingAvg = null;
      publisher.on('audioLevelUpdated', (event) => {
        if (movingAvg === null || movingAvg <= event.audioLevel) {
          movingAvg = event.audioLevel;
        } else {
          movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
        }

        // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
        let logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
        logLevel = Math.min(Math.max(logLevel, 0), 1);

        setPublisherCurrentAudioLevel(logLevel);
      });
    }
  }, [publisher]);

  useEffect(() => {
    if (stream) {
      if (!!tokSession && JSON.parse(stream.connection.data).participant_id !== participant.id) {
        const subscribedStream = tokSession.getSubscribersForStream(stream);
        subscribedStream.forEach((sb) => {
          let movingAvg = null;

          sb.on('audioLevelUpdated', (event) => {
            if (movingAvg === null || movingAvg <= event.audioLevel) {
              movingAvg = event.audioLevel;
            } else {
              movingAvg = 0.7 * movingAvg + 0.3 * event.audioLevel;
            }

            // 1.5 scaling to map the -30 - 0 dBm range to [0,1]
            let logLevel = Math.log(movingAvg) / Math.LN10 / 1.5 + 1;
            logLevel = Math.min(Math.max(logLevel, 0), 1);

            setSubscriberCurrentAudioLevel({
              logLevel,
              id,
            });
          });
        });
      }
    }
  }, [tokSession, stream, id, publisher, participant]);

  const switchCamera = useCallback(() => {
    publisher.cycleVideo().then((device) => {
      console.log('switch camera');
    });
  }, [publisher]);

  return (
    <div
      key={id}
      className={classnames(
        {
          active: isMe,
        },
        'text-light',
        'position-relative',
        'mb-3',
        'w-100',
        'overflow-hidden',
        'rounded'
      )}
      style={{
        height,
        minHeight: height,
        backgroundColor: '#000000',
      }}
    >
      <div
        className="position-absolute overflow-hidden"
        style={{
          bottom: 0,
          left: 0,
          right: 0,
          top: 0,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundImage: bg,
        }}
      >
        <div
          style={{ height: '100%' }}
          className={
            publisherCurrentAudioLevel > 0.0000000000000000005
              ? 'publisher-talking shadow-speaker'
              : null
          }
        >
          {process.env.REACT_APP_STREAM_ENABLED === 'true' && isMe && (
            <div id="publisher" ref={otPublisher} />
          )}
        </div>
      </div>

      <div
        style={{ height: '100%' }}
        className={
          !!subscriberCurrentAudioLevel &&
          subscriberCurrentAudioLevel.logLevel > 0.0000000000000000005 &&
          subscriberCurrentAudioLevel.id === id
            ? 'subscriber-talking shadow-speaker'
            : null
        }
      >
        {!isMe && <div id={`subscriber-${id}`} ref={otSubscriber} />}
        {/* stream && <div key={stream.id} id={`subscriber-${stream.id}`} ref={otSubscriber} /> */}
      </div>

      <span
        className="position-absolute d-inline-block text-truncate pt-1 pb-2 font-weight-bold bg-dark"
        style={{
          bottom: 0,
          left: 0,
          height: 20,
          opacity: 0.85,
          boxSizing: 'content-box',
          width: '100%',
        }}
      >
        <div className="container">
          <div className="row">
            <div className="col-lg-6 -pull-left">{isMe ? t('Me') : name}</div>

            <div className="col-lg-3">
              {!isMe &&
                !!subscriberCurrentAudioLevel &&
                subscriberCurrentAudioLevel.logLevel > 0.0000000000000000005 && (
                  <svg width="32" height="32" viewBox="0 0 24 24">
                    <path
                      fill="#ffffff"
                      d="m22 12l-2 1l-1 1l-1-1l-1
                3l-1-3l-1 8l-1-8l-1 2l-1-2l-1 4l-1-4l-1 9l-1-9l-1 6l-1-6l-1 1l-1-1l-2-1l2-1l1-1l1 1l1-6l1 6l1-9l1
                9l1-4l1 4l1-2l1 2l1-8l1 8l1-3l1 3l1-1l1 1l2 1Z"
                    />
                  </svg>
                )}
              {isMe && publisherCurrentAudioLevel > 0.0000000000000000005 && (
                <svg width="32" height="32" viewBox="0 0 24 24">
                  <path
                    fill="#ffffff"
                    d="m22 12l-2 1l-1 1l-1-1l-1
                3l-1-3l-1 8l-1-8l-1 2l-1-2l-1 4l-1-4l-1 9l-1-9l-1 6l-1-6l-1 1l-1-1l-2-1l2-1l1-1l1 1l1-6l1 6l1-9l1
                9l1-4l1 4l1-2l1 2l1-8l1 8l1-3l1 3l1-1l1 1l2 1Z"
                  />
                </svg>
              )}
            </div>
            <div className="col-lg-3">
              {isMe && (
                <button type="button" onClick={switchCamera}>
                  <svg width="1em" height="1em" viewBox="0 0 24 24">
                    <path
                      fill="currentColor"
                      d="M15 15.5V13H9v2.5L5.5 12L9 8.5V11h6V8.5l3.5 3.5M20
               4h-3.17L15 2H9L7.17 4H4a2 2 0 0 0-2 2v12a
               2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
                    />
                  </svg>
                </button>
              )}
            </div>
          </div>
        </div>
      </span>
    </div>
  );
};

export default Viewer;
