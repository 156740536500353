import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Col, Container, Row } from 'reactstrap';

import { useThemeContext, actions } from 'context/themeContext';
import { useSessionsContext, SET_LAB } from 'context/sessionContext';
import { useAuthContext } from 'context/authContext';
import http from 'utils/http';
import AdminSidebar from './AdminSidebar';
import AdminNavbar from './AdminNavbar';

const AdminLayout = (props) => {
  const { route, children } = props;
  const { routes } = route;
  const [sidenavOpen, setSidenavOpen] = useState(true);
  const mainContent = useRef();
  const history = useHistory();
  const { state, dispatch } = useSessionsContext();
  const authContext = useAuthContext();
  const themeDispatch = useThemeContext().dispatch;

  useEffect(() => {
    if (!mainContent.current) {
      return null;
    }
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    mainContent.current.scrollTop = 0;
    document.querySelector('.App').classList.add('back-office');
    document.body.classList.add('g-sidenav-hidden');
    return () => {
      document.querySelector('.App').classList.remove('back-office');
    };
  }, [history.pathname, mainContent]);

  /**
   * Toggles collapse between mini sidenav and normal
   */
  const toggleSidenav = useCallback(() => {
    if (document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-pinned');
      document.body.classList.add('g-sidenav-hidden');
    } else {
      document.body.classList.add('g-sidenav-pinned');
      document.body.classList.remove('g-sidenav-hidden');
    }
    setSidenavOpen(!sidenavOpen);
  }, [sidenavOpen, setSidenavOpen]);

  // const closeSidenav = useCallback(() => {
  //   document.body.classList.add('g-sidenav-pinned');
  //   document.body.classList.remove('g-sidenav-hidden');
  // }, []);

  /**
   * Fetch lab data
   */
  useEffect(() => {
    if (authContext.state.lab) {
      dispatch({
        type: SET_LAB,
        payload: authContext.state.lab,
      });
      themeDispatch({
        type: actions.SET_LAB,
        payload: authContext.state.lab,
      });
    }

    /*    http.get('current-lab').then((response) => {
      dispatch({
        type: SET_LAB,
        payload: response,
      });
      themeDispatch({
        type: actions.SET_LAB,
        payload: response,
      });
    }); */
  }, [dispatch, themeDispatch, authContext]);

  useEffect(() => {
    if (history.location.pathname === '/admin' || history.location.pathname === '/admin/') {
      history.push('/admin/events');
    }
  }, [history]);

  return (
    <>
      {!!routes && !!state.lab && !authContext.state.user.roles.includes('user') && (
        <AdminSidebar
          {...props}
          routes={routes.filter((value) =>
            state.lab.modules.some(
              (module) => module.name === value.moduleName || !value.moduleName
            )
          )}
          toggleSidenav={toggleSidenav}
          sidenavOpen={sidenavOpen}
          currentLab={authContext.state.lab}
        />
      )}
      <div className="main-content" ref={mainContent}>
        {!!authContext.state.lab && (
          <Container fluid className="pb-3 second-color">
            <div
              style={{
                top: 0,
                left: 0,
                right: 0,
                minHeight: '500px',
                background: authContext.state.lab.header_image
                  ? `url(${process.env.REACT_APP_API_URI_NODE}${authContext.state.lab.header_image.url}) no-repeat center fixed`
                  : authContext.state.lab.third_color,
                backgroundSize: 'cover',
              }}
              className="position-absolute bg-default"
            >
              <span
                className={
                  authContext.state.lab.header_image
                    ? 'mask bg-gradient-dark opacity-5 third-color'
                    : 'mask bg-gradient-dark third-color'
                }
              />
            </div>
            <Row className="mb-6">
              <Col>
                <AdminNavbar {...props} toggleSidenav={toggleSidenav} sidenavOpen={sidenavOpen} />
              </Col>
            </Row>
            <Row className="m-0">
              <Col>{children}</Col>
            </Row>
          </Container>
        )}
      </div>
      {sidenavOpen && (
        <div className="backdrop d-xl-none" role="button" tabIndex={0} onClick={toggleSidenav} />
      )}
    </>
  );
};

export default AdminLayout;
AdminLayout.propTypes = {
  route: PropTypes.object.isRequired,
  children: PropTypes.array,
};
