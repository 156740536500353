import React, { useCallback, useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import { Col } from 'reactstrap';
import {
  useSessionsContext,
  SET_SHARE_SCREEN,
  SET_SHARE_SCREEN_PUBLISHER,
} from 'context/sessionContext';
import Demo from 'components/front/room/Demo';
import Vectibix from 'components/front/room/Vectibix';
import Ambassadeurs from 'components/front/room/Ambassadeurs';
import UnityGame from 'components/front/room/UnityGame';
import StreamsColumn from 'components/front/room/StreamsColumn';
import Quiz from './JanssenQuiz';
import QuizV2 from './JanssenQuizV2';
import SmartRxGame from './smartrx';

const OT = require('@opentok/client');

const Presentation = (props) => {
  const {
    sessionHelper,
    isAnimator,
    medias,
    participant,
    sessionInfos,
    eventID,
    socket,
    activeAudioInputDevice,
    activeVideoInputDevice,
  } = props;
  const { state, dispatch } = useSessionsContext();
  const { shareScreen, shareScreenPublisher } = state;
  const [stream, setStream] = useState(null);
  const screenSharePublisher = useRef();
  const screenShareSubscriber = useRef();

  const onStreamCreated = useCallback(
    (e) => {
      if (e.stream.videoType === 'screen') {
        setStream(e.stream);

        const subOptions = {
          insertMode: 'replace',
          width: '100%',
          height: '100%',
          fitMode: 'contain',
        };

        const subscriber = sessionHelper.subscribe(
          e.stream,
          screenShareSubscriber.current,
          subOptions
        );
      }
    },
    [setStream, sessionHelper]
  );

  const onStreamDestroyed = useCallback(
    (e) => {
      if (e.stream.videoType === 'screen') {
        setStream(null);
      }
    },
    [setStream]
  );

  const stopShareScreen = useCallback(() => {
    dispatch({
      type: SET_SHARE_SCREEN,
      payload: false,
    });
  }, [dispatch]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (isAnimator) {
      return null;
    }
    /*
    const publisher = OT.initPublisher('publisher', {
      insertMode: 'replace',
      width: '100%',
      height: '20%',
      fitMode: 'contain',
      name: 'Test User',
      publishVideo: true,
      publishAudio: true,
    });

    if (sessionHelper.isConnected()) {
      sessionHelper.publish(publisher);
    } */

    sessionHelper.on('streamCreated', (event) => {
      onStreamCreated(event);
    });

    sessionHelper.on('streamDestroyed', (event) => {
      onStreamDestroyed(event);
    });
  }, [sessionHelper, isAnimator, onStreamCreated, onStreamDestroyed, sessionInfos]);

  useEffect(() => {
    sessionHelper.on('streamPropertyChanged', (event) => {
      const logEventData = {
        level: 'info',
        message: 'STREAM_PROPERTY_CHANGED',
        meta: {
          service: 'opentok-meeting-room',
          user: event.stream.connection.data,
          changedProperty: event.changedProperty,
          newValue: event.newValue,
          oldValue: event.oldValue,
        },
      };

      socket.emit('log', logEventData);
    });
  }, [sessionHelper]);

  useEffect(() => {
    if (isAnimator && shareScreen && !shareScreenPublisher && !stream) {
      OT.checkScreenSharingCapability((response) => {
        if (!response.supported || response.extensionRegistered === false) {
          // This browser does not support screen sharing.
        } else if (response.extensionInstalled === false) {
          // Prompt to install the extension.
        } else {
          // Screen sharing is available. Publish the screen.
          const pub = OT.initPublisher(screenSharePublisher.current, {
            videoSource: 'screen',
            showControls: false,
            insertMode: 'replace',
            width: '100%',
            height: '100%',
            fitMode: 'contain',
          });

          sessionHelper.publish(pub, (error) => {
            if (error) {
              console.log(error);
            } else {
              console.log(pub.stream.id);
              setStream(pub.stream);

              dispatch({
                type: SET_SHARE_SCREEN,
                payload: true,
              });

              dispatch({
                type: SET_SHARE_SCREEN_PUBLISHER,
                payload: pub,
              });
            }
          });
        }
      });
    }
  }, [isAnimator, shareScreen, sessionHelper, sessionInfos, stream, shareScreenPublisher]);

  /**
   * If SwitchPresentation, share screen stopped automatically. Check PresentationControls.jsx file
   */
  useEffect(() => {
    if (!shareScreen && !!shareScreenPublisher) {
      shareScreenPublisher.destroy();
      dispatch({
        type: SET_SHARE_SCREEN_PUBLISHER,
        payload: null,
      });
      setStream(null);
    }
  }, [shareScreen, shareScreenPublisher]);

  return (
    <>
      <div className="d-flex h-100 overflow-hidden">
        <Col
          className={classnames(
            'bg-light justify-content-center align-items-center position-relative overflow-hidden h-100 p-0 d-flex'
          )}
        >
          {process.env.REACT_APP_DISABLE_LOAD_GAME !== 'true' && (
            <>
              {medias[0].slug === 'demo' ? (
                <Demo
                  sessionHelper={sessionHelper}
                  show={isAnimator || !stream}
                  media={medias && medias[0]}
                  {...props}
                />
              ) : medias[0].slug === 'rp-ambassadeurs' ? (
                <Ambassadeurs show={isAnimator || !stream} media={medias && medias[0]} {...props} />
              ) : medias[0].slug === 'h-investigation' ? (
                <UnityGame
                  show={isAnimator || !stream}
                  media={medias && medias[0]}
                  participant={participant}
                  eventID={eventID}
                  {...props}
                />
              ) : medias[0].slug === 'janssen-quiz' ? (
                <Quiz
                  participant={participant}
                  show={isAnimator || !stream}
                  media={medias && medias[0]}
                  {...props}
                />
              ) : medias[0].slug === 'janssen-quiz-v2' ? (
                <QuizV2
                  participant={participant}
                  show={isAnimator || !stream}
                  media={medias && medias[0]}
                  {...props}
                />
              ) : medias[0].slug === 'smartrx' ? (
                <SmartRxGame
                  participant={participant}
                  show={isAnimator || !stream}
                  media={medias && medias[0]}
                  {...props}
                />
              ) : (
                <Vectibix show={isAnimator || !stream} media={medias && medias[0]} {...props} />
              )}
            </>
          )}

          {isAnimator && shareScreen && (
            <div
              style={{ width: '100%', boxSizing: 'border-box' }}
              id="screen-preview"
              ref={screenSharePublisher}
            />
          )}

          {!isAnimator && !!stream && stream.videoType === 'screen' && (
            <div
              style={{ width: '100%', boxSizing: 'border-box' }}
              id="screen-subscriber"
              ref={screenShareSubscriber}
            />
          )}
        </Col>

        {!!state.activeModules.includes('active_camera') && (
          <StreamsColumn
            sessionHelper={sessionHelper}
            medias={medias}
            activeAudioInputDevice={activeAudioInputDevice}
            activeVideoInputDevice={activeVideoInputDevice}
          />
        )}
      </div>
    </>
  );
};

export default Presentation;
Presentation.propTypes = {
  sessionHelper: PropTypes.object,
  isAnimator: PropTypes.bool,
  medias: PropTypes.array,
};
