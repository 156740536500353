import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { debugContextDevtool } from 'react-context-devtool';
import * as serviceWorker from 'serviceWorker';

import App from 'App';
import { AuthProvider } from 'context/authContext';
import { SessionProvider } from 'context/sessionContext';
import { ThemeProvider } from 'context/themeContext';

// plugins styles from node_modules
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-notification-alert/dist/animate.css';
import 'react-perfect-scrollbar/dist/css/styles.css';
// plugins styles downloaded
import 'assets/vendor/fullcalendar/dist/fullcalendar.min.css';
import 'assets/vendor/sweetalert2/dist/sweetalert2.min.css';
import 'assets/vendor/select2/dist/css/select2.min.css';
import 'assets/vendor/quill/dist/quill.core.css';
import 'assets/vendor/nucleo/css/nucleo.css';
import 'assets/vendor/@fortawesome/fontawesome-free/css/all.min.css';
// core styles
import 'assets/scss/argon-dashboard-pro-react.scss?v1.1.0';
import 'index.css';

const container = document.getElementById('root');

ReactDOM.render(
  <AuthProvider>
    <SessionProvider>
      <BrowserRouter>
        <ThemeProvider>
          <App />
        </ThemeProvider>
      </BrowserRouter>
    </SessionProvider>
  </AuthProvider>,
  container,
);

if (process.env.NODE_ENV === 'development') {
  try {
    debugContextDevtool(container);
  } catch (error) {
    console.warn('debugContextDevtool extension cannot load.');
  }
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
