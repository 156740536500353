import axios from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_URI;

axios.defaults.headers.common.Authorization = localStorage.getItem('token');

class Http {
  async get(resource) {
    return axios.get(resource).then((res) => res.data);
  }

  async post(resource, data, config) {
    return axios.post(resource, data, config).then((res) => res.data);
  }

  async put(resource, data) {
    return axios.put(resource, data).then((res) => res.data);
  }

  async delete(resource, id) {
    return axios.delete(resource, id).then((res) => res.data);
  }

  setToken(token) {
    axios.defaults.headers.common.Authorization = token;
    localStorage.setItem('token', token);
  }

  async download(url, filename) {
    return axios
      .request({
        url,
        method: 'GET',
        responseType: 'blob',
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
}

export default new Http();
