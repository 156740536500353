/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

// reactstrap components
import { Navbar, Nav, Container } from 'reactstrap';
import UserBanner from 'components/common/UserBanner';

const AdminNavbar = ({ brandText }) => (
  <>
    <Navbar
      className="navbar-top navbar-dark position-absolute"
      style={{
        zIndex: 10,
        top: 0,
        right: 0,
      }}
      expand="md"
      id="navbar-main"
    >
      <Container fluid>
        <Link className="h4 mb-0 text-white text-uppercase d-none d-lg-inline-block" to="/">
          {brandText}
        </Link>
        {/* <Form className='navbar-search navbar-search-dark form-inline mr-3 d-none d-md-flex ml-lg-auto'>
              <FormGroup className='mb-0'>
                <InputGroup className='input-group-alternative'>
                  <InputGroupAddon addonType='prepend'>
                    <InputGroupText>
                      <i className='fas fa-search' />
                    </InputGroupText>
                  </InputGroupAddon>
                  <Input placeholder='Search' type='text' />
                </InputGroup>
              </FormGroup>
            </Form> */}
        <Nav className="align-items-center d-none d-md-flex" navbar>
          <UserBanner />
        </Nav>
      </Container>
    </Navbar>
  </>
);

export default AdminNavbar;
AdminNavbar.propTypes = {
  brandText: PropTypes.string,
};
