import React, { useCallback, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import http from 'utils/http';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logo-vtopia.svg';
import logoV from 'assets/img/logo-v.svg';
import { useThemeContext } from 'context/themeContext';
import Footer from 'components/front/footers/Footer';
import css from './password.module.scss';
import 'App.css';

const ForgotPassword = () => {
  const [t] = useTranslation('front');
  const { logo: labLogo } = useThemeContext();
  const [formError, setFormError] = useState([]);
  const [passwordResetSucceed, setPasswordResetSucceed] = useState(false);
  const handleSubmit = useCallback(
    (e) => {
      const email = e?.target?.email.value;
      setFormError([]);
      e.preventDefault();
      if (email) {
        if (!/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email)) {
          setFormError([...formError, 'not-valid']);
        } else {
          http
            .post('forgot-password', {
              email,
            })
            .then((response) => {
              if (response.sucess) {
                setPasswordResetSucceed(true);
              }
            });
        }
      } else setFormError([...formError, 'is-empty']);
    },
    [formError]
  );

  return (
    <Container fluid className="h-100 text-center bg-default third-color">
      <Row
        className={`${css.password} justify-content-center mb-3 header pb-7 pt-7 position-relative first-color`}
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex"
          style={{
            top: 20,
          }}
        >
          <img
            src={labLogo || logo}
            draggable={false}
            style={{
              width: '60px',
              height: 'auto',
              marginRight: '10px',
            }}
            alt="Vtopia"
          />
          {/*
            <div className="badge-login">BETA</div>
          */}
        </Container>
        <Col>
          <h1 className="text-white mb-5">{t('Forgot password!')}</h1>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      {passwordResetSucceed && (
        <Container>
          <Col
            lg="5"
            md="7"
            className="mt--7"
            style={{
              margin: 'auto',
            }}
          >
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-2 mb-3">
                  <strong>{t('Email sent')}</strong>
                </div>
                <img
                  alt=""
                  src={logoV}
                  style={{
                    height: '80px',
                    width: 'auto',
                  }}
                />
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">{t('Please check your mailbox')}</CardBody>
            </Card>
          </Col>
        </Container>
      )}
      {!passwordResetSucceed && (
        <Container>
          <Col
            lg="5"
            md="7"
            className="mt--7"
            style={{
              margin: 'auto',
            }}
          >
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-2 mb-3">
                  <strong>{t('Forgot password')}</strong>
                </div>
                <img
                  alt=""
                  src={logoV}
                  style={{
                    height: '80px',
                    width: 'auto',
                  }}
                />
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup className="mb-3">
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-email-83" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t('Email address')}
                        type="email"
                        id="email"
                        name="email"
                        required
                      />
                    </InputGroup>
                    {formError.indexOf('is-empty') !== -1 && (
                      <div className="error-warning">
                        <span>{t('Please fill the email field')}</span>
                      </div>
                    )}
                    {formError.indexOf('is-empty') === -1 && formError.indexOf('not-valid') !== -1 && (
                      <div className="error-warning">
                        <span>{t('Please enter a valid email')}</span>
                      </div>
                    )}
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      {t('Reset password')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      )}
      <Footer />
    </Container>
  );
};

export default ForgotPassword;
