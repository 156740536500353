import React, { useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { Container, Button, Row, Col, CardHeader, CardBody, Card, CardFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';

// import http from 'utils/http';
import Pagination from 'components/common/Pagination';
import { ListProvider } from 'context/ListContext';
import DocumentList from './DocumentList';

const Documents = () => {
  const [t] = useTranslation('back-office');
  const [pagination, setPagination] = useState(0);
  const [maxItem, setMaxItem] = useState(5);
  const [itemSubstitutes, setItemSubstitutes] = useState([]);

  const onItemChange = useCallback(
    (value) => {
      setItemSubstitutes(Array(value).fill(0));
      const maxPagination = Math.ceil(value / maxItem);
      if (maxPagination === 0) {
        setPagination(0);
      } else if (maxPagination <= pagination) {
        setPagination(maxPagination - 1);
      }
    },
    [maxItem, pagination]
  );

  return (
    <Container fluid>
      <Row className="mb-3">
        <Col>
          <h1 className="text-white">
            <i className="fas fa-document-h mr-3" />
            {t('Manage Documents')}
          </h1>
        </Col>
        <Col className="text-right">
          <Link to="/admin/documents/create">
            <Button color="primary" className="mr-2">
              <i className="fas fa-plus mr-2" />
              {t('Add document')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card className="bg-secondary shadow">
            <CardHeader className="bg-white border-0">
              <h3>{t('Documents list')}</h3>
            </CardHeader>
            <CardBody className="bg-white">
              <ListProvider
                resource={{
                  routes: {
                    ON_LOAD: 'documents/find/by-lab',
                    ON_DELETE: 'documents',
                    ON_DELETE_MULTIPLE: 'documents/delete-multiple',
                    ON_CHANGE: 'documents',
                  },
                }}
              >
                <DocumentList
                  pagination={pagination}
                  onItemChange={onItemChange}
                  onItemCountChange={(value) => {
                    setMaxItem(value);
                    setPagination(0);
                  }}
                />
              </ListProvider>
            </CardBody>
            <CardFooter>
              <nav aria-label="...">
                <Pagination
                  items={itemSubstitutes}
                  pagination={pagination}
                  setPagination={setPagination}
                  maxItem={maxItem}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Documents;
