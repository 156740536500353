import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { useSessionsContext } from 'context/sessionContext';
import { Player } from '@lottiefiles/react-lottie-player';

import { ReactComponent as CameraOn } from 'assets/img/icons/camera-on.svg';
import { ReactComponent as CameraOff } from 'assets/img/icons/camera-off.svg';
import { ReactComponent as MicrophoneOn } from 'assets/img/icons/microphone-on.svg';
import { ReactComponent as MicrophoneOff } from 'assets/img/icons/microphone-off.svg';
import v from 'assets/img/v.svg';
import { Colors } from 'Constants';
import micro from 'assets/microphone.json';

const ViewersListItem = (props) => {
  const { id, name, role, isMe, userIsAnimator, tokSession, stream, style, speaking } = props;
  const { state } = useSessionsContext();
  const { activeModules } = state;
  const [t] = useTranslation();
  const [cookies] = useCookies(['publishAudio', 'publishVideo']);
  const [hasAudio, setHasAudio] = useState(isMe ? cookies.publishAudio !== 'false' : true);
  const [hasVideo, setHasVideo] = useState(isMe ? cookies.publishVideo !== 'false' : true);
  const toggleAudio = useCallback(() => {
    if (isMe) {
      document.body.dispatchEvent(new Event('toggle-audio'));
    } else if (userIsAnimator) {
      tokSession.signal({
        type: 'force-toggle-audio',
        data: id,
      });
    }
  }, [isMe, userIsAnimator, tokSession, id]);

  const toggleVideo = useCallback(() => {
    if (isMe) {
      document.body.dispatchEvent(new Event('toggle-video'));
    } else if (userIsAnimator) {
      tokSession.signal({
        type: 'force-toggle-video',
        data: id,
      });
    }
  }, [isMe, userIsAnimator, tokSession, id]);

  const onAudioToggle = useCallback(
    (e) => {
      if (e.detail.id === id) {
        setHasAudio(e.detail.enabled);
      }
    },
    [id, setHasAudio]
  );

  const onVideoToggle = useCallback(
    (e) => {
      if (e.detail.id === id) {
        setHasVideo(e.detail.enabled);
      }
    },
    [id, setHasVideo]
  );

  useEffect(() => {
    document.body.addEventListener('user-toggled-audio', onAudioToggle);
    document.body.addEventListener('user-toggled-video', onVideoToggle);
    return () => {
      document.body.removeEventListener('user-toggled-audio', onAudioToggle);
      document.body.removeEventListener('user-toggled-video', onVideoToggle);
    };
  }, [onAudioToggle, onVideoToggle]);

  useEffect(() => {
    if (stream) {
      setHasAudio(stream.hasAudio);
    }
  }, [stream, stream?.hasAudio]);

  useEffect(() => {
    if (stream) {
      setHasVideo(stream.hasVideo);
    }
  }, [stream, stream?.hasVideo]);

  return (
    <ListGroupItem
      className="viewer-list-item"
      style={{
        ...styles.wrapper,
        ...style,
      }}
    >
      <div className="mr-4 avatar" style={styles.avatar}>
        <img
          src={v}
          alt=""
          style={{
            maxWidth: '60%',
            userSelect: 'none',
          }}
        />
      </div>
      <div
        className="d-flex flex-column h-100 flex-1 w-100 overflow-hidden position-relative"
        style={{
          top: 10,
        }}
      >
        <span className="font-weight-bold d-inline-block text-truncate">{name}</span>
        <span
          className="d-inline-block text-truncate"
          style={{
            color: '#8898AA',
          }}
        >
          {t(role || 'Participant')}
        </span>
      </div>
      <div className="d-flex ml-4 align-items-center">
        {hasAudio ? (
          <span className="mr-2" style={{ position: 'relative', ...styles.icon, height: 23 }}>
            <MicrophoneOn
              style={{
                cursor: isMe || userIsAnimator ? 'pointer' : 'initial',
                ...styles.icon,
                position: 'absolute',
                bottom: 0,
                left: 0,
              }}
              fill={Colors.GREY}
              onClick={isMe || userIsAnimator ? toggleAudio : null}
            />
            {speaking && (
              <Player
                autoplay
                loop
                src={micro}
                style={{
                  cursor: isMe || userIsAnimator ? 'pointer' : 'initial',
                  ...styles.icon,
                  position: 'absolute',
                  left: 0,
                  bottom: '50%',
                  transform: 'translateY(50%)',
                }}
                fill={Colors.GREY}
                onClick={isMe || userIsAnimator ? toggleAudio : null}
              />
            )}
          </span>
        ) : (
          <MicrophoneOff
            className="mr-2"
            style={{
              cursor: isMe || userIsAnimator ? 'pointer' : 'initial',
              ...styles.icon,
            }}
            fill={Colors.GREY}
            onClick={isMe || userIsAnimator ? toggleAudio : null}
          />
        )}
        {!!activeModules &&
          activeModules.includes('active_camera') &&
          (hasVideo ? (
            <CameraOn
              style={{
                cursor: isMe || userIsAnimator ? 'pointer' : 'initial',
                ...styles.icon,
              }}
              fill={Colors.GREY}
              onClick={isMe || userIsAnimator ? toggleVideo : null}
            />
          ) : (
            <CameraOff
              style={{
                cursor: isMe || userIsAnimator ? 'pointer' : 'initial',
                ...styles.icon,
              }}
              fill={Colors.GREY}
              onClick={isMe || userIsAnimator ? toggleVideo : null}
            />
          ))}
      </div>
    </ListGroupItem>
  );
};

export default ViewersListItem;
ViewersListItem.propTypes = {
  tokSession: PropTypes.object.isRequired,
  stream: PropTypes.object,
  style: PropTypes.object,
  id: PropTypes.string,
  name: PropTypes.string,
  role: PropTypes.string,
  userIsAnimator: PropTypes.bool,
  isMe: PropTypes.bool,
};
const styles = {
  wrapper: {
    display: 'inline-flex',
    borderRadius: 0,
    borderWidth: 0,
    borderTopWidth: 0,
    borderBottomWidth: 1,
    fontSize: 12,
    alignItems: 'center',
  },
  avatar: {
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#3C7CFF',
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
  },
  icon: {
    display: 'inline-flex',
    justifyContent: 'center',
    width: 15,
    // height: 15,
  },
};
