import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import Unity, { UnityContext } from 'react-unity-webgl';
import { Spinner } from 'reactstrap';
import { SessionContext } from 'context/sessionContext';

const Demo = (props) => {
  const { sessionHelper, show, media } = props;
  const [unityContext, setUnityContext] = useState(null);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const [scene, setScene] = useState(null);
  const container = useRef(null);
  const loading = useRef(null);

  const { state } = useContext(SessionContext);
  const { shareScreen } = state;

  const updateDimensions = useCallback(() => {
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (w / h > 960 / 600) {
      setHeight('100%');
      setWidth((h * 960) / 600);
    } else {
      setWidth('100%');
      setHeight((w * 600) / 960);
    }
  }, [container, setWidth, setHeight]);

  const updateLoading = useCallback(
    (p) => {
      if (!isLoading) {
        return;
      }
      setProgression(p);
      if (loading.current !== null && p >= 1) {
        loading.current.classList.add('fade-out');
        setTimeout(() => setIsLoading(false), 500);
      }
    },
    [loading, isLoading, setProgression, setIsLoading]
  );

  const onPresScene = useCallback(
    (e) => {
      if (e.data.scene) {
        setScene(e.data.scene);
      }
    },
    [setScene]
  );

  useEffect(() => {
    if (!!unityContext && !!scene) {
      unityContext.send('MainBehavior', 'SwitchScene', scene);
    }
  }, [scene, unityContext]);

  useEffect(() => {
    if (unityContext) {
      unityContext.on('progress', updateLoading);
    }
  }, [unityContext, setProgression, updateLoading]);

  useEffect(() => {
    // Make sure to only set the content once
    if (unityContext !== null || !media) {
      return;
    }
    setUnityContext(
      new UnityContext({
        loaderUrl: `${media.build_path}.loader.js`,
        dataUrl: `${media.build_path}.data${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
        frameworkUrl: `${media.build_path}.framework.js${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
        codeUrl: `${media.build_path}.wasm${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
      })
    );
  }, [media, setUnityContext, unityContext]);

  useEffect(() => {
    if (container.current) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
    return null;
  }, [container, setWidth, setHeight, updateDimensions]);

  /**
   * TOK messages
   */
  useEffect(() => {
    if (!sessionHelper) {
      return null;
    }
    sessionHelper.on('signal:presentation-scene', onPresScene);
    return () => {
      sessionHelper.off('signal:presentation-scene', onPresScene);
    };
  }, [sessionHelper, onPresScene]);

  return (
    <div
      ref={container}
      id="vtopia-unity"
      style={{
        ...styles.wrapper,
        width,
        height,
        display: !show || shareScreen ? 'none' : 'block',
      }}
    >
      {unityContext && (
        <Unity
          unityContext={unityContext}
          style={{
            maxHeight: '100%',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {isLoading && (
        <div
          ref={loading}
          className="d-flex flex-column justify-content-center align-items-center bg-dark text-white position-absolute left-0"
          style={styles.loading}
        >
          <Spinner color="light" />
          <p
            className="mt-3"
            style={{
              fontSize: 30,
            }}
          >
            {`${(progression * 100).toFixed()} %`}
          </p>
        </div>
      )}
    </div>
  );
};

export default Demo;
Demo.propTypes = {
  sessionHelper: PropTypes.object,
  show: PropTypes.bool,
  media: PropTypes.object.isRequired,
};

const styles = {
  wrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
    flex: 1,
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
