import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody } from 'reactstrap';
import http from '../../../utils/http';

const PharmaVigilanceComponent = (props) => {
  const { match } = props;
  const [report, setReport] = useState(null);

  useEffect(() => {
    http.get(`pharmacovigilance-report/${match.params.id}`).then((response) => {
      setReport(response);
    });
  }, [match.params.id]);

  return (
    <Container fluid>
      <Row>
        <Col lg="7" md="10">
          <h1 className="display-2 text-white">{!!report && report.drug_name}</h1>
          <p className="text-white mt-0 mb-5 w-100">
            <br />
          </p>
        </Col>
      </Row>
      <Row className="mt-3">
        <Col>
          <Card className="bg-secondary shadow">
            <CardBody className="bg-white">
              <div>Waiting for a template page ...</div>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default PharmaVigilanceComponent;
