import axios from 'axios';
import { setupCache } from 'axios-cache-adapter';

// Create `axios-cache-adapter` instance
const cache = setupCache({
  maxAge: 15 * 60 * 1000,
  exclude: {
    query: true,
  },
});

/* const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI_NODE,
  timeout: 1000,
  headers: { Authorization: `Bearer ${process.env.REACT_APP_NODE_API_TOKEN}` },
}); */

let instance = null;
let Authorization = null;
const getInstance = () => {
  // Create a new instance if authorization or instance is null
  if (instance === null || Authorization === null) {
    Authorization = window.localStorage.getItem('accessToken');
    instance = axios.create({
      baseURL: process.env.REACT_APP_API_URI_NODE,
      headers: { Authorization: `Bearer ${Authorization}` },
    });

    instance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err.config;

        if (err.response) {
          // Access Token was expired
          if (
            err.response.data.message === 'Invalid token.' ||
            err.response.data.message === 'Invalid token: Token did not contain required fields'
          ) {
            try {
              const newToken = await NodeServerHttp.refreshToken();
              window.localStorage.setItem('accessToken', newToken);
              instance.defaults.headers.common.Authorization = newToken;
              originalConfig.headers.Authorization = `Bearer ${newToken}`;

              return instance(originalConfig);
            } catch (_error) {
              if (_error.response && _error.response.data) {
                return Promise.reject(_error.response.data);
              }

              return Promise.reject(_error);
            }
          }

          if (err.response.status === 403 && err.response.data) {
            return Promise.reject(err.response.data);
          }
        }

        return Promise.reject(err);
      }
    );
  }
  return instance;
};

/*
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URI_NODE,
  headers: { Authorization: `Bearer ${window.localStorage.getItem('accessToken')}` },
});
*/

const refreshTokenInstance = axios.create({
  baseURL: process.env.REACT_APP_API_URI_NODE,
  headers: { Authorization: `Bearer ${process.env.REACT_APP_NODE_API_TOKEN}` },
});

class NodeServerHttp {
  async get(resource) {
    return getInstance()
      .get(resource)
      .then((res) => res.data)
      .then(async (res) => {
        const length = await cache.store.length();
        return res;
      });
  }

  async post(resource, data, config) {
    return getInstance()
      .post(resource, data, config)
      .then((res) => res.data);
  }

  async put(resource, data) {
    return getInstance()
      .put(resource, data)
      .then((res) => res.data);
  }

  async delete(resource, id) {
    return getInstance()
      .delete(resource, id)
      .then((res) => res.data);
  }

  static async refreshToken() {
    return refreshTokenInstance
      .post('/refreshToken', { token: process.env.REACT_APP_NODE_API_TOKEN })
      .then((res) => {
        return res.data;
      });
  }

  async download(url, filename) {
    return getInstance()
      .request({
        url,
        method: 'GET',
        responseType: 'blob',
      })
      .then(({ data }) => {
        const downloadUrl = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement('a');
        link.href = downloadUrl;
        link.setAttribute('download', filename); // any other extension
        document.body.appendChild(link);
        link.click();
        link.remove();
      });
  }
}

export default new NodeServerHttp();
