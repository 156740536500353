import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { useAuthContext } from 'context/authContext';

const EventsLayout = (props) => {
  const { children } = props;
  const authContext = useAuthContext();
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  return (
    <>
      <Row className="mt-4 mb-4">
        <Col>
          <h1 className="display-2 text-white">
            {tCommon('Hello')} {authContext.state.user.name}
          </h1>
          <p className="text-white">
            {t('Welcome to the Vtopia dashboard')}
            .
            <br />
            {t('Here, you can create and plan your events')}.
          </p>
        </Col>
      </Row>
      <Row>{children}</Row>
    </>
  );
};

export default EventsLayout;
