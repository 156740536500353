import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Button } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';

import { SessionContext } from 'context/sessionContext';
import arrow from 'assets/img/icons/arrow.svg';
import { hasOverflow } from 'utils/utils';
import Viewer from 'components/front/room/Viewer';

const SCROLL_VALUE = 150;

const Streams = (props) => {
  const {
    sessionHelper,
    medias,
    style,
    collapsed,
    activeAudioInputDevice,
    activeVideoInputDevice,
  } = props;
  const { session: tokSession } = sessionHelper;
  const { state } = useContext(SessionContext);
  const { viewers, participant } = state;
  const expandProgressId = useRef();
  const wrapper = useRef();
  const simpleBar = useRef();
  const [wrapperHasOverflow, setWrapperHasOverflow] = useState(false);
  const [viewerHeight, setViewerHeight] = useState(100);

  const sortViewers = useCallback((a, b) => {
    if (a.isMe) {
      return b.isAdmin ? 1 : -1;
    }
    if (b.isMe) {
      return a.isAdmin ? -1 : 1;
    }
    if (a.isAdmin) {
      return -1;
    }
    if (b.isAdmin) {
      return 1;
    }
    return 0;
  }, []);

  const onWindowResize = useCallback(
    (value) => {
      if (value.collapsed !== undefined) {
        clearTimeout(expandProgressId.current);
        expandProgressId.current = setTimeout(() => {
          setViewerHeight(wrapper.current.clientWidth / 1.32);
          if (simpleBar.current) {
            const el = simpleBar.current.getScrollElement();
            setWrapperHasOverflow(hasOverflow(el));
            if (value.collapsed === true) {
              el.style.display = 'none';
            }
          }
          clearTimeout(expandProgressId.current);
        }, 400);
        if (simpleBar.current) {
          const el = simpleBar.current.getScrollElement();
          if (value.collapsed === false) {
            el.style.display = 'block';
          }
        }
      } else {
        setViewerHeight(wrapper.current.clientWidth / 1.32);
        if (simpleBar.current) {
          const el = simpleBar.current.getScrollElement();
          setWrapperHasOverflow(hasOverflow(el));
        }
      }
    },
    [expandProgressId, wrapper, setViewerHeight, simpleBar, setWrapperHasOverflow]
  );

  const onWheel = useCallback(
    (e) => {
      const el = simpleBar.current.getScrollElement();
      el.scrollTo(0, el.scrollTop + e.deltaY);
    },
    [simpleBar]
  );

  const scrollUp = useCallback(() => {
    const el = simpleBar.current.getScrollElement();
    el.scrollTo({
      top: el.scrollTop - SCROLL_VALUE,
      behavior: 'smooth',
    });
  }, [simpleBar]);

  const scrollDown = useCallback(() => {
    const el = simpleBar.current.getScrollElement();
    el.scrollTo({
      top: el.scrollTop + SCROLL_VALUE,
      behavior: 'smooth',
    });
  }, [simpleBar]);

  useEffect(() => {
    if (!!wrapper.current && !!tokSession) {
      onWindowResize({ collapsed });
    }
  }, [collapsed, wrapper, tokSession, onWindowResize]);

  useEffect(() => {
    if (!simpleBar.current) {
      return null;
    }
    const el = simpleBar.current.getScrollElement();
    setWrapperHasOverflow(hasOverflow(el));
    window.addEventListener('resize', onWindowResize, false);
    return () => {
      window.removeEventListener('resize', onWindowResize, false);
    };
  }, [simpleBar, viewers, setWrapperHasOverflow, onWindowResize]);

  useEffect(() => {
    let id;
    if (!collapsed && simpleBar?.current) {
      const el = simpleBar.current.getScrollElement();
      setWrapperHasOverflow(false);
      id = setTimeout(() => {
        const h = wrapper.current.clientWidth / 1.32;
        if (el.offsetHeight < h * viewers.length) {
          setWrapperHasOverflow(true);
        }
        setViewerHeight(h);
        clearTimeout(id);
      }, 400);
    }
    return () => {
      clearTimeout(id);
    };
  }, [collapsed, simpleBar, viewers]);

  return (
    !!state.activeModules.includes('active_camera') && (
      <div
        ref={wrapper}
        className={classnames(
          'streams p-2 align-items-stretch overflow-visible h-100 position-relative',
          {
            'pr-3': wrapperHasOverflow,
          }
        )}
        onWheel={onWheel}
        style={{ ...style, width: '100% !important' }}
      >
        {wrapperHasOverflow && !collapsed && (
          <Button
            color="white"
            className="rounded-circle position-absolute"
            onClick={scrollUp}
            style={styles.bt}
          >
            <img
              src={arrow}
              alt=""
              style={{
                transform: 'rotate(-90deg)',
              }}
            />
          </Button>
        )}
        <SimpleBar
          ref={simpleBar}
          autoHide={false}
          direction="vertical"
          style={{
            width: '100%',
            height: 'calc(100% - 50px)',
          }}
        >
          {viewers.sort(sortViewers).map((viewer) => (
            <Viewer
              key={viewer.id}
              media={medias[0]}
              participant={participant}
              stream={sessionHelper.streams.find(
                (s) => s.connection.id === viewer.id && s.videoType !== 'screen'
              )}
              height={viewerHeight}
              tokSession={sessionHelper}
              activeAudioInputDevice={activeAudioInputDevice}
              activeVideoInputDevice={activeVideoInputDevice}
              {...viewer}
            />
          ))}
        </SimpleBar>
        {wrapperHasOverflow && !collapsed && (
          <Button
            color="white"
            className="rounded-circle position-absolute"
            onClick={scrollDown}
            style={{
              ...styles.bt,
              ...styles.bt_bottom,
            }}
          >
            <img
              src={arrow}
              alt=""
              style={{
                transform: 'rotate(90deg)',
              }}
            />
          </Button>
        )}
      </div>
    )
  );
};

export default Streams;
Streams.propTypes = {
  sessionHelper: PropTypes.object,
  medias: PropTypes.array,
};
const styles = {
  bt: {
    top: 2,
    left: '50%',
    transform: 'translate(-50%, -0%)',
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    zIndex: 2,
  },
  bt_bottom: {
    transform: 'translate(-50%, 0%)',
    bottom: 33,
    top: 'auto',
  },
};
