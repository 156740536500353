import React, { useContext, useState, useEffect } from 'react';
import { ListGroup, ListGroupItem } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { SessionContext } from 'context/sessionContext';

const Stats = (props) => {
  const [t] = useTranslation('front');
  const { state } = useContext(SessionContext);
  const { style } = props;
  const { datetimeStart, maxViewers, viewers } = state;
  const [timeSinceStart, setTimeSinceStart] = useState(0);

  useEffect(() => {
    if (timeSinceStart === 0) {
      setTimeSinceStart(((Date.now() - datetimeStart.getTime()) / 1000) | 0);
    }
    const timeout = setTimeout(
      () => setTimeSinceStart(((Date.now() - datetimeStart.getTime()) / 1000) | 0),
      1000
    );
    return () => clearTimeout(timeout);
  }, [timeSinceStart, setTimeSinceStart, datetimeStart]);

  return (
    <ListGroup
      {...props}
      style={{
        display: 'flex',
        ...style,
      }}
      variant="flush"
      className="w-100 p-0 flex-column align-items-center list-group rounded"
    >
      <ListGroupItem className="w-100 d-inline-flex justify-content-between">
        <strong>{t('Started at')}</strong>
        <span>{datetimeStart.toLocaleTimeString()}</span>
      </ListGroupItem>
      <ListGroupItem className="w-100 d-inline-flex justify-content-between">
        <strong>{t('Meeting duration')}</strong>
        <span>{new Date(1000 * timeSinceStart).toISOString().substr(11, 8)}</span>
      </ListGroupItem>
      <ListGroupItem className="w-100 d-inline-flex justify-content-between">
        <strong>{t('Current participants')}</strong>
        <span>{viewers.length}</span>
      </ListGroupItem>
      <ListGroupItem className="w-100 d-inline-flex justify-content-between">
        <strong>{t('Maximum count of participants')}</strong>
        <span>{maxViewers >= viewers.length ? maxViewers : viewers.length}</span>
      </ListGroupItem>
    </ListGroup>
  );
};

export default Stats;
