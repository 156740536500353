/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory, useParams } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
  Badge,
} from 'reactstrap';
import ReactDatetime from 'react-datetime';
import TimeInputPolyfill from 'react-time-input-polyfill';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import http from 'utils/http';
import nodeHttpApi from 'utils/nodeApiHttp';
import timePolyfillHelpers from 'lib/timePolyfillHelpers';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { comButtonLoading, comSpinningWhite } from 'components/common/common.module.scss';

const lodash = require('lodash');

const EditEventInfo = () => {
  const [tCommon] = useTranslation();
  const [t] = useTranslation('back-office');
  const { id } = useParams();
  const history = useHistory();
  const [event, setEvent] = useState({
    name: '',
    type: 'rp',
    description: '',
    planned_start_date: moment().hour(9).minute(0).toDate(),
    planned_end_date: moment().hour(18).minute(0).toDate(),
    medias: [],
    rooms_needed: 1,
    auto_dispatcher: true,
  });
  const [medias, setMedias] = useState([]);
  const [deploymentsDates, setDeploymentsDates] = useState([]);
  const [date, setDate] = useState(moment().format('L'));
  const [startTime, setStartTime] = useState(moment().format('HH:mm'));
  const [endTime, setEndTime] = useState(moment().format('HH:mm'));
  const [registering, setRegistering] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [toBroadcast, setToBroadcast] = useState(false);
  const [roomNeededCol, setRoomNeededCol] = useState(6);
  const [allowManualDispatcher, setAllowManualDispatcher] = useState(true);

  useEffect(() => {
    if (event.rooms_needed > 1) {
      setRoomNeededCol(4);
    } else {
      setRoomNeededCol(6);
    }
  }, [event.rooms_needed]);

  useEffect(() => {
    async function fetchData() {
      if (id !== 'create') {
        await nodeHttpApi.get(`events/${id}`).then((response) => {
          setToBroadcast(response.to_broadcast);
          setEvent({
            ...response,
            medias: response.medias.map((data) => {
              return data.id;
            }),
          });
        });
      }
    }
    fetchData();
  }, [id, medias]);

  useEffect(() => {
    http.get('medias/').then((response) => {
      setMedias(response);
      setDefaultEventMedia(response);
    });
  }, []);

  const setDefaultEventMedia = useCallback(
    (mediasData) => {
      if (id === 'create' && event.medias.length === 0) {
        const m = lodash.head(mediasData);
        const ms = m ? [m.id] : [];
        setEvent({
          ...event,
          medias: ms,
        });
      }
    },
    [event, id]
  );

  useEffect(() => {
    setDate(moment(event.planned_start_date));
    setStartTime(moment(event.planned_start_date).format('HH:mm'));
    setEndTime(moment(event.planned_end_date).format('HH:mm'));
  }, [event.planned_start_date, event.planned_end_date]);

  const handleInputChange = useCallback(
    (e, forceName) => {
      const value = e.target?.value ?? e.value;
      const name = e.target?.name ?? forceName;
      switch (forceName) {
        case 'email-relaunch-J7':
          return setEvent({
            ...event,
            email_relaunch_J7: e.target.checked ? 1 : 0,
          });
        case 'email-relaunch-J1':
          return setEvent({
            ...event,
            email_relaunch_J1: e.target.checked ? 1 : 0,
          });
        case 'email-relaunch-h1':
          return setEvent({
            ...event,
            email_relaunch_h1: e.target.checked ? 1 : 0,
          });
        case 'is-public':
          if (e.target.checked) {
            setAllowManualDispatcher(false);
          } else {
            setAllowManualDispatcher(true);
          }
          return setEvent({
            ...event,
            is_public: e.target.checked ? 1 : 0,
          });
        case 'to_broadcast':
          setToBroadcast(!!e.target.checked);
          return setEvent({
            ...event,
            to_broadcast: e.target.checked ? 1 : 0,
          });
        case 'auto_dispatcher':
          return setEvent({
            ...event,
            auto_dispatcher: e.target.value === 'true',
          });
        case 'date':
          return setDate(value);
        case 'startTime':
          return setStartTime(value);
        case 'endTime':
          return setEndTime(value);
        case 'media':
          // TODO: allow to choose multiple medias
          return setEvent({
            ...event,
            medias: [value],
          });
        default:
          return setEvent({
            ...event,
            [name]: value,
          });
      }
    },
    [event, setEvent, setStartTime, setEndTime, setDate]
  );

  const handleSubmit = useCallback(
    async (e) => {
      setSubmitting(true);
      setRegistering(true);
      e.stopPropagation();
      e.preventDefault();
      const [startHour, startMinute] = startTime.split(':');
      const [endHour, endMinute] = endTime.split(':');
      const eventWithDateTime = {
        ...event,
        is_public: e.target['is-public'].checked ? 1 : 0,
        email_relaunch_J1: e.target['email-relaunch-J1'].checked ? 1 : 0,
        email_relaunch_J7: e.target['email-relaunch-J7'].checked ? 1 : 0,
        email_relaunch_h1: e.target['email-relaunch-h1'].checked ? 1 : 0,

        planned_start_date: date
          .hour(+startHour)
          .minute(+startMinute)
          .format('YYYY-MM-DDTHH:mm'),
        planned_end_date: date
          .hour(+endHour)
          .minute(+endMinute)
          .format('YYYY-MM-DDTHH:mm'),
      };

      /*      const broadcastUrl = e.target.to_broadcast.checked
        ? e.target.broadcast_url_server.value
        : null; */

      eventWithDateTime.currentTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

      if (id === 'create') {
        const { id: eventID } = await http.post('events', eventWithDateTime).then((response) => {
          nodeHttpApi.put(`events/${response.id}`, {
            auto_dispatcher: e.target.auto_dispatcher ? e.target.auto_dispatcher.value : true,
            default_timezone: eventWithDateTime.currentTimeZone,
            medias: eventWithDateTime.medias,
            /*          to_broadcast: e.target.to_broadcast.checked ? 1 : 0, */
            email_relaunch_J1: e.target['email-relaunch-J1'].checked ? 1 : 0,
            email_relaunch_J7: e.target['email-relaunch-J7'].checked ? 1 : 0,
            email_relaunch_h1: e.target['email-relaunch-h1'].checked ? 1 : 0,
          });
          return response;
        });
        history.push(`/admin/events/${eventID}/participants`);
      } else {
        eventWithDateTime.medias = event.medias;
        eventWithDateTime.owner_id = event.owner_id.id;

        await http.put(`events/${id}`, eventWithDateTime).then(() => {
          nodeHttpApi
            .put(`events/${id}`, {
              auto_dispatcher: e.target.auto_dispatcher ? e.target.auto_dispatcher.value : true,
              default_timezone: eventWithDateTime.currentTimeZone,
              medias: eventWithDateTime.medias,
              email_relaunch_J1: e.target['email-relaunch-J1'].checked ? 1 : 0,
              email_relaunch_J7: e.target['email-relaunch-J7'].checked ? 1 : 0,
              email_relaunch_h1: e.target['email-relaunch-h1'].checked ? 1 : 0,
            })
            .then((res) => {
              history.push('/admin/events');
            });
          setRegistering(false);
        });
      }
    },
    [event, id, date, startTime, endTime, history]
  );

  useEffect(() => {
    nodeHttpApi.get('/deployment-schedulers').then((response) => {
      setDeploymentsDates(response);
    });
  }, []);

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{t('New event')}</h3>
          </Col>
          <Col className="text-right" xs="4">
            <Link to="/admin/events">
              <Button color="primary">{tCommon('Cancel')}</Button>
            </Link>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form onSubmit={handleSubmit}>
          <h6 className="heading-small text-muted mb-4">{t('Event data')}</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="12">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-event-name">
                    {t('Title')}*
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-event-name"
                    placeholder={t('Event name')}
                    type="text"
                    required
                    onChange={handleInputChange}
                    value={event.name}
                    name="name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg={roomNeededCol} className="transition">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-event-name">
                    {t('Number of rooms')}
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-event-name"
                    placeholder={t('Number of rooms')}
                    type="number"
                    step="1"
                    min="1"
                    required
                    onChange={handleInputChange}
                    value={event.rooms_needed}
                    name="rooms_needed"
                  />
                </FormGroup>
              </Col>

              {event.rooms_needed > 1 && (
                <Col lg={roomNeededCol} className="transition">
                  <FormGroup tag="fieldset">
                    <Label className="form-control-label" htmlFor="auto_dispatcher">
                      Répartition des participants
                    </Label>
                    {!!event && event.is_public ? (
                      <Badge color="info">
                        La répartition manuelle n'est pas disponible si l'événement est public
                      </Badge>
                    ) : (
                      ''
                    )}

                    <FormGroup check>
                      <Input
                        name="auto_dispatcher"
                        type="radio"
                        value="false"
                        checked={(!!event && !event.auto_dispatcher) || !event.is_public}
                        onClick={(e) => handleInputChange(e, 'auto_dispatcher')}
                        disabled={!!(!!event && event.is_public)}
                      />
                      <Label check>Manuel</Label>
                    </FormGroup>
                    <FormGroup check>
                      <Input
                        name="auto_dispatcher"
                        type="radio"
                        value="true"
                        checked={(!!event && event.auto_dispatcher) || !allowManualDispatcher}
                        onClick={(e) => handleInputChange(e, 'auto_dispatcher')}
                      />
                      <Label check>Automatique</Label>
                    </FormGroup>
                  </FormGroup>
                </Col>
              )}

              <Col lg={roomNeededCol}>
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-media">
                    {t('Presentation media')}*
                  </Label>
                  <Input
                    type="select"
                    id="input-media"
                    className="form-control-alternative"
                    onChange={(e) => handleInputChange(e, 'media')}
                    value={!!lodash.head(event.medias) && lodash.head(event.medias)}
                    name="media"
                  >
                    {medias.map((media) => (
                      <option key={media.id} value={media.id}>
                        {media.name}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col lg="6" className="mb-3">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="is-public"
                    type="checkbox"
                    onChange={(e) => handleInputChange(e, 'is-public')}
                    checked={!!event && event.is_public}
                  />
                  <label className="custom-control-label d-flex flex-column" htmlFor="is-public">
                    <span className="form-control-label">{t('Public Event')}</span>
                    <span className="text-muted">
                      {t('The event will be accessible publicly by any user')}
                    </span>
                  </label>
                </div>
              </Col>

              <Col lg="2" className="mb-3">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="email-relaunch-J7"
                    type="checkbox"
                    onChange={(e) => handleInputChange(e, 'email-relaunch-J7')}
                    checked={!!event && event.email_relaunch_J7}
                  />
                  <label
                    className="custom-control-label d-flex flex-column"
                    htmlFor="email-relaunch-J7"
                  >
                    <span className="form-control-label">Relance à J -7</span>
                    <span className="text-muted">
                      {"Un email de rapel sera envoyé 7 jours avant l'évènement\n"}
                    </span>
                  </label>
                </div>
              </Col>

              <Col lg="2" className="mb-3">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="email-relaunch-J1"
                    type="checkbox"
                    onChange={(e) => handleInputChange(e, 'email-relaunch-J1')}
                    checked={!!event && event.email_relaunch_J1}
                  />
                  <label
                    className="custom-control-label d-flex flex-column"
                    htmlFor="email-relaunch-J1"
                  >
                    <span className="form-control-label">Relance à J -1</span>
                    <span className="text-muted">
                      {"Un email de rapel sera envoyé un jour avant l'évènement\n"}
                    </span>
                  </label>
                </div>
              </Col>

              <Col lg="2" className="mb-3">
                <div className="custom-control custom-control-alternative custom-checkbox">
                  <input
                    className="custom-control-input"
                    id="email-relaunch-h1"
                    type="checkbox"
                    onChange={(e) => handleInputChange(e, 'email-relaunch-h1')}
                    checked={!!event && event.email_relaunch_h1}
                  />
                  <label
                    className="custom-control-label d-flex flex-column"
                    htmlFor="email-relaunch-h1"
                  >
                    <span className="form-control-label">Relance à H -1</span>
                    <span className="text-muted">
                      {"Un email de rapel sera envoyé une heure avant l'évènement\n"}
                    </span>
                  </label>
                </div>
              </Col>

              {/*              <Col lg="6" className="mb-3">
                <Row>
                  <Col lg="4">
                    <div className="custom-control custom-control-alternative custom-checkbox">
                      <input
                        className="custom-control-input"
                        id="to_broadcast"
                        type="checkbox"
                        onChange={(e) => handleInputChange(e, 'to_broadcast')}
                        checked={!!event && event.to_broadcast}
                      />
                      <label
                        className="custom-control-label d-flex flex-column"
                        htmlFor="to_broadcast"
                      >
                        <span className="form-control-label">Broadcast Feature</span>
                        <span className="text-muted">Allow broadcast Event</span>
                      </label>
                    </div>
                  </Col>

                  {toBroadcast && (
                    <Col lg="8">
                      <FormGroup>
                        <Input
                          className="form-control-alternative"
                          id="input-event-broadcast-url"
                          placeholder="RTMP server URL*"
                          type="text"
                          required
                          onChange={handleInputChange}
                          value={event.broadcast_url_server}
                          name="broadcast_url_server"
                        />
                      </FormGroup>
                    </Col>
                  )}
                </Row>
              </Col> */}
            </Row>
          </div>
          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">{t('Planning')}</h6>
          <div className="pl-lg-4">
            <Row>
              <Col lg="4">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-date">
                    {t('Date')}*
                  </Label>
                  {/* <DatePicker className='d-flex form-control' onChange={setDate} value={date} /> */}
                  <ReactDatetime
                    inputProps={{
                      placeholder: t('Date'),
                    }}
                    id="input-date"
                    timeFormat={false}
                    isValidDate={(current) => {
                      return !deploymentsDates.find((deploymentDate) => {
                        return moment(current.format('MM-DD-YYYY')).isSame(
                          moment(deploymentDate.date).format('MM-DD-YYYY')
                        );
                      });
                    }}
                    onChange={(value) =>
                      handleInputChange(
                        {
                          value,
                        },
                        'date'
                      )
                    }
                    value={date}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-start-time">
                    {t('Start time')}*
                  </Label>
                  <TimeInputPolyfill
                    label={t('Start time')}
                    id="input-start-time"
                    className="d-flex form-control"
                    onChange={(e) => handleInputChange(e, 'startTime')}
                    value={startTime}
                    polyfillSource={timePolyfillHelpers}
                  />
                </FormGroup>
              </Col>
              <Col lg="4">
                <FormGroup>
                  <Label className="form-control-label" htmlFor="input-end-time">
                    {t('End time')}*
                  </Label>
                  <TimeInputPolyfill
                    label={t('End time')}
                    id="input-end-time"
                    className="d-flex form-control"
                    onChange={(e) => handleInputChange(e, 'endTime')}
                    value={endTime}
                    polyfillSource={timePolyfillHelpers}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
          <hr className="my-4" />
          <h6 className="heading-small text-muted mb-4">{t('Description')}</h6>
          <div className="pl-lg-4">
            <FormGroup>
              <Label>{t('150 characters maximum')}</Label>
              <ReactQuill
                theme="snow"
                modules={{
                  toolbar: [
                    ['bold', 'italic'],
                    ['link', 'blockquote', 'code'],
                    [
                      {
                        list: 'ordered',
                      },
                      {
                        list: 'bullet',
                      },
                    ],
                  ],
                }}
                onChange={(value) => handleInputChange({ value }, 'description')}
                value={event.description}
                name="description"
              />
            </FormGroup>
            {!submitting && (
              <Button
                disabled={registering || (!event.medias[0] && id === 'create')}
                type="submit"
                color="primary"
                className="w-100 mt-3"
                style={{
                  cursor: `${!event.medias[0] && id === 'create' ? 'not-allowed' : 'pointer'}`,
                }}
              >
                {id === 'create' ? t('Save and invite participants') : tCommon('Save')}
              </Button>
            )}
            {submitting && (
              <div
                className="text-center"
                style={{
                  width: '100%',
                }}
              >
                <div
                  className={`${comButtonLoading}`}
                  style={{
                    width: '100%',
                  }}
                >
                  <div className={comSpinningWhite}>
                    <FontAwesomeIcon className="pt-1 mb-1" icon={faCircleNotch} />
                  </div>
                </div>
              </div>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditEventInfo;
