import React, { useState, useEffect, useCallback } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, CardFooter } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js';
import { chartOptions, parseOptions } from 'utils/chart';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowUp, faDesktop, faUserFriends } from '@fortawesome/free-solid-svg-icons';
import http from 'utils/http';
import { ListProvider } from 'context/ListContext';
import StatList from 'components/admin/statistics/StatList';
import StatDocList from 'components/admin/statistics/StatDocList';
import StatAnswerList from 'components/admin/statistics/StatAnswerList';
import Pagination from 'components/common/Pagination';
import moment from 'moment';
import css from './statistics.module.scss';

const Statistics = () => {
  const [pagination, setPagination] = useState(0);
  const [maxItem, setMaxItem] = useState(5);
  const [itemSubstitutes, setItemSubstitutes] = useState([]);
  const [chartSessions, setchartSessions] = useState({ data: {} });
  const [chartSessionRangeType, setChartSessionRangeType] = useState('day');
  const [docPagination, setDocPagination] = useState(0);
  const [maxDocItem, setMaxDocItem] = useState(5);
  const [itemDocSubstitutes, setItemDocSubstitutes] = useState([]);
  const [t] = useTranslation('back-office');

  const fakeDocumentListHttp = {
    get: () => {
      return new Promise((resolve) => {
        resolve([
          {
            name: 'Document 1',
            introduction_count: '2',
            download_count: '3',
          },
          {
            name: 'Document 2',
            introduction_count: '7',
            download_count: '4',
          },
        ]);
      });
    },
    /*
    delete: () => {
    },
    post: () => {
    },
    put: () => {
    }
    */
  };
  const fakePollAnswersListHttp = {
    get: () => {
      return new Promise((resolve) => {
        resolve([
          {
            name: 'Poll answer 1',
            type: 'type 1',
            id: 0,
            answers: [
              {
                name: 'First answer A',
                value: 10,
              },
              {
                name: 'Second answer A',
                value: 50,
              },
            ],
          },
          {
            id: 1,
            name: 'Poll answer 1',
            type: 'type 2',
            answers: [
              {
                name: 'First answer B',
                value: 10,
              },
              {
                name: 'Second answer B',
                value: 50,
              },
              {
                name: 'Third answer B',
                value: 90,
              },
              {
                name: 'Fourth answer B',
                value: 2,
              },
            ],
          },
        ]);
      });
    },
  };

  const onDocItemChange = useCallback((value) => {
    setItemDocSubstitutes(Array(value).fill(0));
  }, []);

  const onItemChange = useCallback((value) => {
    setItemSubstitutes(Array(value).fill(0));
  }, []);

  parseOptions(Chart, chartOptions());

  const getFakeData = useCallback(() => {
    return Array(Math.floor(Math.random() * 12) + 4)
      .fill(0)
      .map(() => {
        return Math.floor(10 + Math.random() * 500);
      });
  }, []);

  const getMouthLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).month();
        return moment()
          .month((index + base) % 12)
          .format('MMM');
      });
  };

  const getWeekLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).day();
        const i = index * 7 + base;
        return moment().day(i).format('D MMM');
      });
  };

  const getDayLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).day();
        const i = index + base;
        const name = moment()
          .day(i % 7)
          .format('ddd');
        const day = moment().day(i).format('D');
        return `${name} ${day}`;
      });
  };

  const getLabels = useCallback((type, date, range) => {
    switch (type) {
      case 'day':
        return getDayLabels(date, range);
      case 'week':
        return getWeekLabels(date, range);
      default:
        return getMouthLabels(date, range);
    }
  }, []);

  useEffect(() => {
    http
      .get(`stats/${chartSessionRangeType}`)
      .then((response) => {
        const { length } = response.values;
        const labels = getLabels(chartSessionRangeType, response.begin_date, length);
        setChartData(labels, response.values);
      })
      .catch(() => {
        // temporary fake data
        let cache = JSON.parse(sessionStorage.getItem('sessionChartCache'));
        if (!cache) cache = {};
        if (!cache[chartSessionRangeType]) {
          cache[chartSessionRangeType] = getFakeData();
          sessionStorage.setItem('sessionChartCache', JSON.stringify(cache));
        }
        const data = cache[chartSessionRangeType];
        const labels = getLabels(chartSessionRangeType, new Date(), data.length);
        setChartData(labels, data);
      });
  }, [getFakeData, getLabels, chartSessionRangeType]);

  const setChartData = (labels, values) => {
    setchartSessions({
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#212529',
                zeroLineColor: '#e9ecef',
              },
              ticks: {},
            },
          ],
        },
      },
      data: {
        labels,
        datasets: [
          {
            label: 'Performance',
            data: values,
            maxBarThickness: 10,
            backgroundColor: '#3c7cff',
          },
        ],
      },
    });
  };

  return (
    <Container fluid className={css.statisticsPage}>
      <Row>
        <Col lg="7" md="10">
          <h1 className="display-2 text-white">{t('Statistics')}</h1>
          <p className="text-white mt-0 mb-5 w-100">
            <br />
          </p>
        </Col>
      </Row>
      <Row className="d-none">
        {/* className="d-none" */}
        <Col>
          <Card className="panel-info">
            <CardHeader className="bg-white border-0">
              <Row>
                <Col>
                  <h6 className="h3 mb-0">{t('TOTAL NUMBER OF CREATED SESSION')}</h6>
                </Col>
                <Col>
                  <FontAwesomeIcon className="badge" icon={faDesktop} />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <b>158</b>
            </CardBody>
            <CardFooter className="border-top-0">
              <FontAwesomeIcon icon={faArrowUp} />
              <span>3.48%</span>
              <span className="ml-2">{t('Since last month')}</span>
            </CardFooter>
          </Card>
        </Col>
        <Col>
          <Card className="panel-info">
            <CardHeader className="bg-white border-0">
              <Row>
                <Col>
                  <h6 className="h3 mb-0">{t('TOTAL NUMBER OF PARTICIPANTS')}</h6>
                </Col>
                <Col>
                  <FontAwesomeIcon className="badge" icon={faUserFriends} />
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <b>450</b>
            </CardBody>
            <CardFooter className="border-top-0">
              <FontAwesomeIcon icon={faArrowUp} />
              <span>20%</span>
              <span className="ml-2">{t('Since last month')}</span>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="d-none">
        {/* className="d-none" */}
        <Col>
          <Card>
            <CardHeader className="bg-white border-0">
              <Row>
                <Col>
                  <span>{t('SESSION ACTIVITY MONITORING')}</span>
                  <h6 className="h3 mb-0">{t('NUMBER OF SESSIONS BY MONTH')}</h6>
                </Col>
                <Col>
                  <div className="float-right">
                    <button
                      type="button"
                      className={`${
                        chartSessionRangeType === 'day'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setChartSessionRangeType('day');
                      }}
                    >
                      Jour
                    </button>
                    <button
                      type="button"
                      className={`${
                        chartSessionRangeType === 'week'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setChartSessionRangeType('week');
                      }}
                    >
                      semaine
                    </button>
                    <button
                      type="button"
                      className={`${
                        chartSessionRangeType === 'month'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setChartSessionRangeType('month');
                      }}
                    >
                      Mois
                    </button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={chartSessions.data}
                  options={chartSessions.options}
                  className="chart-canvas"
                  id="chart-bar"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardHeader className="bg-white border-0">
              <h5 className="h3 mb-0">{t('LIST OF COMPLETED SESSIONS')}</h5>
            </CardHeader>
            <CardBody>
              <ListProvider
                resource={{
                  routes: {
                    ON_LOAD: 'events/find/closed',
                  },
                }}
              >
                <StatList
                  pagination={pagination}
                  onItemChange={onItemChange}
                  onItemCountChange={(value) => {
                    setMaxItem(value);
                    setPagination(0);
                  }}
                />
              </ListProvider>
            </CardBody>
            <CardFooter className="border-top-0">
              <nav aria-label="...">
                <Pagination
                  items={itemSubstitutes}
                  pagination={pagination}
                  setPagination={setPagination}
                  maxItem={maxItem}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="d-none">
        <Col>
          <Card>
            <CardHeader className="bg-white border-0">
              <h5 className="h3 mb-0">{t('LIST OF POLL ANSWERS')}</h5>
            </CardHeader>
            <CardBody>
              <ListProvider
                resource={{
                  http: fakePollAnswersListHttp,
                  routes: {
                    ON_LOAD: '',
                  },
                }}
              >
                <StatAnswerList
                  pagination={docPagination}
                  onItemChange={onDocItemChange}
                  onItemCountChange={(value) => {
                    setMaxDocItem(value);
                    setDocPagination(0);
                  }}
                />
              </ListProvider>
            </CardBody>
            <CardFooter className="border-top-0">
              <nav aria-label="...">
                <Pagination
                  items={itemDocSubstitutes}
                  pagination={docPagination}
                  setPagination={setDocPagination}
                  maxItem={maxDocItem}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
      <Row className="d-none">
        <Col>
          <Card>
            <CardHeader className="bg-white border-0">
              <h5 className="h3 mb-0">{t('LIST OF DOCUMENTS')}</h5>
            </CardHeader>
            <CardBody>
              <ListProvider
                resource={{
                  http: fakeDocumentListHttp,
                  routes: {
                    ON_LOAD: '',
                  },
                }}
              >
                <StatDocList
                  pagination={docPagination}
                  onItemChange={onDocItemChange}
                  onItemCountChange={(value) => {
                    setMaxDocItem(value);
                    setDocPagination(0);
                  }}
                />
              </ListProvider>
            </CardBody>
            <CardFooter className="border-top-0">
              <nav aria-label="...">
                <Pagination
                  items={itemDocSubstitutes}
                  pagination={docPagination}
                  setPagination={setDocPagination}
                  maxItem={maxDocItem}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default Statistics;
