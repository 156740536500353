import React, { useCallback, useEffect, useState } from 'react';
import lodash from 'lodash';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Alert,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { useAuthContext, publicLogin } from 'context/authContext';

import logoV from 'assets/img/logo-v.svg';
import { io as openSocket } from 'socket.io-client';
import HeaderNavbar from './headers/HeaderNavbar';
import Footer from './footers/Footer';

const EventPublic = (props) => {
  const authContext = useAuthContext();
  const [t] = useTranslation('front');
  const { match } = props;
  const history = useHistory();
  const [socket, setSocket] = useState(null);
  const [onlineParticipants, setOnlineParticipants] = useState([]);
  const [errorForm, setErrorForm] = useState(false);
  const onSubmit = useCallback(
    async (e) => {
      e.preventDefault();
      const { firstName, lastName, email } = e.target;
      const data = {
        firstName: firstName.value,
        lastName: lastName.value,
        email: email.value,
        eventSlug: match.params.slug,
      };

      if (lodash.find(onlineParticipants, { email: data.email }) === undefined) {
        try {
          await publicLogin(authContext, data);
          history.push('/event');
        } catch (error) {
          console.log(error);
        }
      } else {
        setErrorForm(true);
      }
    },
    [authContext, history, match.params.slug, onlineParticipants]
  );

  useEffect(() => {
    const socketConnection = openSocket(process.env.REACT_APP_API_URI_NODE, {
      auth: {
        token: process.env.REACT_APP_NODE_API_TOKEN,
      },
    }).emit('connection');
    setSocket(socketConnection);
    socketConnection.emit('checkOnlineParticipant');
    socketConnection.on('updateOnlineParticipant', (data) => {
      setOnlineParticipants(data);
    });
  }, []);

  return (
    <Container fluid className="h-100 text-center bg-default third-color">
      <Row
        className="justify-content-center mb-3 header pb-7 pt-7 position-relative first-color"
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex justify-content-between"
          style={{
            top: '.5rem',
          }}
        >
          <HeaderNavbar />
        </Container>
        <hr
          className="mb-0 mt-2"
          style={{
            width: '100vw',
            borderTop: '2px solid #FFFFFF22',
          }}
        />
        <Col className="mt-5">
          <h1 className="text-white mb-5">{t('Welcome to Vtopia!')}</h1>
          <p className="text-lead text-light">{t('Join an event')}</p>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      <Container>
        <Col
          lg="5"
          md="7"
          className="mt--7"
          style={{
            margin: 'auto',
          }}
        >
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-4">
              <div className="text-muted text-center mt-2 mb-3">
                <strong>{t('Login')}</strong>
              </div>
              <img
                alt=""
                src={logoV}
                style={{
                  height: 80,
                  width: 'auto',
                }}
              />
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={onSubmit}>
                {errorForm && <Alert color="danger">Vous êtes déjà dans une session</Alert>}
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('First Name')}
                      type="text"
                      id="input-user-name"
                      name="firstName"
                      required
                      autoComplete="name"
                      value={match.params.fname}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-single-02" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Last Name')}
                      type="text"
                      id="input-user-password"
                      name="lastName"
                      required
                      autoComplete="last-name"
                      value={match.params.lname}
                    />
                  </InputGroup>
                </FormGroup>

                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder="Email"
                      type="email"
                      id="input-user-email"
                      name="email"
                      required
                      autoComplete="email"
                      value={match.params.email}
                    />
                  </InputGroup>
                </FormGroup>

                <div className="text-center">
                  <Button className="my-4" color="primary" type="submit">
                    {t('Login')}
                  </Button>
                </div>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Container>
      <Footer />
    </Container>
  );
};

export default EventPublic;
