import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Button, Card, CardBody, CardHeader, Col, Form, Input, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import http from 'utils/http';

const EditPoll = (props) => {
  const {
    match: { params },
  } = props;
  const { id } = params;
  const [tCommon] = useTranslation();
  const title = useRef();
  const question = useRef();
  const [t] = useTranslation('front');
  const history = useHistory();
  const [poll, setPoll] = useState({
    question: '',
    options: [],
    allowMultiple: false,
    active: true,
  });

  const [options, setOptions] = useState([]);
  const [allowMultipleCheckbox, setAllowMultiple] = useState(true);

  const updateOption = useCallback(
    (optionID, value) => {
      options[optionID] = value;
      setOptions(options.map((option, i) => (i !== optionID ? option : value)));
    },
    [options, setOptions]
  );

  const addOption = useCallback(() => {
    setOptions([...options, '']);
  }, [options, setOptions]);

  const removeOption = useCallback(
    (optionID) => {
      setOptions(options.filter((_, i) => i !== optionID));
    },
    [options, setOptions]
  );

  const allowMultiple = useCallback(
    (e) => {
      setAllowMultiple(e.target.checked);
    },
    [setAllowMultiple]
  );

  const handleInputChange = useCallback(
    (e) => {
      const { value } = e.target;
      const { name } = e.target;
      setPoll({
        ...poll,
        [name]: value,
      });
    },
    [poll, setPoll]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      poll.options = options;
      poll.allowMultiple = allowMultipleCheckbox;

      await http.put(`polls/${id}`, poll);
      history.push('/admin/polls');
    },
    [poll, id, history, options, allowMultipleCheckbox]
  );

  useEffect(() => {
    http.get(`polls/${id}`).then((response) => {
      setOptions(response.options);
      setPoll(response);
    });
  }, [id]);

  return (
    <>
      <Row className="mb-4">
        <Col xs="8" className="my-auto">
          <h3 className="text-white align-self-center">
            {tCommon('Edit')}: {poll.title}
          </h3>
        </Col>
        <Col className="text-right" xs="4">
          <Link to="/admin/polls">
            <Button color="primary">{tCommon('Cancel')}</Button>
          </Link>
        </Col>
      </Row>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0" />
        <CardBody>
          <Form className="d-flex flex-column mb-3 w-100" action="#" onSubmit={handleSubmit}>
            <Input
              onChange={handleInputChange}
              name="title"
              id="title"
              innerRef={title}
              value={poll.title}
              type="text"
              style={{
                fontSize: 11,
              }}
              placeholder={t('Poll title')}
              className="mb-2 rounded border shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center font-weight-bold"
            />
            <Input
              onChange={handleInputChange}
              name="question"
              id="question"
              innerRef={question}
              value={poll.question}
              type="text"
              style={{
                fontSize: 11,
              }}
              placeholder={t('Ask a question')}
              required
              className="mb-2 rounded border shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center font-weight-bold"
            />
            {options.map((option, i) => (
              <div key={i} className="mb-3 position-relative">
                <Input
                  key={i}
                  type="text"
                  className="w-100"
                  style={{
                    fontSize: 11,
                  }}
                  required
                  onChange={(e) => updateOption(i, e.target.value)}
                  value={option}
                  placeholder={`${t('Answer option')} ${i + 1}`}
                />
                <span
                  className="position-absolute h-100 d-inline-flex align-items-center justify-content-center"
                  onClick={() => removeOption(i)}
                  role="button"
                  tabIndex={0}
                  style={{
                    right: 2,
                    top: 0,
                    width: 30,
                    cursor: 'pointer',
                    zIndex: 2,
                  }}
                >
                  <i className="fa fa-trash text-gray" aria-hidden="true" />
                </span>
              </div>
            ))}
            <span
              className="mb-2 p-2 w-100 text-left d-inline-flex justify-content-start align-items-center"
              onClick={addOption}
              role="button"
              tabIndex={0}
            >
              <span
                style={{
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  minWidth: 20,
                  minHeight: 20,
                  fontSize: 20,
                }}
                className="border border-primary p-1 d-inline-flex justify-content-center align-items-center font-weight-bold text-primary mr-2"
              >
                +
              </span>{' '}
              {t('Add answer option')}
            </span>
            <div className="custom-control custom-checkbox ml-2 mb-3">
              <input
                className="custom-control-input"
                defaultChecked={poll.allowMultiple && true}
                id="check-allow-multiple"
                type="checkbox"
                name="check-allow-multiple"
                onChange={allowMultiple}
              />
              <label
                className="custom-control-label"
                htmlFor="check-allow-multiple"
                style={{
                  fontSize: 12,
                }}
              >
                {t('Multiple choice')}
              </label>
            </div>
            <div className="d-flex justify-content-between">
              <Button
                style={{
                  minWidth: 120,
                }}
                color="primary"
                type="submit"
                outline
              >
                {tCommon('Save')}
              </Button>
            </div>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditPoll;
