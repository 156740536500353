import '@opentok/client';
import _ from 'lodash';
import React, { useEffect, useState, useCallback } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { io as openSocket } from 'socket.io-client';
import { useAuthContext } from 'context/authContext';
import {
  browserName,
  browserVersion,
  isMobile,
  osVersion,
  osName,
  deviceType,
  fullBrowserVersion,
  isTablet,
  isDesktop,
} from 'react-device-detect';

import http from 'utils/http';
import {
  useSessionsContext,
  START_SESSION,
  RESET_RP_DATA,
  SET_MESSAGES,
  SET_POLLS,
  SHOW_POLL,
  REMOVE_POLL,
  ADD_VIEWER,
  REMOVE_VIEWER,
  SET_ROLE,
  SET_ACTIVE_MODULES,
  SET_LAB_LOGO_URL,
  SET_PARTICIPANT,
} from 'context/sessionContext';

import Header from 'components/front/headers/Header';
import PresentationControls from 'components/front/room/animator/PresentationControls';
import RoomColumn from 'components/front/room/RoomColumn';
import Presentation from 'components/front/room/Presentation';

import nodeServerHttp from '../../utils/nodeApiHttp';

const OT = require('@opentok/client');

const Room = (props) => {
  const { id } = useRouteMatch().params;
  const history = useHistory();
  const authContext = useAuthContext();
  const currentParticipant = authContext.state.user;

  // eslint-disable-next-line react/destructuring-assignment
  const {
    eventID,
    activeAudio,
    activeVideo,
    inputAudioList,
    inputVideoList,
    roomData,
    mediaData,
    opentokData,
    isGuest,
    // eslint-disable-next-line react/destructuring-assignment
  } = props.location.state;
  const [isAdmin, setIsAdmin] = useState(false);
  const [deviceInfos, setDeviceInfos] = useState({
    browserName,
    browserVersion,
    isMobile,
    osVersion,
    osName,
    deviceType,
    fullBrowserVersion,
    isTablet,
    isDesktop,
  });

  const { state, dispatch } = useSessionsContext();
  const [t] = useTranslation();
  const { messages, polls } = state;
  const [streams, setStreams] = useState([]);
  const [sessionHelper, setSessionHelper] = useState(null);
  const [room, setRoom] = useState(roomData);
  const isAnimator = state.role && state.role.indexOf('animator') > -1;
  const { participant } = state;
  const [openTok, setOpenTok] = useState(JSON.parse(opentokData));
  const [socket, setSocket] = useState(null);
  const [medias, setMedias] = useState(mediaData);
  const [audioInputList, setAudioInputList] = useState([]);
  const [videoInputList, setVideoInputList] = useState([]);

  useEffect(() => {
    setAudioInputList(JSON.parse(inputAudioList));
    setVideoInputList(JSON.parse(inputVideoList));
  }, [inputAudioList, inputVideoList]);

  useEffect(() => {
    if (authContext?.state) {
      const admin = authContext?.state?.user?.roles || [];
      setIsAdmin(admin.indexOf('admin') !== -1);
    }
  }, [authContext]);

  useEffect(() => {
    console.log('je passe dans mon useEffect');
    const socketConnection = openSocket(process.env.REACT_APP_API_URI_NODE, {
      auth: {
        token: process.env.REACT_APP_NODE_API_TOKEN,
      },
    }).emit('connection');
    setSocket(socketConnection);

    return () => {
      disconnect();
    };
  }, []);

  useEffect(() => {
    if (state.opentokStreamId) {
      const onLineEvent = {
        opentokStream: state.opentokStreamId,
        participantId: state.participant.id,
        name: state.participant.name,
        email: state.participant.email,
        role: state.participant.role,
        eventId: state.participant.event_id,
        eventName: state.sessionName,
        sessionStartedAt: state.datetimeStart,
        sessionId: state.sessionID,
        sessionToken: state.sessionToken,
      };

      if (socket) socket.emit('new-event-connection', onLineEvent);
    }
  }, [state, socket]);

  /**
   * When a user connects to the session
   */
  const onConnectionCreated = useCallback(
    async (e) => {
      const { connection } = e;
      const { id: connectionID, data } = connection;
      const participantData = JSON.parse(data);
      dispatch({
        type: ADD_VIEWER,
        payload: {
          id: connectionID,
          ...participantData,
          isMe: sessionHelper.connection.id === connectionID,
          speaking: false,
        },
      });
    },
    [dispatch, sessionHelper, isAdmin]
  );

  /**
   * When a user disconnects from the session
   */
  const onConnectionDestroyed = useCallback(
    (e) => {
      dispatch({
        type: REMOVE_VIEWER,
        payload: {
          id: e.connection.id,
        },
      });
    },
    [dispatch]
  );

  const onMessageReceived = useCallback(
    (e) => {
      const { data, from } = e;
      const { text, id: messageID } = data;
      const username = JSON.parse(from.data).name;
      dispatch({
        type: SET_MESSAGES,
        payload: [
          ...messages,
          {
            id: messageID,
            from: from.id === sessionHelper.connection.id ? t('Me') : username,
            text,
            isMe: from.id === sessionHelper.connection.id,
          },
        ],
      });
    },
    [messages, dispatch, sessionHelper, t]
  );

  const onMessageRemoved = useCallback(
    (e) => {
      dispatch({
        type: SET_MESSAGES,
        payload: messages.filter((msg) => msg.id !== e.data),
      });
    },
    [messages, dispatch]
  );

  const showPoll = useCallback(
    ({ data }) =>
      dispatch({
        type: SHOW_POLL,
        payload: data,
      }),
    [dispatch]
  );

  const updatePolls = useCallback(
    ({ data }) => {
      const updatedPoll = JSON.parse(data);
      if (!updatedPoll.active) {
        dispatch({
          type: SET_POLLS,
          payload: polls.filter((poll) => poll.id !== updatedPoll.id),
        });
      } else {
        dispatch({
          type: SET_POLLS,
          payload: polls.map((poll) => {
            if (poll.id === updatedPoll.id) {
              return updatedPoll;
            }
            return poll;
          }),
        });
      }
    },
    [dispatch, polls]
  );

  const closePoll = useCallback(
    ({ event }) =>
      dispatch({
        type: REMOVE_POLL,
        payload: JSON.parse(event.data),
      }),
    [dispatch]
  );
  const onPollAnswered = useCallback(
    ({ data }) => {
      const answeredPoll = JSON.parse(data);
      const updatedPolls = polls.map((p) =>
        p.id === answeredPoll.id
          ? {
              ...p,
              answers: answeredPoll.answers,
            }
          : p
      );
      dispatch({
        type: SET_POLLS,
        payload: updatedPolls,
      });
    },
    [dispatch, polls]
  );

  const onNewPollStarted = useCallback(
    (event) => {
      state.polls.push(JSON.parse(event.data));
      dispatch({
        type: SET_POLLS,
        payload: state.polls,
      });
    },
    [state, dispatch]
  );

  const onStreamsUpdated = useCallback(() => {}, []);

  const onStreamCreated = useCallback(
    (e) => {
      setStreams([...streams, e.stream]);
    },
    [setStreams, streams]
  );

  const onStreamDestroyed = useCallback(
    (e) => {
      setStreams(streams.filter((s) => s.id !== e.stream.id));
    },
    [setStreams, streams]
  );

  const onDocumentShared = useCallback(
    (data) => {
      if (data.from.id !== sessionHelper.connection.id) {
        dispatch({
          type: 'INC_SHARE_PANEL_NOTIFICATION',
        });
      }
    },
    [dispatch, sessionHelper]
  );

  /**
   * Once we get the session ID and token from the API, connect to the OpenTok session
   */
  useEffect(() => {
    if (!state.participant) {
      if (isGuest) history.push('/');
      else history.push('/admin/events');
    }

    if (sessionHelper) {
      return;
    }
    if (!openTok || !openTok.sessionToken) {
      setSessionHelper(null);
      setStreams([]);
      return;
    }

    const helper = OT.initSession(openTok.apiKey, openTok.sessionId);

    if (!helper.isConnected() && !!state.participant) {
      // eslint-disable-next-line consistent-return
      helper.connect(openTok.sessionToken, (error) => {
        // If the connection is successful, initialize a publisher and publish to the session
        if (error) {
          handleConnectError(error);
        } else {
          console.log('session connected');
        }
      });
    }

    setSessionHelper(helper);
    dispatch({
      type: START_SESSION,
      payload: {
        helper,
        id: openTok.sessionId,
        token: openTok.sessionToken,
        name: openTok.sessionName,
        datetime: openTok.datetime,
      },
    });
  }, [
    history,
    openTok,
    dispatch,
    sessionHelper,
    setSessionHelper,
    setStreams,
    onStreamsUpdated,
    deviceInfos,
    state,
    currentParticipant,
    isGuest,
  ]);

  /**
   * Set Open TOK event listeners
   */
  useEffect(() => {
    if (!sessionHelper) {
      return null;
    }

    sessionHelper.on('exception', onException);
    sessionHelper.on('sessionConnected', onConnectionSuccess);
    sessionHelper.on('sessionDisconnected', onSessionDisconnected);
    sessionHelper.on('connectionCreated', onConnectionCreated);
    sessionHelper.on('connectionDestroyed', onConnectionDestroyed);
    sessionHelper.on('streamCreated', onStreamCreated);
    sessionHelper.on('streamDestroyed', onStreamDestroyed);
    sessionHelper.on('signal:msg', onMessageReceived);
    sessionHelper.on('signal:msg-remove', onMessageRemoved);
    sessionHelper.on('signal:poll-show', showPoll);
    sessionHelper.on('signal:document-share', onDocumentShared);
    sessionHelper.on('signal:poll-update', updatePolls);
    if (!isAnimator) {
      sessionHelper.on('signal:poll-close', closePoll);
      sessionHelper.on('signal:poll-created-started', onNewPollStarted);
    } else {
      sessionHelper.on('signal:poll-answer', onPollAnswered);
    }
    return () => {
      sessionHelper.off('exception', onException);
      sessionHelper.off('sessionConnected', onConnectionSuccess);
      sessionHelper.off('sessionDisconnected', onSessionDisconnected);
      sessionHelper.off('connectionCreated', onConnectionCreated);
      sessionHelper.off('connectionDestroyed', onConnectionDestroyed);
      sessionHelper.off('streamCreated', onStreamCreated);
      sessionHelper.off('streamDestroyed', onStreamDestroyed);
      sessionHelper.off('signal:msg', onMessageReceived);
      sessionHelper.off('signal:msg-remove', onMessageRemoved);
      sessionHelper.off('signal:poll-show', showPoll);
      sessionHelper.off('signal:document-share', onDocumentShared);
      sessionHelper.off('signal:poll-update', updatePolls);
      if (!isAnimator) {
        sessionHelper.off('signal:poll-close', closePoll);
        sessionHelper.off('signal:poll-answer', onPollAnswered);
        sessionHelper.off('signal:poll-created-started', onNewPollStarted);
      } else {
        sessionHelper.off('signal:poll-answer', onPollAnswered);
      }
    };
  }, [
    onNewPollStarted,
    messages,
    sessionHelper,
    isAnimator,
    onConnectionCreated,
    onConnectionDestroyed,
    onMessageReceived,
    onMessageRemoved,
    showPoll,
    closePoll,
    updatePolls,
    onPollAnswered,
    onStreamCreated,
    onStreamDestroyed,
    onDocumentShared,
    state,
    socket,
  ]);

  // Before leaving the page: disconnect
  const disconnect = useCallback(() => {
    if (!sessionHelper) {
      return;
    }

    http.post(`rooms/${id}/leave`).then(() => {
      if (socket) socket.disconnect();
    });
    dispatch({
      type: RESET_RP_DATA,
    });
  }, [id, socket, sessionHelper, dispatch]);

  const handleConnectError = useCallback(
    (error) => {
      if (state.participant) {
        socket.emit('log', {
          level: 'error',
          message: 'FRONTEND_OPENTOK_CONNEXION_ERROR',
          meta: {
            service: 'opentok-meeting-room',
            message: error.message,
            code: error.code,
            event: state.participant.event_id,
            email: state.participant.email,
            participantId: state.participant.id,
            participantName: state.participant.name,
            participantRole: state.participant.role,
            roomId: state.participant.room_id,
            deviceInfos,
          },
        });
      }
    },
    [state, socket, deviceInfos]
  );

  const onSessionDisconnected = useCallback(
    (event) => {
      if (state.participant) {
        socket.emit('opentok-session-disconnected', {
          event: state.participant.event_id,
          email: state.participant.email,
          participantId: state.participant.id,
          participantName: state.participant.name,
          participantRole: state.participant.role,
          roomId: state.participant.room_id,
          disconnectReason: event.reason,
        });
      }
    },
    [state, socket]
  );

  const onException = useCallback(
    (event) => {
      if (state.participant) {
        socket.emit('log', {
          level: 'error',
          message: 'FRONTEND_OPENTOK_SESSION_EXCEPTION',
          meta: {
            service: 'opentok-meeting-room',
            message: event.message,
            code: event.code,
            event: state.participant.event_id,
            email: state.participant.email,
            participantId: state.participant.id,
            participantName: state.participant.name,
            participantRole: state.participant.role,
            roomId: state.participant.room_id,
            deviceInfos,
          },
        });
      }
    },
    [state, socket, deviceInfos]
  );

  const onConnectionSuccess = useCallback(() => {
    if (state.participant) {
      socket.emit('log', {
        level: 'info',
        message: 'FRONTEND_OPENTOK_CONNEXION_SUCCESS',
        meta: {
          service: 'opentok-meeting-room',
          event: state.participant.event_id,
          email: state.participant.email,
          participantId: state.participant.id,
          participantName: state.participant.name,
          participantRole: state.participant.role,
          roomId: state.participant.room_id,
          deviceInfos,
        },
      });
    }
  }, [state, socket, deviceInfos]);

  return (
    sessionHelper && (
      <Container className="h-100 gradient-background" fluid>
        <Row className="h-100">
          <Col className="d-flex flex-column h-100 flex-grow-1">
            <Header isAnimator={isAnimator} />
            <div className="h-100 px-3 py-3 mb-3 d-flex flex-column rounded overflow-hidden bg-default third-color">
              <Presentation
                participant={participant}
                sessionInfos={openTok}
                sessionHelper={sessionHelper}
                isAnimator={isAnimator}
                medias={medias}
                room={room}
                eventID={eventID}
                socket={socket}
                activeAudioInputDevice={activeAudio}
                activeVideoInputDevice={activeVideo}
              />
              <PresentationControls
                sessionHelper={sessionHelper}
                medias={medias}
                isAnimator={isAnimator}
                activeAudioInputDevice={activeAudio}
                activeVideoInputDevice={activeVideo}
                audioInputList={audioInputList}
                videoInputList={videoInputList}
              />
            </div>
          </Col>
          <RoomColumn
            sessionHelper={sessionHelper}
            streams={streams}
            medias={medias}
            isAnimator={isAnimator}
            room={room}
          />
        </Row>
      </Container>
    )
  );
};

export default Room;
