import React, { useContext, useEffect, useState, useCallback } from 'react';
import { Form, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { SessionContext, REMOVE_POLL } from 'context/sessionContext';
import http from 'utils/http';

const VotePoll = (props) => {
  const { room } = props;
  const { state, dispatch } = useContext(SessionContext);
  const { polls, participant } = state;
  const [t] = useTranslation('front');
  const [poll, setPoll] = useState(null);
  const [checkedOption, setCheckedOption] = useState(-1);
  const [checkedOptions, setCheckedOptions] = useState([]);

  const onToggleChecked = useCallback(
    (i) => {
      if (checkedOptions.includes(i)) {
        setCheckedOptions(checkedOptions.filter((c) => c === i));
      } else {
        setCheckedOptions([...checkedOptions, i]);
      }
    },
    [checkedOptions, setCheckedOptions]
  );

  const submitPoll = useCallback(
    async (e) => {
      e.preventDefault();
      try {
        await http.post(`polls/answer/${poll.id}`, {
          answers: poll.allowMultiple ? checkedOptions : [checkedOption],
          participant_id: participant.id,
          opentok_session_id: room.opentok_session_id,
        });
        dispatch({
          type: REMOVE_POLL,
          payload: poll.id,
        });
      } catch (err) {
        console.log(err);
      }
      setCheckedOption(-1);
    },
    [checkedOption, checkedOptions, setCheckedOption, dispatch, poll, room, participant]
  );

  useEffect(() => {
    setPoll(polls[0]);
  }, [polls, setPoll]);

  return (
    !!poll && (
      <Form className="col w-100 bg-white text-left m-0" action="#" onSubmit={submitPoll}>
        {!!poll.title && poll.title !== poll.question && (
          <p
            className="mb-1"
            style={{
              fontSize: 13,
            }}
          >
            {poll.title}
          </p>
        )}
        <p
          className="mb-4 font-weight-bold text-muted"
          style={{
            fontSize: 13,
          }}
        >
          {poll.question}
        </p>
        {poll.options.map((option, i) =>
          poll.allowMultiple ? (
            <div className="custom-control custom-checkbox mb-1" key={`${poll.id}-${i}`}>
              <input
                className="custom-control-input"
                id={`poll-checkbox-${i}`}
                name={`poll-checkbox-${i}`}
                type="checkbox"
                onChange={() => onToggleChecked(i)}
              />
              <label className="custom-control-label" htmlFor={`poll-checkbox-${i}`}>
                {option}
              </label>
            </div>
          ) : (
            <div className="custom-control custom-radio mb-1" key={`${poll.id}-${i}`}>
              <input
                className="custom-control-input"
                onChange={() => setCheckedOption(i)}
                id={`poll-radio-${i}`}
                name="poll-radio"
                type="radio"
              />
              <label className="custom-control-label" htmlFor={`poll-radio-${i}`}>
                {option}
              </label>
            </div>
          )
        )}
        <Button className="mt-3 w-100" color="primary" type="submit">
          {t('Send Vote')}
        </Button>
      </Form>
    )
  );
};

export default VotePoll;
