import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { Button, Form, ModalBody, ModalFooter, ModalHeader, Modal, Input } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import '@opentok/client';
import toast, { Toaster } from 'react-hot-toast';

import {
  useSessionsContext,
  END_SESSION,
  SET_SHARE_SCREEN,
  SET_PUBLISHER,
  SET_CURRENT_AUDIO_INPUT,
  SET_CURRENT_VIDEO_INPUT,
  SET_OPENTOK_STREAM_ID,
  SET_OT_PUBLISHER_HTML_ELEMENT,
} from 'context/sessionContext';
import { ReactComponent as CameraOn } from 'assets/img/icons/camera-on.svg';
import { ReactComponent as CameraOff } from 'assets/img/icons/camera-off.svg';
import { ReactComponent as MicrophoneOn } from 'assets/img/icons/microphone-on.svg';
import { ReactComponent as MicrophoneOff } from 'assets/img/icons/microphone-off.svg';
import { ReactComponent as SettingsIcon } from 'assets/img/icons/settings.svg';
import { ReactComponent as Monitor } from 'assets/img/icons/monitor.svg';
import { ReactComponent as Exit } from 'assets/img/icons/exit.svg';
import { ReactComponent as FullscreenIcon } from 'assets/img/icons/fullscreen.svg';
import { Colors } from 'Constants';
import { Player } from '@lottiefiles/react-lottie-player';
import micro from 'assets/microphone.json';
import lodash from 'lodash';

const SwitchPresentation = (props) => {
  const { sessionHelper, close, medias } = props;
  const [t] = useTranslation('front');
  const { state, dispatch } = useSessionsContext();
  const { shareScreen } = state;

  const startShareScreen = useCallback(() => {
    if (shareScreen) {
      return;
    }
    dispatch({
      type: SET_SHARE_SCREEN,
      payload: true,
    });
    close();
  }, [shareScreen, dispatch, close]);

  const shareDemo = useCallback(
    (scene) => {
      sessionHelper.signal({
        type: 'presentation-scene',
        data: {
          scene,
        },
      });
      if (shareScreen) {
        dispatch({
          type: SET_SHARE_SCREEN,
          payload: false,
        });
        close();
      }
    },
    [sessionHelper, shareScreen, dispatch, close]
  );

  const onClickAnywhere = useCallback(
    (e) => {
      if (!e.target.closest('#switch-presentation')) {
        close();
      }
    },
    [close]
  );

  useEffect(() => {
    document.body.addEventListener('click', onClickAnywhere, false);
    return () => {
      document.body.removeEventListener('click', onClickAnywhere, false);
    };
  }, [onClickAnywhere]);

  return (
    <div
      className="position-absolute bg-default shadow py-3 px-4 text-left text-white"
      id="switch-presentation"
      style={styles.switchPres}
    >
      <strong>{t('Present')}</strong>
      <hr className="my-2 mx--4 border-grey" />
      {/* TEMPORARY : One media only */}
      {medias[0].slug === 'demo' ? (
        <>
          <div
            className="d-flex my-2 justify-content-between align-items-center py-1 hover-bold"
            style={{
              fontSize: 12,
              cursor: 'pointer',
              fontWeight: shareScreen ? 'normal' : 'bold',
            }}
            onClick={() => shareDemo('lucentis')}
            role="button"
            tabIndex={0}
          >
            <span>Lucentis</span>
            <Monitor fill={Colors.TEXT_PRIMARY} />
          </div>
          <div
            className="d-flex my-2 justify-content-between align-items-center py-1 hover-bold"
            style={{
              fontSize: 12,
              cursor: 'pointer',
              fontWeight: shareScreen ? 'normal' : 'bold',
            }}
            onClick={() => shareDemo('dimensio')}
            role="button"
            tabIndex={0}
          >
            <span>DimensIO</span>
            <Monitor fill={Colors.TEXT_PRIMARY} />
          </div>
        </>
      ) : (
        <div
          className="d-flex my-2 justify-content-between align-items-center py-1 hover-bold"
          style={{
            fontSize: 12,
            cursor: 'pointer',
            fontWeight: shareScreen ? 'normal' : 'bold',
          }}
          role="button"
          tabIndex={0}
          onClick={() => shareDemo()}
        >
          <span>{t('Presentation')}</span>
          <Monitor fill={Colors.TEXT_PRIMARY} />
        </div>
      )}
      <div
        className="d-flex mt-2 justify-content-between align-items-center py-1 hover-bold"
        style={{
          fontSize: 12,
          cursor: 'pointer',
          fontWeight: !shareScreen ? 'normal' : 'bold',
        }}
        role="button"
        tabIndex={0}
        onClick={startShareScreen}
      >
        <span>{t('My screen')}</span>
        <Monitor fill={Colors.TEXT_PRIMARY} />
      </div>
    </div>
  );
};

const PresentationControls = (props) => {
  const {
    sessionHelper,
    isAnimator,
    medias,
    opentokMethods,
    audioInputList,
    videoInputList,
  } = props;
  const { state, dispatch } = useSessionsContext();
  const { shareScreen, viewers } = state;
  const history = useHistory();
  const [cookies, setCookie] = useCookies(['publishAudio', 'publishVideo']);
  const [hasAudio, setHasAudio] = useState(cookies.publishAudio !== 'false');
  const [hasVideo, setHasVideo] = useState(cookies.publishVideo !== 'false');
  const [switchOn, setSwitchOn] = useState(false);
  // const [fullscreen, setFullscreen] = useState(!!document.fullscreenElement);
  const [isLeaving, setIsLeaving] = useState(false);
  const [t] = useTranslation('front');
  const [switchMic, setSwitchMic] = useState(false);
  const [audioInputDeviceList, setAudioInputDeviceList] = useState([]);
  const [videoInputDeviceList, setVideoInputDeviceList] = useState([]);

  const toggleAudio = useCallback(() => document.body.dispatchEvent(new Event('toggle-audio')), []);
  const toggleVideo = useCallback(() => document.body.dispatchEvent(new Event('toggle-video')), []);

  const togglePresSwitch = useCallback(() => {
    setSwitchOn(!switchOn);
  }, [switchOn, setSwitchOn]);

  const leaveSession = useCallback(() => {
    try {
      sessionHelper.disconnect();
      dispatch({
        type: END_SESSION,
      });

      if (!localStorage.getItem('token').includes('user')) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('token');
        localStorage.removeItem('themeData');
        localStorage.removeItem('opentok_client_id');
        localStorage.removeItem('debug');
      }

      history.push('/');
      // TODO: Logout
    } catch (error) {
      console.error(error);
    }
  }, [dispatch, history, sessionHelper]);

  const onAudioToggle = useCallback(
    (e) => {
      if (e.detail.id === sessionHelper.connection.id) {
        setHasAudio(e.detail.enabled);
        setCookie('publishAudio', e.detail.enabled);
      }
    },
    [sessionHelper, setHasAudio, setCookie]
  );

  const onVideoToggle = useCallback(
    (e) => {
      if (e.detail.id === sessionHelper.connection.id) {
        setHasVideo(e.detail.enabled);
        setCookie('publishVideo', e.detail.enabled);
      }
    },
    [sessionHelper, setHasVideo, setCookie]
  );

  const toggleFullScreen = useCallback(() => {
    if (!document.fullscreenElement && !document.webkitCurrentFullScreenElement) {
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.webkitRequestFullScreen) {
        document.documentElement.webkitRequestFullScreen();
      } else if (document.documentElement.webkitEnterFullscreen) {
        document.documentElement.webkitEnterFullscreen();
      }
      // setFullscreen(true);
    } else if (document.exitFullscreen || document.webkitCancelFullScreen) {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
      // setFullscreen(false);
    }
  }, []);

  useEffect(() => {
    document.body.addEventListener('user-toggled-audio', onAudioToggle);
    document.body.addEventListener('user-toggled-video', onVideoToggle);
    return () => {
      document.body.removeEventListener('user-toggled-audio', onAudioToggle);
      document.body.removeEventListener('user-toggled-video', onVideoToggle);
    };
  }, [onAudioToggle, onVideoToggle]);

  const onChangeAudioInput = useCallback(
    (e) => {
      const newAudioDevice = JSON.parse(e.target.value);

      if (state.publisher) {
        state.publisher.setAudioSource(newAudioDevice.deviceId);
        dispatch({
          type: SET_CURRENT_AUDIO_INPUT,
          payload: newAudioDevice,
        });
      }
    },
    [state, dispatch]
  );

  const updatePublisherAudioDevice = useCallback(
    (newDevice) => {
      if (state.publisher) {
        state.publisher.setAudioSource(newDevice.deviceId);
        dispatch({
          type: SET_CURRENT_AUDIO_INPUT,
          payload: newDevice,
        });
      }
    },
    [state, dispatch]
  );

  useEffect(() => {
    setAudioInputDeviceList(audioInputList);
    setVideoInputDeviceList(videoInputList);
  }, [audioInputList, videoInputList]);

  useEffect(() => {
    const oldAudioDeviceList = lodash.filter(audioInputDeviceList, { kind: 'audioinput' });
    const oldVideoDeviceList = lodash.filter(videoInputDeviceList, { kind: 'videoinput' });

    navigator.mediaDevices.ondevicechange = async (event) => {
      const newDeviceList = await navigator.mediaDevices.enumerateDevices();
      const newAudioDeviceList = lodash.filter(newDeviceList, { kind: 'audioinput' });
      const newVideoDeviceList = lodash.filter(newDeviceList, { kind: 'videoinput' });

      // Take the first element from audio device list as default input audio device
      const newDefaultAudioInput = lodash.head(newAudioDeviceList);

      // Take the first element from video device list as default input video device
      const newDefaultVideoInput = lodash.head(newVideoDeviceList);

      const videoDeviceDiff = lodash.difference(oldVideoDeviceList, newVideoDeviceList);
      const audioDeviceDiff = lodash.difference(oldAudioDeviceList, newAudioDeviceList);

      if (oldAudioDeviceList.length > newAudioDeviceList.length) {
        toast.error(<div>Microphone débranché {lodash.head(audioDeviceDiff).label}</div>, {
          duration: 7000,
        });
      }

      if (oldAudioDeviceList.length < newAudioDeviceList.length) {
        toast.success(<div>Nouveau microphone détecté {lodash.head(audioDeviceDiff).label}</div>, {
          duration: 7000,
        });
      }

      if (oldVideoDeviceList.length > newVideoDeviceList.length) {
        toast.error(<div>Caméra débranché: {lodash.head(videoDeviceDiff).label}</div>, {
          duration: 7000,
        });
      }
      if (oldVideoDeviceList.length < newVideoDeviceList.length) {
        if (lodash.head(videoDeviceDiff))
          toast.success(<div>Nouvelle caméra détecté: {lodash.head(videoDeviceDiff).label}</div>, {
            duration: 7000,
          });
      }
      updatePublisherAudioDevice(newDefaultAudioInput);
      initNewPublisher(newDefaultAudioInput, newDefaultVideoInput);

      console.log('je suis laaaaaaaaa');

      console.log(newAudioDeviceList);
      console.log(newVideoDeviceList);

      setAudioInputDeviceList(newAudioDeviceList);
      setVideoInputDeviceList(newVideoDeviceList);
    };
  }, [audioInputDeviceList, videoInputDeviceList, state, dispatch, sessionHelper]);

  const initNewPublisher = useCallback(
    (audio, video) => {
      setHasAudio(false);
      setHasVideo(false);
      const pub = OT.initPublisher(state.otPublisherHtmlElement?.current, {
        audioSource: audio || null,
        videoSource: video || null,
        mirror: false,
        audioFallbackEnabled: false,
        noiseSuppression: true,
        echoCancellation: true,
        audioBitrate: 32000,
        videoBitrate: 200000,
        showControls: false,
        publishVideo: false,
        publishAudio: false,
      });

      sessionHelper.publish(pub, (error) => {
        if (error) {
          console.log(error);
        } else {
          dispatch({
            type: SET_OPENTOK_STREAM_ID,
            payload: pub.stream.id,
          });
          dispatch({
            type: SET_PUBLISHER,
            payload: pub,
          });
          dispatch({
            type: SET_CURRENT_AUDIO_INPUT,
            payload: audio,
          });
          dispatch({
            type: SET_CURRENT_VIDEO_INPUT,
            payload: video,
          });
        }
      });
    },
    [sessionHelper, state]
  );

  return (
    <div className="d-flex pt-3 w-100 rounded align-items-stretch justify-content-between">
      <div>
        <Toaster />
      </div>
      <span />
      <div className="d-flex">
        <Button
          color="white"
          className="p-0 d-flex justify-content-center align-items-center bg-danger border-danger"
          style={styles.button}
          onClick={() => setIsLeaving(true)}
        >
          <Exit fill="#FFF" />
        </Button>
        <Button
          className="p-0 d-flex justify-content-center align-items-center bg-primary border-primary"
          style={styles.button}
          onClick={() => setSwitchMic(true)}
        >
          <SettingsIcon fill="#ffffff" />
        </Button>
        <Button
          color="white"
          className={classnames('p-0 d-flex justify-content-center align-items-center', {
            'bg-dark border-dark text-white': !hasAudio,
          })}
          onClick={toggleAudio}
          style={styles.button}
        >
          {hasAudio ? (
            <span style={{ position: 'relative' }}>
              <MicrophoneOn
                style={{
                  position: 'absolute',
                  left: '50%',
                  top: '50%',
                  transform: 'translate(-50%, -50%)',
                }}
                fill={Colors.GREY}
              />
              {viewers.some((v) => v.isMe) && viewers.find((v) => v.isMe).speaking && (
                <Player
                  autoplay
                  loop
                  src={micro}
                  style={{
                    position: 'absolute',
                    left: '50%',
                    top: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 22.5,
                    height: 33,
                  }}
                  fill={Colors.GREY}
                />
              )}
            </span>
          ) : (
            <MicrophoneOff fill="#FFF" />
          )}
        </Button>

        {state.activeModules.includes('active_camera') && (
          <Button
            color="white"
            className={classnames('p-0 d-flex justify-content-center align-items-center', {
              'bg-dark border-dark': !hasVideo,
            })}
            onClick={toggleVideo}
            style={styles.button}
          >
            {hasVideo ? <CameraOn fill={Colors.GREY} /> : <CameraOff fill="#FFF" />}
          </Button>
        )}
        {isAnimator && (
          <div className="position-relative ml-1">
            <Button
              className={classnames(
                'p-0 m-0 d-flex justify-content-center align-items-center text-white',
                { 'bg-success border-success': shareScreen }
              )}
              style={styles.button}
              onClick={togglePresSwitch}
            >
              <Monitor fill={shareScreen ? '#FFFFFF' : Colors.GREY} />
            </Button>
            {switchOn && (
              <SwitchPresentation
                sessionHelper={sessionHelper}
                medias={medias}
                close={togglePresSwitch}
              />
            )}
          </div>
        )}
      </div>
      <Button
        color="white"
        className="p-0 d-flex justify-content-center align-items-center"
        onClick={toggleFullScreen}
        style={styles.button}
      >
        <FullscreenIcon fill={Colors.GREY} />
        {/* <i className={classnames('fa', { 'fa-expand': !fullscreen }, { 'fa-compress': fullscreen })} /> */}
      </Button>
      <Modal
        isOpen={isLeaving}
        toggle={() => setIsLeaving(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={() => setIsLeaving(false)}>{t('Leave the room')}</ModalHeader>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            leaveSession();
          }}
        >
          <ModalBody>{t('Are you sure you want to leave the room?')}</ModalBody>
          <ModalFooter>
            <Button color="primary" type="submit">
              {t('Leave the room')}
            </Button>
            <Button color="danger" onClick={() => setIsLeaving(false)}>
              {t('Stay in the room')}
            </Button>
          </ModalFooter>
        </Form>
      </Modal>

      <Modal
        isOpen={switchMic}
        toggle={() => setSwitchMic(false)}
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <ModalHeader toggle={() => setSwitchMic(false)}>
          Changer de périphérique capture audio
        </ModalHeader>

        <ModalBody>
          {state.currentAudioInput && (
            <span>
              En cours d'utilisation: <b>{state.currentAudioInput.label}</b>
            </span>
          )}

          {!!audioInputDeviceList && audioInputDeviceList.length !== 0 ? (
            <div>
              <Input
                style={{
                  width: 300,
                }}
                type="select"
                className="form-control-alternative mb-3"
                onChange={onChangeAudioInput}
              >
                {audioInputDeviceList.map((device, key) => {
                  if (
                    state.currentAudioInput &&
                    state.currentAudioInput.deviceId === device.deviceId
                  ) {
                    // console.log(device);
                  }

                  return (
                    <option
                      selected={
                        state.currentAudioInput &&
                        state.currentAudioInput.deviceId === device.deviceId
                      }
                      key={key}
                      value={JSON.stringify(device)}
                    >
                      {device.label}
                    </option>
                  );
                })}
              </Input>
            </div>
          ) : (
            <span className="text-light mb-2">Aucune entrée audio n'a été détectée</span>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default PresentationControls;
PresentationControls.propTypes = {
  sessionHelper: PropTypes.object,
  medias: PropTypes.array,
  isAnimator: PropTypes.bool,
};
const styles = {
  button: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    maxWidth: 50,
    maxHeight: 50,
    fontSize: 18,
    color: 'grey',
    fontWeight: 'normal',
  },
  switchPres: {
    top: -20,
    left: '50%',
    transform: 'translate(-50%, -100%)',
    border: 'solid 1px #3c7cff',
    borderRadius: 6,
    minWidth: 250,
    fontSize: 14,
  },
};
