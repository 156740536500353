import React, { useCallback, useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Button, Form, Input } from 'reactstrap';
import http from 'utils/http';
import { ADD_POLL, useSessionsContext } from 'context/sessionContext';
import gscss from 'components/common/common.module.scss';
import NodeServerHttp from '../../../../utils/nodeApiHttp';
import { useAuthContext } from '../../../../context/authContext';

const CreatePoll = ({ room, onPollCreated, onCreatePoll, onEditPoll, editPoll, onCancel }) => {
  const authContext = useAuthContext();

  const [t] = useTranslation('front');
  const [tCommon] = useTranslation();
  const title = useRef();
  const question = useRef();
  const eventId = useRef();
  const [options, setOptions] = useState(['', '']);
  const [allowMultipleCheckbox, setAllowMultiple] = useState(true);
  const { dispatch } = useSessionsContext();
  const [currentEditPollId, setCurrentEditPollId] = useState(null);
  const [eventList, setEventList] = useState([]);

  const onCreatePollCallback = useCallback(
    (value) => {
      if (onCreatePoll) {
        onCreatePoll(value);
      }
    },
    [onCreatePoll]
  );

  useEffect(() => {
    NodeServerHttp.get(`/events?lab_id=${authContext.state.user.lab_id}`).then((res) => {
      setEventList(res);
    });
  }, [authContext]);

  const onEditPollCallback = useCallback(
    (value) => {
      if (onEditPoll) {
        onEditPoll(value);
      }
    },
    [onEditPoll]
  );

  useEffect(() => {
    if (editPoll.id) {
      if (question?.current) {
        setCurrentEditPollId(editPoll.id);
        title.current.value = editPoll.title;
        question.current.value = editPoll.question;
        setOptions([...editPoll.options]);
        setAllowMultiple(editPoll.allowMultiple);
      }
    }
  }, [editPoll, question, title]);

  const addOption = useCallback(() => {
    setOptions([...options, '']);
  }, [options, setOptions]);

  const updateOption = useCallback(
    (id, value) => {
      setOptions(options.map((option, i) => (i !== id ? option : value)));
    },
    [options, setOptions]
  );

  const removeOption = useCallback(
    (id) => {
      setOptions(options.filter((_, i) => i !== id));
    },
    [options, setOptions]
  );

  const allowMultiple = useCallback(
    (e) => {
      setAllowMultiple(e.target.checked);
    },
    [setAllowMultiple]
  );

  const createPoll = useCallback(
    async (e, active = false) => {
      // if (question?.current) {
      e.preventDefault();
      if (
        question.current.value.match(/^\s*$/) ||
        options.length < 2 ||
        options.some((o) => o.match(/^\s*$/))
      ) {
        return;
      }

      const poll = {
        question: question.current.value,
        title: title.current.value || question.current.value,
        options,
        answers: options.map(() => 0),
        allowMultiple: allowMultipleCheckbox,
        active,
        event_id: eventId.current ? eventId.current.value : room.event_id,
      };

      if (currentEditPollId !== null) {
        poll.id = currentEditPollId;
      }

      // check if poll is created during an event
      if (room) {
        poll.room_id = room.id;
      }

      try {
        const response =
          currentEditPollId === null
            ? await http.post('polls', poll)
            : await http.put(`polls/${currentEditPollId}`, poll);

        if (currentEditPollId === null) {
          onCreatePollCallback(response);
        } else if (onEditPoll) {
          onEditPollCallback(response);
        }
        setCurrentEditPollId(null);

        if (room) {
          dispatch({
            type: ADD_POLL,
            payload: response,
          });
        }
        setOptions(['', '']);
        question.current.value = '';
        title.current.value = '';
        if (typeof onPollCreated === 'function') {
          onPollCreated();
        }
      } catch (err) {
        console.log(err);
      }
      //  }
    },
    [
      question,
      title,
      options,
      allowMultipleCheckbox,
      currentEditPollId,
      room,
      onEditPoll,
      onPollCreated,
      onCreatePollCallback,
      onEditPollCallback,
      dispatch,
    ]
  );

  const scheduleAndStartPoll = useCallback((e) => createPoll(e, true), [createPoll]);

  return (
    <Form className="d-flex flex-column mb-3 w-100" action="#" onSubmit={createPoll}>
      <Input
        id="title"
        innerRef={title}
        type="text"
        style={{
          fontSize: 11,
        }}
        placeholder={t('Poll title')}
        className="mb-2 rounded border shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center font-weight-bold"
      />
      <Input
        id="question"
        innerRef={question}
        type="text"
        style={{
          fontSize: 11,
        }}
        placeholder={t('Ask a question')}
        required
        className="mb-2 rounded border shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center font-weight-bold"
      />

      {!room && (
        <Input
          id="question"
          innerRef={eventId}
          type="select"
          style={{
            fontSize: 11,
          }}
          placeholder={t('Ask a question')}
          required
          className="mb-2 rounded border shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center font-weight-bold"
        >
          {eventList.map((event) => {
            return <option value={event.id}>{event.name}</option>;
          })}
        </Input>
      )}

      {options.map((option, i) => (
        <div key={i} className="mb-3 position-relative">
          <Input
            key={i}
            type="text"
            className="w-100"
            style={{
              fontSize: 11,
            }}
            required
            onChange={(e) => updateOption(i, e.target.value)}
            value={option}
            placeholder={`${t('Answer option')} ${i + 1}`}
          />
          <span
            className="position-absolute h-100 d-inline-flex align-items-center justify-content-center"
            onClick={() => removeOption(i)}
            role="button"
            tabIndex={0}
            style={{
              right: 2,
              top: 0,
              width: 30,
              cursor: 'pointer',
              zIndex: 2,
            }}
          >
            <i className="fa fa-trash text-gray" aria-hidden="true" />
          </span>
        </div>
      ))}
      <span
        className="mb-2 p-2 w-100 text-left d-inline-flex justify-content-start align-items-center"
        onClick={addOption}
        role="button"
        tabIndex={0}
      >
        <span
          style={{
            borderRadius: '50%',
            width: 20,
            height: 20,
            minWidth: 20,
            minHeight: 20,
            fontSize: 20,
          }}
          className="border border-primary p-1 d-inline-flex justify-content-center align-items-center font-weight-bold text-primary mr-2"
        >
          +
        </span>{' '}
        {t('Add answer option')}
      </span>
      <div className="custom-control custom-checkbox ml-2 mb-3">
        <input
          className="custom-control-input"
          defaultChecked
          id="check-allow-multiple"
          type="checkbox"
          name="check-allow-multiple"
          onChange={allowMultiple}
        />
        <label
          className="custom-control-label"
          htmlFor="check-allow-multiple"
          style={{
            fontSize: 12,
          }}
        >
          {t('Multiple choice')}
        </label>
      </div>
      <div className="d-flex justify-content-between">
        {room && (
          <Button
            style={{
              minWidth: 120,
              fontSize: 11,
            }}
            onClick={scheduleAndStartPoll}
            color="primary"
          >
            {t('Start poll')}
          </Button>
        )}
        {!room && (
          <Button
            style={{
              minWidth: 120,
              fontSize: 11,
              width: '40%',
            }}
            className={gscss.comCancelButton}
            color="secondary"
            type="button"
            outline
            onClick={onCancel}
          >
            {tCommon('Cancel')}
          </Button>
        )}
        <Button
          style={{
            minWidth: 120,
            fontSize: 11,
            width: '40%',
          }}
          color="primary"
          type="submit"
          outline
        >
          {tCommon('Save')}
        </Button>
      </div>
    </Form>
  );
};
CreatePoll.propTypes = {
  room: PropTypes.object,
  onPollCreated: PropTypes.func,
};
export default CreatePoll;
