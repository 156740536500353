import React from 'react';
import { Col, Row, Nav, NavItem, NavLink } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const [t] = useTranslation();
  return (
    <footer className="footer bg-default third-color">
      <Row className="align-items-center container m-auto justify-content-xl-between">
        <Col xl="6">
          <div className="copyright text-center text-xl-left text-muted">
            © 2021 {t('Trois Prime')}
          </div>
        </Col>

        <Col xl="6">
          <Nav className="nav-footer justify-content-center justify-content-xl-end">
            <NavItem>
              <NavLink href="#" rel="noopener noreferrer" target="_blank">
                {t('About us')}
              </NavLink>
            </NavItem>
          </Nav>
        </Col>
      </Row>
    </footer>
  );
};

export default Footer;
