import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import http from 'utils/http';
import { useAuthContext } from 'context/authContext';
import EventThumbnail from 'components/common/EventThumbnail';
import { useThemeContext, actions } from 'context/themeContext';
import HeaderNavbar from './headers/HeaderNavbar';
import Footer from './footers/Footer';
import nodeApiHttp from '../../utils/nodeApiHttp';

const Event = () => {
  const authContext = useAuthContext();
  const [t] = useTranslation('front');
  const [event, setEvent] = useState(null);
  const { dispatch } = useThemeContext();

  useEffect(() => {
    if (authContext.state.eventId) {
      nodeApiHttp.get(`events/${authContext.state.eventId}`).then((value) => {
        setEvent(value);
        dispatch({
          type: actions.UPDATE_SOME,
          payload: {
            logo: value.lab.lab_logo,
          },
        });

        authContext.dispatch({
          type: 'EVENT_DATA',
          payload: value,
        });

        authContext.dispatch({
          type: 'CURRENT_LAB',
          payload: value.lab,
        });
      });
    }
  }, [authContext.state.eventId, dispatch]);

  return (
    <Container fluid className="h-100 text-center bg-default third-color">
      <Row
        className="justify-content-center mb-3 header pb-7 pt-7 position-relative first-color"
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex justify-content-between"
          style={{
            top: '.5rem',
          }}
        >
          <HeaderNavbar />
        </Container>
        <hr
          className="mb-0 mt-2"
          style={{
            width: '100vw',
            borderTop: '2px solid #FFFFFF22',
          }}
        />
        <Col className="mt-5">
          <h1 className="text-white mb-5">{t('Welcome to Vtopia!')}</h1>
          <p className="text-lead text-light">{t('Join an event')}</p>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      <Container>
        <Col
          lg="5"
          md="7"
          className="mt--7"
          style={{
            margin: 'auto',
          }}
        >
          <Row>{event && <EventThumbnail event={event} />}</Row>
        </Col>
      </Container>
      <Footer />
    </Container>
  );
};

export default Event;
