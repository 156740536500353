import React, { useCallback, useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import { SessionContext } from 'context/sessionContext';
import { ListGroup } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import ViewersListItem from './ViewersListItem';

const ViewersList = (props) => {
  const { streams, isAnimator, session, media, style } = props;
  const { state } = useContext(SessionContext);
  const { viewers } = state;

  const sortViewers = useCallback((a, b) => {
    if (a.isAnimator) {
      return -1;
    }
    if (b.isAnimator) {
      return 1;
    }
    return 0;
  }, []);

  return (
    <SimpleBar
      className="w-100 viewers-list"
      style={{
        ...style,
      }}
    >
      <ListGroup
        style={{
          display: 'flex',
          ...style,
        }}
        className="flex-column flex-grow-1"
      >
        {viewers.sort(sortViewers).map((viewer) => {
          return (
            <ViewersListItem
              key={viewer.id}
              tokSession={session}
              stream={streams.find((s) => s.connection.id === viewer.id)}
              {...viewer}
              media={media}
              userIsAnimator={isAnimator}
            />
          );
        })}
      </ListGroup>
    </SimpleBar>
  );
};
export default ViewersList;
ViewersList.propTypes = {
  streams: PropTypes.array,
  session: PropTypes.object,
  media: PropTypes.object,
  isAnimator: PropTypes.bool,
};
