import React, { useCallback, useEffect, useState } from 'react';
import { useRouteMatch } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Spinner } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import http from 'utils/http';
import { useSessionsContext } from 'context/sessionContext';
import { Colors } from 'Constants';
import download from 'assets/img/icons/download.svg';
import computer from 'assets/img/icons/computer.svg';

const SharePanel = (props) => {
  const { close } = props;
  const { id } = useRouteMatch().params;
  const { state, dispatch } = useSessionsContext();
  const [t] = useTranslation('front');
  const canShare = state.role.indexOf('animator') > -1;
  const [loading, setLoading] = useState(true);
  const [documents, setDocuments] = useState([]);

  const onClickAnywhere = useCallback(
    (e) => {
      if (!e.target.closest('#share-panel')) {
        close(e);
      }
    },
    [close]
  );

  useEffect(() => {
    // panel closing management
    document.body.addEventListener('click', onClickAnywhere, false);
    return () => document.body.removeEventListener('click', onClickAnywhere, false);
  }, [onClickAnywhere]);

  useEffect(() => {
    const documentsAPI = canShare ? 'documents/find/by-lab' : `rooms/${id}/documents`;
    http
      .get(documentsAPI)
      .then(setDocuments)
      .then(() => setLoading(false));
    dispatch({
      type: 'RESET_SHARE_PANEL_NOTIFICATION',
    });
  }, [id, canShare, close, dispatch]);

  const shareDocument = useCallback(
    async (docId) => {
      const i = documents.findIndex((doc) => doc.id === docId);
      documents[i].shared = true;
      setDocuments([...documents]);
      await http.put(`rooms/${id}/documents/add`, {
        documents: [docId],
      });

      if (state.helper.session) {
        state.helper.session.signal({
          type: 'document-share',
        });
      }
    },
    [id, documents, state.helper]
  );

  const unshareDocument = useCallback(
    async (docId) => {
      const i = documents.findIndex((doc) => doc.id === docId);
      documents[i].shared = false;
      setDocuments([...documents]);
      await http.put(`rooms/${id}/documents/remove`, docId);
    },
    [id, documents]
  );

  const downloadDocument = useCallback(
    async (docId) => {
      const doc = documents.find((d) => d.id === docId);
      await http.download(`documents/${docId}/download`, `${doc.name}.${doc.extension}`);
    },
    [documents]
  );

  return (
    <table
      className="position-absolute shadow rounded text-left text-white"
      id="share-panel"
      style={styles.panel}
    >
      <thead
        style={{
          borderBottom: 'solid 1px #1F3A68',
        }}
      >
        <tr>
          <th className="px-4 pt-3 pb-2 text-light w-100">{t('Documents')}</th>
          <th
            className="px-4 pt-3 pb-2 text-light text-center"
            style={{
              minWidth: 120,
            }}
          >
            {t('Action')}
          </th>
        </tr>
      </thead>
      <tbody>
        {loading && (
          <div className="w-100 d-flex justify-content-center">
            <Spinner color="secondary" />
          </div>
        )}
        {documents.map((doc, key) => (
          <tr key={key}>
            <td className="py-4 px-4 w-100">{doc.name}</td>
            <td
              className="py-4 px-4 text-center"
              style={{
                cursor: 'pointer',
                minWidth: 120,
              }}
            >
              {canShare && !doc.shared && (
                // <i className='fa fa-share-square text-primary p-1' onClick={() => shareDocument(doc.id)} />
                <span onClick={() => shareDocument(doc.id)} role="button" tabIndex={0}>
                  <img className="mr-2" src={computer} alt={t('Share')} title={t('Share')} />
                </span>
              )}
              {canShare && doc.shared && (
                // <i className='fa fa-minus-square text-primary mr' onClick={() => unshareDocument(doc.id)} />
                <span onClick={() => unshareDocument(doc.id)} role="button" tabIndex={0}>
                  <img className="mr-2" src={computer} alt={t('Share')} title={t('Share')} />
                </span>
              )}
              <span onClick={() => downloadDocument(doc.id)} role="button" tabIndex={0}>
                <img className="ml-2" src={download} alt={t('Download')} title={t('Download')} />
              </span>
            </td>
          </tr>
        ))}
        {!loading && documents.length === 0 && <div>{t('No documents')}</div>}
      </tbody>
      {/* <strong className=''>{t('Document')}</strong> */}
      {/* <hr className='my-2 mx--4' style={{ borderColor: '#1F3A68' }} /> */}
    </table>
  );
};

export default SharePanel;

SharePanel.propTypes = {
  close: PropTypes.func.isRequired,
};

const styles = {
  panel: {
    top: 20,
    right: 0,
    // border: 'solid 1px #3c7cff',
    backgroundColor: Colors.DARK_BLUE,
    minWidth: 400,
    fontSize: 10.5,
    color: '#707070',
    zIndex: 1,
  },
};
