/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable max-len */
import React from 'react';
import { Row, Col } from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useAuthContext } from 'context/authContext';

import css from './profile.module.scss';

const Avatar = () => {
  const history = useHistory();
  const authContext = useAuthContext();
  const navigate = () => {
    if (history.location.pathname && history.location.pathname.indexOf('/admin') === 0) {
      history.push('/admin/profile');
    }
  };
  return (
    <>
      {authContext?.state?.user && Object.keys(authContext.state.user).length !== 0 && (
        <Row>
          <Col>
            <div onClick={navigate} className={css.avatar}>
              <div className="imageContainer">
                {!!authContext.state.user && !!authContext.state.user.avatar_url && (
                  <img
                    alt="avatar"
                    src={`${process.env.REACT_APP_API_URI_NODE}${authContext.state.user.avatar_url.formats.thumbnail.url}`}
                  />
                )}
              </div>
              <span>{`${authContext.state.user.first_name} ${authContext.state.user.last_name}`}</span>
            </div>
          </Col>
        </Row>
      )}
    </>
  );
};

export default Avatar;
