import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { SessionContext } from 'context/sessionContext';
import { AuthContext } from 'context/authContext';

const Quiz = ({ show, isAnimator, room, participant }) => {
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const container = useRef(null);

  const { state } = useContext(SessionContext);
  const { state: authState } = useContext(AuthContext);
  const { shareScreen } = state;

  const updateDimensions = useCallback(() => {
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (w / h > 960 / 600) {
      setHeight('100%');
      setWidth((h * 960) / 600);
    } else {
      setWidth('100%');
      setHeight((w * 600) / 960);
    }
  }, [container, setWidth, setHeight]);

  useEffect(() => {
    if (container.current) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
    return null;
  }, [container, setWidth, setHeight, updateDimensions, authState]);

  return (
    <div
      ref={container}
      id="vtopia-unity"
      style={{
        ...styles.wrapper,
        width,
        height,
        display: shareScreen || !show ? 'none' : 'block',
      }}
    >
      {!!participant && (
        <iframe
          allow="autoplay"
          title="Janssen"
          src={`${process.env.REACT_APP_JANSSEN_QUIZ_V2}?&room=${room.id}&role=${participant.role}&email=${participant.email}`}
          className="w-100 h-100"
          frameBorder="0"
        />
      )}
    </div>
  );
};

export default Quiz;

const styles = {
  wrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
    flex: 1,
  },
};
