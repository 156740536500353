import React from 'react';

export const SessionContext = React.createContext();

export function useSessionsContext() {
  const context = React.useContext(SessionContext);
  if (context === undefined) {
    throw new Error('useSessionsContext must be used within a SessionProvider');
  }
  return context;
}

export const SET_OPENTOK_STREAM_ID = 'SET_OPENTOK_STREAM_ID';
export const START_SESSION = 'START_SESSION';
export const END_SESSION = 'END_SESSION';
export const ADD_VIEWER = 'ADD_VIEWER';
export const UPDATE_VIEWER = 'UPDATE_VIEWER';
export const SET_VIEWER_SPEAKING = 'SET_VIEWER_SPEAKING';
export const REMOVE_VIEWER = 'REMOVE_VIEWER';
export const UPDATE_VIEWER_NOTES = 'UPDATE_VIEWER_NOTES';

export const VIEWER_REQUEST_SPEAK = 'VIEWER_REQUEST_SPEAK';
export const SET_SPEAKER = 'SET_SPEAKER';
export const SET_MESSAGES = 'SET_MESSAGES';
export const SET_SHARE_SCREEN = 'SET_SHARE_SCREEN';
export const SET_SHARE_SCREEN_PUBLISHER = 'SET_SHARE_SCREEN_PUBLISHER';
export const SET_PUBLISHER = 'SET_PUBLISHER';
export const SET_CURRENT_AUDIO_INPUT = 'SET_CURRENT_AUDIO_INPUT';
export const SET_CURRENT_VIDEO_INPUT = 'SET_CURRENT_VIDEO_INPUT';
export const SET_OT_PUBLISHER_HTML_ELEMENT = 'SET_OT_PUBLISHER_HTML_ELEMENT';

export const SHOW_POLL = 'SHOW_POLL';
export const REMOVE_POLL = 'REMOVE_POLL';
export const UPDATE_POLL = 'UPDATE_POLL';
export const SET_POLLS = 'SET_POLLS';
export const ADD_POLL = 'ADD_POLL';
export const SET_NOTES = 'SET_NOTES';
export const SET_ROLE = 'SET_ROLE';
export const SET_ACTIVE_MODULES = 'SET_ACTIVE_MODULES';
export const SET_LAB = 'SET_LAB';
export const SET_PARTICIPANT = 'SET_PARTICIPANT';
export const SET_LOCAL_STREAM = 'SET_LOCAL_STREAM';
export const SET_LAB_LOGO_URL = 'SET_LAB_LOGO_URL';
export const INC_SHARE_PANEL_NOTIFICATION = 'INC_SHARE_PANEL_NOTIFICATION';
export const RESET_SHARE_PANEL_NOTIFICATION = 'RESET_SHARE_PANEL_NOTIFICATION';

export const RESET_RP_DATA = 'RESET_RP_DATA';

export const initialState = {
  opentokStreamId: null,
  helper: null,
  shareScreen: false,
  shareScreenPublisher: null,
  sessionID: '',
  sessionToken: '',
  sessionName: '',
  datetimeStart: null,
  viewers: [],
  activeModules: [],
  lab: null,
  labLogoUrl: false,
  maxViewers: 0,
  polls: [],
  messages: [],
  speaker: null,
  presentation: {
    step: 0,
  },
  notes: '',
  viewerNotes: [],
  role: null,
  participant: null,
  sharePanelNotification: 0,
  publisher: null,
  currentAudioInput: null,
  currentVideoInput: null,
  otPublisherHtmlElement: null,
  localStream: null,
};

const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case START_SESSION:
      return {
        ...state,
        helper: payload.helper,
        sessionID: payload.id,
        sessionToken: payload.token,
        sessionName: payload.name,
        datetimeStart: payload.datetime ? new Date(payload.datetime) : new Date(),
      };
    case END_SESSION:
      return {
        ...initialState,
      };
    case SET_PUBLISHER:
      return {
        ...state,
        publisher: payload,
      };
    case SET_CURRENT_AUDIO_INPUT:
      return {
        ...state,
        currentAudioInput: payload,
      };
    case SET_CURRENT_VIDEO_INPUT:
      return {
        ...state,
        currentVideoInput: payload,
      };
    case SET_OT_PUBLISHER_HTML_ELEMENT:
      return {
        ...state,
        otPublisherHtmlElement: payload,
      };
    case ADD_VIEWER:
      return {
        ...state,
        viewers: [...state.viewers, payload],
        maxViewers:
          state.viewers.length >= state.maxViewers ? state.viewers.length + 1 : state.maxViewers,
      };
    case UPDATE_VIEWER:
      return {
        ...state,
        viewers: state.viewers.map((v) => (v.id === payload.id ? { ...v, ...payload } : v)),
      };
    case SET_VIEWER_SPEAKING:
      return {
        ...state,
        viewers: state.viewers.map((v) =>
          v.id === payload.id ? { ...v, speaking: payload.speaking } : v
        ),
      };
    case UPDATE_VIEWER_NOTES:
      if (
        !state.viewerNotes.some((note) => note.id === payload.id) &&
        state.viewers.some((v) => v.id === payload.id)
      ) {
        // Get the user's name if it's the first time receiving the note
        return {
          ...state,
          viewerNotes: [
            ...state.viewerNotes,
            {
              ...payload,
              name: state.viewers.find((v) => v.id === payload.id).name,
            },
          ],
        };
      }
      return {
        ...state,
        viewerNotes: state.viewerNotes.map((note) =>
          note.id === payload.id
            ? {
                ...note,
                ...payload,
              }
            : note
        ),
      };

    case REMOVE_VIEWER:
      return {
        ...state,
        viewers: state.viewers.filter((viewer) => viewer.id !== payload.id),
        speaker: state.speaker === payload.id ? null : state.speaker,
      };
    case VIEWER_REQUEST_SPEAK:
      return {
        ...state,
        viewers: state.viewers.map((viewer) =>
          viewer.id === payload.id
            ? {
                ...viewer,
                requestingSpeak: payload.request,
              }
            : viewer
        ),
      };
    case SET_OPENTOK_STREAM_ID:
      return {
        ...state,
        opentokStreamId: payload,
      };
    case SET_SPEAKER:
      return {
        ...state,
        speaker: payload,
      };
    case SET_MESSAGES:
      return {
        ...state,
        messages: payload,
      };
    case SET_LAB:
      return {
        ...state,
        lab: payload,
      };
    case ADD_POLL:
      return {
        ...state,
        polls: [...state.polls, payload],
      };
    case SET_POLLS:
      return {
        ...state,
        polls: payload,
      };
    case SET_NOTES:
      return {
        ...state,
        notes: payload,
      };
    case SET_PARTICIPANT:
      return {
        ...state,
        participant: payload,
      };
    case SHOW_POLL:
      return {
        ...state,
        polls: [...state.polls, payload],
      };
    case REMOVE_POLL:
      return {
        ...state,
        polls: state.polls.filter((poll) => poll.id !== payload),
      };
    case UPDATE_POLL:
      return {
        ...state,
        polls: state.polls.map((poll) => (poll.id !== payload.id ? poll : payload)),
      };
    case SET_SHARE_SCREEN:
      return {
        ...state,
        shareScreen: !!payload,
      };
    case SET_SHARE_SCREEN_PUBLISHER:
      return {
        ...state,
        shareScreenPublisher: payload,
      };
    case RESET_RP_DATA:
      return {
        ...state,
        viewers: [],
        maxViewers: 0,
        polls: [],
        messages: [],
        shareScreen: false,
        notes: '',
        viewerNotes: [],
      };
    case SET_ROLE:
      return {
        ...state,
        role: payload,
      };
    case SET_ACTIVE_MODULES:
      return {
        ...state,
        activeModules: payload,
      };
    case SET_LAB_LOGO_URL:
      return {
        ...state,
        labLogoUrl: payload,
      };
    case INC_SHARE_PANEL_NOTIFICATION:
      return {
        ...state,
        sharePanelNotification: state.sharePanelNotification + 1,
      };
    case RESET_SHARE_PANEL_NOTIFICATION:
      return {
        ...state,
        sharePanelNotification: 0,
      };
    case SET_LOCAL_STREAM:
      return {
        ...state,
        localStream: payload,
      };
    default:
      return state;
  }
};

export const SessionProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <SessionContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </SessionContext.Provider>
  );
};
export default reducer;
