import React, { useEffect, useState } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import css from 'components/admin/pharmacovigilance/pharma-report.module.scss';
import http from 'utils/http';
import moment from 'moment';

const PharmaReportListDetail = (props) => {
  const { id } = props;
  const [content, setContent] = useState();

  useEffect(() => {
    http
      .get(`pharmacovigilance-report/${id}`)
      .then((response) => {
        setContent(response);
      })
      .catch(() => {
        setContent({
          sexe: 'F',
          initials: 'AIKO',
          age_range: '20-28',
          date_of_birth: '2021-09-02T13:11:43.000000Z',
          name: 'unamed',
          first_name: 'unapmed',
          phone_number: '+00000000000',
          email_adress: 'aiko@at.dot',
          specialty: '',
          postal_address: '1 rue de la rue',
          zip_code: '75016',
          drug_name: '4hydro',
          drug_batch_number: 'BHF06742',
          side_effect_description: '...',
        });
      });
  }, [id]);

  const [tCommon] = useTranslation();
  return (
    <Row className={css.detailPanel}>
      <Col>
        <Row>
          <Col>
            <h5>{tCommon('TREATED PATIENT')}</h5>
          </Col>
        </Row>
        <Row className="pb-3 pl-3">
          <Col>
            <Label>
              <h6>{tCommon('Sexe')}</h6>
            </Label>
            <p>{content?.gender}</p>
          </Col>
          <Col>
            <Label>
              <h6>{tCommon('Initials')}</h6>
            </Label>
            <p>{content?.patient_lastname}</p>
          </Col>
          <Col>
            <Label>
              <h6>{tCommon('Age range')}</h6>
            </Label>
            <p>{content?.age_range}</p>
          </Col>
          <Col>
            <Label>
              <h6>{tCommon('Date of birth')}</h6>
            </Label>
            <p>
              {content?.patient_birthday && moment(content.patient_birthday).format('DD/MM/YYYY')}
            </p>
          </Col>
          <Col />
        </Row>
        <Row>
          <Col>
            <h5>{tCommon('HEALTH PROFESSIONAL')}</h5>
          </Col>
        </Row>
        <Row className="pb-3 pl-3">
          <Col>
            <Row>
              <Col>
                <Label>
                  <h6>{tCommon('Name')}</h6>
                </Label>
                <p>{content?.doctor_lastname}</p>
              </Col>
              <Col>
                <Label>
                  <h6>{tCommon('First name')}</h6>
                </Label>
                <p>{content?.doctor_firstname}</p>
              </Col>
              <Col>
                <Label>
                  <h6>{tCommon('Phone number')}</h6>
                </Label>
                <p>{content?.phone_number}</p>
              </Col>
              <Col>
                <Label>
                  <h6>{tCommon('E-mail address')}</h6>
                </Label>
                <p>{content?.doctor_email}</p>
              </Col>
              <Col />
            </Row>
            <Row>
              <Col>
                <Label>
                  <h6>{tCommon('Specialty')}</h6>
                </Label>
                <p>{content?.doctor_specialty}</p>
              </Col>
              <Col>
                <Label>
                  <h6>{tCommon('Postal address')}</h6>
                </Label>
                <p>{content?.doctor_address}</p>
              </Col>
              <Col>
                <Label>
                  <h6>{tCommon('Zip code')}</h6>
                </Label>
                <p>{content?.doctor_zip_code}</p>
              </Col>
              <Col />
              <Col />
            </Row>
          </Col>
        </Row>
        <Row>
          <Col>
            <h5>{tCommon('DROGS')}</h5>
          </Col>
        </Row>
        <Row className="pl-3">
          <Col>
            <Label>
              <h6>{tCommon('Drug name')}</h6>
            </Label>
            <p>{content?.drug_name}</p>
          </Col>
          <Col>
            <Label>
              <h6>{tCommon('Drug batch number')}</h6>
            </Label>
            <p>{content?.drug_batch_number}</p>
          </Col>
          <Col>
            <Label>
              <h6>{tCommon('Side effect description')}</h6>
            </Label>
            <p>{content?.drug_adverse_reaction}</p>
          </Col>
          <Col />
          <Col />
        </Row>
        <Row className="pl-3">
          <Col>
            <h6>ihjszdf shfk hfkhqs fkhsfk fq</h6>
            <p>djlkqshdf kqsdfhqsgfhjqsfjqsf hjgfhjg fjhqsgfj</p>
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default PharmaReportListDetail;
