import React, { useCallback, useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import { useHistory, Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

import { useAuthContext, login, hasAnyRole } from 'context/authContext';
import logo from 'assets/img/logo-vtopia.svg';
import logoV from 'assets/img/logo-v.svg';
import { useThemeContext } from 'context/themeContext';
import css from 'components/common/common.module.scss';
import Footer from './footers/Footer';

import 'App.css';

const Login = (props) => {
  const { location } = props;
  const authContext = useAuthContext();
  const [t] = useTranslation('front');
  const history = useHistory();
  const [rememberMe, setRememberMe] = useState(false);
  const [display, setDisplay] = useState(false);
  const { logo: labLogo } = useThemeContext();
  const [isLoading, setIsLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);

  const handleSubmit = useCallback(
    async (e) => {
      setLoginError(false);
      e.preventDefault();
      const form = e.target;
      const email = form.email.value;
      const password = form.password.value;
      if (email.match(/^\s*$/) || password.match(/^\s*$/)) {
        return;
      }
      try {
        setIsLoading(true);
        await login(authContext, {
          email,
          password,
          rememberMe,
        });
      } catch (error) {
        console.log(error);
        setLoginError(true);
        setIsLoading(false);
      }
    },
    [authContext, rememberMe]
  );

  useEffect(() => {
    if (authContext.state.isLogged && hasAnyRole(authContext, ['user', 'admin', 'super-admin'])) {
      history.push(location?.state?.from ?? '/admin/events');
    } else {
      setDisplay(true);
    }
  }, [authContext, history, location]);

  return display ? (
    <Container fluid className="h-100 text-center bg-default third-color">
      <Row
        className="justify-content-center mb-3 header pb-7 pt-7 position-relative first-color"
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex"
          style={{
            top: 20,
          }}
        >
          <img
            src={labLogo || logo}
            draggable={false}
            style={{
              width: '60px',
              height: 'auto',
              marginRight: '10px',
            }}
            alt="Vtopia"
          />
        </Container>
        <Col>
          <h1 className="text-white mb-5">{t('Welcome to Vtopia!')}</h1>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      <Container>
        <Col
          lg="5"
          md="7"
          className="mt--7"
          style={{
            margin: 'auto',
          }}
        >
          <Card className="bg-secondary shadow border-0">
            <CardHeader className="bg-transparent pb-4">
              <div className="text-muted text-center mt-2 mb-3">
                <strong>{t('Login')}</strong>
              </div>
              <img
                alt=""
                src={logoV}
                style={{
                  height: '80px',
                  width: 'auto',
                }}
              />
            </CardHeader>
            <CardBody className="px-lg-5 py-lg-5">
              <Form role="form" onSubmit={handleSubmit}>
                <FormGroup className="mb-3">
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-email-83" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input placeholder={t('Email address')} type="email" id="email" required />
                  </InputGroup>
                </FormGroup>
                <FormGroup>
                  <InputGroup className="input-group-alternative">
                    <InputGroupAddon addonType="prepend">
                      <InputGroupText>
                        <i className="ni ni-lock-circle-open" />
                      </InputGroupText>
                    </InputGroupAddon>
                    <Input
                      placeholder={t('Password')}
                      id="password"
                      type="password"
                      required
                      autoComplete="password"
                    />
                  </InputGroup>
                </FormGroup>
                <div className="custom-control custom-control-alternative custom-checkbox text-left">
                  <input
                    className="custom-control-input"
                    id="customCheckLogin"
                    type="checkbox"
                    value={rememberMe}
                    onChange={() => setRememberMe(!rememberMe)}
                  />
                  <label className="custom-control-label" htmlFor="customCheckLogin">
                    <span className="text-muted">{t('Remember me')}</span>
                  </label>
                </div>
                <div className="text-center">
                  {!isLoading && (
                    <Button className="my-4" color="primary" type="submit">
                      {t('Login')}
                    </Button>
                  )}
                  {isLoading && (
                    <div className={`${css.comLoading}`}>
                      <div className={css.comSpinning}>
                        <FontAwesomeIcon className="pt-1 mb-1" icon={faCircleNotch} />
                      </div>
                    </div>
                  )}
                  {loginError && (
                    <div className={css.comError}>
                      <span>{t('Please verify your login/password')}</span>
                    </div>
                  )}
                </div>
              </Form>
            </CardBody>
          </Card>
          <Row className="mt-3">
            <Col
              style={{
                textAlign: 'center',
              }}
            >
              {/* <Link className="text-light" to="/reset-password">
                <small>{t('Forgot password')}</small>
                </Link>
              */}
            </Col>
          </Row>
        </Col>
      </Container>
      <Footer />
    </Container>
  ) : (
    <div />
  );
};

export default Login;
