import React from 'react';

export const NotificationContext = React.createContext();

export function useNotificationContext() {
  const context = React.useContext(NotificationContext);
  if (context === undefined) {
    throw new Error('useNotificationContext must be used within a NotificationProvider');
  }
  return context;
}

export const initialState = [];

let lastId = 0;
const newId = () => lastId++;

export const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'ADD_NOTIFICATION':
      payload.id = newId();
      payload.title = payload.title ?? 'Success';
      payload.message = payload.message ?? 'Sample message...';
      payload.type = payload.type ?? 'success';
      payload.icon = payload.icon ?? 'ni ni-bell-55';
      payload.place = payload.place ?? 'tr';
      payload.closeButton = payload.place ?? true;
      return [...state, payload];

    case 'REMOVE_NOTIFICATION':
      return [...state.filter((n) => n.id !== payload)];

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const NotificationProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <NotificationContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </NotificationContext.Provider>
  );
};
