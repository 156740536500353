import React, { useCallback } from 'react';
import { Card, CardBody, CardHeader, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import http from 'utils/http';

const PollsStats = ({ poll, openTokSessionId }) => {
  const [t] = useTranslation('front');

  const closePoll = useCallback(async () => {
    try {
      await http.put(`polls/${poll.id}`, {
        event_id: poll.event_id,
        active: false,
        opentok_session_id: openTokSessionId,
      });
    } catch (err) {
      console.log(err);
    }
  }, [openTokSessionId, poll.id]);

  return (
    <Card className="w-100 flex-shrink-0 bg-light shadow-none">
      <CardHeader
        className="p-0"
        style={{
          display: 'inline-flex',
          borderRadius: 0,
          borderWidth: 1,
          border: '1px solid #e9ecef',
          fontSize: 12,
        }}
      >
        <b>{poll.title}</b>
      </CardHeader>
      <CardBody
        className={classnames(
          {
            'bg-secondary': poll.active,
          },
          'p-0'
        )}
      >
        <div>{poll.question}</div>
        {poll.options.map((option, j) => (
          <div key={j} className="text-dark d-flex justify-content-between bg-transparent p-2">
            <strong>{option}</strong>
            <span>{poll.answers[j] && poll.answers[j]}</span>
          </div>
        ))}
        <Button className="mt-3 w-100" onClick={closePoll}>
          {t('Close poll')}
        </Button>
      </CardBody>
    </Card>
  );
};

export default PollsStats;
