import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import { Spinner } from 'reactstrap';
import { SessionContext } from 'context/sessionContext';
import { browserName, osName } from 'react-device-detect';
import GameServerHttp from 'utils/gameServerHttp';
import NodeApiHttp from 'utils/nodeApiHttp';

const UnityGame = (props) => {
  const { id: roomID } = useRouteMatch().params;
  const { show, isAnimator, media, participant } = props;
  const [unityContext, setUnityContext] = useState(null);
  const [gameServerRoomExist, setGameServerRoomExist] = useState(false);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const container = useRef(null);
  const loading = useRef(null);

  const { state } = useContext(SessionContext);
  const { shareScreen, role } = state;

  useEffect(() => {
    GameServerHttp.get('/rooms').then((roomList) => {
      if (!roomList.some((room) => room.id === roomID)) {
        GameServerHttp.post(`/rooms/${roomID}`).then((response) => {
          setGameServerRoomExist(true);
        });

        const data = {
          identifier: roomID,
          last_step: 0,
          last_module: 0,
        };

        NodeApiHttp.post('/hinvestigation-sessions', data).then((response) => {
          console.log(response);
        });
      }
      setGameServerRoomExist(roomList.some((room) => room.id === roomID));
    });
  }, [gameServerRoomExist, roomID]);

  const updateDimensions = useCallback(() => {
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (w / h > (media.canvas_width || 960) / (media.canvas_height || 600)) {
      setHeight('100%');
      setWidth((h * (media.canvas_width || 960)) / (media.canvas_height || 600));
    } else {
      setWidth('100%');
      setHeight((w * (media.canvas_height || 600)) / (media.canvas_width || 960));
    }
  }, [container, setWidth, setHeight, media]);

  const updateLoading = useCallback(
    (p) => {
      if (!isLoading) {
        return;
      }
      setProgression(p);
      if (loading.current !== null && p >= 1 && !!participant) {
        loading.current.classList.add('fade-out');
        setTimeout(() => setIsLoading(false), 500);
      }
    },
    [loading, isLoading, setProgression, setIsLoading, participant]
  );

  /**
   * @todo Update the SendMessage calls to unity, to use generic method names that fit with a specific .unitypackage for vtopia games
   * @todo Handle different Unity versions
   */
  const onPlayerReady = useCallback(() => {
    unityContext.send(
      '[ReactHook]',
      'Initialization',
      JSON.stringify({
        Token: process.env.REACT_APP_NODE_API_TOKEN,
        OS: osName,
        browser: browserName,
        serverURL: process.env.REACT_APP_HIV_GAME_SERVER_URI,
        apiUrl: process.env.REACT_APP_API_URI_NODE,
        port: 0,
        sessionID: roomID,
        uniqueIdentifier: participant.id,
        admin: (!!role && role.indexOf('animator') > -1) || !!isAnimator,
        spectator: !!role && role.indexOf('observer') > -1,
        SSL: process.env.REACT_APP_GAME_SERVER_SSL_ENABLED === 'true',
        apiSSL: process.env.REACT_APP_NODE_API_SSL_ENABLED === 'true',
      })
    );
  }, [unityContext, isAnimator, roomID, role]);

  useEffect(() => {
    if (unityContext && gameServerRoomExist) {
      unityContext.on('PlayerReady', onPlayerReady);
      unityContext.on('progress', updateLoading);
    }
  }, [unityContext, setProgression, updateLoading, onPlayerReady, gameServerRoomExist]);

  useEffect(() => {
    // Make sure to only set the content once
    if (unityContext !== null || !media) {
      return;
    }

    if (gameServerRoomExist) {
      setUnityContext(
        new UnityContext({
          streamingAssetsUrl: '/games/h_investigation/StreamingAssets',
          loaderUrl: `${media.build_path}.loader.js`,
          dataUrl: `${media.build_path}.data${
            media.build_compression ? `.${media.build_compression}` : ''
          }`,
          frameworkUrl: `${media.build_path}.framework.js${
            media.build_compression ? `.${media.build_compression}` : ''
          }`,
          codeUrl: `${media.build_path}.wasm${
            media.build_compression ? `.${media.build_compression}` : ''
          }`,
        })
      );
    }
  }, [media, setUnityContext, unityContext, roomID, gameServerRoomExist]);

  useEffect(() => {
    if (!container.current || media.full_canvas !== 0) {
      return null;
    }
    updateDimensions();
    window.addEventListener('resize', updateDimensions, false);
    return () => {
      window.removeEventListener('resize', updateDimensions, false);
    };
  }, [container, media, setWidth, setHeight, updateDimensions]);

  return (
    <div
      ref={container}
      id="vtopia-unity"
      style={{
        ...styles.wrapper,
        width,
        height,
        display: !show || shareScreen ? 'none' : 'block',
      }}
    >
      {unityContext && (
        <Unity
          unityContext={unityContext}
          style={{
            maxHeight: '100%',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {isLoading && (
        <div
          ref={loading}
          className="d-flex flex-column justify-content-center align-items-center bg-light position-absolute left-0"
          style={styles.loading}
        >
          <Spinner color="dark" />
          <p
            className="mt-3 text-dark"
            style={{
              fontSize: 30,
            }}
          >
            {`${(progression * 100).toFixed()} %`}
          </p>
        </div>
      )}
    </div>
  );
};

export default UnityGame;
UnityGame.propTypes = {
  isAnimator: PropTypes.bool,
  show: PropTypes.bool,
  media: PropTypes.object.isRequired,
};

const styles = {
  wrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
