import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Label,
  Table,
  InputGroup,
  InputGroupText,
  Button,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import FilterBar from 'components/admin/polls/FilterBar';

import { faTrash, faSearch, faList, faBars } from '@fortawesome/free-solid-svg-icons';

import { actions, useListContext } from 'context/ListContext';
import css from './statistics.module.scss';
import ccss from '../../common/common.module.scss';

const StatDocList = (props) => {
  const { pagination, onItemCountChange, onItemChange } = props;
  const [itemCount, setItemCount] = useState(5);
  const [tCommon] = useTranslation();
  const [docId, setDocId] = useState(-1);
  const [docIds, setDocIds] = useState({});
  const [listRendering, setListRendering] = useState('');
  const listContext = useListContext();

  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        //
        break;
      case 'multiple-delete':
        //
        break;
      default:
        setDocId(-1);
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const load = useCallback(
    (paginate) => {
      const { dispatch } = listContext;
      dispatch({
        type: actions.LOAD_LIST,
        payload: {
          start: paginate * itemCount,
          count: itemCount,
        },
      });
    },
    [itemCount, listContext]
  );

  const previous = usePrevious({ pagination, listContext });

  useEffect(() => {
    if (previous === undefined || previous.pagination !== pagination) {
      load(pagination);
    }
  }, [pagination, previous, load]);

  const renderFilteringOptions = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              setItemCount(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
          <InputGroup
            style={{
              display: 'none',
              cursor: Object.keys(docIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${ccss.comUnified}
              ${Object.keys(docIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(docIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
            // display: 'none',
          }}
        >
          {/* <InputGroup className={`${ccss.comUnified} ${css.filterElSize}`}>
            <InputGroupText>
              <FontAwesomeIcon icon={faList} />
            </InputGroupText>
            <Button color="white">{tCommon('Sort')}</Button>
          </InputGroup>
            <InputGroup className={`${ccss.comUnified} ${css.filterElSize} ml-2`}>
              <InputGroupText>
                <i>
                  <FontAwesomeIcon icon={faBars} />
                </i>
              </InputGroupText>
              <Button color="white">{tCommon('Filter')}</Button>
            </InputGroup>
          */}
          {/* <FilterBar updateRoute="" filterRoute="" /> */}
          {/* <InputGroup className={`${ccss.comUnified} ${css.filterElSize} ml-2`}>
            <Input type="text" name="search" placeholder={tCommon('Search')} />
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroup>
          */}
        </Col>
      </Row>
    );
  }, [listContext, tCommon, docIds, onItemCountChange]);

  const renderList = useCallback(() => {
    const { state } = listContext;
    state.then(({ list }) => {
      setListRendering(
        <Row className="docList">
          <Table>
            <thead className={css.thead}>
              <tr>
                <th className="text-center">{tCommon('DOCUMENT NAME')}</th>
                <th style={{ width: '0px' }} className="text-center">
                  {tCommon('NUMBER OF INTRODUCTION')}
                </th>
                <th style={{ width: '0px' }} className="text-center">
                  {tCommon('NUMBER OF DOWLOADED FILE')}
                </th>
              </tr>
            </thead>
            <tbody>
              {list.map((element, index) => (
                <tr key={index}>
                  <td className="text-center">{element.name}</td>
                  <td className="text-center">{element.introduction_count}</td>
                  <td className="text-center">{element.download_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      );
    });
  }, [listContext, tCommon]);

  const callBackItemChange = useCallback(
    (context) => {
      if (previous !== undefined) {
        const { listLength } = context;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    },
    [onItemChange, previous]
  );

  useEffect(() => {
    callBackItemChange(listContext);
  }, [listContext, callBackItemChange]);

  useEffect(() => {
    renderList(listContext);
  }, [listContext, renderList]);

  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilteringOptions()}
            {listRendering}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default StatDocList;
