import { useTranslation } from 'react-i18next';
import { PDFViewer } from '@react-pdf/renderer';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Col,
  Form,
  Input,
  Row,
  Label,
  Table,
  Collapse,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalFooter,
  ModalBody,
  Button,
} from 'reactstrap';
// import FilterBar from 'components/admin/polls/FilterBar';
import { actions, useListContext } from 'context/ListContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import gcss from 'components/common/common.module.scss';
import css from 'components/admin/pharmacovigilance/pharma-report.module.scss';
import PharmaReportListDetail from 'components/admin/pharmacovigilance/PharmaReportListDetail';
import PharmaReportPdf from 'components/admin/pharmacovigilance/PharmaReportPdf';
import ReactDOM from 'react-dom';

const PharmaReportList = (props) => {
  const { /* pagination, */ onItemCountChange, onItemChange, children } = props;
  const [collapsers, setCollapsers] = useState([]);
  // const [itemCount, setItemCount] = useState(5);
  const [tCommon] = useTranslation();
  const [listRendering, setListRendering] = useState('');
  const [deleteModal, setDeleteModal] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [elementId, setElementId] = useState(-1);
  const [elementIds, setElementIds] = useState({});
  const [downloadMode, setDownloadMode] = useState([]);
  const listContext = useListContext();

  const Pdf = useCallback(
    (element) => (
      <PDFViewer showToolbar height="550" width="100%">
        <PharmaReportPdf id={element.id} />
      </PDFViewer>
    ),
    []
  );

  const renderDeleteModal = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Modal
        className="modal-lg"
        isOpen={deleteModal || multipleDelete}
        fade
        toggle={toggle('close')}
      >
        <ModalHeader toggle={toggle('close')}>{tCommon('Delete')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {multipleDelete ? (
                <p>{tCommon('Are you sure you want to delete those selected elements?')}</p>
              ) : (
                <p>{tCommon('Are you sure you want to delete this element?')}</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (multipleDelete) {
                dispatch({
                  type: actions.DELETE_MULTIPLE,
                  payload: {
                    ids: Object.keys(elementIds),
                  },
                });
              } else {
                dispatch({
                  type: actions.DELETE,
                  payload: {
                    id: elementId,
                  },
                });
              }
              setElementIds({});
              toggle('close')();
            }}
          >
            Validate
          </Button>
        </ModalFooter>
      </Modal>
    );
  }, [tCommon, elementId, elementIds, listContext, deleteModal, multipleDelete]);

  const onCheck = useCallback(
    (e, element) => {
      const ids = {
        ...elementIds,
      };
      if (e.target.checked) {
        ids[element.id] = true;
        setElementIds(ids);
      } else {
        delete ids[element.id];
        setElementIds(ids);
      }
    },
    [elementIds]
  );

  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        setDeleteModal(true);
        break;
      case 'multiple-delete':
        setMultipleDelete(true);
        break;
      default:
        setDeleteModal(false);
        setMultipleDelete(false);
        setElementId(-1);
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous = usePrevious({ listContext });

  useEffect(() => {
    if (elementId !== -1) {
      toggle('delete')();
    }
  }, [elementId]);

  const renderFilteringOptions = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${gcss.listToolBar} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              // setItemCount(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
          <InputGroup
            style={{
              cursor: Object.keys(elementIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${css.unified}
              ${Object.keys(elementIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(elementIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          {/* <FilterBar updateRoute="" filterRoute="" /> */}
        </Col>
      </Row>
    );
  }, [listContext, tCommon, elementIds, onItemCountChange]);

  const uncollaspePdf = useCallback(
    (toggler) => {
      const i = collapsers.indexOf(toggler);
      if (i === -1) {
        // const c = [...collapsers];
        // c.splice(i, 1);
        const c = [...collapsers, toggler];
        setCollapsers(c);
      }
    },
    [collapsers]
  );

  const uncollapse = useCallback(
    (toggler) => {
      const i = collapsers.indexOf(toggler);
      if (i === -1) {
        const c = [...collapsers];
        c.push(toggler);
        setCollapsers(c);
      } else {
        const c = [...collapsers];
        c.splice(i, 1);
        setCollapsers(c);
      }
    },
    [collapsers]
  );

  const buttonCollapser = useCallback(
    (index) => {
      const toggler = `pharmaco-detail-toggler-${index}`;
      return (
        <button
          type="button"
          className={`${css.collapser} ${
            collapsers.indexOf(toggler) === -1 ? css.uncollapsed : ''
          }`}
          onClick={() => {
            const idx = downloadMode.indexOf(index);
            if (idx !== -1) {
              const dm = [...downloadMode];
              dm.splice(idx, 1);
              setDownloadMode(dm);
            }
            uncollapse(toggler);
          }}
        >
          <span>^</span>
        </button>
      );
    },
    [collapsers, downloadMode, uncollapse]
  );

  const detailCollapser = useCallback(
    (index, child) => {
      const toggler = `pharmaco-detail-toggler-${index}`;
      const isOpen = collapsers.indexOf(toggler) !== -1;

      return (
        <>
          <Collapse
            style={{
              overflow: 'hidden',
              position: 'relative',
              height: '550px',
              width: '100%',
            }}
            tag="tr"
            isOpen={isOpen}
            className={css.collapsed}
          >
            <td
              style={{
                zIndex: 99,
                top: 0,
                left: 0,
                display: `${downloadMode.indexOf(index) !== -1 ? 'block' : 'none'}`,
              }}
              id={`pdf-tab-${index}`}
              className={`${isOpen ? 'open' : ''}`}
            />
            <td
              style={{
                display: `${downloadMode.indexOf(index) !== -1 ? 'none' : 'block'}`,
              }}
              className={`${isOpen ? 'open' : ''}`}
            >
              {child}
            </td>
          </Collapse>
        </>
      );
    },
    [collapsers, downloadMode]
  );

  const renderList = useCallback(() => {
    const { state } = listContext;
    state.then(({ list }) => {
      setListRendering(
        <Row className={css.pharmaList}>
          <Table>
            <thead>
              <tr>
                <th
                  style={{
                    width: '20px',
                  }}
                  className="pl-0 pr-0"
                />
                <th className="text-center">{tCommon('USER')}</th>
                <th className="text-center">{tCommon('DATE')}</th>
                <th className="text-center">{tCommon('SESSION NAME')}</th>
                {/* <th className="text-center">{tCommon('DOCUMENT')}</th> */}
                <th className="text-right pr-1" />
                <th
                  style={{
                    width: '20px',
                  }}
                  className="text-right pl-0 pr-0"
                />
              </tr>
            </thead>
            <tbody>
              {list
                .filter((element) => !element.deleted)
                .map((element, index) => (
                  <>
                    <tr key={index}>
                      <td>
                        <Input
                          addon
                          type="checkbox"
                          checked={elementIds[element.id] === true}
                          aria-label="Checkbox for following text input"
                          onChange={(e) => onCheck(e, element)}
                        />
                      </td>
                      <td className="text-center">{element.user}</td>
                      <td className="text-center">{moment(element.date).format('DD/MM/YYYY')}</td>
                      <td className="text-center">{element.session_name}</td>
                      {/* <td className="text-center">
                        <button
                          className="download-button btn btn-primary"
                          type="button"
                          onClick={() => {
                            if (downloadMode.indexOf(index) === -1) {
                              setDownloadMode([...downloadMode, index]);
                            }
                            uncollaspePdf(`pharmaco-detail-toggler-${index}`);
                            ReactDOM.render(
                              <Pdf element={element} />,
                              document.getElementById(`pdf-tab-${index}`)
                            );
                          }}
                        >
                          {tCommon('Download')}
                        </button>
                      </td>
                      */}
                      <td className="text-right pr-1">{buttonCollapser(index)}</td>
                      <td className="pl-1 pr-1">
                        <span className="float-right">
                          <FontAwesomeIcon
                            className="opacity-4 cursor-pointer"
                            onClick={() => setElementId(element.id)}
                            icon={faTrash}
                          />
                        </span>
                      </td>
                    </tr>
                    {detailCollapser(index, <PharmaReportListDetail id={element.id} />)}
                  </>
                ))}
            </tbody>
          </Table>
        </Row>
      );
    });
  }, [
    buttonCollapser,
    detailCollapser,
    downloadMode,
    elementIds,
    listContext,
    onCheck,
    tCommon,
    uncollaspePdf,
  ]);

  const callBackItemChange = useCallback(
    (context) => {
      if (previous !== undefined) {
        const { listLength } = context;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    },
    [onItemChange, previous]
  );

  useEffect(() => {
    callBackItemChange(listContext);
  }, [listContext, callBackItemChange]);

  useEffect(() => {
    renderList(listContext);
  }, [listContext, renderList]);

  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilteringOptions()}
            {listRendering}
            {renderDeleteModal()}
          </Col>
        </Row>
        <Row>
          <Col>{children}</Col>
        </Row>
      </div>
    </Form>
  );
};

export default PharmaReportList;
