import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import Unity, { UnityContext } from 'react-unity-webgl';
import { Spinner } from 'reactstrap';
import { SessionContext } from 'context/sessionContext';
import { isMobile } from 'react-device-detect';

import bg from 'assets/img/rp-ambassadeurs/bg.png';
import tableL from 'assets/img/rp-ambassadeurs/table-l.png';
import tableR from 'assets/img/rp-ambassadeurs/table-r.png';
import tableB from 'assets/img/rp-ambassadeurs/table-b.png';
import shadow from 'assets/img/rp-ambassadeurs/shadow.png';
import shadowR from 'assets/img/rp-ambassadeurs/shadow-r.png';
import shadowB from 'assets/img/rp-ambassadeurs/shadow-b.png';

const SmartRx = (props) => {
  const { id: roomID } = useRouteMatch().params;
  const { show, isAnimator, media } = props;
  const [unityContext, setUnityContext] = useState(null);
  const [width, setWidth] = useState('100%');
  const [height, setHeight] = useState('100%');
  const [isLoading, setIsLoading] = useState(true);
  const [progression, setProgression] = useState(0);
  const container = useRef(null);
  const loading = useRef(null);
  const [showTable, setShowTable] = useState(false);

  const { state } = useContext(SessionContext);
  const { shareScreen, role } = state;

  const updateDimensions = useCallback(() => {
    if (!container.current) {
      return;
    }
    const w = container.current.parentNode.clientWidth;
    const h = container.current.parentNode.clientHeight;
    if (w / h > 960 / 600) {
      setHeight('100%');
      setWidth((h * 960) / 600);
    } else {
      setWidth('100%');
      setHeight((w * 600) / 960);
    }
  }, [container, setWidth, setHeight]);

  const updateLoading = useCallback(
    (p) => {
      if (!isLoading) {
        return;
      }
      console.log('loading progress');
      console.log(p);
      setProgression(p);
      if (loading.current !== null && p >= 1) {
        loading.current.classList.add('fade-out');
        setTimeout(() => setIsLoading(false), 500);
      }
    },
    [loading, isLoading, setProgression, setIsLoading]
  );

  const onPlayerReady = useCallback(() => {
    unityContext.send(
      'NetworkUser',
      'InitNetworkUser',
      JSON.stringify({
        room: roomID,
        participantData: state.participant,
        port: 0,
        admin: role.indexOf('animator') > -1 || !!isAnimator,
        spectator: role.indexOf('observer') > -1,
      })
    );
    unityContext.send('NetworkUser', 'IsMobile', isMobile ? 'true' : 'false');
  }, [unityContext, isAnimator, roomID, role, state]);

  useEffect(() => {
    if (unityContext) {
      unityContext.on('PlayerReady', onPlayerReady);
      unityContext.on('ShowTable', () => setShowTable(true));
      unityContext.on('HideTable', () => setShowTable(false));
      unityContext.on('progress', updateLoading);
    }
  }, [unityContext, setProgression, updateLoading, onPlayerReady, setShowTable]);

  useEffect(() => {
    // Make sure to only set the content once
    if (unityContext !== null || !media) {
      return;
    }
    setUnityContext(
      new UnityContext({
        streamingAssetsUrl: '/games/smartrx/StreamingAssets',
        loaderUrl: `${media.build_path}.loader.js`,
        dataUrl: `${media.build_path}.data${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
        frameworkUrl: `${media.build_path}.framework.js${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
        codeUrl: `${media.build_path}.wasm${
          media.build_compression ? `.${media.build_compression}` : ''
        }`,
      })
    );
  }, [setUnityContext, media, unityContext]);

  useEffect(() => {
    if (container.current) {
      updateDimensions();
      window.addEventListener('resize', updateDimensions, false);
      return () => {
        window.removeEventListener('resize', updateDimensions, false);
      };
    }
    return null;
  }, [container, setWidth, setHeight, updateDimensions]);

  useEffect(() => {
    if (!container.current) {
      return undefined;
    }
    const parent = container.current.parentNode;
    if (showTable) {
      parent.style.backgroundImage = `url(${bg})`;
      parent.style.backgroundSize = 'cover';
    } else {
      parent.style.backgroundImage = 'none';
      parent.style.backgroundSize = 'initial';
    }
    return () => {
      parent.style.backgroundImage = 'none';
      parent.style.backgroundSize = 'initial';
    };
  }, [showTable, container]);

  return (
    <div
      ref={container}
      style={{
        ...styles.wrapper,
        width,
        height,
        display: !show || shareScreen ? 'none' : 'block',
      }}
      id="vtopia-unity"
    >
      {!isLoading && showTable && (
        <>
          <img
            src={shadowR}
            alt=""
            className="position-absolute h-100"
            style={{
              right: 0.5,
              bottom: 0,
              pointerEvents: 'none',
              opacity: 0.745,
              transform: 'translateX(100%)',
            }}
          />
          <img
            src={shadowB}
            alt=""
            className="position-absolute w-100"
            style={{
              left: 0,
              bottom: 0.5,
              pointerEvents: 'none',
              opacity: 0.745,
              transform: 'translateY(100%)',
            }}
          />
          <img
            src={shadow}
            alt=""
            className="position-absolute w-100 h-100"
            style={{
              left: 0,
              bottom: 0,
              pointerEvents: 'none',
              opacity: 0.745,
            }}
          />
          <img
            src={tableL}
            alt=""
            className="position-absolute h-100"
            style={{
              left: -0.5,
              bottom: 0,
              pointerEvents: 'none',
              transform: 'translateX(-100%)',
            }}
          />
          <img
            src={tableR}
            alt=""
            className="position-absolute h-100"
            style={{
              right: 0.5,
              bottom: 0,
              pointerEvents: 'none',
              transform: 'translateX(100%)',
            }}
          />
          <img
            src={tableB}
            alt=""
            className="position-absolute w-100"
            style={{
              left: 0,
              bottom: 0.5,
              pointerEvents: 'none',
              transform: 'translateY(100%)',
            }}
          />
        </>
      )}
      {unityContext && (
        <Unity
          unityContext={unityContext}
          style={{
            maxHeight: '100%',
            width: '100%',
            height: '100%',
          }}
        />
      )}
      {isLoading && (
        <div
          ref={loading}
          className="d-flex flex-column justify-content-center align-items-center bg-light position-absolute left-0"
          style={styles.loading}
        >
          <Spinner color="dark" />
          <p
            className="mt-3 text-dark"
            style={{
              fontSize: 30,
            }}
          >
            {`${(progression * 100).toFixed()} %`}
          </p>
        </div>
      )}
    </div>
  );
};

export default SmartRx;
SmartRx.propTypes = {
  isAnimator: PropTypes.bool,
  show: PropTypes.bool,
};

const styles = {
  wrapper: {
    maxHeight: '100%',
    maxWidth: '100%',
    alignSelf: 'center',
    justifySelf: 'center',
    position: 'relative',
    boxSizing: 'border-box',
    margin: 0,
  },
  loading: {
    position: 'absolute',
    left: 0,
    top: 0,
    right: 0,
    bottom: 0,
    color: 'white',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
};
