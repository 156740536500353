import React, { useState, useCallback, useContext, useEffect, useRef } from 'react';
import { Row, Col, CardHeader, CardBody, Card, CardFooter, Button, Collapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CreatePoll from 'components/front/room/polls/CreatePoll';
import PollList from 'components/admin/polls/PollList';
import Pagination from 'components/common/Pagination';
import { ListProvider, actions, useListContext } from 'context/ListContext';
import { NotificationContext } from 'context/notificationContext';
import { useAuthContext } from '../../../context/authContext';

const Polls = () => {
  const authContext = useAuthContext();

  const Render = () => {
    const scrollToRef = useRef();
    const pollContainerRef = useRef();
    const [poll, setPoll] = useState({});
    const [editPoll, setEditPoll] = useState({});
    const [pollToggle, setPollToggle] = useState(false);
    const [tCommon] = useTranslation();

    const [maxItem, setMaxItem] = useState(5);
    const [itemCount, setItemCount] = useState(5);
    const [pagination, setPagination] = useState(0);
    const [itemSubstitutes, setItemSubstitutes] = useState([]);
    const listContext = useListContext();

    // Pagination
    const load = useCallback(
      (paginate) => {
        const { dispatch } = listContext;
        dispatch({
          type: actions.LOAD_LIST,
          payload: {
            start: paginate * itemCount,
            count: itemCount,
          },
        });
      },
      [itemCount, listContext]
    );

    const onItemChange = useCallback(
      (value) => {
        setItemSubstitutes(Array(value).fill(0));
        const maxPagination = Math.ceil(value / maxItem);
        if (maxPagination === 0) {
          setPagination(0);
        } else if (maxPagination <= pagination) {
          setPagination(maxPagination - 1);
        }
      },
      [maxItem, pagination]
    );

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const previous = usePrevious({ pagination, listContext, poll });

    useEffect(() => {
      if (previous?.poll?.id !== poll.id) {
        const { dispatch } = listContext;
        dispatch({
          type: actions.REFRESH_LIST,
        });
      }
    }, [poll, previous, itemCount, listContext]);

    useEffect(() => {
      if (previous === undefined || previous.pagination !== pagination) {
        load(pagination);
      }
    }, [pagination, previous, load]);

    useEffect(() => {
      if (previous !== undefined) {
        const { listLength } = listContext;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    }, [previous, listContext, onItemChange]);

    // Create
    let pollResolve;
    const pollAsync = {
      state: new Promise((resolve) => {
        pollResolve = resolve;
      }),
    };

    let collapseResolve;
    const collapseAsync = {
      state: new Promise((resolve) => {
        collapseResolve = resolve;
      }),
    };

    const collapse = useCallback(() => {
      setPollToggle(false);
      pollContainerRef.current.style.minHeight = '0px';
    }, []);

    useEffect(() => {
      let mounted = true;
      pollAsync.state.then((value) => {
        if (mounted) {
          collapse();
          setPoll(value);
        }
      });
      return () => {
        mounted = false;
      };
    }, [collapse, pollAsync.state]);

    useEffect(() => {
      let mounted = true;
      collapseAsync.state.then(() => {
        if (mounted) {
          collapse();
        }
      });
      return () => {
        mounted = false;
      };
    }, [collapse, collapseAsync.state]);

    const onEdit = useCallback((value) => {
      setEditPoll(value);
      setPollToggle(true);
      pollContainerRef.current.style.minHeight = '500px';
      scrollToRef.current.scrollIntoView();
    }, []);

    const RenderCreatePoll = (props) => {
      const { editPoll: editPollProp, onSetPoll: onSetPollProp, onDone: onDoneProp } = props;
      const { dispatch } = useListContext();
      const { dispatch: notify } = useContext(NotificationContext);
      return (
        <CreatePoll
          onCancel={() => {
            setPollToggle(false);
          }}
          editPoll={editPollProp}
          onCreatePoll={(value) => {
            onSetPollProp(value);
            notify({
              type: 'ADD_NOTIFICATION',
              payload: {
                message: tCommon('Poll created!'),
              },
            });
          }}
          onEditPoll={(value) => {
            setEditPoll({ ...value });
            onDoneProp(true);
            notify({
              type: 'ADD_NOTIFICATION',
              payload: {
                message: tCommon('Poll updated!'),
              },
            });
            dispatch({
              type: actions.UPDATE_VALUE_ONLY,
              payload: {
                id: value.id,
                value: { title: value.title },
              },
            });
            dispatch({
              type: actions.UPDATE_VALUE_ONLY,
              payload: {
                id: value.id,
                value: { options: value.options },
              },
            });
          }}
        />
      );
    };

    const setCreateToggle = useCallback(() => {
      setEditPoll({});
      setPollToggle(true);
      pollContainerRef.current.style.minHeight = '500px';
      scrollToRef.current.scrollIntoView();
    }, [scrollToRef, pollContainerRef]);

    return (
      <Row>
        <Col>
          <Row className="mb-4">
            <Col>
              <h1 className="text-white">
                <i className="fas fa-poll-h mr-3" />
                {tCommon('Polls')}
              </h1>
            </Col>
            <Col className="text-right" xs="4" />
          </Row>
          <Row className="rounded-top" style={{ background: '#f7fafc' }}>
            <Col className="p-0">
              <Card className="bg-secondary shadow bg-white">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col>
                      <h3 className="mb-0">{tCommon('Polls')}</h3>
                    </Col>
                    <Col />
                    <Col>
                      <Button onClick={setCreateToggle} color="primary" className="w-100 mt-3">
                        {tCommon('Add Poll')}
                      </Button>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody>
                  <PollList
                    onEdit={onEdit}
                    onItemCountChange={(value) => {
                      setMaxItem(value);
                      setPagination(0); //
                      setItemCount(value);
                    }}
                  />
                </CardBody>
                <CardFooter>
                  <nav aria-label="...">
                    <Pagination
                      items={itemSubstitutes}
                      pagination={pagination}
                      setPagination={setPagination}
                      maxItem={maxItem}
                    />
                  </nav>
                </CardFooter>
              </Card>
              <div className="w-100 h-auto" ref={pollContainerRef}>
                <Collapse isOpen={pollToggle}>
                  <Card className="bg-transparent shadow-none">
                    <CardHeader className="bg-transparent border-0" />
                    <CardBody className="bg-transparent">
                      <RenderCreatePoll
                        editPoll={editPoll}
                        onSetPoll={pollResolve}
                        onDone={collapseResolve}
                      />
                    </CardBody>
                  </Card>
                </Collapse>
              </div>
              <span ref={scrollToRef} />
            </Col>
          </Row>
        </Col>
      </Row>
    );
  };
  return (
    <ListProvider
      resource={{
        routes: {
          ON_LOAD: `polls/by-lab/${authContext.state.user.lab_id}`,
          ON_DELETE: 'polls',
          ON_DELETE_MULTIPLE: 'polls/delete-multiple',
          ON_CHANGE: 'polls',
        },
      }}
    >
      <Render />
    </ListProvider>
  );
};

export default Polls;
