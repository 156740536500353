import React, { useEffect, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import classnames from 'classnames';
import EventThumbnail from 'components/common/EventThumbnail';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';
// import css from './event-module.scss';
import css from 'components/common/common.module.scss';

const EventList = ({
  loaded,
  titleIsWhite,
  title,
  events,
  deleteEvent,
  updateEvent,
  isPast,
  isCurrent,
  numberOfCurrentEvents,
}) => {
  const [hasEvent, setHasEvent] = useState(false);
  const [t] = useTranslation('back-office');
  const history = useHistory();

  useEffect(() => {
    if (loaded) {
      if (events?.length && events.length > 0) {
        setHasEvent(true);
      } else {
        setHasEvent(false);
      }
    }
  }, [events, isCurrent, isPast, loaded]);

  const onAddEvent = useCallback(() => {
    history.push('/admin/events/create');
  }, [history]);

  return (
    <Row style={{ width: '100%' }}>
      <Col>
        <h4
          className={classnames('mb-4', {
            'text-white': titleIsWhite,
          })}
        >
          <i
            className="fas fa-calendar-alt mr-3"
            style={{
              fontSize: 24,
            }}
          />
          {title}
        </h4>
        {!hasEvent && loaded && !isCurrent && !isPast && (
          <Row>
            <Col />
            <Col>
              <div
                style={{
                  marginTop: numberOfCurrentEvents > 0 ? '0px' : '300px',
                }}
                role="button"
                tabIndex={0}
                className={css.addEventButton}
                onClick={onAddEvent}
              >
                <Row>
                  <Col className="aeb-text">
                    <span>{t('Add a new event')}</span>
                  </Col>
                </Row>
                <Row>
                  <Col />
                  <Col className="aeb-icon">
                    <span>
                      <FontAwesomeIcon icon={faPlus} />
                    </span>
                  </Col>
                  <Col />
                </Row>
              </div>
            </Col>
            <Col />
          </Row>
        )}
        {events.length > 0 && (
          <div style={styles.grid}>
            {events.map((event) => (
              <EventThumbnail
                key={event.id}
                event={event}
                deleteEvent={deleteEvent}
                updateEvent={updateEvent}
                isPast={isPast}
              />
            ))}
          </div>
        )}
      </Col>
    </Row>
  );
};

export default EventList;

EventList.propTypes = {
  events: PropTypes.array.isRequired,
  title: PropTypes.string.isRequired,
  titleIsWhite: PropTypes.bool.isRequired,
  deleteEvent: PropTypes.func.isRequired,
  updateEvent: PropTypes.func.isRequired,
};

const styles = {
  grid: {
    display: 'grid',
    gridTemplateColumns: '1fr 1fr 1fr 1fr',
    columnGap: 15,
    rowGap: 15,
  },
};
