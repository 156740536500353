import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEllipsisV } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import FilterBar from 'components/admin/polls/FilterBar';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Label,
  Table,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import moment from 'moment';
import { ListContext, actions } from 'context/ListContext';
import css from './list.module.css';

const PollList = (props) => {
  const { onItemCountChange, onEdit } = props;
  const [tCommon] = useTranslation();

  /**
   * States and context
   * */
  const [editorId, setEditorId] = useState(-1);
  const [editorChanged, setEditorChanged] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [elementId, setElementId] = useState(-1);
  const [elementIds, setElementIds] = useState({});
  const [listRendering, setListRendering] = useState('');

  const listContext = useContext(ListContext);

  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        setDeleteModal(true);
        break;
      case 'multiple-delete':
        setMultipleDelete(true);
        break;
      default:
        setDeleteModal(false);
        setMultipleDelete(false);
        setElementId(-1);
    }
  };

  /*
  const load = useCallback(
    (paginate) => {
      const { dispatch } = listContext;
      dispatch({
        type: actions.LOAD_LIST,
        payload: {
          start: paginate * itemCount,
          count: itemCount,
        },
      });
    },
    [itemCount, listContext]
  );

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const previous = usePrevious({ pagination, listContext, poll });

  useEffect(() => {
    if (previous?.poll?.id !== poll.id) {
      const { dispatch } = listContext;
      dispatch({
        type: actions.REFRESH_LIST,
      });
    }
  }, [poll, previous, itemCount, listContext]);

  useEffect(() => {
    if (previous === undefined || previous.pagination !== pagination) {
      load(pagination);
    }
  }, [pagination, previous, load]);
  */

  useEffect(() => {
    if (elementId !== -1) {
      toggle('delete')();
    }
  }, [elementId]);

  /**
   * Render the filtering options and the search bar
   */
  const renderFilter = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
          <InputGroup
            style={{
              cursor: Object.keys(elementIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${css.unified}
              ${Object.keys(elementIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(elementIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          {/* <FilterBar updateRoute="" filterRoute="" /> */}
        </Col>
      </Row>
    );
  }, [elementIds, listContext, tCommon, onItemCountChange]);

  const dropDownMenu = useCallback(
    (element) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only opacity-4"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={() => {
                onEdit(element);
              }}
            >
              {tCommon('Edit')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
    [tCommon, onEdit]
  );

  /**
   * Render the 'delete' modal
   */
  const renderDeleteModal = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Modal
        className="modal-lg"
        isOpen={deleteModal || multipleDelete}
        fade
        toggle={toggle('close')}
      >
        <ModalHeader toggle={toggle('close')}>{tCommon('Delete')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {multipleDelete ? (
                <p>{tCommon('Are you sure you want to delete those selected elements?')}</p>
              ) : (
                <p>{tCommon('Are you sure you want to delete this element?')}</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (multipleDelete) {
                dispatch({
                  type: actions.DELETE_MULTIPLE,
                  payload: {
                    ids: Object.keys(elementIds),
                  },
                });
              } else {
                dispatch({
                  type: actions.DELETE,
                  payload: {
                    id: elementId,
                  },
                });
              }
              setElementIds({});
              toggle('close')();
            }}
          >
            {tCommon('Validate')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }, [tCommon, elementId, elementIds, listContext, deleteModal, multipleDelete]);

  const onCheck = useCallback(
    (e, element) => {
      if (e.target.checked) {
        const ids = {
          ...elementIds,
        };
        ids[element.id] = true;
        setElementIds(ids);
      } else {
        const ids = {
          ...elementIds,
        };
        delete ids[element.id];
        setElementIds(ids);
      }
    },
    [elementIds]
  );

  // const [key, setKey] = useState(0);
  const renderOptions = useCallback((element) => {
    return (
      <div className={css.renderOptions}>
        {element.options.length &&
          Array(Math.ceil(element.options.length / 3))
            .fill(0)
            .map((v0, id) => {
              const startAt = id * 3;
              const ret = [];
              Object.keys([0, 0, 0]).forEach((v1, pos) => {
                const option = element.options[startAt + pos];
                ret.push(<div key={pos}>{option || ''}</div>);
              });
              // setKey(key + 1);
              return <div key={id}>{ret}</div>;
            })}
      </div>
    );
  }, []);
  /**
   * Render the list
   */
  const renderList = useCallback(
    (mounted) => {
      const { state, dispatch } = listContext;
      state.then(({ list }) => {
        if (mounted.value) {
          setListRendering(
            <Row>
              <Table>
                <thead className={css.thead}>
                  <tr>
                    <th
                      style={{
                        width: '20px',
                      }}
                      className="pl-1 pr-0"
                    />
                    <th
                      style={{
                        width: '20%',
                      }}
                    >
                      {tCommon('POLLS NAMES')}
                    </th>
                    <th
                      style={{
                        width: '20px',
                      }}
                    >
                      {tCommon('GROUP')}
                    </th>
                    <th
                      style={{
                        width: '0px',
                      }}
                    >
                      {tCommon('TYPE')}
                    </th>
                    <th
                      style={{
                        width: '60%',
                      }}
                    >
                      {tCommon('ANSWER CHOICES')}
                    </th>
                    <th
                      style={{
                        width: '20px',
                      }}
                    />
                  </tr>
                </thead>
                <tbody className={css.pollListTbody}>
                  {list
                    .filter((element) => !element.deleted)
                    .map((element, index) =>
                      !element.tmp ? (
                        <tr key={index}>
                          <td className="pl-1 pr-0">
                            <Input
                              addon
                              type="checkbox"
                              checked={elementIds[element.id] === true}
                              aria-label="Checkbox for following text input"
                              onChange={(e) => onCheck(e, element)}
                            />
                          </td>
                          <td className={css.editor}>
                            <Label
                              className={`${editorId === element.id ? css.hide : ''}`}
                              for={`editor-${element.id}`}
                            >
                              {`${element.title}`}
                            </Label>
                            <Input
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  return event.preventDefault();
                                }
                                return true;
                              }}
                              onClick={(e) => {
                                setEditorId(element.id);
                                e.target.value = element.title;
                                const time = setTimeout(() => {
                                  e.target.focus();
                                  clearTimeout(time);
                                }, 10);
                              }}
                              className={`${editorId !== element.id ? css.hide : ''}`}
                              id={`editor-${element.id}`}
                              type="text"
                              onBlur={() => {
                                setEditorId(-1);
                              }}
                              onChange={(event) => {
                                if (!editorChanged) {
                                  setEditorChanged(true);
                                  const listener = (e) => {
                                    if (!e.key || e.key === 'Enter') {
                                      setEditorId(-1);
                                      dispatch({
                                        type: actions.UPDATE_VALUE,
                                        payload: {
                                          id: element.id,
                                          value: { title: event.target.value },
                                        },
                                      });
                                      window.removeEventListener('mousedown', listener);
                                      window.removeEventListener('keypress', listener);
                                      setEditorChanged(false);
                                    }
                                  };
                                  window.addEventListener('mousedown', listener, {
                                    once: true,
                                  });
                                  window.addEventListener('keypress', listener);
                                }
                              }}
                            />
                            <div>
                              {moment(element.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                            </div>
                          </td>
                          <td>{element.groups ? element.groups.join('/') : ''}</td>
                          <td>
                            {element.allowMultiple
                              ? tCommon('Multiple answers')
                              : tCommon('Single answer')}
                          </td>
                          <td>{renderOptions(element)}</td>
                          <td className="pl-0 pr-0">
                            <span className="float-right">
                              <FontAwesomeIcon
                                className="opacity-4 cursor-pointer"
                                onClick={() => setElementId(element.id)}
                                icon={faTrash}
                              />
                              {dropDownMenu(element)}
                            </span>
                          </td>
                        </tr>
                      ) : (
                        <tr key={`tmp-${index}`}>
                          <td className="pl-1 pr-0">
                            <Input
                              addon
                              type="checkbox"
                              disabled
                              aria-label="Checkbox for following text input"
                            />
                          </td>
                          <td>
                            {element.title}
                            <div>
                              {moment(element.created_at).format('MMMM Do YYYY, h:mm:ss a')}
                            </div>
                          </td>
                          <td>{element.groups ? element.groups.join('/') : ''}</td>
                          <td>
                            {element.allowMultiple
                              ? tCommon('Multiple answers')
                              : tCommon('Single answer')}
                          </td>
                          <td>{renderOptions(element)}</td>
                          <td className="pl-0 pr-0">
                            <span className="float-right opacity-2">
                              <FontAwesomeIcon icon={faTrash} />
                              <FontAwesomeIcon className="mr-1" icon={faEllipsisV} />
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </Row>
          );
        }
      });
    },
    [
      listContext,
      tCommon,
      elementIds,
      editorId,
      renderOptions,
      dropDownMenu,
      onCheck,
      editorChanged,
    ]
  );

  useEffect(() => {
    const mounted = { value: true };
    renderList(mounted);
    return () => {
      mounted.value = false;
    };
  }, [elementIds, editorId, editorChanged, renderList]);

  /**
   * Render the view used to manage the list
   */
  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilter()}
            {listRendering}
            {renderDeleteModal()}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default PollList;
