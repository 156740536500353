import React, { useState, useCallback, useRef, useEffect } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Button } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ListProvider, actions, useListContext } from 'context/ListContext';
import Pagination from 'components/common/Pagination';
import { Link } from 'react-router-dom';
import EmailList from './EmailList';
import css from './emaillist.module.css';

const PharmaVigilanceComponent = () => {
  const [t] = useTranslation('back-office');

  const Render = () => {
    const [maxItem, setMaxItem] = useState(5);
    const [itemCount, setItemCount] = useState(5);
    const [pagination, setPagination] = useState(0);
    const [itemSubstitutes, setItemSubstitutes] = useState([]);
    const listContext = useListContext();
    // Pagination
    const load = useCallback(
      (paginate) => {
        const { dispatch } = listContext;
        dispatch({
          type: actions.LOAD_LIST,
          payload: {
            start: paginate * itemCount,
            count: itemCount,
          },
        });
      },
      [itemCount, listContext]
    );

    const onItemChange = useCallback(
      (value) => {
        setItemSubstitutes(Array(value).fill(0));
        const maxPagination = Math.ceil(value / maxItem);
        if (maxPagination === 0) {
          setPagination(0);
        } else if (maxPagination <= pagination) {
          setPagination(maxPagination - 1);
        }
      },
      [maxItem, pagination]
    );

    function usePrevious(value) {
      const ref = useRef();
      useEffect(() => {
        ref.current = value;
      });
      return ref.current;
    }

    const previous = usePrevious({ pagination, listContext /* , poll */ });

    /*
    useEffect(() => {
      if (previous?.poll?.id !== poll.id) {
        const { dispatch } = listContext;
        dispatch({
          type: actions.REFRESH_LIST,
        });
      }
    }, [poll, previous, itemCount, listContext]);
    */

    useEffect(() => {
      if (previous === undefined || previous.pagination !== pagination) {
        load(pagination);
      }
    }, [pagination, previous, load]);

    useEffect(() => {
      if (previous !== undefined) {
        const { listLength } = listContext;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    }, [previous, listContext, onItemChange]);

    return (
      <Container fluid>
        <Row>
          <Col lg="7" md="10">
            <h1 className="display-2 text-white">{t('Pharmacovigilance')}</h1>
            <p className="text-white mt-0 mb-5 w-100">
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className={css.defaultButtonNavigation}>
            <Button color="primary">{t('E-mails')}</Button>
            <Link to="/admin/pharma-report">
              <Button color="outline-primary">{t('Reports')}</Button>
            </Link>
          </Col>
        </Row>
        <Row className="mt-3">
          <Col>
            <Card className="bg-secondary shadow">
              <CardHeader className="bg-white border-0">
                <Row className="align-items-center">
                  <Col xs="8">
                    <h3 className="mb-0">{t('Add Email address')}</h3>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="bg-white">
                <EmailList
                  pagination={pagination}
                  onItemChange={onItemChange}
                  onItemCountChange={(value) => {
                    setMaxItem(value);
                    setItemCount(value);
                    setPagination(0);
                  }}
                >
                  <nav aria-label="...">
                    <Pagination
                      items={itemSubstitutes}
                      pagination={pagination}
                      setPagination={setPagination}
                      maxItem={maxItem}
                    />
                  </nav>
                </EmailList>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    );
  };
  return (
    <ListProvider
      resource={{
        routes: {
          ON_LOAD: 'pharmacovigilance-receiver',
          ON_DELETE: 'pharmacovigilance-receiver',
          ON_DELETE_MULTIPLE: 'pharmacovigilance-remove-receivers',
          ON_CHANGE: 'pharmacovigilance-receiver',
        },
      }}
    >
      <Render />
    </ListProvider>
  );
};

export default PharmaVigilanceComponent;
