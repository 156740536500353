import React, { useState } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMapMarker, faEnvelope } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import pharmacoIcon from 'assets/img/pharmaco.svg';
import { yupResolver } from '@hookform/resolvers/yup';
import ReactDatetime from 'react-datetime';
import moment from 'moment';
import * as yup from 'yup';
import http from 'utils/http';
// import { parse } from 'date-fns';
import css from './report.module.css';
import { comDateTimeReadOnly } from '../../common/common.module.scss';

const PharmacoReport = () => {
  const [tCommon] = useTranslation();
  const [modal, setModal] = useState(false);
  const [dataSuccess, setDataSuccess] = useState(false);
  const toggle = () => {
    setModal(!modal);
    if (!modal) {
      setDataSuccess(false);
    }
  };

  yup.addMethod(yup.date, 'format', (formats) => {
    return yup.date().transform((value, originalValue) => {
      const date = moment(originalValue, formats, true);
      const isValidFormat = date.isValid();
      if (isValidFormat) return date.toDate();
      return false;
    });
  });

  const schema = yup.object().shape({
    patient_firstname: yup.string().required(),
    doctor_address: yup.string().required(),
    doctor_email: yup
      .string()
      .matches(/^(.+)@(.+)\.(.+)$/)
      .required(),
    doctor_firstname: yup.string().required(),
    doctor_lastname: yup.string().required(),
    doctor_phone: yup.string().matches(tCommon('Phone number (pattern)')).required(),
    doctor_specialty: yup.string().required(),
    doctor_zip_code: yup.number().positive().required(),
    drug_adverse_reaction: yup.string().required(),
    drug_batch_number: yup.number().positive().required(),
    drug_name: yup.string().required(),
    patient_lastname: yup.string().required(),
    gender: yup.string().length(1).required(),
    patient_birthdate: yup.date().format(tCommon('Date_Time_Format')),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (data) => {
    data.patient_birthdate = moment(data.patient_birthdate).format('YYYY-MM-DD HH:mm:ss');
    http
      .post('pharmacovigilance-report', data)
      .then(() => {
        setDataSuccess(true);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <div>
      <Button
        onClick={toggle}
        color="link"
        className="ml-3 mr-2 text-white d-flex align-teims-center"
      >
        <img className="mr-2" src={pharmacoIcon} alt="" />
        <span>{tCommon('Pharmacovigilance')}</span>
      </Button>
      <Modal className={`${css.report} modal-xl`} isOpen={modal} fade toggle={toggle}>
        <ModalHeader toggle={toggle}>
          <img className="mr-2 mb-1" src={pharmacoIcon} alt="" />
          {tCommon('Pharmacovigilance cases')}
        </ModalHeader>
        <ModalBody className="pl-5">
          {dataSuccess ? (
            <Form>
              <Row>
                <Col className="text-center">{tCommon('Your data has been saved!')}</Col>
              </Row>
              <Row>
                <Col>
                  <Button color="primary" className="w-100 mt-3" onClick={toggle}>
                    {tCommon('Ok')}
                  </Button>
                </Col>
              </Row>
            </Form>
          ) : (
            <Form onSubmit={handleSubmit(onSubmit)}>
              <h6>{tCommon('TREATED PATIENT')}</h6>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="patient_firstname">
                      {tCommon('First name')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="patient_firstname"
                      name="patient_firstname"
                      placeholder="John"
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="patient_lastname">
                      {tCommon('Last name')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="patient_lastname"
                      name="patient_lastname"
                      placeholder="Doe"
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="patient_sex">
                      {tCommon('Gender')}
                    </Label>
                    <Row
                      style={{
                        marginTop: '12px',
                      }}
                    >
                      <Col>
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="patient_sex_0"
                              type="radio"
                              name="gender"
                              value="f"
                              innerRef={register}
                            />
                            F
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col className="col-6">
                        <FormGroup check>
                          <Label check>
                            <Input
                              id="patient_sex_1"
                              type="radio"
                              name="gender"
                              value="m"
                              innerRef={register}
                            />
                            M
                          </Label>
                        </FormGroup>
                      </Col>
                      <Col />
                    </Row>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-4">
                  {/*
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="patient_birthdate">
                      {tCommon('Birthdate')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="patient_birthdate"
                      name="patient_birthdate"
                      type="date"
                      required
                    />
                  </FormGroup>
                  */}
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="patient_birthdate">
                      {tCommon('Birthdate')}
                    </Label>
                    <ReactDatetime
                      className={comDateTimeReadOnly}
                      timeFormat={false}
                      inputProps={{
                        readOnly: true,
                        ref: register,
                        id: 'patient_birthdate',
                        name: 'patient_birthdate',
                        placeholder: tCommon('Birthdate'),
                      }}
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-4">
                  <FormGroup>
                    <Label className="form-control-label" for="patient_age_range">
                      {tCommon('Age range')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      type="select"
                      name="patient_age_range"
                      id="patient_age_range"
                      required
                    >
                      <option>{tCommon('Newborns')}</option>
                      <option>{tCommon('Nurslings')}</option>
                      <option>{tCommon('Infants')}</option>
                      <option>{tCommon('Teenagers')}</option>
                      <option>{tCommon('Adults')}</option>
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <h6>{tCommon('HEALTH PROFESSIONALS')}</h6>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_firstname">
                      {tCommon('First name')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="doctor_firstname"
                      name="doctor_firstname"
                      placeholder="John"
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_lastname">
                      {tCommon('Last name')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="doctor_lastname"
                      name="doctor_lastname"
                      placeholder="John"
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_phone">
                      {tCommon('Phone number')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="doctor_phone"
                      name="doctor_phone"
                      placeholder="+336 14 25 25 14"
                      type="tel"
                      pattern={tCommon('Phone number (pattern)')}
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_email">
                      {tCommon('Email')}
                    </Label>
                    <InputGroup>
                      <Input
                        className="form-control-alternative"
                        id="doctor_email"
                        name="doctor_email"
                        placeholder="john@doe.com"
                        type="email"
                        pattern="^(.+)@(.+)\.(.+)$"
                        innerRef={register}
                        required
                      />
                      <InputGroupText className={css.igtr}>
                        <FontAwesomeIcon icon={faEnvelope} />
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Col>
                <Col className="pl-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_specialty">
                      {tCommon('Specialty')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="doctor_specialty"
                      name="doctor_specialty"
                      placeholder={tCommon('Specialty')}
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col className="pr-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_address">
                      {tCommon('Postal address')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="doctor_address"
                      name="doctor_address"
                      placeholder={tCommon('Postal address')}
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="doctor_zip_code">
                      {tCommon('Zip code')}
                    </Label>
                    <InputGroup>
                      <Input
                        className="form-control-alternative"
                        id="doctor_zip_code"
                        name="doctor_zip_code"
                        placeholder={tCommon('Zip code')}
                        type="text"
                        innerRef={register}
                        required
                      />
                      <InputGroupText className={css.igtr}>
                        <FontAwesomeIcon icon={faMapMarker} />
                      </InputGroupText>
                    </InputGroup>
                  </FormGroup>
                </Col>
              </Row>
              <hr />
              <h6>{tCommon('DRUG')}</h6>
              <Row>
                <Col className="pr-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="drug_name">
                      {tCommon('Drug name')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="drug_name"
                      name="drug_name"
                      placeholder={tCommon('Drug name')}
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
                <Col className="pl-4">
                  <FormGroup>
                    <Label className="form-control-label" htmlFor="drug_batch_number">
                      {tCommon('Drug batch number')}
                    </Label>
                    <Input
                      className="form-control-alternative"
                      id="drug_batch_number"
                      name="drug_batch_number"
                      placeholder={tCommon('Drug batch number (holder)')}
                      type="text"
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <FormGroup>
                    <Label className="form-control-label" for="drug_adverse_reaction">
                      {tCommon('Adverse reaction description')}
                    </Label>
                    <Input
                      type="textarea"
                      rows="4"
                      className="form-control-alternative"
                      name="drug_adverse_reaction"
                      id="drug_adverse_reaction"
                      placeholder={tCommon('Adverse reaction description')}
                      innerRef={register}
                      required
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col>
                  <Button type="submit" color="primary" className="w-100 mt-3">
                    {tCommon('Save')}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </ModalBody>
        <ModalFooter />
      </Modal>
    </div>
  );
};

export default PharmacoReport;
