import AdminLayout from 'components/admin/Admin.layout';
import EventsLayout from 'components/admin/events/Events.layout';
import UsersLayout from 'components/admin/users/Users.layout';
import EventEditLayout from 'components/admin/events/EventEdit.layout';

import Event from 'components/front/Event';
import EventPublic from 'components/front/EventPublic';
import Login from 'components/front/Login';
import ForgotPassword from 'components/front/password/ForgotPassword';
import ResetPassword from 'components/front/password/ResetPassword';
import Room from 'components/front/Room';
import WaitingRoom from 'components/front/WaitingRoom';

import ListEvents from 'components/admin/events/ListEvents';
import EditEventParticipants from 'components/admin/events/EditEventParticipants';
import EditEventInfo from 'components/admin/events/EditEventInfo';
import EditEventParticipantAdd from 'components/admin/events/EditEventParticipantAdd';
import Polls from 'components/admin/polls/Polls';
import EditPoll from 'components/admin/polls/EditPoll';
import Documents from 'components/admin/documents/Documents';
import EditDoc from 'components/admin/documents/EditDoc';
import GroupList from 'components/admin/groups/GroupList';
import UserList from 'components/admin/users/UserList';
import UsersComponent from 'components/admin/users/Users';
import PharmaVigilanceComponent from 'components/admin/pharmacovigilance/PharmaVigilanceComponent';
import PharmaReportComponent from 'components/admin/pharmacovigilance/PharmaReport';
import PharmaReportDetailComponent from 'components/admin/pharmacovigilance/PharmaReportDetail';
import ConsumptionTable from 'components/admin/consumption/ConsumptionTable';
import Profile from 'components/admin/profile/Profile';
import Statistics from 'components/admin/statistics/Statistics';
import GoogleCalendar from './components/front/googleCalendar/GoogleCalendar';

const routes = [
  {
    path: '/',
    exact: true,
    component: Login,
    public: true,
  },
  {
    path: '/my-calendar',
    exact: true,
    component: GoogleCalendar,
    public: true,
  },
  {
    path: '/forgot',
    exact: true,
    component: ForgotPassword,
    public: true,
  },
  {
    path: '/reset',
    exact: true,
    component: ResetPassword,
    public: true,
  },
  {
    path: '/waiting/:id',
    exact: true,
    component: WaitingRoom,
  },
  {
    path: '/room/:id',
    exact: true,
    component: Room,
  },
  {
    path: '/event',
    exact: true,
    component: Event,
  },
  {
    path: '/admin',
    layout: AdminLayout,
    routes: [
      {
        path: '/admin/events',
        layout: EventsLayout,
        permissions: ['manage events'],
        routes: [
          {
            path: '/admin/events',
            exact: true,
            component: ListEvents,
            sidebar: {
              name: 'Events',
              icon: 'fas fa-calendar-alt text-primary',
            },
          },
          {
            path: '/admin/events/:id',
            layout: EventEditLayout,
            routes: [
              {
                path: '/admin/events/:id',
                exact: true,
                component: EditEventInfo,
              },
              {
                path: '/admin/events/:id/participants',
                exact: true,
                component: EditEventParticipants,
              },
              {
                path: '/admin/events/:eventId/participants/:partId',
                exact: true,
                component: EditEventParticipantAdd,
              },
            ],
          },
        ],
      },
      {
        path: '/admin/users',
        layout: UsersLayout,
        permissions: ['manage users'],
        routes: [
          {
            path: '/admin/users',
            layout: UsersComponent,
            permissions: ['manage users'],
            routes: [
              {
                path: '/admin/users/list',
                permissions: ['manage users'],
                exact: true,
                component: UserList,
                sidebar: {
                  name: 'Users',
                  icon: 'ni ni-single-02 text-primary',
                },
              },
              {
                path: '/admin/users/groups',
                permissions: ['manage users'],
                exact: true,
                component: GroupList,
              },
            ],
          },
        ],
      },
      {
        path: '/admin/documents',
        exact: true,
        component: Documents,
        permissions: ['manage documents'],
        moduleName: 'share_document',
        sidebar: {
          name: 'Documents',
          icon: 'fas fa-file text-primary',
        },
      },
      {
        path: '/admin/documents/:id',
        exact: true,
        component: EditDoc,
        permissions: ['manage documents'],
        moduleName: 'share_document',
      },
      {
        path: '/admin/polls',
        exact: true,
        component: Polls,
        permissions: ['manage polls'],
        moduleName: 'polls',
        sidebar: {
          name: 'Polls',
          icon: 'fas fa-poll-h text-primary',
        },
      },
      {
        path: '/admin/polls/:id',
        exact: true,
        component: EditPoll,
        permissions: ['manage polls'],
        moduleName: 'polls',
      },
      {
        path: '/admin/pharmacovigilance',
        exact: true,
        component: PharmaVigilanceComponent,
        moduleName: 'pharmacovigilance',
        permissions: ['manage pharmacovigilance'],
        sidebar: {
          name: 'E-mails',
          icon: 'fas fa-at text-primary',
          joint: {
            rootPath: '/admin/pharmacovigilance',
            name: 'pharmacovigilance',
            icon: 'fas fa-notes-medical text-primary',
            header: true,
          },
        },
      },
      {
        path: '/admin/pharma-report',
        exact: true,
        component: PharmaReportComponent,
        moduleName: 'pharmacovigilance',
        permissions: ['manage pharmacovigilance'],
        sidebar: {
          name: 'Reports',
          icon: 'fas fa-file text-primary',
          joint: {
            name: 'pharmacovigilance',
            footer: true,
          },
        },
      },
      {
        path: '/admin/pharma-report-detail/:id',
        exact: true,
        component: PharmaReportDetailComponent,
        moduleName: 'pharmacovigilance',
        permissions: ['manage pharmacovigilance'],
      },
      {
        path: '/admin/stats',
        exact: true,
        component: Statistics,
        moduleName: 'stats',
        sidebar: {
          name: 'Statistics',
          icon: 'fas fa-chart-bar text-primary',
        },
      },
      {
        path: '/admin/myconsumption',
        exact: true,
        component: ConsumptionTable,
        permissions: ['view consumption'],
        sidebar: {
          name: 'My consumption',
          icon: 'fas fa-chart-area text-primary',
        },
      },
      {
        path: '/admin/profile',
        exact: true,
        component: Profile,
      },
    ],
  },
  {
    path: '/:slug',
    exact: true,
    component: EventPublic,
    public: true,
  },
];

export default routes;
