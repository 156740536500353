import { useTranslation } from 'react-i18next';
import { AuthContext } from 'context/authContext';
import { useForm, useFieldArray } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FilterBar from 'components/admin/polls/FilterBar';
import { faTrash, faTimesCircle, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Alert,
  Label,
  Table,
  CustomInput,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import http from 'utils/http';
import { ListContext, actions } from 'context/ListContext';
import css from './emaillist.module.css';

const defaultValues = {
  emails: [{}],
};

const EmailList = (props) => {
  const { onItemCountChange, children } = props;
  const [tCommon] = useTranslation();

  /**
   * States and context
   * */
  const [editorId, setEditorId] = useState(-1);
  const [editorChanged, setEditorChanged] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [emailId, setEmailId] = useState(-1);
  const [emailIds, setEmailIds] = useState({});
  const [emailListRendering, setEmailListRendering] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [submittedData, setSubmittedData] = useState({});

  const emailListContext = useContext(ListContext);
  const authContext = useContext(AuthContext);

  /**
   * toggle
   */
  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        setDeleteModal(true);
        break;
      case 'multiple-delete':
        setMultipleDelete(true);
        break;
      default:
        setDeleteModal(false);
        setMultipleDelete(false);
        setEmailId(-1);
    }
  };

  /**
   * Form hooks
   */
  const {
    errors,
    control,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues: {
      emails: [{}],
    },
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'emails',
  });

  const removeSuccessMessage = useCallback(() => {
    const timer = setTimeout(() => {
      setFormSuccess(false);
    }, 2000);
    return () => clearTimeout(timer);
  }, [setFormSuccess]);

  /**
   * Calls and actions on api
   */
  const onSubmit = useCallback(
    (data) => {
      const dataNormalized = {
        emails: [],
      };
      data.emails.forEach((email) => {
        dataNormalized.emails.push({
          email,
          active: true,
          lab_id: authContext.state.user.lab_id,
        });
      });
      const { dispatch } = emailListContext;
      dispatch({
        type: actions.PRE_UPDATE_LIST,
        payload: {
          elements: data.emails.map((email) => ({
            email,
          })),
        },
      });

      http
        .post('pharmacovigilance-receiver', dataNormalized)
        .then((response) => {
          dispatch({
            type: actions.UPDATE_LIST,
            payload: {
              elements: response,
            },
          });
          setSubmittedData(response);
          setFormSuccess(true);
          removeSuccessMessage();
        })
        .catch((error) => {
          console.log(error);
          dispatch({
            type: actions.CLEAN_PRE_UPDATED_LIST,
          });
        });
    },
    [emailListContext, authContext.state.user.lab_id, removeSuccessMessage]
  );

  /**
   * Effects
   */
  useEffect(() => {
    if (isSubmitSuccessful) {
      /*
      setFormSuccess(true);
      removeSuccessMessage(); // Timeout function
      */
      reset({
        ...defaultValues,
      }); // Forms input
    }
  }, [isSubmitSuccessful, submittedData, reset, removeSuccessMessage]);

  useEffect(() => {
    if (emailId !== -1) {
      toggle('delete')();
    }
  }, [emailId]);

  /**
   * Render the filtering options and the search bar
   */
  const renderFilter = () => {
    const { dispatch } = emailListContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0,
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="20">30</option>
            <option value="50">50</option>
          </Input>
          <InputGroup
            style={{
              cursor: Object.keys(emailIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${css.unified}
              ${Object.keys(emailIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(emailIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          { /* <FilterBar updateRoute="" filterRoute="" /> */ }
          {/*
          <InputGroup className={`${css.unified} ${css.filterElSize}`}>
            <InputGroupText>
              <FontAwesomeIcon icon={faList} />
            </InputGroupText>
            <Button color="white">{tCommon('Sort')}</Button>
          </InputGroup>
          <InputGroup className={`${css.unified} ${css.filterElSize} ml-2`}>
            <InputGroupText>
              <i>
                <FontAwesomeIcon icon={faBars} />
              </i>
            </InputGroupText>
            <Button color="white">{tCommon('Filter')}</Button>
          </InputGroup>
          <InputGroup className={`${css.unified} ${css.filterElSize} ml-2`}>
            <Input type="text" name="search" placeholder={tCommon('Search')} />
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroup>
          */}
        </Col>
      </Row>
    );
  };

  /**
   * Render the 'delete' modal
   */
  const renderDeleteModal = () => {
    const { dispatch } = emailListContext;
    return (
      <Modal
        className="modal-lg"
        isOpen={deleteModal || multipleDelete}
        fade
        toggle={toggle('close')}
      >
        <ModalHeader toggle={toggle('close')}>Delete</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {multipleDelete ? (
                <p>Are you sure you want to delete those selected elements?</p>
              ) : (
                <p>Are you sure you want to delete this element?</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (multipleDelete) {
                dispatch({
                  type: actions.DELETE_MULTIPLE,
                  payload: {
                    ids: Object.keys(emailIds),
                  },
                });
              } else {
                dispatch({
                  type: actions.DELETE,
                  payload: {
                    id: emailId,
                  },
                });
              }
              setEmailIds({});
              toggle('close')();
            }}
          >
            {tCommon('Validate')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  };

  const onCheck = useCallback(
    (e, element) => {
      if (e.target.checked) {
        const ids = {
          ...emailIds,
        };
        ids[element.id] = true;
        setEmailIds(ids);
      } else {
        const ids = {
          ...emailIds,
        };
        delete ids[element.id];
        setEmailIds(ids);
      }
    },
    [emailIds]
  );

  /**
   * Render the list containing the registered e-mail addresses
   */
  const renderEmailList = useCallback(
    (mounted) => {
      const { state, dispatch } = emailListContext;
      state.then(({ list }) => {
        if (mounted.value === true) {
          setEmailListRendering(
            <Row>
              <Table>
                <thead className={css.thead}>
                  <tr>
                    <th
                      style={{
                        width: '20px',
                      }}
                      className="pl-1 pr-0"
                    />
                    <th
                      style={{
                        width: '30%',
                      }}
                    >
                      {tCommon('E-MAIL ADDRESSES')}
                    </th>
                    <th
                      style={{
                        width: '40px',
                      }}
                    >
                      {tCommon('ACTIVE')}
                    </th>
                    <th />
                  </tr>
                </thead>
                <tbody>
                  {list
                    .filter((element) => !element.deleted)
                    .map((element, index) =>
                      !element.tmp ? (
                        <tr key={index}>
                          <td className="pl-1 pr-0">
                            <Input
                              addon
                              type="checkbox"
                              checked={emailIds[element.id] === true}
                              aria-label="Checkbox for following text input"
                              onChange={(e) => onCheck(e, element)}
                            />
                          </td>
                          <td className={css.emailEditor}>
                            <Label
                              className={`${editorId === element.id ? css.hide : ''}`}
                              for={`email-editor-${element.id}`}
                            >
                              {element.email}
                            </Label>
                            <Input
                              onKeyPress={(event) => {
                                if (event.key === 'Enter') {
                                  return event.preventDefault();
                                }
                                return true;
                              }}
                              onClick={(e) => {
                                setEditorId(element.id);
                                e.target.value = element.email;
                                const time = setTimeout(() => {
                                  e.target.focus();
                                  clearTimeout(time);
                                }, 10);
                              }}
                              className={`${editorId !== element.id ? css.hide : ''}`}
                              id={`email-editor-${element.id}`}
                              type="text"
                              onBlur={() => {
                                setEditorId(-1);
                              }}
                              onChange={(event) => {
                                if (!editorChanged) {
                                  setEditorChanged(true);
                                  const listener = (e) => {
                                    if (!e.key || e.key === 'Enter') {
                                      setEditorId(-1);
                                      dispatch({
                                        type: actions.UPDATE_VALUE,
                                        payload: {
                                          id: element.id,
                                          value: { email: event.target.value },
                                        },
                                      });
                                      window.removeEventListener('mousedown', listener);
                                      window.removeEventListener('keypress', listener);
                                      setEditorChanged(false);
                                    }
                                  };
                                  window.addEventListener('mousedown', listener, {
                                    once: true,
                                  });
                                  window.addEventListener('keypress', listener);
                                }
                              }}
                            />
                          </td>
                          <td>
                            <CustomInput
                              onChange={(e) => {
                                dispatch({
                                  type: actions.UPDATE_VALUE,
                                  payload: {
                                    id: element.id,
                                    value: { active: e.target.checked },
                                  },
                                });
                              }}
                              id={`pv-sw-${index}`}
                              type="checkbox"
                              checked={element.active}
                              className={`${css.centerSwitch} custom-switch`}
                            />
                          </td>
                          <td>
                            <span className="float-right opacity-4">
                              <FontAwesomeIcon
                                className="mr-1 cursor-pointer"
                                icon={faUserEdit}
                                onClick={() => {
                                  setEditorId(element.id);
                                  const target = document.getElementById(
                                    `email-editor-${element.id}`
                                  );
                                  target.value = element.email;
                                  const time = setTimeout(() => {
                                    target.focus();
                                    clearTimeout(time);
                                  }, 10);
                                }}
                              />
                              <FontAwesomeIcon
                                className="cursor-pointer"
                                onClick={() => setEmailId(element.id)}
                                icon={faTrash}
                              />
                            </span>
                          </td>
                        </tr>
                      ) : (
                        <tr key={`tmp-${index}`}>
                          <td className="pl-1 pr-0">
                            <Input
                              addon
                              type="checkbox"
                              disabled
                              aria-label="Checkbox for following text input"
                            />
                          </td>
                          <td>{element.email}</td>
                          <td>
                            <CustomInput
                              id={`tmp-pv-sw-${index}`}
                              type="checkbox"
                              disabled
                              className={`${css.centerSwitch} custom-switch`}
                            />
                          </td>
                          <td>
                            <span className="float-right opacity-2">
                              <FontAwesomeIcon className="mr-1" icon={faUserEdit} />
                              <FontAwesomeIcon icon={faTrash} />
                            </span>
                          </td>
                        </tr>
                      )
                    )}
                </tbody>
              </Table>
            </Row>
          );
        }
      });
    },
    [editorChanged, editorId, emailIds, emailListContext, onCheck, tCommon]
  );

  useEffect(() => {
    const mounted = { value: true };
    renderEmailList(mounted);
    return () => {
      mounted.value = false;
    };
  }, [renderEmailList]);

  /**
   * Render the e-mail addresses collector
   */
  const renderAddrCollector = () => (
    <FormGroup className={`${css.form}`}>
      <h5 className="pb-3 pl-3">{tCommon('NEW CONTACTS')}</h5>
      <Row className="justify-content-end pr-3">
        <Button
          className={`mt-10 ${css.collector_button}`}
          color="primary"
          type="button"
          onClick={() => append({})}
        >
          {tCommon('Add')}
        </Button>
      </Row>
      <Row className="pl-5 pr-3">
        <ul className={css.ul}>
          {fields.map((item, index) => (
            <li key={item.id}>
              <Row>
                <Col lg="12" className="pl-2">
                  <label className="form-control-label" htmlFor="input-event-name">
                    {tCommon('Email')}*
                  </label>
                  <InputGroup className={`${css.unified}`}>
                    <Input
                      className="form-control-alternative"
                      id="input-user-email"
                      name={`emails[${index}][]`}
                      placeholder="john@doe.fr"
                      type="text"
                      innerRef={register({
                        required: true,
                        minLength: 1,
                      })}
                    />
                    {errors.email && errors.email.type === 'required' && (
                      <p
                        style={{
                          color: 'red',
                        }}
                      >
                        {tCommon('This field must be filled')}
                      </p>
                    )}
                    {index > 0 && (
                      <Button
                        type="button"
                        className={css.removeButton}
                        onClick={() => remove(index)}
                      >
                        <FontAwesomeIcon icon={faTimesCircle} />
                      </Button>
                    )}
                  </InputGroup>
                </Col>
              </Row>
            </li>
          ))}
        </ul>
      </Row>
    </FormGroup>
  );

  /**
   * Render the view used to manage the Pharmacovigilance e-mail addresses
   */
  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {formSuccess && <Alert color="success">{tCommon('Your data has been saved!')}</Alert>}
      <div>
        <Row>
          <Col lg="12">
            {renderFilter()}
            {emailListRendering}
            {renderDeleteModal()}
          </Col>
        </Row>
        <Row className="border-top pt-4 pb-4">
          <Col>{children}</Col>
        </Row>
        <Row className="border-top pt-4">
          <Col lg="12">{renderAddrCollector()}</Col>
        </Row>
      </div>
      <Row className="pl-5 pr-3">
        <Button type="submit" color="primary" className="w-100 mt-3">
          {tCommon('Save')}
        </Button>
      </Row>
    </Form>
  );
};

export default EmailList;
