import React, { useCallback, useRef, useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import { InputGroup, InputGroupAddon, Input } from 'reactstrap';
import { v4 as uuid } from 'uuid';
import SimpleBar from 'simplebar-react';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import 'emoji-mart/css/emoji-mart.css';
import { Picker } from 'emoji-mart';

import { Colors } from 'Constants';
import { SessionContext } from 'context/sessionContext';
import v from 'assets/img/v.svg';
import { ReactComponent as Emoji } from 'assets/img/icons/emoji.svg';

const Chat = (props) => {
  const { session, isAnimator, style } = props;
  const [t] = useTranslation();
  const { state } = useContext(SessionContext);
  const { messages } = state;
  const input = useRef(null);
  const chatList = useRef(null);
  const [showEmoji, setShowEmoji] = useState(false);

  const sendMessage = useCallback(() => {
    if (!input.current) {
      return;
    }
    const { value } = input.current;
    if (value.match(/^\s*$/)) {
      return;
    }
    session.signal(
      {
        type: 'msg',
        data: {
          id: uuid(),
          text: input.current.value,
        },
      },
      (error) => {
        if (error) {
          console.log('Error sending signal:', error.name, error.message);
        } else {
          input.current.value = '';
        }
      }
    );
  }, [input, session]);

  const addEmoji = useCallback(
    (emoji) => {
      input.current.value += ` ${emoji.native} `;
      setShowEmoji(false);
      input.current.focus();
    },
    [input, setShowEmoji]
  );

  const removeMsg = useCallback(
    (id) => {
      session.signal(
        {
          type: 'msg-remove',
          data: id,
        },
        (error) => {
          if (error) {
            console.log('Error sending signal:', error.name, error.message);
          }
        }
      );
    },
    [session]
  );

  const onInputKeyDown = useCallback((e) => e.key === 'Enter' && sendMessage(), [sendMessage]);

  /**
   * When the messages are updated, scroll the list down
   */
  useEffect(() => {
    if (chatList.current) {
      setTimeout(() => {
        chatList.current.contentWrapperEl.scroll({
          top: chatList.current.contentWrapperEl.scrollHeight,
          left: 0,
          behavior: 'smooth',
        });
      }, 100);
    }
  }, [messages, chatList]);

  /**
   * Hide emojis if click outside
   */
  useEffect(() => {
    const onClickAnywhere = (e) => {
      if (!e.target.closest('.emoji-mart')) {
        setShowEmoji(false);
      }
    };
    if (showEmoji) {
      document.body.addEventListener('click', onClickAnywhere, false);
    }
    return () => {
      document.body.removeEventListener('click', onClickAnywhere, false);
    };
  }, [showEmoji, setShowEmoji]);

  return (
    <div
      style={{
        display: 'flex',
        ...style,
      }}
      className="flex-column chat flex-grow-1 p-3"
    >
      <SimpleBar
        ref={chatList}
        className="flex-grow-1"
        style={{
          maxHeight: 'calc(100% - 38px - 1.5rem)',
          paddingLeft: 'inherit',
        }}
      >
        {messages.map((msg, i) => (
          <span
            key={i}
            className={classnames('message position-relative w-100 mt-3 mb-3 p-3')}
            style={{
              ...styles.msg,
              userSelect: 'text',
            }}
          >
            <div className="mr-4 avatar position-absolute" style={styles.avatar}>
              <img
                src={v}
                alt=""
                style={{
                  maxWidth: '60%',
                  userSelect: 'none',
                }}
              />
            </div>
            <strong
              className="d-flex mb-2"
              style={{
                flexWrap: 'nowrap',
                wordBreak: 'normal',
                whiteSpace: 'nowrap',
              }}
            >
              {msg.from}
            </strong>
            {msg.text}
            {isAnimator && (
              <span
                className="position-absolute message-remove-bt"
                style={{
                  top: '0.05rem',
                  right: '0.75rem',
                  cursor: 'pointer',
                }}
                onClick={() => removeMsg(msg.id)}
                role="button"
                tabIndex={0}
              >
                <i className="text-danger fas fa-times" />
              </span>
            )}
          </span>
        ))}
      </SimpleBar>
      {showEmoji && (
        <Picker
          set="apple"
          title=""
          onSelect={addEmoji}
          style={{
            position: 'absolute',
            bottom: 80,
            left: 0,
            zIndex: 20,
            width: '100%',
          }}
        />
      )}
      <InputGroup className="mt-3 mb-1 position-relative">
        <Input
          className="rounded"
          type="text"
          innerRef={input}
          onKeyDown={onInputKeyDown}
          placeholder={t('Message')}
          aria-label={t('Message')}
          autoComplete="new-password"
        />
        <InputGroupAddon
          addonType="append"
          className="position-absolute h-auto"
          style={{
            right: 0,
            top: 0,
            bottom: 0,
            width: 40,
            zIndex: 10,
          }}
        >
          <span
            className="w-100 d-inline-flex justify-content-center align-items-center"
            style={{
              cursor: 'pointer',
            }}
            role="button"
            tabIndex={0}
            onClick={() => setShowEmoji(!showEmoji)}
          >
            <Emoji fill={showEmoji ? Colors.TEXT_PRIMARY : '#b7c1cc'} />
          </span>
        </InputGroupAddon>
      </InputGroup>
    </div>
  );
};
Chat.propTypes = {
  session: PropTypes.object,
  isAnimator: PropTypes.bool,
};
export default Chat;
const styles = {
  msg: {
    wordBreak: 'break-word',
    borderRadius: 9,
    backgroundColor: '#ced4da55',
  },
  avatar: {
    borderRadius: '50%',
    border: 'none',
    backgroundColor: '#3C7CFF',
    minWidth: 30,
    minHeight: 30,
    width: 30,
    height: 30,
    top: 0,
    left: 0,
    transform: 'translate(-50%, -50%)',
  },
};
