import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';

const EventEditLayout = (props) => {
  const { location, children } = props;
  const [t] = useTranslation('back-office');

  const currentPage = location.pathname.includes('list') ? 'list' : 'groups';
  return (
    <Container fluid>
      <Row>
        <Col>
          <Link to="/admin/users/list" className="mr-2 ml-3">
            <Button color={currentPage !== 'list' ? 'outline-primary' : 'primary'}>
              {t('Users Management')}
            </Button>
          </Link>
          <Link to="/admin/users/groups">
            <Button color={currentPage !== 'groups' ? 'outline-primary' : 'primary'}>
              {t('Group Management')}
            </Button>
          </Link>
        </Col>
      </Row>
      <Row>
        <Col>{children}</Col>
      </Row>
    </Container>
  );
};

export default EventEditLayout;
