import { Pagination, PaginationItem, PaginationLink } from 'reactstrap';
import classnames from 'classnames';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const AppPagination = (props) => {
  const { pagination, items, setPagination, maxItem } = props;
  const [maxItemByPage, setMaxItemByPage] = useState(4);

  useEffect(() => {
    if (maxItem) {
      setMaxItemByPage(maxItem);
    }
  }, [maxItem]);

  const [t] = useTranslation();
  return (
    <Pagination
      className="pt-1 pagination justify-content-end mb-0"
      listClassName="justify-content-end mb-0"
    >
      <PaginationItem
        className={classnames({
          disabled: pagination <= 0,
        })}
      >
        <PaginationLink onClick={() => pagination > 0 && setPagination(pagination - 1)}>
          <i className="fas fa-angle-left" />
          <span className="sr-only">{t('Previous')}</span>
        </PaginationLink>
      </PaginationItem>
      {items
        .filter((_, i) => i % maxItemByPage === 0)
        .map((_, i) => (
          <PaginationItem key={i} className={pagination === i ? 'active' : ''}>
            <PaginationLink onClick={() => setPagination(i)}>{i + 1}</PaginationLink>
          </PaginationItem>
        ))}
      <PaginationItem
        className={classnames({
          disabled: pagination >= ((items.length / maxItemByPage) | 0),
        })}
      >
        <PaginationLink
          onClick={() =>
            pagination < ((items.length / maxItemByPage) | 0) && setPagination(pagination + 1)
          }
        >
          <i className="fas fa-angle-right" />
          <span className="sr-only">{t('Next')}</span>
        </PaginationLink>
      </PaginationItem>
    </Pagination>
  );
};

export default AppPagination;

AppPagination.propTypes = {
  items: PropTypes.array.isRequired,
  pagination: PropTypes.number.isRequired,
  setPagination: PropTypes.func.isRequired,
};
