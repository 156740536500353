/*!

=========================================================
* Argon Dashboard PRO React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-pro-react
* Copyright 2020 Creative Tim (https://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from 'react';
// react library for routing
import { NavLink as NavLinkRRD, Link, withRouter } from 'react-router-dom';
// nodejs library that concatenates classes
import classnames from 'classnames';
// nodejs library to set properties for components
import { PropTypes } from 'prop-types';
// react library that creates nice scrollbar on windows devices
import PerfectScrollbar from 'react-perfect-scrollbar';
// reactstrap components
import { Collapse, NavbarBrand, Navbar, NavItem, NavLink, Nav } from 'reactstrap';
import { withTranslation } from 'react-i18next';

import logoVtopia from 'assets/img/logo-vtopia-blue.svg';
import { ThemeContext } from 'context/themeContext';
import { comBrand, comSideBar } from 'components/common/common.module.scss';

/**
 * @todo route.sidebar.name pass through the react-i18next translation
 */
class Sidebar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      collapseOpen: true,
      currentJointName: '',
      ...this.getCollapseStates(props.routes),
    };
    this.activeRoute = this.activeRoute.bind(this);
    this.onMouseEnterSidenav = this.onMouseEnterSidenav.bind(this);
    this.onMouseLeaveSidenav = this.onMouseLeaveSidenav.bind(this);
    this.toggleCollapse = this.toggleCollapse.bind(this);
    this.closeCollapse = this.closeCollapse.bind(this);
    this.getCollapseStates = this.getCollapseStates.bind(this);
    this.getCollapseInitialState = this.getCollapseInitialState.bind(this);
    this.closeSidenav = this.closeSidenav.bind(this);
    this.createLinks = this.createLinks.bind(this);
  }

  componentDidMount() {
    // document.body.classList.add('g-sidenav-show', 'g-sidenav-pinned');
  }

  // makes the sidenav normal on hover (actually when mouse enters on it)
  onMouseEnterSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.add('g-sidenav-show');
    }
  }

  // makes the sidenav mini on hover (actually when mouse leaves from it)
  onMouseLeaveSidenav() {
    if (!document.body.classList.contains('g-sidenav-pinned')) {
      document.body.classList.remove('g-sidenav-show');
    }
  }

  // this creates the intial state of this component based on the collapse routes
  // that it gets through this.props.routes
  getCollapseStates(routes) {
    let initialState = {};
    routes.map((route) => {
      if (route.collapse) {
        initialState = {
          [route.state]: this.getCollapseInitialState(route.views),
          ...this.getCollapseStates(route.views),
          ...initialState,
        };
      }
      return null;
    });
    return initialState;
  }

  // this verifies if any of the collapses should be default opened on a rerender of this component
  // for example, on the refresh of the page,
  // while on the src/views/forms/RegularForms.js - route /admin/regular-forms
  getCollapseInitialState(routes) {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].collapse && this.getCollapseInitialState(routes[i].views)) {
        return true;
      }
      if (window.location.href.indexOf(routes[i].path) !== -1) {
        return true;
      }
    }
    return false;
  }

  // toggles collapse between opened and closed (true/false)
  toggleCollapse() {
    const { collapseOpen } = this.state;
    this.setState({
      collapseOpen: !collapseOpen,
    });
  }

  // closes the collapse
  closeCollapse() {
    this.setState({
      collapseOpen: false,
    });
  }

  // this is used on mobile devices, when a user navigates
  // the sidebar will autoclose
  closeSidenav() {
    if (window.innerWidth < 1200) {
      const { toggleSidenav } = this.props;
      toggleSidenav();
    }
  }

  navigate(path) {
    // eslint-disable-next-line react/destructuring-assignment
    this.props.history.push(path);
  }

  // this function creates the links and collapses that appear in the sidebar (left menu)
  createLinks(routes) {
    return routes.map((route, key) => {
      if (route.routes) {
        return this.createLinks(route.routes);
      }
      if (!route.sidebar || route.redirect) {
        return null;
      }
      const { t } = this.props;

      const joint = route?.sidebar?.joint || {};
      const header = joint.header || false;
      const footer = joint.footer || false;
      const { currentJointName } = this.state;
      const closed = joint.name && currentJointName !== joint.name;

      return (
        <div className={comSideBar} key={key}>
          {header && (
            <li className={`${closed ? 'closed' : ''} nav-item joint-header`}>
              <button
                type="button"
                className="nav-link"
                onClick={() => {
                  const { name } = route.sidebar.joint;
                  this.setState({
                    currentJointName: currentJointName !== name ? name : '',
                  });
                  if (route.sidebar?.joint?.rootPath) {
                    this.navigate(route.sidebar.joint.rootPath);
                  }
                }}
              >
                <i className={route.sidebar.joint.icon} />
                <span className="nav-link-text">{joint.name}</span>
              </button>
            </li>
          )}
          <div
            className={`${closed ? 'joint-closed' : footer ? 'joint-footer' : ''} ${
              joint.name ? 'joined' : ''
            }`}
          >
            <NavItem className={this.activeRoute(route.path)}>
              <NavLink
                to={route.path ?? ''}
                activeClassName=""
                onClick={(e) => {
                  if (!joint.name) {
                    this.setState({
                      currentJointName: '',
                    });
                  }
                  this.closeSidenav(e);
                }}
                tag={NavLinkRRD}
              >
                {route.sidebar.icon !== undefined ? (
                  <>
                    <i className={route.sidebar.icon} />
                    <span className="nav-link-text">{t(route.sidebar.name)}</span>
                  </>
                ) : route.sidebar.miniName !== undefined ? (
                  <>
                    <span className="sidenav-mini-icon"> {t(route.sidebar.miniName)} </span>
                    <span className="sidenav-normal"> {t(route.sidebar.name)} </span>
                  </>
                ) : (
                  t(route.sidebar.name)
                )}
              </NavLink>
            </NavItem>
          </div>
        </div>
      );
    });
  }

  // verifies if routeName is the one active (in browser input)
  activeRoute(routeName) {
    const { location } = this.props;
    return location.pathname.indexOf(routeName) > -1 ? 'active' : '';
  }

  render() {
    const { routes, logo, sidenavOpen, toggleSidenav, rtlActive, currentLab } = this.props;
    let navbarBrandProps;
    if (logo && logo.innerLink) {
      navbarBrandProps = {
        to: logo.innerLink,
        tag: Link,
      };
    } else if (logo && logo.outterLink) {
      navbarBrandProps = {
        href: logo.outterLink,
        target: '_blank',
      };
    }
    const scrollBarInner = (
      <div className="scrollbar-inner">
        <div className={`${comBrand} sidenav-header d-flex align-items-center justify-content-end`}>
          <NavbarBrand {...navbarBrandProps}>
            {
              // <img
              // className="navbar-brand-img"
              // src={
              //   !!currentLab && currentLab.logo_image
              //     ? `${process.env.REACT_APP_API_URI_NODE}${currentLab.logo_image.url}`
              //     : logoVtopia
              // }
              // alt=""
              // style={{
              //   width: '100%',
              //   height: '100%',
              // }}
              // />
            }
            <div
              className="image"
              style={{
                backgroundImage: `url('${
                  !!currentLab && currentLab.logo_image
                    ? `${process.env.REACT_APP_API_URI_NODE}${currentLab.logo_image.url}`
                    : logoVtopia
                }')`,
              }}
            >
              <div className="badge">BETA</div>
            </div>
          </NavbarBrand>
          <div>
            <div
              className={classnames('sidenav-toggler d-none d-xl-block', {
                active: sidenavOpen,
              })}
              role="button"
              tabIndex={0}
              onClick={toggleSidenav}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
        </div>
        <div className="navbar-inner">
          <Collapse navbar isOpen>
            <Nav navbar>{this.createLinks(routes)}</Nav>
          </Collapse>
        </div>
      </div>
    );
    return (
      <Navbar
        className={`sidenav navbar-vertical navbar-expand-xs navbar-light bg-white ${
          rtlActive ? '' : 'fixed-left'
        }`}
        onMouseEnter={this.onMouseEnterSidenav}
        onMouseLeave={this.onMouseLeaveSidenav}
        style={{
          overflowX: 'hidden',
        }}
      >
        {navigator.platform.indexOf('Win') > -1 ? (
          <PerfectScrollbar>{scrollBarInner}</PerfectScrollbar>
        ) : (
          scrollBarInner
        )}
      </Navbar>
    );
  }
}

Sidebar.defaultProps = {
  routes: [{}],
  toggleSidenav: () => {},
  sidenavOpen: false,
  rtlActive: false,
};

Sidebar.contextType = ThemeContext;

Sidebar.propTypes = {
  // function used to make sidenav mini or normal
  toggleSidenav: PropTypes.func,
  // prop to know if the sidenav is mini or normal
  sidenavOpen: PropTypes.bool,
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  // logo
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
  // rtl active, this will make the sidebar to stay on the right side
  rtlActive: PropTypes.bool,
  location: PropTypes.object,
  t: PropTypes.func,
};

export default withRouter(withTranslation()(Sidebar));
