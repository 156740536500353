import React, { useEffect, useState, useCallback } from 'react';
import { Container, Row, Col, Card, CardHeader, CardBody, Progress, CardFooter } from 'reactstrap';
import { Bar, Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';
import Chart from 'chart.js';
import { chartOptions, parseOptions } from 'utils/chart';
import { useSessionsContext } from 'context/sessionContext';
import moment from 'moment';
import { ListProvider } from 'context/ListContext';
import Pagination from 'components/common/Pagination';
import css from './consumption.module.scss';
import ConsumptionSessionList from './ConsumptionSessionList';

const ConsumptionTable = () => {
  const defaultChartData = {
    data: {},
  };
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation('common');
  const { state } = useSessionsContext();
  const [chartConsumption, setChartConsumption] = useState(defaultChartData);
  const [chartCreditTracking, setChartCreditTracking] = useState(defaultChartData);
  const [consumptionRangeType, setConsumptionRangeType] = useState('day');
  const [creditTrackingRangeType, setCreditTrackingRangeType] = useState('day');
  const [creditPlan, setCreditPlan] = useState(0);
  const [credit, setCredit] = useState(0);
  const [average, setAverage] = useState(0);
  const [counterCredit, setCounterCredit] = useState(0);
  const [creditPercent, setCreditPercent] = useState(0);
  const [counterCreditPercent, setCounterCreditPercent] = useState(0);

  const [pagination, setPagination] = useState(0);
  const [maxItem, setMaxItem] = useState(5);
  const [itemSubstitutes, setItemSubstitutes] = useState([]);

  parseOptions(Chart, chartOptions());

  const onItemChange = useCallback((value) => {
    setItemSubstitutes(Array(value).fill(0));
  }, []);

  const getFakeData = useCallback(() => {
    return Array(Math.floor(Math.random() * 4) + 4)
      .fill(0)
      .map(() => {
        return Math.floor(10 + Math.random() * 500);
      });
  }, []);

  const getMouthLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).month();
        return moment()
          .month((index + base) % 12)
          .format('MMM');
      });
  };

  const getWeekLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).day();
        const i = index * 7 + base;
        return moment().day(i).format('D MMM');
      });
  };

  const getDayLabels = (from, number) => {
    return Array(number)
      .fill(0)
      .map((value, index) => {
        const base = moment(from).day();
        const i = index + base;
        const name = moment()
          .day(i % 7)
          .format('ddd');
        const day = moment().day(i).format('D');
        return `${name} ${day}`;
      });
  };

  const setChartConsuptionData = (labels, values) => {
    setChartConsumption({
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#212529',
                zeroLineColor: '#e9ecef',
              },
              ticks: {},
            },
          ],
        },
      },
      data: {
        labels,
        datasets: [
          {
            label: 'Performance',
            data: values,
            maxBarThickness: 10,
            backgroundColor: '#3c7cff',
          },
        ],
      },
    });
  };

  const setChartCreditTrackingData = (labels, values) => {
    setChartCreditTracking({
      options: {
        scales: {
          yAxes: [
            {
              gridLines: {
                color: '#212529',
                zeroLineColor: '#e9ecef',
              },
              ticks: {},
            },
          ],
        },
      },
      data: {
        labels,
        datasets: [
          {
            label: 'Performance',
            data: values,
            borderColor: '#f2b800',
          },
        ],
      },
    });
  };

  const getLabels = useCallback((type, date, range) => {
    switch (type) {
      case 'day':
        return getDayLabels(date, range);
      case 'week':
        return getWeekLabels(date, range);
      default:
        return getMouthLabels(date, range);
    }
  }, []);

  useEffect(() => {
    http
      .get(`chart-consumption/${consumptionRangeType}`)
      .then((response) => {
        const { length } = response.values;
        const labels = getLabels(consumptionRangeType, response.begin_date, length);
        setChartConsuptionData(labels, response.values);
      })
      .catch(() => {
        // temporary fake data
        let cache = JSON.parse(sessionStorage.getItem('consuptionCache'));
        if (!cache) cache = {};
        if (!cache[consumptionRangeType]) {
          cache[consumptionRangeType] = getFakeData();
          sessionStorage.setItem('consuptionCache', JSON.stringify(cache));
        }
        const data = cache[consumptionRangeType];
        const labels = getLabels(consumptionRangeType, new Date(), data.length);
        setChartConsuptionData(labels, data);
      });
  }, [getFakeData, getLabels, consumptionRangeType]);

  useEffect(() => {
    http
      .get(`chart-credit-tracking/${creditTrackingRangeType}`)
      .then((response) => {
        const { length } = response.values;
        const labels = getLabels(creditTrackingRangeType, response.begin_date, length);
        setChartCreditTrackingData(labels, response.values);
      })
      .catch(() => {
        // temporary fake data
        let cache = JSON.parse(sessionStorage.getItem('trackingCache'));
        if (!cache) cache = {};
        if (!cache[creditTrackingRangeType]) {
          cache[creditTrackingRangeType] = getFakeData();
          sessionStorage.setItem('trackingCache', JSON.stringify(cache));
        }
        const data = cache[creditTrackingRangeType];
        const labels = getLabels(creditTrackingRangeType, new Date(), data.length);
        setChartCreditTrackingData(labels, data);
      });
  }, [getFakeData, getLabels, creditTrackingRangeType]);

  useEffect(() => {
    if (state.lab !== null) {
      const { plan } = state.lab;
      setCreditPlan(plan);
      const setCreditInfo = (data) => {
        setCredit(Math.floor(data.consumption));
        const counterConsumption = plan - data.consumption;
        setCounterCredit(Math.floor(counterConsumption));
        setCreditPercent(Math.floor((data.consumption / plan) * 100));
        setCounterCreditPercent(Math.floor((counterConsumption / plan) * 100));
        setAverage(Math.floor(data.average));
      };
      http
        .get('lab-consumption')
        .then((response) => {
          setCreditInfo(response);
        })
        .catch((e) => {
          console.log(e);
        });
    }
  }, [state.lab]);

  return (
    <Container fluid className={css.comsumptionPage}>
      <Row>
        <Col lg="7" md="10">
          <h1 className="display-2 text-white">{t('My consumption')}</h1>
          <p className="text-white mt-0 mb-5 w-100">
            <br />
          </p>
        </Col>
      </Row>
      <Row>
        <Col>
          <Card>
            <CardBody className="consumptionCard">
              <Row className="pb-0 align-items-center">
                <Col>
                  <h5 className="h3 mb-0">{t('MY CREDIT')}</h5>
                </Col>
                <Col>
                  <div className="float-right">
                    <form action="/" target="_blank">
                      {/*                      <button type="submit" className="btn btn-sm btn-outline-primary">
                        Ajouter du crédit
                      </button> */}
                    </form>
                  </div>
                </Col>
              </Row>
              <Row className="pb-4">
                <Col>
                  <span className="credit">{`${creditPlan} min`}</span>
                </Col>
              </Row>
              <Row>
                <Col>
                  <h6>{t('Number of minutes consumed')}</h6>
                  <div className="progress-wrapper">
                    <div className="progress-info">
                      <div className="progress-label">
                        <span
                          style={{
                            minWidth: `${creditPercent}%`,
                          }}
                        >
                          {`${credit} min: ${creditPercent}%`}
                        </span>
                      </div>
                    </div>
                    <Progress max="100" value={creditPercent} color="default" />
                  </div>
                </Col>
                <Col>
                  <h6>{t('Number of minutes left')}</h6>
                  <div className="progress-wrapper">
                    <div className="progress-info">
                      <div className="progress-label">
                        <span
                          style={{
                            minWidth: `${counterCreditPercent}%`,
                          }}
                        >
                          {`${counterCredit} min: ${counterCreditPercent}%`}
                        </span>
                      </div>
                    </div>
                    <Progress max="100" value={counterCreditPercent} color="default" />
                  </div>
                </Col>
              </Row>
              <Row className="consumption-bottom pt-3">
                <Col>
                  <Row>
                    <Col>
                      <h5>{t('AVERAGE CONSUMPTION/SESSION')}</h5>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span>{`${average} min`}</span>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Col />
      </Row>
      <Row className="d-none">
        <Col className="col-xl-6">
          <Card className="consumptionChartCard first">
            <CardHeader>
              <Row>
                <Col>
                  <h5 className="h3 mb-0">{t('Monitoring of my Vtopia consumption')}</h5>
                </Col>
                <Col>
                  <div className="float-right">
                    <button
                      type="button"
                      className={`${
                        consumptionRangeType === 'day'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setConsumptionRangeType('day');
                      }}
                    >
                      {tCommon('Day')}
                    </button>
                    <button
                      type="button"
                      className={`${
                        consumptionRangeType === 'week'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setConsumptionRangeType('week');
                      }}
                    >
                      {tCommon('Week')}
                    </button>
                    <button
                      type="button"
                      className={`${
                        consumptionRangeType === 'month'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setConsumptionRangeType('month');
                      }}
                    >
                      {tCommon('Month')}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Bar
                  data={chartConsumption.data}
                  options={chartConsumption.options}
                  className="chart-canvas"
                  id="chart-bar"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col className="col-xl-6">
          <Card className="consumptionChartCard">
            <CardHeader>
              <Row>
                <Col>
                  <h5 className="h3 mb-0">{t('Monitoring of my Vtopia credit')}</h5>
                </Col>
                <Col>
                  <div className="float-right">
                    <button
                      type="button"
                      className={`${
                        creditTrackingRangeType === 'day'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setCreditTrackingRangeType('day');
                      }}
                    >
                      {tCommon('Day')}
                    </button>
                    <button
                      type="button"
                      className={`${
                        creditTrackingRangeType === 'week'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setCreditTrackingRangeType('week');
                      }}
                    >
                      {tCommon('Week')}
                    </button>
                    <button
                      type="button"
                      className={`${
                        creditTrackingRangeType === 'month'
                          ? 'btn btn-primary btn-sm'
                          : 'btn btn-outline-primary btn-sm'
                      }`}
                      onClick={() => {
                        setCreditTrackingRangeType('month');
                      }}
                    >
                      {tCommon('Month')}
                    </button>
                  </div>
                </Col>
              </Row>
            </CardHeader>
            <CardBody>
              <div className="chart">
                <Line
                  data={chartCreditTracking.data}
                  options={chartCreditTracking.options}
                  className="chart-canvas"
                  id="chart-Line"
                />
              </div>
            </CardBody>
          </Card>
        </Col>
      </Row>
      <Row className="d-none">
        <Col>
          <Card>
            <CardHeader className="bg-white border-0">
              <h5 className="h3 mb-0">{t('LIST OF CLOSED SESSIONS')}</h5>
            </CardHeader>
            <CardBody>
              <ListProvider
                resource={{
                  routes: {
                    ON_LOAD: '',
                  },
                }}
              >
                <ConsumptionSessionList
                  pagination={pagination}
                  onItemChange={onItemChange}
                  onItemCountChange={(value) => {
                    setMaxItem(value);
                    setPagination(0);
                  }}
                />
              </ListProvider>
            </CardBody>
            <CardFooter className="border-top-0">
              <nav aria-label="...">
                <Pagination
                  items={itemSubstitutes}
                  pagination={pagination}
                  setPagination={setPagination}
                  maxItem={maxItem}
                />
              </nav>
            </CardFooter>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default ConsumptionTable;
