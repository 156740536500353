import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faEllipsisV, faDownload } from '@fortawesome/free-solid-svg-icons';
// import FilterBar from 'components/admin/polls/FilterBar';
import React, { useCallback, useEffect, useState, useContext, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  Input,
  Row,
  Label,
  Table,
  InputGroup,
  InputGroupText,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import http from 'utils/http';
import moment from 'moment';
import { ListContext, actions } from 'context/ListContext';
// import AddTo from 'components/common/AddTo';
import css from './list.module.css';

const DocumentList = (props) => {
  const { pagination, onItemCountChange, onItemChange } = props;
  const [itemCount, setItemCount] = useState(5);
  const [tCommon] = useTranslation();
  /**
   * States and context
   * */
  const [editorId, setEditorId] = useState(-1);
  const [editorChanged, setEditorChanged] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [multipleDelete, setMultipleDelete] = useState(false);
  const [multipleDownload, setMultipleDownload] = useState(false);
  const [elementId, setElementId] = useState(-1);
  const [elementIds, setElementIds] = useState({});
  const [listRendering, setListRendering] = useState('');
  // const [toggleAddToFolder, setToggleAddToFolder] = useState(false);
  const listContext = useContext(ListContext);

  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        setDeleteModal(true);
        break;
      case 'multiple-delete':
        setMultipleDelete(true);
        break;
      default:
        setDeleteModal(false);
        setMultipleDelete(false);
        setElementId(-1);
    }
  };

  const toggleDownload = (value) => () => {
    switch (value) {
      case 'multiple-download':
        setMultipleDownload(true);
        break;
      default:
        setMultipleDownload(false);
    }
  };

  const load = useCallback(
    (paginate) => {
      const { dispatch } = listContext;
      dispatch({
        type: actions.LOAD_LIST,
        payload: {
          start: paginate * itemCount,
          count: itemCount,
        },
      });
    },
    [itemCount, listContext]
  );

  function usePrevious(value) {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  }

  const previous = usePrevious({ pagination, listContext });

  useEffect(() => {
    if (previous === undefined || previous.pagination !== pagination) {
      load(pagination);
    }
  }, [pagination, previous, load]);

  useEffect(() => {
    if (elementId !== -1) {
      toggle('delete')();
    }
  }, [elementId]);

  /**
   * Render the filtering options and the search bar
   */
  const renderFilter = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              setItemCount(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
          {/* <AddTo type="folder" /> */}
          <InputGroup
            style={{
              display: 'none',
              cursor: Object.keys(elementIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${css.unified}
              ${Object.keys(elementIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(elementIds).length > 0) {
                toggleDownload('multiple-download')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Download')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faDownload} />
            </InputGroupText>
          </InputGroup>
          <InputGroup
            style={{
              cursor: Object.keys(elementIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${css.unified}
              ${Object.keys(elementIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(elementIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
          }}
        >
          {/* <FilterBar updateRoute="" filterRoute="" /> */}
        </Col>
      </Row>
    );
  }, [listContext, tCommon, elementIds, onItemCountChange]);

  const download = useCallback((id, filename) => {
    http.download(`documents/${id}/download`, filename);
  }, []);

  const dropDownMenu = useCallback(
    (element) => {
      return (
        <UncontrolledDropdown>
          <DropdownToggle
            className="btn-icon-only opacity-4"
            role="button"
            size="sm"
            color=""
            onClick={(e) => e.preventDefault()}
          >
            <i className="fas fa-ellipsis-v" />
          </DropdownToggle>
          <DropdownMenu className="dropdown-menu-arrow" right>
            <DropdownItem
              onClick={() => {
                setEditorId(element.id);
                const target = document.getElementById(`editor-${element.id}`);
                target.value = element.name;
                const time = setTimeout(() => {
                  target.focus();
                  clearTimeout(time);
                }, 10);
              }}
            >
              {tCommon('Edit')}
            </DropdownItem>
            {/*
            <DropdownItem
              target="_blank"
              download
              href={`${process.env.REACT_APP_API_BASE_URI}/${element.path}`}
            >
              {tCommon('Open')}
            </DropdownItem>
            */}
            <DropdownItem
              onClick={() => {
                download(element.id, `${element.name}.${element.extension}`);
              }}
            >
              {tCommon('Download')}
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      );
    },
    [tCommon, download]
  );

  const renderDownloadModal = useCallback(() => {
    return (
      <Modal className="modal-lg" isOpen={multipleDownload} fade toggle={toggleDownload('close')}>
        <ModalHeader toggle={toggleDownload('close')}>{tCommon('Download')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {multipleDownload ? (
                <p>{tCommon('Click on continue to download all files')}</p>
              ) : (
                <p>{tCommon('Click on continue to download the file')}</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              //
              setElementIds({});
              toggleDownload('close')();
            }}
          >
            {tCommon('Validate')}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }, [multipleDownload, tCommon]);

  /**
   * Render the 'delete' modal
   */
  const renderDeleteModal = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Modal
        className="modal-lg"
        isOpen={deleteModal || multipleDelete}
        fade
        toggle={toggle('close')}
      >
        <ModalHeader toggle={toggle('close')}>{tCommon('Delete')}</ModalHeader>
        <ModalBody>
          <Row>
            <Col>
              {multipleDelete ? (
                <p>{tCommon('Are you sure you want to delete those selected elements?')}</p>
              ) : (
                <p>{tCommon('Are you sure you want to delete this element?')}</p>
              )}
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button
            onClick={() => {
              if (multipleDelete) {
                dispatch({
                  type: actions.DELETE_MULTIPLE,
                  payload: {
                    ids: Object.keys(elementIds),
                  },
                });
              } else {
                dispatch({
                  type: actions.DELETE,
                  payload: {
                    id: elementId,
                  },
                });
              }
              setElementIds({});
              toggle('close')();
            }}
          >
            Validate
          </Button>
        </ModalFooter>
      </Modal>
    );
  }, [tCommon, elementId, elementIds, listContext, deleteModal, multipleDelete]);

  const onCheck = useCallback(
    (e, element) => {
      if (e.target.checked) {
        const ids = {
          ...elementIds,
        };
        ids[element.id] = true;
        setElementIds(ids);
      } else {
        const ids = {
          ...elementIds,
        };
        delete ids[element.id];
        setElementIds(ids);
      }
    },
    [elementIds]
  );

  /**
   * Render the list
   */
  const renderList = useCallback(() => {
    const { state, dispatch } = listContext;
    state.then(({ list }) => {
      setListRendering(
        <Row>
          <Table>
            <thead className={css.thead}>
              <tr>
                <th
                  style={{
                    width: '20px',
                  }}
                  className="pl-1 pr-0"
                />
                <th
                  style={{
                    width: '30%',
                  }}
                >
                  {tCommon('DOCUMENT NAMES')}
                </th>
                <th
                  style={{
                    width: '40px',
                  }}
                >
                  {tCommon('GROUP')}
                </th>
                <th />
              </tr>
            </thead>
            <tbody>
              {list
                .filter((element) => !element.deleted)
                .map((element, index) =>
                  !element.tmp ? (
                    <tr key={index}>
                      <td className="pl-1 pr-0">
                        <Input
                          addon
                          type="checkbox"
                          checked={elementIds[element.id] === true}
                          aria-label="Checkbox for following text input"
                          onChange={(e) => onCheck(e, element)}
                        />
                      </td>
                      <td className={css.editor}>
                        <Label
                          className={`${editorId === element.id ? css.hide : ''}`}
                          for={`editor-${element.id}`}
                        >
                          {`${element.name}.${element.extension}`}
                        </Label>
                        <Input
                          onKeyPress={(event) => {
                            if (event.key === 'Enter') {
                              return event.preventDefault();
                            }
                            return true;
                          }}
                          onClick={(e) => {
                            setEditorId(element.id);
                            e.target.value = element.name;
                            const time = setTimeout(() => {
                              e.target.focus();
                              clearTimeout(time);
                            }, 10);
                          }}
                          className={`${editorId !== element.id ? css.hide : ''}`}
                          id={`editor-${element.id}`}
                          type="text"
                          onBlur={() => {
                            setEditorId(-1);
                          }}
                          onChange={(event) => {
                            if (!editorChanged) {
                              setEditorChanged(true);
                              const listener = (e) => {
                                if (!e.key || e.key === 'Enter') {
                                  setEditorId(-1);
                                  dispatch({
                                    type: actions.UPDATE_VALUE,
                                    payload: {
                                      id: element.id,
                                      value: { name: event.target.value },
                                    },
                                  });
                                  window.removeEventListener('mousedown', listener);
                                  window.removeEventListener('keypress', listener);
                                  setEditorChanged(false);
                                }
                              };
                              window.addEventListener('mousedown', listener, {
                                once: true,
                              });
                              window.addEventListener('keypress', listener);
                            }
                          }}
                        />
                        <div>{moment(element.created_at).format('MMMM Do YYYY, h:mm:ss a')}</div>
                      </td>
                      <td>
                        {element.groups.reduce((last, current) => {
                          return `${last ? `${last},` : ''}${current.name}`;
                        }, undefined)}
                      </td>
                      <td>
                        <span className="float-right">
                          <FontAwesomeIcon
                            className="opacity-4 cursor-pointer"
                            onClick={() => setElementId(element.id)}
                            icon={faTrash}
                          />
                          {dropDownMenu(element)}
                        </span>
                      </td>
                    </tr>
                  ) : (
                    <tr key={`tmp-${index}`}>
                      <td className="pl-1 pr-0">
                        <Input
                          addon
                          type="checkbox"
                          disabled
                          aria-label="Checkbox for following text input"
                        />
                      </td>
                      <td>
                        {element.name}
                        <div>{moment(element.created_at).format('MMMM Do YYYY, h:mm:ss a')}</div>
                      </td>
                      <td>{element.groups.join('/')}</td>
                      <td>
                        <span className="float-right opacity-2">
                          <FontAwesomeIcon icon={faTrash} />
                          <FontAwesomeIcon className="mr-1" icon={faEllipsisV} />
                        </span>
                      </td>
                    </tr>
                  )
                )}
            </tbody>
          </Table>
        </Row>
      );
    });
  }, [editorChanged, editorId, elementIds, listContext, onCheck, tCommon, dropDownMenu]);

  const callBackItemChange = useCallback(
    (context) => {
      if (previous !== undefined) {
        const { listLength } = context;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    },
    [onItemChange, previous]
  );

  useEffect(() => {
    callBackItemChange(listContext);
  }, [listContext, callBackItemChange]);

  useEffect(() => {
    renderList(listContext);
  }, [listContext, elementIds, editorId, editorChanged, renderList]);

  /**
   * Render the view used to manage the list
   */
  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilter()}
            {listRendering}
            {renderDeleteModal()}
            {renderDownloadModal()}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default DocumentList;
