import React, { useCallback, useState, useEffect } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
} from 'reactstrap';
import http from 'utils/http';
import { useTranslation } from 'react-i18next';
import logo from 'assets/img/logo-vtopia.svg';
import logoV from 'assets/img/logo-v.svg';
import { useThemeContext } from 'context/themeContext';
import Footer from 'components/front/footers/Footer';
import css from './password.module.scss';
import 'App.css';

const ResetPassword = () => {
  const [t] = useTranslation('front');
  const [tCommon] = useTranslation();
  const { logo: labLogo } = useThemeContext();
  const [passwordChangeSucceed, setPasswordChangeSucceed] = useState(false);
  const [userId, setUserId] = useState(0);
  const [formError, setFormError] = useState([]);

  const enCrypt = (password) => {
    // todo
    return password;
  };

  useEffect(() => {
    // todo from token
    setUserId(0);
  }, []);

  const handleSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setFormError([]);
      const form = e.target;
      if (form.password.value !== form.confirmPassword.value) {
        setFormError([...formError, 'not-matching']);
      } else if (!form?.password?.value || !form?.confirmPassword?.value) {
        setFormError([...formError, 'not-valid']);
      } else {
        http
          .post(`change-password/${userId}`, {
            password: enCrypt(form.password.value),
          })
          .then((response) => {
            if (response.sucess) {
              setPasswordChangeSucceed(true);
            }
          });
      }
    },
    [formError, userId]
  );

  return (
    <Container fluid className={`${css.password} h-100 text-center bg-default third-color`}>
      <Row
        className="justify-content-center mb-3 header pb-7 pt-7 position-relative first-color"
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex"
          style={{
            top: 20,
          }}
        >
          <img
            src={labLogo || logo}
            draggable={false}
            style={{
              width: '60px',
              height: 'auto',
              marginRight: '10px',
            }}
            alt="Vtopia"
          />
          {/*
            <div className="badge-login">BETA</div>
          */}
        </Container>
        <Col>
          <h1 className="text-white mb-5">{t('Welcome to Vtopia!')}</h1>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      {passwordChangeSucceed && (
        <Container>
          <Col
            lg="5"
            md="7"
            className="mt--7"
            style={{
              margin: 'auto',
            }}
          >
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-2 mb-3">
                  <strong>{tCommon('Success')}</strong>
                </div>
                <img
                  alt=""
                  src={logoV}
                  style={{
                    height: '80px',
                    width: 'auto',
                  }}
                />
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">{t('Your password has been changed')}</CardBody>
            </Card>
          </Col>
        </Container>
      )}
      {!passwordChangeSucceed && (
        <Container>
          <Col
            lg="5"
            md="7"
            className="mt--7"
            style={{
              margin: 'auto',
            }}
          >
            <Card className="bg-secondary shadow border-0">
              <CardHeader className="bg-transparent pb-4">
                <div className="text-muted text-center mt-2 mb-3">
                  <strong>{t('Change password')}</strong>
                </div>
                <img
                  alt=""
                  src={logoV}
                  style={{
                    height: '80px',
                    width: 'auto',
                  }}
                />
              </CardHeader>
              <CardBody className="px-lg-5 py-lg-5">
                <Form role="form" onSubmit={handleSubmit}>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t('New password')}
                        id="password"
                        type="password"
                        required
                        autoComplete="password"
                      />
                    </InputGroup>
                  </FormGroup>
                  <FormGroup>
                    <InputGroup className="input-group-alternative">
                      <InputGroupAddon addonType="prepend">
                        <InputGroupText>
                          <i className="ni ni-lock-circle-open" />
                        </InputGroupText>
                      </InputGroupAddon>
                      <Input
                        placeholder={t('Confirm password')}
                        id="confirmPassword"
                        type="password"
                        required
                        autoComplete="password"
                      />
                    </InputGroup>
                    {formError.indexOf('not-matching') !== -1 && (
                      <div className="error-warning">
                        <span>{t('Both passwords are not matching')}</span>
                      </div>
                    )}
                    {formError.indexOf('not-matching') === -1 &&
                      formError.indexOf('not-valid') !== -1 && (
                        <div className="error-warning">
                          <span>{t('Please enter valid passwords')}</span>
                        </div>
                      )}
                  </FormGroup>
                  <div className="text-center">
                    <Button className="my-4" color="primary" type="submit">
                      {t('Confirm password')}
                    </Button>
                  </div>
                </Form>
              </CardBody>
            </Card>
          </Col>
        </Container>
      )}
      <Footer />
    </Container>
  );
};

export default ResetPassword;
