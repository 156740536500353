import React from 'react';
import _ from 'lodash';

import http from 'utils/http';
import nodeServerHttp from '../utils/nodeApiHttp';

export const AuthContext = React.createContext();

export function useAuthContext() {
  const context = React.useContext(AuthContext);
  if (context === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }
  return context;
}

export const initialState = {
  isLogged: false,
  lab: null,
  user: {},
  reconnecting: false,
  eventId: null, // only for guests
  eventData: null, // only for guests
};

export const reducer = (state = initialState, action) => {
  const { payload } = action;
  switch (action.type) {
    case 'REQUEST_RECONNECT':
      return {
        ...initialState,
        reconnecting: true,
      };

    case 'LOGIN_SUCCESS':
      return {
        ...state,
        user: payload,
        isLogged: true,
        reconnecting: false,
      };

    case 'CURRENT_LAB':
      return {
        ...state,
        lab: payload,
      };

    case 'LOGOUT':
      return {
        ...initialState,
      };

    case 'LOGIN_ERROR':
      return {
        ...initialState,
        errorMessage: action.error,
      };

    case 'EVENT_SUCCESS':
      return {
        ...state,
        eventId: payload,
      };

    case 'EVENT_DATA':
      return {
        ...state,
        eventData: payload,
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const AuthProvider = ({ children }) => {
  const [state, dispatch] = React.useReducer(reducer, initialState);
  return (
    <AuthContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

/*
 Async Actions
 */
export async function login(context, { email, password, invitation }, eventId = null) {
  try {
    let res = await http.post('login', {
      email,
      password,
      invitation,
    });

    // If User is not a Vtopia admin, check event invitation
    if (eventId && res.user.roles.indexOf('admin') === -1) {
      res = await nodeServerHttp
        .get(`/event-invitations?event=${eventId}&participant=${res.user.participant}`)
        .then((response) => {
          return {
            ...res,
            user: {
              ...res.user,
              role: response.length !== 0 ? _.head(response).role : 'participant',
              roomId: _.head(response).room_id.id,
              eventId: res.event_id,
            },
          };
        })
        .catch((error) => {
          console.log('error');
          console.log(error);
        });
    }
    putLoginResponseInContext(context, res, eventId);
    return res;
  } catch (error) {
    context.dispatch({
      type: 'LOGIN_ERROR',
      error,
    });
    return Promise.reject(error);
  }
}

/*
 Async Actions
 */
export async function publicLogin(context, { firstName, lastName, email, eventSlug }) {
  try {
    let res = await http.post('login', {
      firstName,
      lastName,
      email,
      eventSlug,
      isPublic: true,
    });

    // If User is not a Vtopia admin, check event invitation
    if (res.event_id && res.user.roles.indexOf('admin') === -1) {
      const defaultRoom = await nodeServerHttp.get(`/events/${res.event_id}`);

      res = await nodeServerHttp
        .post('event-invitations', {
          participant: res.user.participant,
          event: res.event_id,
          room_id: _.head(defaultRoom.rooms).id,
          role: res.user.role,
        })
        .then((response) => {
          return {
            ...res,
            user: {
              ...res.user,
              role: response.role,
              roomId: response.room_id.id,
              eventId: response.event.id,
            },
          };
        });

      await nodeServerHttp.put(`/participants/${res.user.participant}`, {
        room_id: res.user.roomId,
      });
    }

    putLoginResponseInContext(context, res);
    return res;
  } catch (error) {
    context.dispatch({
      type: 'LOGIN_ERROR',
      error,
    });
    return Promise.reject(error);
  }
}

export async function logout(context) {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('token');
  localStorage.removeItem('themeData');
  localStorage.removeItem('opentok_client_id');
  localStorage.removeItem('debug');
  context.dispatch({
    type: 'LOGOUT',
  });
  return http.post('logout');
}

export async function reconnect(context) {
  try {
    context.dispatch({
      type: 'REQUEST_RECONNECT',
    });
    const res = await http.get('me');
    putLoginResponseInContext(context, res);
    return res;
  } catch (error) {
    context.dispatch({
      type: 'LOGIN_ERROR',
      error,
    });
    return Promise.reject(error);
  }
}

async function putLoginResponseInContext(context, res, paramEventId = null) {
  if (res.token) {
    http.setToken(res.token);
  }
  const eventId = paramEventId || res.event_id;

  if (eventId) {
    // guest mode
    context.dispatch({
      type: 'EVENT_SUCCESS',
      payload: eventId,
    });

    context.dispatch({
      type: 'LOGIN_SUCCESS',
      payload: res.user,
    });
  } else {
    // Overload PHP API current user data by NODEJS API data -- to refactor later
    nodeServerHttp.get(`users/${res.user.id}`).then((user) => {
      res.user.avatar_url = user.avatar_url;
      res.user.lab = user.lab_id; // "lab_id" To rename later from Js Node api
      context.dispatch({
        type: 'LOGIN_SUCCESS',
        payload: res.user,
      });
    });

    nodeServerHttp.get(`labs/${res.user.lab.id}`).then((lab) => {
      context.dispatch({
        type: 'CURRENT_LAB',
        payload: lab,
      });
    });
  }
}

/**
 * Return if logged user has the permission, or AT LEAST ONE permission in array
 * @param {AuthContext} context
 * @param {string|array} roles
 * @returns {boolean}
 */
export function hasAnyRole(context, roles) {
  if (!context.state.isLogged) return false;
  roles = Array.isArray(roles) ? roles : [roles];
  return roles.some((p) => context.state.user.roles?.includes(p));
}

/**
 * @param {AuthContext} context
 * @param {string} role
 * @returns {boolean}
 */
export function hasRole(context, role) {
  if (!context.state.isLogged) return false;
  return context.state.user.roles.includes(role);
}

/**
 * Return if logged user has the permission, or AT LEAST ONE permission in array
 * @param {AuthContext} context
 * @param {string|array} permissions
 * @returns {boolean}
 */
export function hasAnyPermission(context, permissions) {
  if (!context.state.isLogged) return false;
  permissions = Array.isArray(permissions) ? permissions : [permissions];
  return permissions.some((p) => context.state.user.permissions?.includes(p));
}

/**
 * Return if logged user has ALL the permissions
 * @param {AuthContext} context
 * @param {array} permissionsRequired
 * @returns {boolean}
 */
export function hasAllPermissions(context, permissionsRequired) {
  if (!context.state.isLogged) return false;
  return permissionsRequired.every((p) => context.state.user.permissions?.includes(p));
}
