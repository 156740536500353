/* eslint-disable jsx-a11y/no-autofocus */
/* eslint-disable max-len */
import React, { useEffect, useState, useContext } from 'react';
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Button,
  Input,
  Label,
  Form,
  InputGroup,
  InputGroupText,
} from 'reactstrap';
import { NotificationContext } from 'context/notificationContext';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';
import { useAuthContext } from 'context/authContext';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';
import css from './profile.module.scss';
import NodeServerHttp from '../../../utils/nodeApiHttp';

const Profile = () => {
  // const editorRef = useRef();
  const authContext = useAuthContext();
  const [file, setFile] = useState(null);
  const notificationContext = useContext(NotificationContext);
  const [labName, setLabName] = useState('');
  const [currentUploadName, setCurrentUploadName] = useState('');
  const [userData, setUserData] = useState({});
  const [t] = useTranslation();

  useEffect(() => {
    setLabName(authContext.state.user.lab.name);
    http
      .get(`users/${authContext.state.user.id}`)
      .then((response) => {
        setUserData(response.data);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [authContext, setUserData, setLabName]);

  const schema = yup.object().shape({
    email: yup
      .string()
      .matches(/^(.+)@(.+)\.(.+)$/)
      .required(),
    first_name: yup.string().required(),
    last_name: yup.string().required(),
    // job: yup.string().required(),
    // therapeutic_area: yup.string().required(),
  });

  const { register, handleSubmit } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = (event) => {
    event.preventDefault();
    handleSubmit((data) => {
      // data.description = editorRef.current.editor.getContents();
      if (file) {
        upload();
      }
      http
        .put(`users/${authContext.state.user.id}`, data)
        .then((response) => {
          const updatedData = {
            ...data,
            avatar_url: response.avatar_url,
          };
          setUserData(updatedData);
          notificationContext.dispatch({
            type: 'ADD_NOTIFICATION',
            payload: {
              message: t('Profile updated!'),
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    })(event);
  };

  const upload = () => {
    const formData = new FormData();
    formData.append('files', file);
    formData.append('field', 'avatar_url');
    formData.append('ref', 'users');
    formData.append('refId', authContext.state.user.id);
    NodeServerHttp.post(`upload`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
  };

  const onFileChange = (event) => {
    setFile(event.target.files[0]);
    setCurrentUploadName(event.target.files[0].name);
  };

  return (
    <Form onSubmit={onSubmit}>
      <Container fluid className={css.profile}>
        <Row>
          <Col lg="7" md="10">
            <h1 className="display-2 text-white">{t('My account')}</h1>
            <p className="text-white mt-0 mb-5 w-100">
              <br />
            </p>
          </Col>
        </Row>
        <Row>
          <Col className="col-8">
            <Row className="mt-3">
              <Col>
                <Card className="bg-secondary shadow">
                  <CardHeader className="border-0">
                    <Row className="align-items-center">
                      <Col>
                        <div />
                      </Col>
                    </Row>
                  </CardHeader>
                  <CardBody>
                    <Row>
                      <Col>
                        <h6>{t('PROFILE INFORMATIONS')}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label htmlFor="email_address" className="form-label">
                          {t('E-mail address')}*
                        </Label>
                        <Input
                          id="email"
                          name="email"
                          innerRef={register}
                          type="email"
                          className="form-control-alternative"
                          defaultValue={userData.email}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label htmlFor="last_name" className="form-label">
                          {t('Last name')}*
                        </Label>
                        <Input
                          id="last_name"
                          name="last_name"
                          innerRef={register}
                          type="text"
                          className="form-control-alternative"
                          defaultValue={userData.last_name}
                        />
                      </Col>
                      <Col>
                        <Label htmlFor="first_name" className="form-label">
                          {t('First name')}*
                        </Label>
                        <Input
                          id="first_name"
                          name="first_name"
                          innerRef={register}
                          type="text"
                          className="form-control-alternative"
                          defaultValue={userData.first_name}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label className="form-label" htmlFor="avatar">
                          {t('Profile picture')}
                        </Label>
                        <Label
                          className="upload form-control-alternative form-control"
                          htmlFor="avatar"
                        >
                          <span>{t('Search file')}</span>
                          <span>{currentUploadName || t('Please select a profil picture')}</span>
                        </Label>
                        <input type="file" name="avatar" id="avatar" onChange={onFileChange} />
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <hr />
                        <h6>{t('PROFESSIONAL INFORMATION')}</h6>
                      </Col>
                    </Row>
                    <Row>
                      <Col>
                        <Label htmlFor="job">{t('Position')}</Label>
                        <Input
                          type="text"
                          id="job"
                          name="job"
                          innerRef={register}
                          className="form-control-alternative"
                          defaultValue={userData.job || ''}
                          placeholder={t('Position')}
                        />
                      </Col>
                    </Row>
                    <Row>
                      {/*
                        <Col>
                          <Label htmlFor="therapeutic_area" className="form-label">
                            {t('Therapeutic Areas')}*
                          </Label>
                          <Input
                            type="text"
                            id="therapeutic_area"
                            name="therapeutic_area"
                            innerRef={register}
                            className="form-control-alternative"
                            defaultValue={userData.therapeutic_area}
                          />
                        </Col>
                      */}
                      <Col>
                        <Label htmlFor="address">{t('Address')}</Label>
                        <InputGroup>
                          <Input
                            id="address"
                            name="address"
                            innerRef={register}
                            className="form-control-alternative"
                            placeholder={t('Address')}
                            type="text"
                            defaultValue={userData.address}
                          />
                          <InputGroupText className="igtr">
                            <i className="fas fa-map-marker" />
                          </InputGroupText>
                        </InputGroup>
                      </Col>
                      <Col>
                        <Label htmlFor="laboratory">{t('Laboratory')}</Label>
                        <Input
                          type="text"
                          id="labolatory"
                          name="laboratory"
                          className="form-control-alternative"
                          defaultValue={labName}
                          readOnly
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col className="d-flex justify-content-center">
                        <Button type="submit" color="primary" className="mt-3">
                          {t('Save')}
                        </Button>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
          <Col className="col-4 mt-3">
            <Card className="bg-secondary shadow rightSide">
              <CardHeader className="bg-white border-0">
                <div className="picture">
                  <div>
                    {!authContext.state.user.avatar_url ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="122.737"
                        height="122.737"
                        viewBox="0 0 122.737 122.737"
                      >
                        <g
                          id="Groupe_744"
                          data-name="Groupe 744"
                          transform="translate(14821 -10272.5)"
                        >
                          <g
                            id="Groupe_723"
                            data-name="Groupe 723"
                            transform="translate(-16210 10237)"
                          >
                            <path
                              id="Path"
                              d="M61.369,0a61.369,61.369,0,1,0,61.369,61.369A61.437,61.437,0,0,0,61.369,0Z"
                              transform="translate(1389 35.5)"
                              fill="#8898aa"
                            />
                            <path
                              id="Path-2"
                              data-name="Path"
                              d="M0,20.456A20.633,20.633,0,0,1,20.456,0,20.633,20.633,0,0,1,40.912,20.456V25.57A20.633,20.633,0,0,1,20.456,46.026,20.633,20.633,0,0,1,0,25.57Z"
                              transform="translate(1429.911 66.184)"
                              fill="#fff"
                            />
                            <path
                              id="Path-3"
                              data-name="Path"
                              d="M32.735,25.57A50.912,50.912,0,0,1,0,13.675,25.466,25.466,0,0,1,22.507,0H42.963A25.466,25.466,0,0,1,65.47,13.675,50.912,50.912,0,0,1,32.735,25.57Z"
                              transform="translate(1417.632 122.438)"
                              fill="#fff"
                            />
                          </g>
                        </g>
                      </svg>
                    ) : (
                      <img
                        style={{
                          borderRadius: '50%',
                        }}
                        alt="avatar"
                        src={`${process.env.REACT_APP_API_URI_NODE}${authContext.state.user.avatar_url.formats.thumbnail.url}`}
                      />
                    )}
                  </div>
                </div>
              </CardHeader>
              <CardBody className="bg-white pl-4">
                {/*
                <Row>
                  <Col className="text-right">
                    <span>22</span>
                    <span>RP</span>
                  </Col>
                  <Col className="text-left">
                    <span>10</span>
                    <span>VM</span>
                  </Col>
                </Row>
                */}
                <Row>
                  <Col className="text-center mt-2">
                    <span>{`${userData.first_name} ${userData.last_name}`}</span>
                    <span className="pb-3">{userData.address || '...'}</span>
                    <span>{`${userData.job || '...'} - ${labName || '...'}`}</span>
                    <span className="d-none">{userData.therapeutic_area}</span>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </Form>
  );
};

export default Profile;
