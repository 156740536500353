import React, { useCallback, useEffect, useState } from 'react';
import _ from 'lodash';
import {
  Button,
  Form,
  FormGroup,
  Input,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Alert,
} from 'reactstrap';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAuthContext } from 'context/authContext';
import http from 'utils/http';
import EventSelectRole from 'components/common/EventSelectRole';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { comButtonLoading, comSpinningWhite } from 'components/common/common.module.scss';
import nodeApiHttp from '../../../utils/nodeApiHttp';

const EditEventParticipantAdd = (props) => {
  const { match } = props;
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const { eventId } = match.params;
  const [displayFormError, setDisplayFormError] = useState(false);
  const { partId } = match.params;
  const history = useHistory();
  const [participant, setParticipant] = useState({
    role: 'participant',
    email: '',
    job: '',
    name: '',
    location: '',
    event_id: eventId,
  });
  const [submitting, setSubmitting] = useState(false);
  const [event, setEvent] = useState(null);
  const [customEmailTemplate, setCustomEmailTemplate] = useState(false);

  const authContext = useAuthContext();

  const handleInputChange = useCallback(
    (e) => {
      const { value } = e.target;
      const { name } = e.target;
      setParticipant({
        ...participant,
        [name]: value,
      });
    },
    [participant, setParticipant]
  );

  const handleSubmit = useCallback(
    async (e) => {
      setSubmitting(true);
      setDisplayFormError(false);
      e.stopPropagation();
      e.preventDefault();
      participant.timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      participant.lab_id = authContext.state.lab.id;
      if (e.target.room !== undefined) participant.room_id = e.target.room.value;
      participant.mediaHasCustomEmailTemplate = null;

      if (partId === 'create') {
        if (customEmailTemplate) {
          customEmailTemplate.map((template) => {
            if (template.email_tag.tag === 'invitation') {
              participant.mediaHasCustomEmailTemplate = {
                params: template.params,
                sendinblueTemplateId: template.sendinblue_template_number,
              };
            }
            return participant;
          });
        }

        await http
          .post('participants', participant)
          .then((res) => {
            nodeApiHttp
              .post('event-invitations', {
                participant: res.id,
                event: res.event_id,
                room_id: res.room_id,
                role: res.role,
              })
              .then((res) => {
                setSubmitting(false);
                history.push(`/admin/events/${eventId}/participants`);
              });
          })
          .catch((err) => {
            setSubmitting(false);
            setDisplayFormError(err.response.data);
          });
      } else {
        await http.put(`participants/${partId}`, participant);
        history.push(`/admin/events/${eventId}/participants`);
      }
    },
    [participant, eventId, partId, history, authContext, customEmailTemplate]
  );

  // If editing an existing event, get the data
  useEffect(() => {
    if (partId !== 'create') {
      http.get(`participants/${partId}`).then(setParticipant);
    }
  }, [partId]);

  useEffect(() => {
    http.get(`events/${eventId}`).then(setEvent);
  }, [eventId]);

  useEffect(() => {
    if (event)
      nodeApiHttp
        .get(`/email-templates/?media=${_.head(event.medias).id}`)
        .then(setCustomEmailTemplate);
  }, [event]);

  return (
    <Card className="bg-secondary shadow">
      <CardHeader className="bg-white border-0">
        <Row className="align-items-center">
          <Col xs="8">
            <h3 className="mb-0">{t('New participant')}</h3>
          </Col>
        </Row>
      </CardHeader>
      <CardBody>
        <Form action="" onSubmit={handleSubmit} className="mb-5">
          {displayFormError && <Alert color="warning">{displayFormError}</Alert>}
          <div className="pl-lg-4">
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-name">
                    {t('Full Name')}*
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-name"
                    placeholder="Jean Dupont"
                    type="text"
                    required
                    onChange={handleInputChange}
                    value={participant.name}
                    name="name"
                  />
                </FormGroup>
              </Col>
            </Row>
            {!!event && event.auto_dispatcher === 0 && (
              <Row>
                <Col>
                  <FormGroup>
                    <label className="form-control-label" htmlFor="input-name">
                      Sélectionné une salle*
                    </label>
                    <Input id="room" name="room" type="select">
                      {event.rooms.map((room) => {
                        return (
                          <option key={room.id} value={room.id}>
                            {room.id}
                          </option>
                        );
                      })}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-email">
                    {t('Email address')}*
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-email"
                    type="email"
                    placeholder="example@troisprime.com"
                    required
                    onChange={handleInputChange}
                    value={participant.email}
                    name="email"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-role">
                    {tCommon('Role')}*
                  </label>
                  <EventSelectRole onChange={handleInputChange} value={participant.role} />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <label className="form-control-label" htmlFor="input-title">
                    {t('Job title')}
                  </label>
                  <Input
                    className="form-control-alternative"
                    id="input-title"
                    placeholder={tCommon('Doctor')}
                    type="text"
                    onChange={handleInputChange}
                    value={participant.job}
                    name="job"
                  />
                </FormGroup>
              </Col>
            </Row>
            {submitting && !displayFormError && (
              <Row>
                <Col />
                <Col
                  className="text-center"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    className={`${comButtonLoading}`}
                    style={{
                      minWidth: '240px',
                    }}
                  >
                    <div className={comSpinningWhite}>
                      <FontAwesomeIcon className="pt-1 mb-1" icon={faCircleNotch} />
                    </div>
                  </div>
                </Col>
                <Col />
              </Row>
            )}
            {!submitting && (
              <Row
                style={{
                  textAlign: 'center',
                }}
              >
                <Button
                  type="submit"
                  color="primary"
                  className="d-flex m-auto mt-3"
                  style={{
                    minWidth: '240px',
                    alignItems: 'center',
                    justifyContent: 'center',
                  }}
                >
                  {t('Add participant')}
                </Button>
              </Row>
            )}
          </div>
        </Form>
      </CardBody>
    </Card>
  );
};

export default EditEventParticipantAdd;
