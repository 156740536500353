import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
  FormText,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';

const EditDoc = (props) => {
  const { match } = props;
  const { params } = match;
  const { id } = params;
  const [t] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const history = useHistory();
  const [document, setDocument] = useState({
    name: '',
    file: '',
  });

  const handleInputChange = useCallback(
    (e) => {
      const { name } = e.target;
      const value = e.target.files?.[0] ?? e.target.value;
      setDocument({
        ...document,
        [name]: value,
      });
    },
    [document, setDocument]
  );

  const handleSubmit = useCallback(
    async (e) => {
      e.stopPropagation();
      e.preventDefault();
      if (id === 'create') {
        const formData = new FormData();
        Object.entries(document).map(([key, value]) => formData.append(key, value));
        await http.post('documents', formData, {
          headers: {
            'content-type': 'multipart/form-data',
          },
        });
      } else {
        await http.put(`documents/${id}`, document);
      }
      history.push('/admin/documents');
    },
    [document, id, history]
  );

  useEffect(() => {
    if (id !== 'create') http.get(`documents/${id}`).then(setDocument);
  }, [id]);

  return (
    <>
      <Row className="mb-4">
        <Col xs="8" className="my-auto">
          <h3 className="text-white align-self-center">{t('New document')}</h3>
        </Col>
        <Col className="text-right" xs="4">
          <Link to="/admin/documents">
            <Button color="primary">{tCommon('Cancel')}</Button>
          </Link>
        </Col>
      </Row>
      <Card className="bg-secondary shadow">
        <CardHeader className="bg-white border-0" />
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" for="input-document-name">
                    {t('Name')}*
                  </Label>
                  <Input
                    className="form-control-alternative"
                    id="input-document-name"
                    placeholder={t('Name')}
                    type="text"
                    required
                    onChange={handleInputChange}
                    value={document.name}
                    name="name"
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <Label className="form-control-label" for="file">
                    {t('File')}*
                  </Label>
                  {/*
                  accept=".png,.jpg,.jpeg,.txt,.svg,.pdf"
                  */}
                  <Input type="file" required name="file" id="file" onChange={handleInputChange} />
                  <FormText color="muted">
                    {t('Select a file to upload it, then you will be able to use it during events')}
                  </FormText>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Button type="submit" color="primary" className="w-100">
                {t('Upload File')}
              </Button>
            </Row>
          </Form>
        </CardBody>
      </Card>
    </>
  );
};

export default EditDoc;
