import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Col, Form, Input, Row, Label, Table, Progress, Collapse } from 'reactstrap';
// import FilterBar from 'components/admin/polls/FilterBar';
import { actions, useListContext } from 'context/ListContext';
import css from './statistics.module.scss';

const StatAnswerList = (props) => {
  const { pagination, onItemCountChange, onItemChange } = props;
  const [itemCount, setItemCount] = useState(5);
  const [tCommon] = useTranslation();
  const [answerId, setAnswerId] = useState(-1);
  const [answerIds, setAnswerIds] = useState({});
  const [collapsers, setCollapsers] = useState([]);
  const [listRendering, setListRendering] = useState('');
  const listContext = useListContext();

  const toggle = (value) => () => {
    switch (value) {
      case 'delete':
        //
        break;
      case 'multiple-delete':
        //
        break;
      default:
        setAnswerId(-1);
    }
  };

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const load = useCallback(
    (paginate) => {
      const { dispatch } = listContext;
      dispatch({
        type: actions.LOAD_LIST,
        payload: {
          start: paginate * itemCount,
          count: itemCount,
        },
      });
    },
    [itemCount, listContext]
  );

  const previous = usePrevious({ pagination, listContext });

  useEffect(() => {
    if (previous === undefined || previous.pagination !== pagination) {
      load(pagination);
    }
  }, [pagination, previous, load]);

  const renderAnswers = useCallback(
    (element) => {
      const toggler = `toggler-${element.id}`;
      const collapser = (children) => {
        return (
          <>
            <button
              type="button"
              className={`${css.collapser} ${
                collapsers.indexOf(toggler) === -1 ? css.uncollapsed : ''
              }`}
              onClick={() => {
                const i = collapsers.indexOf(toggler);
                if (i === -1) {
                  const c = [...collapsers];
                  c.push(toggler);
                  setCollapsers(c);
                } else {
                  const c = [...collapsers];
                  c.splice(i, 1);
                  setCollapsers(c);
                }
              }}
            >
              <span>^</span>
            </button>
            <Collapse isOpen={collapsers.indexOf(toggler) === -1} className={css.renderAnswers}>
              {children}
            </Collapse>
          </>
        );
      };
      return (
        <>
          {element.answers.length &&
            collapser(
              Array(Math.ceil(element.answers.length / 3))
                .fill(0)
                .map((v0, id) => {
                  const startAt = id * 3;
                  const ret = [];
                  Object.keys([0, 0, 0]).forEach((v1, pos) => {
                    const index = startAt + pos;
                    const answer = element.answers[index];
                    ret.push(
                      <div className="answer">
                        <div>{answer?.name || ''}</div>
                        <div>
                          {answer?.value && (
                            <>
                              <span>{answer?.value}%</span>
                              <Progress max="100" value={answer.value} color="default" />
                            </>
                          )}
                        </div>
                      </div>
                    );
                  });
                  return <div>{ret}</div>;
                })
            )}
        </>
      );
    },
    [collapsers]
  );

  const renderFilteringOptions = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              setItemCount(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
          {/* <InputGroup
            style={{
              cursor: Object.keys(answerIds).length > 0 ? 'pointer' : 'not-allowed',
            }}
            className={`
              ml-4 border-0
              ${ccss.comUnified}
              ${Object.keys(answerIds).length === 0 ? 'opacity-4' : ''}
            `}
            onClick={() => {
              if (Object.keys(answerIds).length > 0) {
                toggle('multiple-delete')();
              }
            }}
          >
            <Label
              className="mr-2"
              style={{
                cursor: 'inherit',
              }}
            >
              {tCommon('Remove')}
            </Label>
            <InputGroupText>
              <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
            </InputGroupText>
          </InputGroup>
          */}
        </Col>
        <Col
          className="form-inline justify-content-end"
          style={{
            justifyContent: 'flex-end',
            // display: 'none',
          }}
        >
          {/*
          <InputGroup className={`${ccss.comUnified} ${css.filterElSize}`}>
            <InputGroupText>
              <FontAwesomeIcon icon={faList} />
            </InputGroupText>
            <Button color="white">{tCommon('Sort')}</Button>
          </InputGroup>
          <InputGroup className={`${ccss.comUnified} ${css.filterElSize} ml-2`}>
            <InputGroupText>
              <i>
                <FontAwesomeIcon icon={faBars} />
              </i>
            </InputGroupText>
            <Button color="white">{tCommon('Filter')}</Button>
          </InputGroup>
          */}
          {/* <FilterBar updateRoute="" filterRoute="" /> */}
          {/* <InputGroup className={`${ccss.comUnified} ${css.filterElSize} ml-2`}>
            <Input type="text" name="search" placeholder={tCommon('Search')} />
            <InputGroupText>
              <FontAwesomeIcon icon={faSearch} />
            </InputGroupText>
          </InputGroup>
          */}
        </Col>
      </Row>
    );
  }, [listContext, tCommon, onItemCountChange]);

  const renderList = useCallback(() => {
    const { state } = listContext;
    state.then(({ list }) => {
      setListRendering(
        <Row className="answerList">
          <Table>
            <thead className={css.thead}>
              <tr>
                <th style={{ width: '20%' }} className="text-center">
                  {tCommon('POLL NAME')}
                </th>
                <th style={{ width: '20%' }} className="text-center">
                  {tCommon('TYPE')}
                </th>
                <th className="text-center">{tCommon('ANSWERS')}</th>
              </tr>
            </thead>
            <tbody>
              {list.map((element, index) => (
                <tr key={index}>
                  <td className="text-center">{element.name}</td>
                  <td className="text-center">{element.type}</td>
                  <td className="text-center">{renderAnswers(element)}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      );
    });
  }, [listContext, tCommon, renderAnswers]);

  const callBackItemChange = useCallback(
    (context) => {
      if (previous !== undefined) {
        const { listLength } = context;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    },
    [onItemChange, previous]
  );

  useEffect(() => {
    callBackItemChange(listContext);
  }, [listContext, callBackItemChange]);

  useEffect(() => {
    renderList(listContext);
  }, [listContext, renderList]);

  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilteringOptions()}
            {listRendering}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default StatAnswerList;
