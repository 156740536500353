import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container, Row, Col } from 'reactstrap';

import logoVtopia from 'assets/img/logo-vtopia.svg';
import UserBanner from 'components/common/UserBanner';
import { useAuthContext } from '../../../context/authContext';

const HeaderNavbar = (props) => {
  const { hideMenu /* , room */, stopDevices } = props;
  const { state } = useAuthContext();

  return (
    <Navbar className="navbar-top navbar-horizontal navbar-dark w-100" expand="md">
      {!state.lab && (
        <Container className="px-4" fluid>
          <img
            style={{
              height: 60,
            }}
            src={logoVtopia}
            className="img-fluid ml-3"
            alt="Lab logo"
          />
        </Container>
      )}

      {state.lab && (
        <Container className="px-4" fluid>
          <img
            style={{
              height: 60,
            }}
            src={
              state.lab.logo_image
                ? `${process.env.REACT_APP_API_URI_NODE}${state.lab.logo_image.url}`
                : logoVtopia
            }
            className="img-fluid ml-3"
            alt="Lab logo"
          />
          {/* </a> */}
          {!hideMenu && (
            <>
              <button type="button" className="navbar-toggler" id="navbar-collapse-main">
                <span className="navbar-toggler-icon" />
              </button>
              <div navbar="true" toggler="#navbar-collapse-main">
                <div className="navbar-collapse-header d-md-none">
                  <Row>
                    <Col className="collapse-brand" xs="6">
                      <Link to="/" />
                    </Col>
                    <Col className="collapse-close" xs="6">
                      <button type="button" className="navbar-toggler" id="navbar-collapse-main">
                        <span />
                        <span />
                      </button>
                    </Col>
                  </Row>
                </div>
                <Nav className="ml-auto" navbar>
                  {/* <NavItem>
                  <NavLink className='nav-link-icon d-inline-flex align-items-center text-white'
                    to='#' tag={Link}>
                    <img src={about} alt='' />
                    <span className='ml-2 nav-link-inner--text font-weight-bold'>{t('About the lab')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='nav-link-icon d-inline-flex align-items-center text-white'
                    to='#' tag={Link}>
                    <i className='ni ni-single-copy-04' />
                    <span
                      className='ml-2 nav-link-inner--text font-weight-bold'>{t('Documents')}</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink className='nav-link-icon d-inline-flex align-items-center text-white'
                    to='#' tag={Link}>
                    <i className='ni ni-email-83' />
                    <span className='ml-2 nav-link-inner--text font-weight-bold'>{t('Contact')}</span>
                  </NavLink>
                </NavItem> */}
                </Nav>
              </div>
            </>
          )}
        </Container>
      )}

      <UserBanner backHome stopDevices={stopDevices} />
    </Navbar>
  );
};

export default HeaderNavbar;
HeaderNavbar.propTypes = {
  hideMenu: PropTypes.bool,
};
