import React, { useCallback, useEffect, useState } from 'react';
import { Col } from 'reactstrap';
import styled, { css } from 'styled-components';

import collapse from 'assets/img/icons/collapse.svg';
import Streams from 'components/front/room/Streams';
import classNames from 'classnames';

const StreamsColumn = (props) => {
  const { sessionHelper, medias, activeAudioInputDevice, activeVideoInputDevice } = props;
  const [collapsed, setCollapsed] = useState(false);

  const toggleCollapsed = useCallback(() => {
    setCollapsed(!collapsed);
  }, [collapsed, setCollapsed]);

  return (
    <Wrapper
      collapsed={collapsed.toString()}
      className={classNames('p-0 ml-3 rounded bg-light overflow-visible', { 'h-100': !collapsed })}
      md={2}
      sm={1}
    >
      <CollapseButton role="button" tabIndex={0} onClick={toggleCollapsed} collapsed={collapsed}>
        <img src={collapse} alt="" fill="#000000" />
      </CollapseButton>

      <Streams
        sessionHelper={sessionHelper}
        medias={medias}
        collapsed={collapsed}
        activeAudioInputDevice={activeAudioInputDevice}
        activeVideoInputDevice={activeVideoInputDevice}
      />
    </Wrapper>
  );
};

export default StreamsColumn;

const Wrapper = styled(Col)`
  height: 100%;
  max-height: 100%;

  ${(props) =>
    props.collapsed === 'true' &&
    css`
      width: 40px;
      max-width: 40px;
      height: 40px;
      max-height: 40px;
      /*
      & .streams {
        display: none;
      }
      */
    `}

  transition: all ease-in 0.35s;
`;
const CollapseButton = styled.span`
  padding-top: 12px;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  display: inline-flex;
  justify-content: flex-end;

  ${(props) =>
    props.collapsed &&
    css`
      & img {
        transform: scale3d(-1, 1, 1);
      }
    `}
`;
