import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Card, CardBody, CardHeader, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';
import styled from 'styled-components';

import { SessionContext } from 'context/sessionContext';
import Chat from 'components/front/room/Chat';
import ViewersList from 'components/front/room/ViewersList';
import Notes from 'components/front/room/Notes';
import Stats from 'components/front/room/animator/Stats';
import Polls from 'components/front/room/polls/Polls';
import { ReactComponent as InfoIcon } from 'assets/img/icons/info.svg';
import { ReactComponent as UsersIcon } from 'assets/img/icons/users.svg';
import { Colors } from 'Constants';

export const HeaderButton = (props) => {
  const { tab, 'data-tab': dataTab, icon, icontype, children } = props;
  return (
    <Button
      color="link"
      style={
        tab !== dataTab
          ? {
              color: Colors.LIGHT_GREY,
            }
          : {}
      }
      className="flex-grow-1 d-inline-flex justify-content-center align-items-center pt-3 pb-3 position-relative p-0"
      {...props}
    >
      {icon && (
        <i
          className={`${icontype || 'ni'} ${
            ['fas', 'fa'].includes(icontype) ? 'fa' : 'ni'
          }-${icon}`}
          style={{
            fontSize: 20,
          }}
        />
      )}
      {children}
    </Button>
  );
};

const ChatButton = (props) => {
  const { tab } = props;
  const { state } = useContext(SessionContext);
  const { messages } = state;
  const [nbReadMessages, setNbReadMessages] = useState(0);
  useEffect(() => {
    if (tab === 'chat') {
      setNbReadMessages(messages.length);
    }
  }, [tab, messages.length, setNbReadMessages]);
  return (
    <HeaderButton {...props} data-tab="chat" icon="chat-round" icontype="ni">
      {messages.length - nbReadMessages > 0 && (
        <span
          className="position-absolute"
          style={{
            left: 'calc(50% + 10px)',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {messages.length - nbReadMessages}
        </span>
      )}
    </HeaderButton>
  );
};

const PollsButton = (props) => {
  const { isAnimator, tab, onClick } = props;
  const { state } = useContext(SessionContext);
  const { polls } = state;
  return isAnimator ? (
    <HeaderButton tab={tab} onClick={onClick} data-tab="polls" icon="chart-bar-32" icontype="ni" />
  ) : (
    <HeaderButton tab={tab} onClick={onClick} data-tab="polls" icon="chart-bar-32" icontype="ni">
      {polls.length > 0 && (
        <span
          className="position-absolute"
          style={{
            left: 'calc(50% + 10px)',
            top: '50%',
            transform: 'translateY(-50%)',
          }}
        >
          {polls.length}
        </span>
      )}
    </HeaderButton>
  );
};

const RoomColumn = (props) => {
  const { isAnimator, sessionHelper, streams, medias, room } = props;
  const { state } = useContext(SessionContext);
  const { viewers, sessionName } = state;
  const [tab, setTab] = useState('users');
  const [t] = useTranslation('front');
  const [collapseOpen, setCollapseOpen] = useState(true);

  const openTab = useCallback(
    (e) => {
      setTab(e.currentTarget.dataset.tab);
      setCollapseOpen(true);
    },
    [setTab, setCollapseOpen]
  );
  const toggleCollapse = useCallback(() => setCollapseOpen(!collapseOpen), [
    collapseOpen,
    setCollapseOpen,
  ]);

  useEffect(() => {
    let anim = requestAnimationFrame(resize);
    function resize() {
      window.dispatchEvent(new Event('resize'));
      anim = requestAnimationFrame(resize);
    }
    setTimeout(() => {
      cancelAnimationFrame(anim);
    }, 400);
  }, [collapseOpen]);

  return (
    <Wrapper
      className={classnames(
        'h-100 pl-3 pr-0 d-flex flex-column overflow-hidden sidebar sidebar-right',
        {
          collapsed: !collapseOpen,
        }
      )}
      md={2}
    >
      <div className="h-100">
        <Card
          className="h-100 m-0 rounded-left"
          style={{
            borderTopRightRadius: 0,
            borderBottomRightRadius: 0,
          }}
        >
          <div>
            <div
              className={classnames('sidenav-toggler d-flex align-items-center w-100', {
                active: collapseOpen,
                'mb--4': collapseOpen,
              })}
              role="button"
              tabIndex={0}
              onClick={toggleCollapse}
              style={{
                padding: 23,
              }}
            >
              <div className="sidenav-toggler-inner">
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
                <i className="sidenav-toggler-line" />
              </div>
            </div>
          </div>
          <div
            className={classnames('w-100 text-center pb-1', {
              'd-none': !collapseOpen,
            })}
          >
            <strong className="" style={styles.title}>
              {sessionName}
            </strong>
          </div>
          <hr
            className={classnames('m-0', {
              'd-none': !collapseOpen,
            })}
          />
          <CardHeader className="d-flex p-0 mt-0 flex-wrap flex-shrink-0 border-0">
            <HeaderButton tab={tab} onClick={openTab} data-tab="users">
              <UsersIcon fill={tab === 'users' ? Colors.BLUE_VTOPIA : Colors.LIGHT_GREY} />
            </HeaderButton>
            {state.activeModules.includes('chat') && <ChatButton tab={tab} onClick={openTab} />}
            {state.activeModules.includes('polls') && (
              <PollsButton tab={tab} onClick={openTab} isAnimator={isAnimator} />
            )}
            {state.activeModules.includes('notes') && (
              <HeaderButton tab={tab} onClick={openTab} data-tab="notes" icon="collection" />
            )}
            {isAnimator && state.activeModules.includes('stats') && (
              <HeaderButton tab={tab} onClick={openTab} data-tab="stats">
                <InfoIcon fill={tab === 'stats' ? Colors.BLUE_VTOPIA : Colors.LIGHT_GREY} />
              </HeaderButton>
            )}
          </CardHeader>
          <div
            className={classnames('pt-2 pb-2 pl-3 pr-3 font-weight-bold', {
              'd-none': !collapseOpen,
            })}
            style={styles.head}
          >
            {viewers.length} {t('connected participants')}
          </div>
          <CardBody
            className={classnames('p-0', {
              'd-none': !collapseOpen,
              'd-flex': collapseOpen,
            })}
          >
            <Chat
              style={
                tab !== 'chat'
                  ? {
                      display: 'none',
                    }
                  : {}
              }
              isAnimator={isAnimator}
              session={sessionHelper}
            />
            <ViewersList
              style={
                tab !== 'users'
                  ? {
                      display: 'none',
                    }
                  : {}
              }
              isAnimator={isAnimator}
              session={sessionHelper}
              streams={streams}
              media={medias[0]}
            />
            <Polls
              style={
                tab !== 'polls'
                  ? {
                      display: 'none',
                    }
                  : {}
              }
              session={sessionHelper}
              isAnimator={isAnimator}
              room={room}
            />
            {isAnimator && (
              <Stats
                style={
                  tab !== 'stats'
                    ? {
                        display: 'none',
                      }
                    : {}
                }
              />
            )}
            <Notes
              style={
                tab !== 'notes'
                  ? {
                      display: 'none',
                    }
                  : {}
              }
              session={sessionHelper}
              isAnimator={isAnimator}
            />
          </CardBody>
        </Card>
      </div>
    </Wrapper>
  );
};

export default RoomColumn;
RoomColumn.propTypes = {
  isAnimator: PropTypes.bool,
  medias: PropTypes.array,
  sessionHelper: PropTypes.object.isRequired,
};
const styles = {
  head: {
    color: Colors.LIGHT_GREY,
    backgroundColor: '#f6f9fc',
    border: 'solid 1px #e9ecef',
    fontSize: 11,
  },
  title: {
    color: Colors.LIGHT_GREY,
    fontSize: 11,
  },
};
const Wrapper = styled(Col)`
  &.collapsed .card-body {
    display: none;
  }
`;
