import React, { useContext, useState, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Input } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import { SET_NOTES, UPDATE_VIEWER_NOTES, SessionContext } from 'context/sessionContext';

const Notes = (props) => {
  const { session, isAnimator, style } = props;
  const [t] = useTranslation('front');
  const { state, dispatch } = useContext(SessionContext);
  const { viewerNotes, viewers, notes } = state;
  const [editing, setEditing] = useState(false);
  const [notesReadyToDownload, setNotesReadyToDownload] = useState(false);

  const handleReactQuillChange = useCallback(
    (e) => {
      dispatch({
        type: SET_NOTES,
        payload: e.target.value,
      });
    },
    [dispatch]
  );

  const download = useCallback(
    (content, user) =>
      new Promise((resolve, reject) => {
        try {
          const element = document.createElement('a');
          const file = new Blob([content], {
            type: 'text/plain',
          });
          element.href = URL.createObjectURL(file);
          element.download = `${user}.txt`;
          document.body.appendChild(element); // Required for this to work in FireFox
          element.click();
          resolve();
        } catch (error) {
          reject(error);
        }
      }),
    []
  );

  const downloadMyNotes = useCallback(async () => {
    if (!notes) {
      return;
    }
    await download(notes, 'Notes-Vtopia');
  }, [notes, download]);

  const downloadAll = useCallback(async () => {
    await Promise.all(
      viewerNotes.map(async (viewerNote) => {
        await download(viewerNote.notes, viewerNote.name);
      })
    );
    await download(notes, 'Notes-Vtopia');
  }, [viewerNotes, download, notes]);

  const onReceiveViewerNotes = useCallback(
    (e) => {
      const { data: notesContent, from } = e;
      const { id } = from;
      dispatch({
        type: UPDATE_VIEWER_NOTES,
        payload: {
          id,
          notes: notesContent,
        },
      });
    },
    [dispatch]
  );

  const requestAllNotes = useCallback(
    (onlyDownload = false) => {
      if (!session || !isAnimator) {
        return;
      }

      if (!onlyDownload) {
        session.signal({
          type: 'request-notes',
        });
        return;
      }

      let i = 0;
      const onNoteReceiveAfterRequest = () => {
        ++i;
        if (i >= viewers.length - 1) {
          i = 0;
          session.off('signal:notes', onNoteReceiveAfterRequest);
          // All the requested notes have been received, now trigger the next re-render to download them
          setNotesReadyToDownload(true);
        }
      };
      session.off('signal:notes', onNoteReceiveAfterRequest);
      if (viewers.length <= 1) {
        setNotesReadyToDownload(true);
      } else {
        session.on('signal:notes', onNoteReceiveAfterRequest);
        session.signal({
          type: 'request-notes',
        });
      }
    },
    [session, isAnimator, viewers.length, setNotesReadyToDownload]
  );

  const sendNotesToAdmin = useCallback(() => {
    session.signal({
      type: 'notes',
      data: notes,
    });
  }, [session, notes]);

  const onClickAnywhere = useCallback(
    (e) => {
      if (editing && !e.target.closest('#notes-tab')) {
        setEditing(false);
        // Send the content to the admin
        if (!isAnimator) {
          sendNotesToAdmin();
        }
      }
    },
    [editing, isAnimator, sendNotesToAdmin, setEditing]
  );

  useEffect(() => {
    if (isAnimator && notesReadyToDownload) {
      downloadAll();
      setNotesReadyToDownload(false);
    }
  }, [notesReadyToDownload, setNotesReadyToDownload, isAnimator, downloadAll]);

  /**
   * When the user clicks anywhere outside of the notes, the editing stops
   */
  useEffect(() => {
    document.body.addEventListener('click', onClickAnywhere, false);
    return () => {
      document.body.removeEventListener('click', onClickAnywhere, false);
    };
  }, [onClickAnywhere, editing]);

  /**
   * On first mount of the admin, request all notes
   */
  useEffect(() => {
    if (isAnimator && !!session && session.currentState === 'connected') {
      requestAllNotes();
    }
  }, [
    isAnimator,
    session,
    requestAllNotes,
    viewers.length,
    onReceiveViewerNotes,
    setNotesReadyToDownload,
  ]);

  useEffect(() => {
    if (!session) {
      return undefined;
    }
    if (isAnimator) {
      session.on('signal:notes', onReceiveViewerNotes);
    } else {
      session.on('signal:request-notes', sendNotesToAdmin);
    }
    return () => {
      if (isAnimator) {
        session.off('signal:notes', onReceiveViewerNotes);
      } else {
        session.off('signal:request-notes', sendNotesToAdmin);
      }
    };
  }, [isAnimator, session, notes, onReceiveViewerNotes, sendNotesToAdmin]);

  return (
    <div
      style={{
        display: 'flex',
        ...style,
      }}
      id="notes-tab"
      className="h-100 flex-column flex-grow-1 px-2 pt-3 pb-3 overflow-hidden"
    >
      <div className="h-100 flex-grow-1 mb-2">
        <Input
          className="h-100 flex-grow-1 d-flex flex-column"
          style={{
            maxHeight: '100%',
          }}
          type="textarea"
          value={notes}
          onChange={handleReactQuillChange}
        />
      </div>
      <Button className="m-0 mb-1" color="primary" onClick={downloadMyNotes}>
        {t('Download my notes')}
      </Button>
      {isAnimator && (
        <Button className="m-0" color="primary" onClick={() => requestAllNotes(true)}>
          {t('Download all notes')}
        </Button>
      )}
    </div>
  );
};

export default Notes;
Notes.propTypes = {
  session: PropTypes.object,
  isAnimator: PropTypes.bool,
};
