import React from 'react';

export function PhCalendarCheckBold(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M208 28h-20v-8a12 12 0 0 0-24 0v8H92v-8a12 12 0 0 0-24
        0v8H48a20.1 20.1 0 0 0-20 20v160a20.1 20.1 0 0 0 20 20h160a20.1
        20.1 0 0 0 20-20V48a20.1 20.1 0 0 0-20-20Zm-4 24v24H52V52ZM52
        204V100h152v104Zm120.7-84.2a11.9 11.9 0 0 1-.5 16.9l-46.6 44a12.2 12.2
        0 0 1-8.3 3.3a11.9 11.9 0 0 1-8.2-3.3l-25.4-24a12 12 0 0 1-.4-17a11.9 11.9 0 0 1 16.9-.4l17.1 16.2l38.5-36.2a11.8 11.8 0 0 1 16.9.5Z"
      />
    </svg>
  );
}

export function PhCalendarPlusBold(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 256 256" {...props}>
      <path
        fill="currentColor"
        d="M168 152a12 12 0 0 1-12 12h-16v16a12 12
         0 0 1-24 0v-16h-16a12 12 0 0
         1 0-24h16v-16a12 12 0 0 1 24 0v16h16a12
         12 0 0 1 12 12Zm60-104v160a20.1 20.1 0 0 1-20
         20H48a20.1 20.1 0 0 1-20-20V48a20.1 20.1 0 0 1 20-20h20v-8a12 12 0 0 1 24 0v8h72v-8a12 12 0
         0 1 24 0v8h20a20.1 20.1 0 0 1 20 20ZM52 52v24h152V52Zm152 152V100H52v104Z"
      />
    </svg>
  );
}

export function GmailColorSVGIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px">
      <path
        fill="#fbc02d"
        d="M43.611,20.083H42V20H24v8h11.303c-1.649,4.657-6.08,8-11.303,8c-6.627,0-12-5.373-12-12
        s5.373-12,12-12c3.059,0,5.842,1.154,7.961,3.039l5.657-5.657C34.046,6.053,29.268,4,24,4C12.955,4,4,12.955,4,24s8.955,20,20,20
        	s20-8.955,20-20C44,22.659,43.862,21.35,43.611,20.083z"
      />
      <path
        fill="#e53935"
        d="M6.306,14.691l6.571,4.819C14.655,15.108,18.961,12,24,12c3.059,0,5.842,1.154,7.961,3.039
        l5.657-5.657C34.046,6.053,29.268,4,24,4C16.318,4,9.656,8.337,6.306,14.691z"
      />
      <path
        fill="#4caf50"
        d="M24,44c5.166,0,9.86-1.977,13.409-5.192l-6.19-5.238C29.211,35.091,26.715,36,24,36	c-5.202,0-9.619-3.317-11.283-7.
        946l-6.522,5.025C9.505,39.556,16.227,44,24,44z"
      />
      <path
        fill="#1565c0"
        d="M43.611,20.083L43.595,20L42,20H24v8h11.303c-0.792,2.237-2.231,4.166-4.087,5.571	c0.001-0.001,0.002-0.001,0.003-0
        .002l6.19,5.238C36.971,39.205,44,34,44,24C44,22.659,43.862,21.35,43.611,20.083z"
      />
    </svg>
  );
}

export function GoogleCalendarColorSVGIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px">
      <rect width="22" height="22" x="13" y="13" fill="#fff" />
      <polygon
        fill="#1e88e5"
        points="25.68,20.92 26.688,22.36 28.272,21.208 28.272,29.56 30,29.56 30,18.616 28.56,18.616"
      />
      <path
        fill="#1e88e5"
        d="M22.943,23.745c0.625-0.574,1.013-1.37,1.013-2.249c0-1.747-1.533-3.168-3.417-3.168 c-1.602,0-2.972,1.009-3.33,
        2.453l1.657,0.421c0.165-0.664,0.868-1.146,1.673-1.146c0.942,0,1.709,0.646,1.709,1.44 c0,0.794-0.767,1.44-1.709,1.
        44h-0.997v1.728h0.997c1.081,0,1.993,0.751,1.993,1.64c0,0.904-0.866,1.64-1.931,1.64 c-0.962,0-1.784-0.61-1.914-1.
        418L17,26.802c0.262,1.636,1.81,2.87,3.6,2.87c2.007,0,3.64-1.511,3.64-3.368 C24.24,25.281,23.736,24.363,22.943,23.745z"
      />
      <polygon fill="#fbc02d" points="34,42 14,42 13,38 14,34 34,34 35,38" />
      <polygon fill="#4caf50" points="38,35 42,34 42,14 38,13 34,14 34,34" />
      <path fill="#1e88e5" d="M34,14l1-4l-1-4H9C7.343,6,6,7.343,6,9v25l4,1l4-1V14H34z" />
      <polygon fill="#e53935" points="34,34 34,42 42,34" />
      <path fill="#1565c0" d="M39,6h-5v8h8V9C42,7.343,40.657,6,39,6z" />
      <path fill="#1565c0" d="M9,42h5v-8H6v5C6,40.657,7.343,42,9,42z" />
    </svg>
  );
}

export function OutlookColorSVGIcon(props) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 48 48" width="30px" height="30px">
      <path
        fill="#1a237e"
        d="M43.607,23.752l-7.162-4.172v11.594H44v-6.738C44,24.155,43.85,23.894,43.607,23.752z"
      />
      <path
        fill="#0c4999"
        d="M33.919,8.84h9.046V7.732C42.965,6.775,42.19,6,41.234,6H17.667c-0.956,0-1.732,0.775-1.732,1.732 V8.84h9.005H33.919z"
      />
      <path
        fill="#0f73d9"
        d="M33.919,33.522h7.314c0.956,0,1.732-0.775,1.732-1.732v-6.827h-9.046V33.522z"
      />
      <path
        fill="#0f439d"
        d="M15.936,24.964v6.827c0,0.956,0.775,1.732,1.732,1.732h7.273v-8.558H15.936z"
      />
      <path fill="#2ecdfd" d="M33.919 8.84H42.964999999999996V16.866999999999997H33.919z" />
      <path fill="#1c5fb0" d="M15.936 8.84H24.941000000000003V16.866999999999997H15.936z" />
      <path fill="#1467c7" d="M24.94 24.964H33.919V33.522H24.94z" />
      <path fill="#1690d5" d="M24.94 8.84H33.919V16.866999999999997H24.94z" />
      <path fill="#1bb4ff" d="M33.919 16.867H42.964999999999996V24.963H33.919z" />
      <path fill="#074daf" d="M15.936 16.867H24.941000000000003V24.963H15.936z" />
      <path fill="#2076d4" d="M24.94 16.867H33.919V24.963H24.94z" />
      <path
        fill="#2ed0ff"
        d="M15.441,42c0.463,0,26.87,0,26.87,0C43.244,42,44,41.244,44,40.311V24.438 c0,0-0.03,0.658-1.751,1.617c-1.3,
        0.724-27.505,15.511-27.505,15.511S14.978,42,15.441,42z"
      />
      <path
        fill="#139fe2"
        d="M42.279,41.997c-0.161,0-26.59,0.003-26.59,0.003C14.756,42,14,41.244,14,40.311V25.067 l29.363,16.562C43.118,
        41.825,42.807,41.997,42.279,41.997z"
      />
      <path
        fill="#00488d"
        d="M22.319,34H5.681C4.753,34,4,33.247,4,32.319V15.681C4,14.753,4.753,14,5.681,14h16.638 C23.247,14,24,14.753,24,
        15.681v16.638C24,33.247,23.247,34,22.319,34z"
      />
      <path
        fill="#fff"
        d="M13.914,18.734c-3.131,0-5.017,2.392-5.017,5.343c0,2.951,1.879,5.342,5.017,5.342 c3.139,0,5.017-2.392,5.017-5.
        342C18.931,21.126,17.045,18.734,13.914,18.734z M13.914,27.616c-1.776,0-2.838-1.584-2.838-3.539 s1.067-3.539,2.
        838-3.539c1.771,0,2.839,1.585,2.839,3.539S15.689,27.616,13.914,27.616z"
      />
    </svg>
  );
}

export default function MdiGmail(props) {
  return (
    <svg width="30px" height="30px" viewBox="0 0 24 24" {...props}>
      <path
        fill="currentColor"
        d="M20 18h-2V9.25L12 13L6 9.25V18H4V6h1.2l6.8 4.25L18.8 6H20m0-2H4c-1.11 0-2 .89-2 2v12a2 2 0 0 0 2 2h16a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2Z"
      />
    </svg>
  );
}
