/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useState, useContext } from 'react';
import {
  Label,
  InputGroup,
  InputGroupText,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Alert,
  Card,
  CardBody,
  // CustomInput,
} from 'reactstrap';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';
import NodeServerHttp from 'utils/nodeApiHttp';
import { NotificationContext } from 'context/notificationContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import _ from 'lodash';
import { Link } from 'react-router-dom';
import AddTo from 'components/common/AddTo';
import {
  comBadge,
  comGroupedBtn,
  comDropdown,
  comUploadFile,
  comFadeIn,
} from '../../../common/common.module.scss';

const AddUserFormComponent = (props) => {
  const { authContext } = props;
  const notificationContext = useContext(NotificationContext);
  const { cancel, userEdited, addUserCallback, editUserCallback } = props;
  const [t] = useTranslation('back-office');
  const [tBack] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const [currentUploadName, setCurrentUploadName] = useState('');
  const [formSuccess, setFormSuccess] = useState(false);
  const [submittedData, setSubmittedData] = useState({});
  const [groupsList, setGroupList] = useState([]);
  const [role, setRole] = useState('user');
  const [formRoles, setFormRoles] = useState([]);
  const [groupSelected, setGroupSelected] = useState([]);
  const [avatar, setAvatar] = useState(null);
  const defaultValues = userEdited || {};

  const {
    errors,
    register,
    handleSubmit,
    reset,
    formState: { isSubmitSuccessful },
  } = useForm({
    defaultValues,
  });

  const onFileUpload = useCallback(
    (e) => {
      setAvatar(e.target.files[0]);
      setCurrentUploadName(e.target.files[0].name);
    },
    [setAvatar, setCurrentUploadName]
  );

  const onGroupSelected = useCallback(
    (e) => {
      setGroupSelected(_.xor(groupSelected, [parseInt(e.target.value, 10)]));
    },
    [groupSelected, setGroupSelected]
  );

  const selectedAllGroups = useCallback(() => {
    if (groupsList.length === groupSelected.length) {
      setGroupSelected([]);
    } else {
      setGroupSelected(groupsList.map((group) => parseInt(group.id, 10)));
    }
  }, [groupSelected, groupsList]);

  const onRoleSelected = useCallback(
    (e) => {
      setRole(e.target.value);
    },
    [setRole]
  );

  const removeSuccessMessage = useCallback(() => {
    const timer = setTimeout(() => {
      setFormSuccess(false);
    }, 3000);
    return () => clearTimeout(timer);
  }, [setFormSuccess]);

  const uploadAvatar = useCallback(
    async (userId) => {
      const formData = new FormData();
      formData.append('files', avatar);
      formData.append('field', 'avatar_url');
      formData.append('ref', 'users');
      formData.append('refId', userId);
      return NodeServerHttp.post(`upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [avatar]
  );

  const onSubmit = useCallback(
    (data) => {
      data.groups = groupSelected;
      data.roles = [JSON.parse(role)];

      if (userEdited) {
        NodeServerHttp.put(`users/${userEdited.id}`, data)
          .then((response) => {
            if (avatar) {
              uploadAvatar(response.id).then((user) => {
                editUserCallback(user);
              });
            }

            setGroupSelected([]);
            setSubmittedData(response);
            editUserCallback(response);
            cancel();
            notificationContext.dispatch({
              type: 'ADD_NOTIFICATION',
              payload: {
                message: tBack('A new user has been edited'),
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      } else {
        data.lab_id = authContext.state.user.lab.id;
        NodeServerHttp.post('users', data)
          .then((response) => {
            if (avatar) {
              uploadAvatar(response.id).then((user) => {
                editUserCallback(user);
              });
            }
            setGroupSelected([]);
            setSubmittedData(response);
            addUserCallback(response);
            cancel();
            notificationContext.dispatch({
              type: 'ADD_NOTIFICATION',
              payload: {
                message: tBack('A new user has been created'),
              },
            });
          })
          .catch((error) => {
            console.log(error);
          });
      }
    },
    [
      cancel,
      tBack,
      userEdited,
      uploadAvatar,
      editUserCallback,
      notificationContext,
      setSubmittedData,
      groupSelected,
      setGroupSelected,
      role,
      avatar,
      addUserCallback,
    ]
  );

  useEffect(() => {
    if (isSubmitSuccessful) {
      setFormSuccess(true);
      removeSuccessMessage(); // Timeout function
      reset({
        ...defaultValues,
      }); // Forms input
    }
  }, [
    isSubmitSuccessful,
    submittedData,
    reset,
    removeSuccessMessage,
    setFormSuccess,
    defaultValues,
  ]);

  useEffect(() => {
    http.get('groups').then((response) => {
      setGroupList(response);
    });
  }, []);

  useEffect(() => {
    NodeServerHttp.get('roles').then((response) => {
      setFormRoles(response);
    });
  }, []);

  useEffect(() => {
    if (userEdited) {
      if (userEdited.groups.length !== 0)
        setGroupSelected(userEdited.groups.map((group) => parseInt(group.id, 10)));

      setRole(userEdited.roles[0].id);
    }
  }, [userEdited]);

  return (
    <Col className={comFadeIn}>
      <Row className="mt-3 pl-2">
        <Col className="pr-0 pl-4">
          <Card className="bg-secondary shadow">
            <CardBody className="p-0">
              <Form onSubmit={handleSubmit(onSubmit)}>
                {formSuccess && (
                  <Alert color="success">{tBack('A new user has been created')}</Alert>
                )}
                <h6 className="ml-n4 heading-small text-muted mb-4">{tBack('User information')}</h6>
                <Row form>
                  <Col>
                    <FormGroup className="pr-2">
                      <label className="form-control-label" htmlFor="input-event-name">
                        {tCommon('First Name')}*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-user-name"
                        name="first_name"
                        placeholder={tCommon('First Name')}
                        type="text"
                        innerRef={register({
                          required: true,
                          minLength: 1,
                        })}
                      />
                      {errors.first_name && errors.first_name.type === 'required' && (
                        <p
                          style={{
                            color: 'red',
                          }}
                        >
                          {tCommon('This field must be filled')}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="pl-2">
                      <label className="form-control-label" htmlFor="input-user-last-name">
                        {tCommon('Last Name')}*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-user-last-name"
                        name="last_name"
                        placeholder={tCommon('Last Name')}
                        type="text"
                        innerRef={register({
                          required: true,
                          minLength: 1,
                        })}
                      />
                      {errors.last_name && errors.last_name.type === 'required' && (
                        <p
                          style={{
                            color: 'red',
                          }}
                        >
                          {tCommon('This field must be filled')}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup className="pr-2">
                      <label className="form-control-label" htmlFor="input-event-name">
                        {tCommon('Email')}*
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-user-email"
                        name="email"
                        placeholder="john@doe.fr"
                        type="text"
                        innerRef={register({
                          required: true,
                          minLength: 1,
                        })}
                      />
                      {errors.email && errors.email.type === 'required' && (
                        <p
                          style={{
                            color: 'red',
                          }}
                        >
                          {tCommon('This field must be filled')}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="pl-2">
                      <label className="form-control-label" htmlFor="input-user-job">
                        {tBack('Job title')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-user-job"
                        name="job"
                        placeholder={tCommon('Job Title')}
                        type="text"
                        innerRef={register({
                          required: false,
                          minLength: 0,
                        })}
                      />
                    </FormGroup>
                  </Col>
                </Row>

                <Row form>
                  <Col>
                    <FormGroup className="pr-2">
                      <label className="form-control-label" htmlFor="input-event-name">
                        {tCommon('Role')}*
                      </label>
                      <Input value={role} name="role" onChange={onRoleSelected} type="select">
                        {formRoles.map((role) => {
                          return (
                            <option key={role.id} value={role.id}>
                              {role.name}
                            </option>
                          );
                        })}
                      </Input>
                    </FormGroup>
                  </Col>
                  {/*
                    <AddTo typeName="Group" typeRoute="group" />
                  */}
                  <Col>
                    <UncontrolledDropdown className={`${comDropdown} w-100`} group>
                      <FormGroup className="w-100 pl-2 ">
                        <Label className="form-control-label" htmlFor="input-event-name">
                          {tCommon('Group')}*
                        </Label>
                        <DropdownToggle
                          className="form-control btn btn-outline-light text-right"
                          caret
                          color="secondary"
                        >
                          <span className="d-inline-block w-100 text-left">
                            {groupSelected.reduce((last, id) => {
                              let group = groupsList.find((element) => +element.id === +id);
                              if (!group) {
                                group = '';
                              }
                              return `${last ? `${last},` : ''}${group.name}`;
                            }, undefined)}
                          </span>
                        </DropdownToggle>
                        <DropdownMenu className="w-100 pl-3 pr-3">
                          <ul className="pl-0">
                            <li className="pb-2">
                              <input
                                onChange={selectedAllGroups}
                                checked={groupSelected.length === groupsList.length}
                                type="checkbox"
                                id="group-all"
                              />
                              <Label className="pl-2" htmlFor="group-all">
                                {tBack('Select All')}
                              </Label>
                            </li>
                            {groupsList.map((group, index) => (
                              <li className="pl-3 ml-1" key={index}>
                                <Input
                                  onChange={onGroupSelected}
                                  checked={groupSelected.includes(parseInt(group.id, 10))}
                                  id={`group-${index}`}
                                  value={group.id}
                                  type="checkbox"
                                  name="groups"
                                  style={{
                                    position: 'relative',
                                    marginLeft: 0,
                                  }}
                                  innerRef={
                                    userEdited
                                      ? register({ required: false })
                                      : register({ required: true })
                                  }
                                />
                                <Label className="pl-2" htmlFor={`group-${index}`}>
                                  {group.name}
                                </Label>
                                <span className={`${comBadge} float-right`}>
                                  {group.users.length > 99 ? '+99' : group.users.length}
                                </span>
                              </li>
                            ))}
                          </ul>
                          <DropdownItem divider />
                          <Link to="/admin/users/groups">
                            <InputGroup className={`${comGroupedBtn}`}>
                              <Button color="primary">{tBack('Add Group')}</Button>
                              <InputGroupText>
                                <FontAwesomeIcon icon={faPlusCircle} />
                              </InputGroupText>
                            </InputGroup>
                          </Link>
                        </DropdownMenu>
                        {errors.groups && errors.groups.type && (
                          <p
                            style={{
                              color: 'red',
                            }}
                          >
                            {tCommon('This field must be filled')}
                          </p>
                        )}
                      </FormGroup>
                      <Input
                        type="text"
                        name="groups"
                        defaultValue={`${groupSelected.length ? 'filled' : ''}`}
                        innerRef={register({
                          required: true,
                        })}
                        className="d-none"
                      />
                    </UncontrolledDropdown>
                  </Col>
                </Row>

                {!userEdited && (
                  <Row>
                    <Col>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-user-password">
                          {tBack('Password')}*
                        </label>
                        <Input
                          className="form-control-alternative"
                          id="input-user-password"
                          name="password"
                          placeholder={tBack('Password')}
                          type="password"
                          innerRef={register({
                            required: true,
                            minLength: 1,
                          })}
                        />
                        {errors.password && errors.password.type === 'required' && (
                          <p
                            style={{
                              color: 'red',
                            }}
                          >
                            {tCommon('This field must be filled')}
                          </p>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <FormGroup>
                      <label className="form-control-label" htmlFor="input-user-address">
                        {tBack('Place')}
                      </label>
                      <Input
                        className="form-control-alternative"
                        id="input-user-address"
                        name="address"
                        placeholder={tBack('Place')}
                        type="text"
                        innerRef={register({
                          required: false,
                        })}
                      />
                      {errors.address && errors.address.type === 'required' && (
                        <p
                          style={{
                            color: 'red',
                          }}
                        >
                          {tCommon('This field must be filled')}
                        </p>
                      )}
                    </FormGroup>
                  </Col>
                </Row>

                <Row>
                  {/*
                    <Col>
                      <FormGroup>
                        <label className="form-control-label" htmlFor="input-event-name">
                          {tBack('Profile Picture')}
                        </label>
                        <CustomInput
                          name="avatar"
                          onChange={onFileUpload}
                          type="file"
                          id="exampleCustomFileBrowser"
                        />
                      </FormGroup>
                    </Col>
                    */}
                  <Col className={comUploadFile}>
                    <Label className="form-control-label" htmlFor="avatar">
                      {tCommon('Profile picture')}
                    </Label>
                    <Label
                      className="upload form-control-alternative form-control"
                      htmlFor="avatar"
                    >
                      <span>{tCommon('Search file')}</span>
                      <span>{currentUploadName || tCommon('Please select a profil picture')}</span>
                    </Label>
                    <input type="file" name="avatar" id="avatar" onChange={onFileUpload} />
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Button onClick={cancel} type="button" className="w-100 mt-3">
                      {tCommon('Cancel')}
                    </Button>
                  </Col>
                  <Col>
                    <Button type="submit" color="primary" className="w-100 mt-3">
                      {userEdited ? tCommon('Edit') : tCommon('Add this user')}
                    </Button>
                  </Col>
                </Row>
              </Form>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Col>
  );
};

export default AddUserFormComponent;
