import axios from 'axios';

const protocol = process.env.REACT_APP_GAME_SERVER_SSL_ENABLED === 'true' ? 'https://' : 'http://';

/* console.log('check protocol');
console.log(protocol + process.env.REACT_APP_HIV_GAME_SERVER_URI); */

const instance = axios.create({
  baseURL: protocol + process.env.REACT_APP_HIV_GAME_SERVER_URI,
  // timeout: 1000,
});

class GameServerHttp {
  async get(resource) {
    return instance.get(resource).then((res) => res.data);
  }

  async post(resource, data, config) {
    return instance.post(resource, data, config).then((res) => res.data);
  }

  async put(resource, data) {
    return instance.put(resource, data).then((res) => res.data);
  }

  async delete(resource, id) {
    return instance.delete(resource, id).then((res) => res.data);
  }
}

export default new GameServerHttp();
