import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSessionsContext } from '../../context/sessionContext';
import { hasAllPermissions, useAuthContext } from '../../context/authContext';

const PrivateRoute = ({
  component: Component,
  render: Render,
  permissionsRequired = [],
  ...rest
}) => {
  const authContext = useAuthContext();
  const sessionsContext = useSessionsContext();
  const accessGranted =
    authContext.state.isLogged && hasAllPermissions(authContext, permissionsRequired);

  function renderRoute(props, routeAccessGranted, routeRender, RouteComponent) {
    if (
      !!rest.moduleName &&
      !!sessionsContext.state.lab &&
      !sessionsContext.state.lab.modules.some((module) => module.name === rest.moduleName)
    ) {
      return (
        <Route
          {...rest}
          render={({ location }) => (
            <Redirect
              to={{
                pathname: '/admin/events',
                state: {
                  from: location,
                },
              }}
            />
          )}
        />
      );
    }

    if (!authContext.state.isLogged) {
      return (
        <Redirect
          to={{
            pathname: '/',
            state: {
              from: props.location,
            },
          }}
        />
      );
    }
    if (routeAccessGranted) {
      if (routeRender) return routeRender(props);
      if (RouteComponent) return <RouteComponent {...props} />;
      return <div>PrivateRoute component needs a render or a component prop.</div>;
    }
    return <div>Not enough permissions</div>;
  }

  return (
    <Route {...rest} render={(props) => renderRoute(props, accessGranted, Render, Component)} />
  );
};

export default PrivateRoute;
