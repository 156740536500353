import React, { useEffect } from 'react';
import NotificationAlert from 'react-notification-alert';

import { useNotificationContext } from 'context/notificationContext';

const Notifications = () => {
  const { state, dispatch } = useNotificationContext();
  let notificationAlert;

  useEffect(() => {
    if (state?.length > 0) {
      const notif = state[0];
      notif.autoDismiss = 5;
      notif.message = <span className="pl-3">{notif.message}</span>;
      notificationAlert(notif);
      dispatch({
        type: 'REMOVE_NOTIFICATION',
        payload: notif.id,
      });
    }
  }, [state, dispatch, notificationAlert]);

  return (
    <NotificationAlert
      ref={(node) => {
        notificationAlert = node?.notificationAlert;
      }}
    />
  );
};

export default Notifications;
