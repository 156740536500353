import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link, useHistory } from 'react-router-dom';
import { Row, Col, Button, Card, CardBody, CardImg, Input, Label, FormGroup } from 'reactstrap';
import classnames from 'classnames';
import ReactQuill from 'react-quill';
import { useTranslation } from 'react-i18next';

import http from 'utils/http';
import { useAuthContext, hasAnyPermission } from 'context/authContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { comQuillReadOnly, comButtonLoading, comSpinningWhite } from './common.module.scss';

const lodash = require('lodash');

const EventThumbnail = (props) => {
  const { isPast, deleteEvent, event: eventProp } = props;
  const [t] = useTranslation('front');
  const [tBack] = useTranslation('back-office');

  const [joining, setJoining] = useState(false);
  const authContext = useAuthContext();
  const isAdmin = hasAnyPermission(authContext, 'manage events');
  const history = useHistory();

  const [rooms, setRooms] = useState([]);
  const [errorMessage, setErrorMessage] = useState(false);
  const [event, setEvent] = useState(eventProp);
  const plannedStartDate = new Date(event.planned_start_date);
  const isEventModerator =
    isAdmin || ['observer', 'animator', 'observer-animator'].includes(event.participation?.role);

  useEffect(() => {
    if (isEventModerator && event.active) {
      http.get(`events/${event.id}/rooms`).then(setRooms);
    }
  }, [isEventModerator, event]);

  const joinEvent = useCallback(async () => {
    setJoining(true);
    const { id } = await http.get(`/events/${event.id}/joinroom`);

    let roomId = id;
    let isGuest = false;

    if (!isEventModerator) {
      isGuest = true;
      if (!authContext.state.user.roomId) {
        setErrorMessage(true);
        setJoining(false);
        return;
      }

      roomId = authContext.state.user.roomId;
    }

    history.push(`/waiting/${roomId}`, { eventID: event.id, isGuest });
  }, [event, authContext.state.user, history, isEventModerator]);

  const startEvent = useCallback(async () => {
    await props.updateEvent(event.id, {
      active: true,
      start_date: new Date(),
    });
  }, [event.id, props]);

  const closeEvent = useCallback(async () => {
    await props.updateEvent(
      event.id,
      {
        active: false,
        end_date: new Date(),
      },
      {
        modal: true,
      }
    );
  }, [event, props]);

  const handleRoomSelection = useCallback(
    async (e) => {
      const participation = event.participation?.id
        ? await http.put(`participants/${event.participation.id}`, {
            room_id: e.target.value,
            event_id: event.id,
          })
        : await http.post('participants', {
            event_id: event.id,
            user_id: authContext.state.user.id,
            room_id: e.target.value,
            email: authContext.state.user.email,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
            lab_id: authContext.state.user.lab_id,
            name: authContext.state.user.name,
            first_name: authContext.state.user.first_name,
            last_name: authContext.state.user.last_name,
          });
      setEvent({
        ...event,
        participation,
      });
    },
    [event, authContext]
  );

  const copyToClipBoard = useCallback(() => {
    const el = document.createElement('textarea');
    el.value = `${process.env.REACT_APP_URI}/${event.slug}`;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
  }, [event]);

  return (
    <Card
      className={classnames(
        'shadow position-relative event-thumbnail align-items-start overflow-hidden',
        {
          admin: isAdmin,
        }
      )}
      style={{
        border: 'none',
      }}
      key={event.id}
      title={event.name}
    >
      <CardImg
        variant="top"
        src={
          (!!event.thumbnail &&
            process.env.REACT_APP_API_URI_NODE + event.thumbnail.formats.medium.url) ||
          (!!event.medias &&
            event.medias.length > 0 &&
            !!lodash.head(event.medias).thumbnail &&
            process.env.REACT_APP_API_URI_NODE +
              lodash.head(event.medias).thumbnail.formats.medium.url) ||
          '/default-thumbnail.png'
        }
        draggable={false}
      />
      <CardBody
        className="position-relative w-100"
        style={{
          textAlign: 'left',
        }}
      >
        <h4>{event.name}</h4>
        {isAdmin && !!event.is_public && event.slug && (
          <div
            style={{
              userSelect: 'all',
            }}
          >
            <span>{`${process.env.REACT_APP_URI}/${event.slug}`}</span>
            <span
              className="ml-2"
              style={{
                cursor: 'pointer',
              }}
              role="button"
              tabIndex={0}
              onClick={copyToClipBoard}
            >
              <i className="fa fa-copy" />
            </span>
          </div>
        )}
        <ReactQuill
          className={comQuillReadOnly}
          theme="snow"
          readOnly
          modules={{
            toolbar: false,
          }}
          value={event.description}
          name="description"
        />
        <div
          className="position-absolute mt--4 text-dark font-weight-bold"
          style={{
            top: 0,
          }}
        >
          {plannedStartDate && (
            <>
              <span>
                <i className="fas fa-calendar-alt mr-1" />
                {plannedStartDate.toLocaleDateString()}
              </span>
              <span className="ml-3">
                <i className="fas fa-clock mr-1" />
                {plannedStartDate.toLocaleTimeString()}
              </span>
            </>
          )}
        </div>

        {isEventModerator && !!event.active && (
          <div>
            <FormGroup className="row align-items-baseline">
              <Label
                className="form-control-label col-8"
                htmlFor="select-room"
                disabled={event.rooms_needed === 1}
              >
                {t('Select room')}
              </Label>
              <Input
                type="select"
                id="select-room"
                className="col mr-3"
                disabled={event.rooms_needed === 1}
                onChange={handleRoomSelection}
                value={event.participation?.room_id ?? ''}
              >
                {rooms.map(({ id }, i) => (
                  <option key={id} value={id}>
                    {i + 1}
                  </option>
                ))}
              </Input>
            </FormGroup>
          </div>
        )}

        <div className="d-flex justify-content-between">
          {!!event.active && (
            <>
              {!joining && (
                <Button
                  color="primary"
                  style={{
                    width: '120px',
                  }}
                  onClick={joinEvent}
                >
                  {t('Join')}
                </Button>
              )}
              {joining && (
                <div className="text-center">
                  <div className={`${comButtonLoading}`}>
                    <div className={comSpinningWhite}>
                      <FontAwesomeIcon className="pt-1 mb-1" icon={faCircleNotch} />
                    </div>
                  </div>
                </div>
              )}
              {isAdmin && (
                <Button color="danger" onClick={closeEvent}>
                  {t('Close')}
                </Button>
              )}
            </>
          )}
          {!event.active && !isPast && isAdmin && (
            <>
              {/*              {plannedStartDate.getTime() < Date.now() ? (
                <Button color="success" className="event-start-bt" onClick={startEvent}>
                  {t('Start')}
                </Button>
              ) : null} */}
              <Button color="success" className="event-start-bt" onClick={startEvent}>
                {t('Start')}
              </Button>
              {deleteEvent && isAdmin && (
                <Button color="danger" onClick={() => deleteEvent(event.id)}>
                  {tBack('Cancel event')}
                </Button>
              )}
            </>
          )}
        </div>
      </CardBody>
      {!isPast && isAdmin && (
        <Link to={`/admin/events/${event.id}`}>
          <Button
            className="position-absolute d-inline-flex justify-content-center align-items-center"
            style={{
              borderRadius: '50%',
              top: 20,
              right: 20,
              width: 40,
              height: 40,
            }}
            color="primary"
          >
            <i className="fa fa-pen" />
          </Button>
        </Link>
      )}
      {!event.active && isPast && isAdmin && (
        <>
          <div className="position-absolute w-100 h-100 opacity-5 bg-white" />
          <Button
            href=""
            className="position-absolute d-inline-flex justify-content-center align-items-center"
            style={{
              borderRadius: '50%',
              top: 20,
              right: 20,
              width: 40,
              height: 40,
              color: '#3c7cff',
              opacity: 0.5,
              cursor: 'default',
              visibility: 'hidden',
            }}
            color="white"
          >
            <i className="fa fa-chart-pie" />
          </Button>
        </>
      )}
    </Card>
  );
};

export default EventThumbnail;

EventThumbnail.propTypes = {
  event: PropTypes.object.isRequired,
  isPast: PropTypes.bool,
  deleteEvent: PropTypes.func,
  updateEvent: PropTypes.func,
};
