import http from 'utils/http';
import React, { useEffect, useState } from 'react';
import { Col, Row, Label } from 'reactstrap';
import { Page, Text, View, Document, StyleSheet, Svg, Path } from '@react-pdf/renderer';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

const style = StyleSheet.create({
  page: {
    flexDirection: 'column',
    backgroundColor: '#F4F5F7',
    fontSize: '14pt',
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
  row: {
    flexDirection: 'row',
    justifyContent: 'space-around',
    paddingTop: '5pt',
    paddingBottom: '5pt',
  },
  col: {
    flexGrow: 1,
    flexDirection: 'column',
    // alignSelf: 'start',
    textAlign: 'left',
    paddingLeft: '10pt',
    paddingRight: '10pt',
  },
  title: {
    fontWeight: 'bold',
    fontSize: '14pt',
    color: 'grey',
    marginBottom: 10,
  },
  mainTitle: {
    fontWeight: 'bold',
    fontSize: '18pt',
    color: '#343036',
    marginBottom: 10,
    marginTop: 10,
  },
  label: {
    paddingBottom: 5,
    paddingTop: 5,
    color: '#343036',
  },
  hr: {
    marginTop: 10,
    marginBottom: 10,
    borderBottom: 1,
    width: '100%',
    height: '1pt',
    backgroundColor: '#e0e0e0',
    display: 'block',
    border: 'none',
  },
  field: {
    flexDirection: 'row',
    backgroundColor: 'white',
    height: '30pt',
    borderRadius: '3pt',
    color: '#C6CBDA',
    width: '100%',
    alignItems: 'center',
    paddingLeft: '10pt',
    fontSize: '12pt',
    borderColor: '#eaeaea',
    borderWidth: '1pt',
    borderStyle: 'solid',
  },
  largeField: {
    width: '100%',
    height: 100,
    backgroundColor: 'white',
    borderRadius: '3pt',
    borderColor: '#eaeaea',
    borderWidth: '1pt',
    borderStyle: 'solid',
  },
  checkBox: {
    backgroundColor: '#CED4DA',
    borderRadius: '3pt',
    width: '20px',
    height: '20px',
    padding: 4,
  },
  checked: {
    backgroundColor: 'white',
    width: '50%',
    height: '50%',
    marginLeft: '25%',
    marginTop: '25%',
  },
  icon: {
    position: 'absolute',
    right: 0,
    top: 0,
    width: '30pt',
    height: '100%',
    opacity: 0.5,
    borderLeftColor: 'grey',
    borderLeftStyle: 'solid',
    borderLeftWidth: '1pt',
    padding: '8pt',
  },
});

const PharmaReportPdf = (props) => {
  const { id } = props;
  const [content, setContent] = useState();
  const [tCommon] = useTranslation();

  useEffect(() => {
    http
      .get(`_pharmacovigilance-report/${id}`)
      .then((response) => {
        setContent(response);
      })
      .catch(() => {
        setContent({
          sexe: 'w',
          initials: 'AIKO',
          age_range: '20-28',
          date_of_birth: '2021-09-02T13:11:43.000000Z',
          name: 'unamed',
          first_name: 'unapmed',
          phone_number: '+00000000000',
          email_adress: 'aiko@at.dot',
          specialty: '',
          postal_address: '1 rue de la rue',
          zip_code: '75016',
          drug_name: '4hydro',
          drug_batch_number: `BHF06742${new Date().getTime()}`,
          side_effect_description: '...',
          gender: 'w',
          patient_lastname: 'patient lastname',
          patient_birthday: '2021-09-02T13:11:43.000000Z',
        });
      });
  }, [id]);

  return (
    <Document>
      <Page size="A4" style={style.page}>
        <View style={style.section}>
          <Text style={style.mainTitle}>{tCommon('Pharmacovigilance cases')}</Text>
          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Username')}</Text>
              <div style={style.field}>
                <Text>{content?.username}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Date')}</Text>
              <div style={style.field}>
                <Text>{content?.date}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Session name')}</Text>
              <div style={style.field}>
                <Text>{content?.session_name}</Text>
              </div>
            </View>
          </View>
          <div style={style.hr} />
          <Text style={style.title}>{tCommon('TREATED PATIENT')}</Text>
          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('First name')}</Text>
              <div style={style.field}>
                <Text>{content?.patient_firstname}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Initials')}</Text>
              <div style={style.field}>
                <Text>{content?.patient_lastname}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Sexe')}</Text>
              <View style={style.row}>
                <div style={style.checkBox}>
                  {content?.gender && content.gender.toLowerCase() === 'w' && (
                    <div style={style.checked} />
                  )}
                </div>
                <Text style={style.label}>F</Text>
                <div style={style.checkBox}>
                  {content?.gender && content.gender.toLowerCase() === 'm' && (
                    <div style={style.checked} />
                  )}
                </div>
                <Text style={style.label}>M</Text>
              </View>
            </View>
          </View>

          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Birthdate')}</Text>
              <div style={style.field}>
                <Text>
                  {content?.patient_birthday &&
                    moment(content.patient_birthday).format('DD/MM/YYYY')}
                </Text>
                <div style={style.icon}>
                  <Svg
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="far"
                    data-icon="calendar-alt"
                    className="svg-inline--fa fa-calendar-alt fa-w-14"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 448 512"
                  >
                    <Path
                      fill="#808080"
                      d="M148 288h-40c-6.6 0-12-5.4-12-12v-40c0-6.6 5.4-12
                        12-12h40c6.6 0 12 5.4 12 12v40c0 6.6-5.4 12-12
                        12zm108-12v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                        5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4
                        12-12zm96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                        5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4
                        12-12zm-96 96v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                        5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4
                        12-12zm-96 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                        5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4
                        12-12zm192 0v-40c0-6.6-5.4-12-12-12h-40c-6.6 0-12
                        5.4-12 12v40c0 6.6 5.4 12 12 12h40c6.6 0 12-5.4
                        12-12zm96-260v352c0 26.5-21.5 48-48 48H48c-26.5
                        0-48-21.5-48-48V112c0-26.5 21.5-48 48-48h48V12c0-6.6
                        5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h128V12c0-6.6
                        5.4-12 12-12h40c6.6 0 12 5.4 12 12v52h48c26.5 0 48
                        21.5 48 48zm-48 346V160H48v298c0 3.3 2.7 6 6
                        6h340c3.3 0 6-2.7 6-6z"
                    />
                  </Svg>
                </div>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Age range')}</Text>
              <div style={style.field}>
                <Text>{content?.age_range}</Text>
              </div>
            </View>
          </View>
          <div style={style.hr} />
          <Text style={style.title}>{tCommon('HEALTH PROFESSIONAL')}</Text>
          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Name')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_lastname}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('First name')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_firstname}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Phone number')}</Text>
              <div style={style.field}>
                <Text>{content?.phone_number}</Text>
              </div>
            </View>
          </View>

          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('E-mail address')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_email}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Specialty')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_specialty}</Text>
              </div>
            </View>
          </View>

          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Postal address')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_address}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Zip code')}</Text>
              <div style={style.field}>
                <Text>{content?.doctor_zip_code}</Text>
                <div style={style.icon}>
                  <Svg
                    style={{
                      width: '100%',
                      height: '100%',
                    }}
                    width="50"
                    height="50"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="map-marker"
                    className="svg-inline--fa fa-map-marker fa-w-12"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 384 512"
                  >
                    <Path
                      fill="#808080"
                      d="M172.268 501.67C26.97 291.031 0 269.413 0 192 0 85.961
                      85.961 0 192 0s192 85.961 192 192c0 77.413-26.97
                      99.031-172.268 309.67-9.535 13.774-29.93 13.773-39.464 0z"
                    />
                  </Svg>
                </div>
              </div>
            </View>
          </View>
          <div style={style.hr} />
          <Text style={style.title}>{tCommon('DROGS')}</Text>
          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Drug name')}</Text>
              <div style={style.field}>
                <Text>{content?.drug_name}</Text>
              </div>
            </View>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Drug batch number')}</Text>
              <div style={style.field}>
                <Text>{content?.drug_batch_number}</Text>
              </div>
            </View>
          </View>

          <View style={style.row}>
            <View style={style.col}>
              <Text style={style.label}>{tCommon('Adverse reaction description')}</Text>
              <div style={style.largeField}>
                <Text>{content?.drug_adverse_reaction}</Text>
              </div>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default PharmaReportPdf;
