import React, { useCallback, useContext, useEffect, useState, useRef } from 'react';
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Row,
  Label,
  CustomInput,
  CardBody,
  Card,
} from 'reactstrap';
import { useForm, useFieldArray } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import http from 'utils/http';
import { NotificationContext } from 'context/notificationContext';
import _ from 'lodash';

import {
  comUploadFile,
  comSwitch,
  comFadeIn,
  comTextItem,
} from '../../../common/common.module.scss';
import NodeServerHttp from '../../../../utils/nodeApiHttp';

const AddGroupFormComponent = (props) => {
  const { cancel, groupEdited, addGroupCallback, editGroupCallback } = props;
  const [tBack] = useTranslation('back-office');
  const [tCommon] = useTranslation();
  const notificationContext = useContext(NotificationContext);
  const [currentUploadName, setCurrentUploadName] = useState('');
  const [documents, setDocuments] = useState([]);
  const [documentList, setDocumentList] = useState([]);
  const [users, setUsers] = useState([]);
  const [polls, setPolls] = useState([]);
  const [pollList, setPollList] = useState([]);
  const [medias, setMedias] = useState([]);
  const [mediaList, setMediaList] = useState([]);
  const [color, setColor] = useState('#3c7cff');
  const [picture, setPicture] = useState(null);
  const [fieldIds, setFieldIds] = useState([]);
  const defaultValues = groupEdited || {};
  const addParticipantRef = useRef();
  const { errors, register, handleSubmit, control } = useForm({
    defaultValues,
  });

  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users',
  });

  const onColorOnChange = useCallback(
    (e) => {
      setColor(e.target.value);
    },
    [setColor]
  );

  const onSwitchMedia = useCallback(
    (e) => {
      setMediaList(_.xor(mediaList, [parseInt(e.target.value, 10)]));
    },
    [mediaList, setMediaList]
  );

  const onDocumentCheckboxToggle = useCallback(
    (e) => {
      setDocumentList(_.xor(documentList, [parseInt(e.target.value, 10)]));
    },
    [documentList, setDocumentList]
  );

  const onPollCheckboxToggle = useCallback(
    (e) => {
      setPollList(_.xor(pollList, [parseInt(e.target.value, 10)]));
    },
    [pollList, setPollList]
  );

  const onFileUpload = useCallback(
    (e) => {
      setPicture(e.target.files[0]);
      setCurrentUploadName(e.target.files[0].name);
    },
    [setPicture]
  );

  const uploadPicture = useCallback(
    async (groupId) => {
      const formData = new FormData();
      formData.append('files', picture);
      formData.append('field', 'picture_url');
      formData.append('ref', 'group');
      formData.append('refId', groupId);

      return NodeServerHttp.post(`upload`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
    },
    [picture]
  );

  const onSubmit = useCallback(
    async (data) => {
      data.medias = mediaList;
      data.documents = documentList;
      data.polls = pollList;
      data.color = color || '#000000';
      data.users = [...fieldIds];
      if (!('users' in data)) data.users = [];

      if (groupEdited) {
        await http.put(`groups/${groupEdited.id}`, data).then((response) => {
          if (picture) {
            uploadPicture(response.id).then(() => {
              editGroupCallback(response);
            });
          }
          editGroupCallback(response);
          cancel();
        });
        notificationContext.dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            message: tBack('A new group has been edited'),
          },
        });
      } else {
        await http.post('groups', data).then((response) => {
          if (picture) {
            uploadPicture(response.id).then(() => {
              editGroupCallback(response);
            });
          }
          addGroupCallback(response);
          cancel();
        });
        notificationContext.dispatch({
          type: 'ADD_NOTIFICATION',
          payload: {
            message: tBack('A new group has been created'),
          },
        });
      }
    },
    [
      mediaList,
      documentList,
      pollList,
      color,
      fieldIds,
      groupEdited,
      notificationContext,
      tBack,
      picture,
      editGroupCallback,
      cancel,
      uploadPicture,
      addGroupCallback,
    ]
  );

  useEffect(() => {
    http.get('users').then((response) => {
      setUsers(response);
    });
  }, [setUsers]);

  useEffect(() => {
    http.get('medias').then((response) => {
      setMedias(response);
    });
  }, [setMedias]);

  useEffect(() => {
    http.get('polls').then((response) => {
      setPolls(response);
    });
  }, [setPolls]);

  useEffect(() => {
    http.get('documents').then((res) =>
      setDocuments(
        res.map((doc) => ({
          value: doc.id,
          label: doc.name,
        }))
      )
    );
  }, [setDocuments]);

  useEffect(() => {
    if (groupEdited) {
      setDocumentList(groupEdited.documents.map((document) => document.id));
      setPollList(groupEdited.polls.map((poll) => poll.id));
      setMediaList(groupEdited.medias.map((media) => media.id));
      setColor(groupEdited.color);
    }
  }, [groupEdited]);

  useEffect(() => {
    const ids = fields.map((value) => +value.id);
    setFieldIds(ids);
  }, [fields]);

  const SelectParticipant = useCallback(() => {
    return (
      <select className="form-control" typeof="select" ref={addParticipantRef}>
        {users
          .filter((value) => fieldIds.indexOf(+value.id) === -1)
          .map((user, i) => (
            <option key={i} value={user.id}>
              {user.name}
            </option>
          ))}
      </select>
    );
  }, [fieldIds, users]);
  return (
    <Form onSubmit={handleSubmit(onSubmit)} className={`pl-2 ${comFadeIn}`}>
      <h6 className="ml-n4 heading-small text-muted mb-4">{tBack('Group information')}</h6>
      <Row>
        <Col lg="12">
          <FormGroup>
            <Label className="form-control-label" htmlFor="input-event-name">
              {tBack('Group name')}*
            </Label>
            <Input
              className="form-control-alternative"
              id="input-event-name"
              name="name"
              placeholder={tBack('Group name')}
              type="text"
              innerRef={register({
                required: true,
                minLength: 1,
              })}
            />
            {errors.name && errors.name.type === 'required' && (
              <p
                style={{
                  color: 'red',
                }}
              >
                {tCommon('This field must be filled')}
              </p>
            )}
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col className={comUploadFile}>
          <Label className="form-control-label" htmlFor="avatar">
            {tBack('Choose a picture for this group')}
          </Label>
          <Label className="upload form-control-alternative form-control" htmlFor="avatar">
            <span>{tCommon('Search file')}</span>
            <span>{currentUploadName || tCommon('Import a picture')}</span>
          </Label>
          <input type="file" name="avatar" id="avatar" onChange={onFileUpload} />
          <span
            style={{
              position: 'absolute',
              top: '42px',
              right: '-8px',
            }}
          >
            {tBack('Or')}
          </span>
        </Col>
        <Col>
          <FormGroup>
            <Label className="form-control-label" for="exampleColor">
              {tBack('Color')}
            </Label>
            <Input
              onChange={onColorOnChange}
              type="color"
              name="color"
              id="exampleColor"
              placeholder="color"
              value={color}
            />
          </FormGroup>
        </Col>
      </Row>
      <h6 className="heading-small text-muted mb-4">{tBack('Resource list')}</h6>
      <Row>
        <Col>
          <Label className="form-control-label mb-3">
            {tBack('Interactive support presentation')}
          </Label>
          {medias.map((media, index) => (
            <FormGroup key={index} className="pb-1 mb-0 custom-control">
              <CustomInput
                className={comSwitch}
                checked={mediaList.includes(parseInt(media.id, 10))}
                onChange={onSwitchMedia}
                type="switch"
                id={`media-input-${media.id}`}
                name="customSwitch"
                value={media.id}
                label={media.name}
              />
            </FormGroup>
          ))}
        </Col>
      </Row>

      <Row className="mt-3">
        <Col>
          <Row>
            <Col>
              <Label className="form-control-label">{tBack('Documents')}</Label>
            </Col>
            <Col>
              <Label className="form-control-label">{tBack('Polls')}</Label>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card>
                <CardBody>
                  {documents.map((document, index) => (
                    <FormGroup key={index} check>
                      <Label check>
                        <Input
                          onChange={onDocumentCheckboxToggle}
                          checked={documentList.includes(parseInt(document.value, 10))}
                          type="checkbox"
                          value={document.value}
                        />
                        {document.label}
                      </Label>
                    </FormGroup>
                  ))}
                </CardBody>
              </Card>
            </Col>

            <Col>
              <Card>
                <CardBody>
                  <Row>
                    {polls.map((poll, index) => (
                      <Col lg="2" key={index}>
                        <FormGroup check>
                          <Label check>
                            <Input
                              onChange={onPollCheckboxToggle}
                              checked={pollList.includes(parseInt(poll.id, 10))}
                              type="checkbox"
                              value={poll.id}
                            />
                            {poll.title}
                          </Label>
                        </FormGroup>
                      </Col>
                    ))}
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Col>
      </Row>

      <Row>
        <Col>
          <Row>
            <Col>
              <SelectParticipant />
            </Col>
            <Col>
              <Button
                type="button"
                color="primary"
                onClick={() => {
                  const id = addParticipantRef.current.value;
                  const user = users.filter((value) => {
                    return +value.id === +id;
                  })[0];
                  append({
                    id: user.id,
                    name: user.name,
                  });
                }}
              >
                {tBack('Add participant')}
              </Button>
            </Col>
          </Row>
          <Row>
            <Col>
              {fields.map((item, index) => {
                return (
                  <li
                    className="d-flex w-100 align-items-center"
                    key={item.id}
                    style={{
                      listStyleType: 'none',
                    }}
                  >
                    <FormGroup className={`flex-grow-1 pr-2 ${comTextItem}`}>
                      <span>{item.name}</span>
                    </FormGroup>
                    <Button
                      className="mt-1"
                      color="danger"
                      type="button"
                      onClick={() => remove(index)}
                    >
                      {tCommon('Delete')}
                    </Button>
                  </li>
                );
              })}
            </Col>
          </Row>
        </Col>
      </Row>
      <Row className="mt-5">
        <Col>
          <Button type="button" onClick={cancel} className="w-100">
            {tCommon('Cancel')}
          </Button>
        </Col>
        <Col>
          <Button type="submit" color="primary" className="w-100">
            {tCommon('Save')}
          </Button>
        </Col>
      </Row>
    </Form>
  );
};

export default AddGroupFormComponent;
