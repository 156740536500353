/* eslint-disable max-len */
import React, { useCallback, useEffect, useState, useRef } from 'react';
import {
  Button,
  Row,
  Col,
  Label,
  Input,
  InputGroup,
  InputGroupText,
  Container,
  Card,
  CardHeader,
  CardBody,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from 'reactstrap';
import { useTranslation } from 'react-i18next';
import http from 'utils/http';
import NodeApiHttp from 'utils/nodeApiHttp';
import _ from 'lodash';

// Fragments
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faList, faSearch, faTrash, faUserEdit } from '@fortawesome/free-solid-svg-icons';
import css from '../pharmacovigilance/emaillist.module.css';
import AddGroupFormComponent from './forms/AddGroup.form';
import { useAuthContext } from '../../../context/authContext';

const GroupList = () => {
  const [tCommon] = useTranslation();
  const authContext = useAuthContext();
  const [t] = useTranslation('back-office');
  const [displayAddGroupForm, setDisplayAddGroupForm] = useState(false);
  const [groupEdited, setGroupEdited] = useState(null);
  const [groupList, setGroupList] = useState([]);
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [nodeGroupList, setNodeGroupList] = useState(null);
  const [deleteModal, setDeleteModal] = useState(false);

  const [lastDisplayedGroupId, setLastDisplayedGroupId] = useState(null);
  const [addGroupFormRefreshed, setAddGroupFormRefreshed] = useState(false);

  const addGroupFormContainer = useRef();

  const onSelectMultipleGroup = useCallback(
    (e, group) => {
      setSelectedGroups(_.xor(selectedGroups, [parseInt(group.id, 10)]));
    },
    [selectedGroups, setSelectedGroups]
  );

  const toggle = useCallback(() => {
    if (deleteModal) setSelectedGroups([]);
    setDeleteModal(!deleteModal);
  }, [deleteModal]);

  const refresh = useCallback(() => {
    setAddGroupFormRefreshed(false);
    const currentElement = addGroupFormContainer?.current;
    if (currentElement) {
      currentElement.style.height = `${currentElement.offsetHeight - 10}px`;
    }
    const id = setTimeout(() => {
      setAddGroupFormRefreshed(true);
      if (currentElement) {
        currentElement.style.height = 'auto';
      }
      clearTimeout(id);
    }, 10);
    return () => {
      clearTimeout(id);
    };
  }, [addGroupFormContainer]);

  const addGroupFormToggle = useCallback(
    (element) => {
      if (element === false) {
        setGroupEdited(null);
        setDisplayAddGroupForm(true);
        refresh();
      } else if (element === true) {
        setGroupEdited(null);
        setDisplayAddGroupForm(false);
        refresh();
      } else {
        if (lastDisplayedGroupId === element.id) {
          if (displayAddGroupForm) {
            setGroupEdited(null);
          }
          setDisplayAddGroupForm(!displayAddGroupForm);
        } else {
          setDisplayAddGroupForm(true);
        }
        setLastDisplayedGroupId(element.id);
      }
    },
    [displayAddGroupForm, lastDisplayedGroupId, refresh]
  );

  const onAddGroup = useCallback(
    (group) => {
      groupList.unshift(group);
      setGroupList(groupList);
      setDisplayAddGroupForm(false);
    },
    [groupList]
  );

  const onEditGroup = useCallback(
    (gEdited) => {
      setGroupList(groupList.map((group) => (group.id === gEdited.id ? gEdited : group)));
      setDisplayAddGroupForm(false);
    },
    [groupList]
  );

  const editGroup = useCallback(
    (element) => {
      refresh();
      setGroupEdited(element);
      addGroupFormToggle(element);
    },
    [addGroupFormToggle, refresh]
  );

  const onSingleDeleteModal = useCallback(
    (e, element) => {
      toggle();
      onSelectMultipleGroup(e, element);
    },
    [toggle, onSelectMultipleGroup]
  );

  const onDelete = useCallback(() => {
    http.delete(`groups/${selectedGroups[0]}`).then(() => {
      const list = groupList.filter((group) => +group.id !== +selectedGroups[0]);
      setGroupList(list);
      toggle();
    });
  }, [groupList, selectedGroups, toggle]);

  const ondDeleteMultiple = useCallback(() => {
    http
      .post('groups/delete-multiple', {
        ids: selectedGroups,
      })
      .then(() => {
        setDeleteModal(false);
        setGroupList(groupList.filter((group) => !selectedGroups.includes(group.id)));
      });
  }, [selectedGroups, groupList]);

  /**
   * To refactor for get all group data from node js api
   */
  useEffect(() => {
    http.get('groups').then((response) => {
      if (nodeGroupList) {
        setGroupList(
          response.map((group) => {
            return nodeGroupList
              .map((nodeGroup) => {
                return {
                  ...nodeGroup,
                  id:
                    parseInt(nodeGroup.id, 10) === group.id ? group.id : parseInt(nodeGroup.id, 10),
                  documents: parseInt(nodeGroup.id, 10) === group.id ? group.documents : [],
                  polls: parseInt(nodeGroup.id, 10) === group.id ? group.polls : [],
                };
              })
              .find((eric) => eric.id === group.id);
          })
        );
      }
    });
  }, [nodeGroupList]);

  useEffect(() => {
    NodeApiHttp.get(`groups?lab_id=${authContext.state.lab.id}`).then((response) => {
      setNodeGroupList(response);
    });
  }, [authContext]);

  return (
    <Container fluid>
      <Card className="bg-secondary shadow mt-3">
        <CardBody className="bg-white">
          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardHeader className="bg-white border-0">
                  <Row className="align-items-center">
                    <Col xs="6">
                      <h3 className="mb-0">{t('Group List')}</h3>
                    </Col>
                    <Col xs="6">
                      <Row>
                        <Col lg={6} />
                        <Col lg={6}>
                          <Button
                            onClick={() => {
                              addGroupFormToggle(false);
                            }}
                            color="primary"
                            className="w-100 mt-3"
                          >
                            {t('Add Group')}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardHeader>
                <CardBody className="bg-white">
                  <div>
                    <div>
                      <Row>
                        <Col lg="12">
                          {/* Filter */}
                          <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
                            <Col className="form-inline">
                              <Label for="pv-sel-01">{tCommon('Display by')}</Label>
                              <Input
                                className="ml-2"
                                type="select"
                                name="display-by"
                                id="pv-sel-01"
                              >
                                <option value="10">10</option>
                                <option value="20">20</option>
                                <option value="50">50</option>
                                <option value="100">100</option>
                                <option value="1000">1000</option>
                              </Input>
                              <InputGroup
                                style={{
                                  cursor: selectedGroups.length > 0 ? 'pointer' : 'not-allowed',
                                }}
                                className={`
                                  ml-4 border-0
                                  ${css.unified}
                                  ${selectedGroups.length === 0 ? 'opacity-4' : ''}
                                `}
                                onClick={toggle}
                              >
                                <Label
                                  className="mr-2"
                                  style={{
                                    cursor: 'inherit',
                                  }}
                                >
                                  {tCommon('Remove')}
                                </Label>
                                <InputGroupText>
                                  <FontAwesomeIcon className="pt-1 mb-1" icon={faTrash} />
                                </InputGroupText>
                              </InputGroup>
                            </Col>
                            <Col
                              className="form-inline justify-content-end"
                              style={{
                                justifyContent: 'flex-end',
                                display: 'none',
                              }}
                            >
                              <InputGroup className={`${css.unified} ${css.filterElSize}`}>
                                <InputGroupText>
                                  <FontAwesomeIcon icon={faList} />
                                </InputGroupText>
                                <Button color="white">{tCommon('Sort')}</Button>
                              </InputGroup>
                              <InputGroup className={`${css.unified} ${css.filterElSize} ml-2`}>
                                <InputGroupText>
                                  <i>
                                    <FontAwesomeIcon icon={faBars} />
                                  </i>
                                </InputGroupText>
                                <Button color="white">{tCommon('Filter')}</Button>
                              </InputGroup>
                              <InputGroup className={`${css.unified} ${css.filterElSize} ml-2`}>
                                <Input type="text" name="search" placeholder={tCommon('Search')} />
                                <InputGroupText>
                                  <FontAwesomeIcon icon={faSearch} />
                                </InputGroupText>
                              </InputGroup>
                            </Col>
                          </Row>
                          {/* Filter */}

                          <Row>
                            <Table>
                              <thead className={css.thead}>
                                <tr>
                                  <th
                                    style={{
                                      width: '20px',
                                    }}
                                    className="pl-1 pr-0"
                                  />
                                  <th
                                    style={{
                                      width: '30%',
                                    }}
                                  >
                                    {t('Group name')}
                                  </th>
                                  <th
                                    style={{
                                      width: '60%',
                                    }}
                                  >
                                    {t('Members')}
                                  </th>
                                  <th />
                                </tr>
                              </thead>
                              <tbody>
                                {groupList.map((element, index) => (
                                  <tr key={index}>
                                    <td className="pl-1 pr-0">
                                      <Input
                                        addon
                                        type="checkbox"
                                        checked={selectedGroups.includes(parseInt(element.id, 10))}
                                        aria-label="Checkbox for following text input"
                                        onChange={(e) => onSelectMultipleGroup(e, element)}
                                      />
                                    </td>
                                    <td>
                                      <Row>
                                        <Col lg={2}>
                                          <div
                                            style={
                                              element.picture_url
                                                ? {
                                                    background: `url(${process.env.REACT_APP_API_URI_NODE}${element.picture_url.url}) no-repeat center`,
                                                    backgroundSize: 'cover',
                                                    borderRadius: '50%',
                                                    width: '40px',
                                                    height: '40px',
                                                  }
                                                : {
                                                    backgroundColor: element.color,
                                                    borderRadius: '50%',
                                                    width: '40px',
                                                    height: '40px',
                                                  }
                                            }
                                          />
                                        </Col>
                                        <Col lg={2}>{element.name}</Col>
                                      </Row>
                                    </td>
                                    <td>
                                      {element.users.map((user, i) => (
                                        <div key={i}>
                                          {user.avatar_url ? (
                                            <div
                                              style={{
                                                background: `url(${process.env.REACT_APP_API_URI_NODE}${user.avatar_url.url}) no-repeat center`,
                                                backgroundSize: 'cover',
                                                borderRadius: '50%',
                                                width: '30px',
                                                height: '30px',
                                                float: 'left',
                                                marginLeft: '-13px',
                                              }}
                                            />
                                          ) : (
                                            <svg
                                              style={{
                                                float: 'left',
                                                marginLeft: '-13px',
                                              }}
                                              xmlns="http://www.w3.org/2000/svg"
                                              width="30"
                                              height="30"
                                              viewBox="0 0 122.737 122.737"
                                            >
                                              <g
                                                id="Groupe_744"
                                                data-name="Groupe 744"
                                                transform="translate(14821 -10272.5)"
                                              >
                                                <g
                                                  id="Groupe_723"
                                                  data-name="Groupe 723"
                                                  transform="translate(-16210 10237)"
                                                >
                                                  <path
                                                    id="Path"
                                                    d="M61.369,0a61.369,61.369,0,1,0,61.369,61.369A61.437,61.437,0,0,0,61.369,0Z"
                                                    transform="translate(1389 35.5)"
                                                    fill="#8898aa"
                                                  />
                                                  <path
                                                    id="Path-2"
                                                    data-name="Path"
                                                    d="M0,20.456A20.633,20.633,0,0,1,20.456,0,20.633,20.633,0,0,1,40.912,20.456V25.57A20.633,20.633,0,0,1,20.456,46.026,20.633,20.633,0,0,1,0,25.57Z"
                                                    transform="translate(1429.911 66.184)"
                                                    fill="#fff"
                                                  />
                                                  <path
                                                    id="Path-3"
                                                    data-name="Path"
                                                    d="M32.735,25.57A50.912,50.912,0,0,1,0,13.675,25.466,25.466,0,0,1,22.507,0H42.963A25.466,25.466,0,0,1,65.47,13.675,50.912,50.912,0,0,1,32.735,25.57Z"
                                                    transform="translate(1417.632 122.438)"
                                                    fill="#fff"
                                                  />
                                                </g>
                                              </g>
                                            </svg>
                                          )}
                                        </div>
                                      ))}
                                    </td>
                                    <td>
                                      <span className="float-right opacity-4">
                                        <Button onClick={() => editGroup(element)} className="mr-1">
                                          <FontAwesomeIcon icon={faUserEdit} />
                                        </Button>

                                        <Button
                                          value="singleDelete"
                                          type="button"
                                          onClick={(e) => onSingleDeleteModal(e, element)}
                                        >
                                          <FontAwesomeIcon icon={faTrash} />
                                        </Button>
                                      </span>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </Row>
                          {/* Delete Modal */}
                          <Modal className="modal-lg" isOpen={deleteModal} fade toggle={toggle}>
                            <ModalHeader>{tCommon('Delete')}</ModalHeader>
                            <ModalBody>
                              <Row>
                                <Col>
                                  {selectedGroups.length > 1 ? (
                                    <p>
                                      {tCommon(
                                        'Are you sure you want to delete those selected elements?'
                                      )}
                                    </p>
                                  ) : (
                                    <p>
                                      {tCommon('Are you sure you want to delete this element?')}
                                    </p>
                                  )}
                                </Col>
                              </Row>
                            </ModalBody>
                            <ModalFooter>
                              <Button color="secondary" onClick={toggle}>
                                {tCommon('Cancel')}
                              </Button>
                              <Button
                                color="primary"
                                onClick={() => {
                                  if (selectedGroups.length > 1) {
                                    ondDeleteMultiple();
                                  } else {
                                    onDelete();
                                  }
                                }}
                              >
                                {tCommon('Validate')}
                              </Button>
                            </ModalFooter>
                          </Modal>
                          {/* Delete Modal */}
                        </Col>
                      </Row>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col>
              <Card className="bg-secondary shadow">
                <CardBody className="pr-0">
                  <div style={{ width: '100%', display: 'block' }} ref={addGroupFormContainer}>
                    {displayAddGroupForm && addGroupFormRefreshed && (
                      <AddGroupFormComponent
                        cancel={() => {
                          addGroupFormToggle(true);
                        }}
                        groupEdited={groupEdited}
                        addGroupCallback={onAddGroup}
                        editGroupCallback={onEditGroup}
                      />
                    )}
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default GroupList;
