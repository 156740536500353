import { useTranslation } from 'react-i18next';
import React, { useCallback, useEffect, useState, useRef } from 'react';
import { Col, Form, Input, Row, Label, Table } from 'reactstrap';
import { actions, useListContext } from 'context/ListContext';
import moment from 'moment';
import { comBreak } from 'components/common/common.module.scss';
import css from './statistics.module.scss';

const StatList = (props) => {
  const { pagination, onItemCountChange, onItemChange } = props;
  const [itemCount, setItemCount] = useState(5);
  const [tCommon] = useTranslation();

  const [listRendering, setListRendering] = useState('');
  const listContext = useListContext();

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const load = useCallback(
    (paginate) => {
      const { dispatch } = listContext;
      dispatch({
        type: actions.LOAD_LIST,
        payload: {
          start: paginate * itemCount,
          count: itemCount,
        },
      });
    },
    [itemCount, listContext]
  );

  const previous = usePrevious({ pagination, listContext });

  useEffect(() => {
    if (previous === undefined || previous.pagination !== pagination) {
      load(pagination);
    }
  }, [pagination, previous, load]);

  const renderFilteringOptions = useCallback(() => {
    const { dispatch } = listContext;
    return (
      <Row className={`${css.filter} pl-1 pr-1 mb-3 form-group`}>
        <Col className="form-inline">
          <Label for="pv-sel-01">{tCommon('Display by')}</Label>
          <Input
            className="ml-2"
            type="select"
            name="display-by"
            id="pv-sel-01"
            onChange={(e) => {
              const count = +e.target.value;
              onItemCountChange(count);
              setItemCount(count);
              dispatch({
                type: actions.LOAD_LIST,
                payload: {
                  start: 0, //
                  count,
                },
              });
            }}
          >
            <option value="5">5</option>
            <option value="15">15</option>
            <option value="30">30</option>
          </Input>
        </Col>
      </Row>
    );
  }, [listContext, tCommon, onItemCountChange]);

  const renderList = useCallback(() => {
    const { state } = listContext;
    state.then(({ list }) => {
      setListRendering(
        <Row>
          <Table
            style={{
              tableLayout: 'fixed',
            }}
          >
            <thead className={css.thead}>
              <tr>
                <th className="text-center">{tCommon('SESSION ID')}</th>
                <th className="text-center">{tCommon('SESSION NAME')}</th>
                <th className="text-center">{tCommon('SESSION DATE')}</th>
                <th className="text-center">{tCommon('ADMINISTRATOR')}</th>
                <th className="text-center">{tCommon('NUMBER OF GUESS')}</th>
                <th className="text-center">{tCommon('NUMBER OF PARTICIPANT')}</th>
                <th className="text-center">{tCommon('LENGTH')}</th>
              </tr>
            </thead>
            <tbody>
              {list.map((element, index) => (
                <tr key={index}>
                  <td className="text-center">{element.event_id}</td>
                  <td className={`${comBreak} text-center`}>{element.name}</td>
                  <td className="text-center">{moment(element.date).format('DD/MM/YYYY')}</td>
                  <td className="text-center">{element.admin}</td>
                  <td className="text-center">{element.invitation_total_count}</td>
                  <td className="text-center">{element.participant_total_count}</td>
                  <td className="text-center">
                    {element.event_duration_days
                      ? `${element.event_duration_days}${tCommon('date_format_day')}`
                      : ''}
                    {element.event_duration_days > 1 && 's '}
                    {element.event_duration_hour
                      ? `${element.event_duration_hour}${tCommon('date_format_hour')} `
                      : ''}
                    {element.event_duration_minute && `${element.event_duration_minute}min`}
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </Row>
      );
    });
  }, [listContext, tCommon]);

  const callBackItemChange = useCallback(
    (context) => {
      if (previous !== undefined) {
        const { listLength } = context;
        if (listLength !== previous.listContext.listLength) {
          onItemChange(listLength);
        }
      }
    },
    [onItemChange, previous]
  );

  useEffect(() => {
    callBackItemChange(listContext);
  }, [listContext, callBackItemChange]);

  useEffect(() => {
    renderList(listContext);
  }, [listContext, renderList]);

  return (
    <Form>
      <div>
        <Row>
          <Col lg="12">
            {renderFilteringOptions()}
            {listRendering}
          </Col>
        </Row>
      </div>
    </Form>
  );
};

export default StatList;
