/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Alert, Button, Container, Input, Spinner } from 'reactstrap';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';

import { Colors } from 'Constants';
import http from 'utils/http';
import NodeApiHttp from 'utils/nodeApiHttp';
import { useAuthContext } from 'context/authContext';
import EventSelectRole from 'components/common/EventSelectRole';
import HeaderNavbar from 'components/front/headers/HeaderNavbar';
import { ReactComponent as CameraOn } from 'assets/img/icons/camera-on.svg';
import { ReactComponent as CameraOff } from 'assets/img/icons/camera-off.svg';
import { ReactComponent as MicrophoneOn } from 'assets/img/icons/microphone-on.svg';
import { ReactComponent as MicrophoneOff } from 'assets/img/icons/microphone-off.svg';
import { ReactComponent as WarningAlert } from 'assets/img/icons/alert.svg';
import _ from 'lodash';
import { io as openSocket } from 'socket.io-client';
import {
  browserName,
  browserVersion,
  deviceType,
  fullBrowserVersion,
  isDesktop,
  isMobile,
  isTablet,
  osName,
  osVersion,
} from 'react-device-detect';
import toast, { Toaster } from 'react-hot-toast';
import {
  SET_ACTIVE_MODULES,
  SET_LAB_LOGO_URL,
  SET_PARTICIPANT,
  SET_POLLS,
  SET_ROLE,
  useSessionsContext,
} from '../../context/sessionContext';

const lodash = require('lodash');
const OT = require('@opentok/client');

const WaitingRoom = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  const { eventID, isGuest } = props.location.state;

  const [deviceInfos] = useState({
    browserName,
    browserVersion,
    isMobile,
    osVersion,
    osName,
    deviceType,
    fullBrowserVersion,
    isTablet,
    isDesktop,
  });

  const { id } = useRouteMatch().params;
  const authContext = useAuthContext();
  const { state, dispatch } = useSessionsContext();
  const sessionsContext = useSessionsContext();
  const history = useHistory();
  const [t] = useTranslation('front');
  const webcam = useRef();
  const camWrapper = useRef();
  const [hasVideo, setHasVideo] = useState(true);
  const [hasAudio, setHasAudio] = useState(true);
  const [video, setVideo] = useState(null);
  const [audio, setAudio] = useState(null);
  const [room, setRoom] = useState(null);
  const [lab, setLab] = useState(null);
  const [, setCookie] = useCookies(['publishAudio', 'publishVideo']);
  const [asParticipant, setAsParticipant] = useState(false);

  const toggleAudio = useCallback(() => setHasAudio(!hasAudio), [hasAudio, setHasAudio]);
  const toggleVideo = useCallback(() => setHasVideo(!hasVideo), [hasVideo, setHasVideo]);
  const [activeAudioDevice, setActiveAudioDevice] = useState(null);
  const [activeAudioInputDevice, setActiveAudioInputDevice] = useState(null);
  const [activeVideoInputDevice, setActiveVideoInputDevice] = useState(null);
  const [audioInputDeviceList, setAudioInputDeviceList] = useState([]);
  const [videoInputDeviceList, setVideoInputDeviceList] = useState([]);
  const [changeVideoOutputError, setChangeVideoOutputError] = useState(null);
  const [changeAudioOutputError, setChangeAudioOutputError] = useState(null);
  const [devicesInitialized, setDevicesInitialized] = useState(false);
  const [deviceList, setDeviceList] = useState([]);
  const [isAdmin, setIsAdmin] = useState(false);
  const [getUserDeviceError, setGetUserDeviceError] = useState(false);

  const [openTok, setOpenTok] = useState({});
  const [asToken, setAsToken] = useState(false);
  const [medias, setMedias] = useState(['']);
  const [socket, setSocket] = useState(null);
  const [streamStarted, setStreamStarted] = useState(false);

  useEffect(() => {
    const socketConnection = openSocket(process.env.REACT_APP_API_URI_NODE, {
      auth: {
        token: process.env.REACT_APP_NODE_API_TOKEN,
      },
    }).emit('connection');
    setSocket(socketConnection);
  }, []);

  useEffect(() => {
    if (authContext?.state) {
      const admin = authContext?.state?.user?.roles || [];
      setIsAdmin(admin.indexOf('admin') !== -1);
    }
  }, [authContext]);

  useEffect(async () => {
    const participantData = await NodeApiHttp.get(
      `/participants?email=${authContext.state.user.email}`
    );

    let participant = _.head(participantData);

    if (participant.length !== 0 && room) {
      let { role } = participant;

      if (!isAdmin) {
        participant = await NodeApiHttp.get(
          `/event-invitations?event=${room.event_id}&participant=${participant.id}`
        )
          .then((response) => {
            return {
              ...participant,
              role: response.length !== 0 ? _.head(response).role : participant.role,
            };
          })
          .catch((error) => {
            if (socket) {
              socket.emit('log', {
                level: 'error',
                message: 'WAITING_ROOM_GET_INVITATION_PARTICIPANT_ERROR',
                meta: {
                  service: 'opentok-meeting-room',
                  message: error.message,
                  code: error.code,
                  participantEmail: participant.email,
                  deviceInfos,
                },
              });
            }
          });

        role = participant.role;
      }

      dispatch({
        type: SET_PARTICIPANT,
        payload: participant,
      });
      dispatch({
        type: SET_ROLE,
        payload: role,
      });

      if (participant) setAsParticipant(true);
    }
  }, [dispatch, room, isAdmin, socket, deviceInfos]);

  useEffect(async () => {
    const activeAudioOutputDevice = await OT.getActiveAudioOutputDevice();
    setActiveAudioDevice(activeAudioOutputDevice);
  }, []);

  const joinSession = useCallback(async () => {
    stopDevices();
    const roomData = room;
    const mediaData = medias;
    const opentokData = JSON.stringify(openTok);

    const activeAudio = JSON.stringify(activeAudioInputDevice);
    const activeVideo = JSON.stringify(activeVideoInputDevice);
    const inputAudioList = JSON.stringify(audioInputDeviceList);
    const inputVideoList = JSON.stringify(videoInputDeviceList);

    history.push(`/room/${id}`, {
      eventID,
      activeAudio,
      activeVideo,
      inputAudioList,
      inputVideoList,
      roomData,
      mediaData,
      opentokData,
      isGuest,
    });
  }, [
    history,
    id,
    room,
    activeAudioInputDevice,
    activeVideoInputDevice,
    medias,
    openTok,
    isGuest,
    audioInputDeviceList,
    videoInputDeviceList,
  ]);

  const onResize = useCallback(() => {
    camWrapper.current.style.height = `${(camWrapper.current.clientWidth * 9) / 16}px`;
  }, [camWrapper]);

  /**
   * Listen to resize
   */
  useEffect(() => {
    if (!camWrapper.current) {
      return null;
    }
    onResize();
    window.addEventListener('resize', onResize, false);
    return () => {
      window.removeEventListener('resize', onResize, false);
    };
  }, [camWrapper, onResize]);

  /**
   * Video
   */
  useEffect(() => {
    if (!webcam.current || !room.active_modules.includes('active_camera')) {
      return;
    }

    if (!video && hasVideo) {
      setDevicesInitialized(false);

      const getVideo = async () => {
        try {
          if (!streamStarted) {
            setStreamStarted(true);
            const devicesList = await navigator.mediaDevices.enumerateDevices();
            setDeviceList(devicesList);

            // Take the first element from audio device list as default input audio device
            const defaultAudioInput = lodash.head(
              lodash.filter(devicesList, { kind: 'audioinput' })
            );

            // Take the first element from video device list as default input video device
            const defaultVideoInput = lodash.head(
              lodash.filter(devicesList, { kind: 'videoinput' })
            );

            const videoDevice = activeVideoInputDevice || defaultVideoInput;
            const audioDevice = activeAudioInputDevice || defaultAudioInput;

            if (!videoDevice) {
              setHasVideo(false);
              toast.error(`Caméra non détecté`);
            }

            if (!audioDevice) {
              setHasAudio(false);
              toast.error(`Microphone non détecté`);
            }

            const videoStream = await navigator.mediaDevices
              .getUserMedia({
                audio: audioDevice ? { deviceId: audioDevice.deviceId } : false,
                video: videoDevice ? { deviceId: videoDevice.deviceId } : false,
              })
              .then(async (mediaStream) => {
                const newDeviceList = await navigator.mediaDevices.enumerateDevices();
                const tracks = mediaStream.getTracks();

                setActiveAudioInputDevice(lodash.head(lodash.filter(tracks, { kind: 'audio' })));
                setActiveVideoInputDevice(lodash.head(lodash.filter(tracks, { kind: 'video' })));

                setAudioInputDeviceList(lodash.filter(newDeviceList, { kind: 'audioinput' }));
                setVideoInputDeviceList(lodash.filter(newDeviceList, { kind: 'videoinput' }));

                webcam.current.srcObject = mediaStream;
                setVideo(mediaStream);
                setDevicesInitialized(true);
              })
              .catch((err) => {
                console.log('getUserMedia error');
                console.error(`${err.name}: ${err.message}`);
                setGetUserDeviceError(true);
                setDevicesInitialized(true);
                setHasVideo(false);
                setHasAudio(false);

                initAudioOnly();
              })
              .finally(() => {
                setDevicesInitialized(true);
              });
          }
        } catch (error) {
          setHasVideo(false);
          setHasAudio(false);
        }
      };
      if (!video) getVideo();
    } else if (!!video && !hasVideo) {
      webcam.current.srcObject = null;
      const tracks = video.getTracks();
      tracks.forEach((track) => track.stop());
      setVideo(null);
    }
  }, [
    video,
    hasVideo,
    streamStarted,
    webcam,
    room,
    activeAudioInputDevice,
    activeVideoInputDevice,
  ]);

  const stopDevices = useCallback(() => {
    if (webcam.current.srcObject) {
      const stream = webcam.current.srcObject;
      const tracks = stream.getTracks();

      tracks.forEach((track) => {
        track.stop();
        track.enabled = false;
      });

      webcam.current.srcObject = null;
    }
  }, [webcam]);

  const initAudioOnly = useCallback(async () => {
    const devicesList = await navigator.mediaDevices.enumerateDevices();
    setDeviceList(devicesList);
    const defaultAudioInput = lodash.head(lodash.filter(devicesList, { kind: 'audioinput' }));
    const audioDevice = activeAudioInputDevice || defaultAudioInput;

    await navigator.mediaDevices
      .getUserMedia({
        audio: audioDevice ? { deviceId: audioDevice.deviceId } : true,
      })
      .then(async (mediaStream) => {
        const newDeviceList = await navigator.mediaDevices.enumerateDevices();
        const tracks = mediaStream.getTracks();
        const audioTrack = lodash.head(lodash.filter(tracks, { kind: 'audio' }));

        setActiveAudioInputDevice({
          id: audioTrack.id,
          kind: audioTrack.kind,
          label: audioTrack.label,
          muted: audioTrack.muted,
        });
        setActiveVideoInputDevice(null);

        setAudioInputDeviceList(lodash.filter(newDeviceList, { kind: 'audioinput' }));
        setVideoInputDeviceList([]);

        webcam.current.srcObject = mediaStream;
        setVideo(mediaStream);
        setDevicesInitialized(true);
        setHasAudio(true);
      })
      .catch((err) => {
        console.log('getUserMedia error');
        console.error(`${err.name}: ${err.message}`);
        setGetUserDeviceError(true);
        setDevicesInitialized(true);
        setHasVideo(false);
        setHasAudio(false);
      })
      .finally(() => {
        setDevicesInitialized(true);
      });
  }, [video, hasVideo, webcam, room, activeAudioInputDevice, activeVideoInputDevice]);

  useEffect(() => {
    if (!audio || !room || room.active_modules.includes('active_camera')) {
      return null;
    }
    return () => {
      const tracks = audio.getTracks();
      tracks.forEach((track) => track.stop());
    };
  }, [audio, room]);

  useEffect(() => {
    http.get(`rooms/${id}`).then(setRoom);
  }, [id, authContext]);

  useEffect(() => {
    if (room) {
      NodeApiHttp.get(`labs/${room.lab_id}`).then(setLab);
    }
  }, [room, authContext]);

  /**
   * Cookies
   */
  useEffect(() => {
    setCookie('publishAudio', hasAudio);
    setCookie('publishVideo', hasVideo);
  }, [hasAudio, hasVideo, setCookie]);

  const handleInputChange = useCallback(
    (e) => {
      const role = e.target.value;
      const newRoom = {
        ...room,
      };
      newRoom.participation.role = role;
      http
        .put(`participants/${room.participation.id}`, {
          role,
        })
        .then(() => setRoom(newRoom));
    },
    [room]
  );

  const onChangeAudioInput = useCallback(
    async (e) => {
      setDevicesInitialized(false);
      const devicesList = await navigator.mediaDevices.enumerateDevices();
      const newAudioDevice = lodash.head(
        lodash.filter(devicesList, { kind: 'audioinput', deviceId: e.target.value })
      );

      const videoStream = await navigator.mediaDevices
        .getUserMedia({
          audio: {
            deviceId: e.target.value,
          },
          video: activeVideoInputDevice ? { deviceId: activeVideoInputDevice.deviceId } : false,
        })
        .catch((err) => {
          setDevicesInitialized(true);
          setChangeAudioOutputError(
            `Impossible de démarrer la source audio: ${newAudioDevice.label}`
          );
        });

      if (videoStream && videoStream.getVideoTracks() && videoStream.getAudioTracks()) {
        webcam.current.srcObject = videoStream;
        setActiveAudioInputDevice(newAudioDevice);
        setDevicesInitialized(true);
      }
    },
    [webcam, activeVideoInputDevice]
  );

  const onChangeVideoInput = useCallback(
    async (e) => {
      setChangeVideoOutputError(null);
      setDevicesInitialized(false);

      const tracks = video.getTracks();
      tracks.forEach((track) => track.stop());

      const devicesList = await navigator.mediaDevices.enumerateDevices();
      const newVideoDevice = lodash.head(
        lodash.filter(devicesList, { kind: 'videoinput', deviceId: e.target.value })
      );

      const videoStream = await navigator.mediaDevices
        .getUserMedia({
          video: {
            deviceId: e.target.value,
          },
          audio: {
            deviceId: activeAudioInputDevice ? activeAudioInputDevice.deviceId : null,
          },
        })
        .catch((err) => {
          setDevicesInitialized(true);
          setChangeVideoOutputError(
            `Impossible de démarrer la source vidéo: ${newVideoDevice.label}`
          );
        });

      if (videoStream && videoStream.getVideoTracks() && videoStream.getAudioTracks()) {
        webcam.current.srcObject = videoStream;
        setVideo(videoStream);
        setActiveVideoInputDevice(newVideoDevice);
        setDevicesInitialized(true);
      }
    },
    [webcam, activeAudioInputDevice, video]
  );

  /**
   * Fetch room data
   */
  useEffect(() => {
    http
      .get(`rooms/${id}/token`)
      .then(async (data) => {
        setOpenTok(data);
        setMedias(data.medias);
        setAsToken(true);

        if (data.polls && data.polls.length > 0) {
          dispatch({
            type: SET_POLLS,
            payload: data.polls.map((p) => ({ ...p, active: !!p.active })),
          });
        }
        dispatch({
          type: SET_ACTIVE_MODULES,
          payload: data.activeModules,
        });
        dispatch({
          type: SET_LAB_LOGO_URL,
          payload: data.labLogoUrl,
        });
      })
      .catch((err) => {
        setAsToken(false);
        setAsParticipant(false);
      });
  }, [id, dispatch, isAdmin, room]);

  useEffect(() => {
    const oldAudioDeviceList = lodash.filter(deviceList, { kind: 'audioinput' });
    const oldVideoDeviceList = lodash.filter(deviceList, { kind: 'videoinput' });

    navigator.mediaDevices.ondevicechange = async (event) => {
      let constraints = {
        video: true,
        audio: true,
      };
      const newDeviceList = await navigator.mediaDevices.enumerateDevices();
      const newAudioDeviceList = lodash.filter(newDeviceList, { kind: 'audioinput' });
      const newVideoDeviceList = lodash.filter(newDeviceList, { kind: 'videoinput' });
      // Take the first element from audio device list as default input audio device
      const newDefaultAudioInput = lodash.head(newAudioDeviceList);

      // Take the first element from video device list as default input video device
      const newDefaultVideoInput = lodash.head(newVideoDeviceList);

      const videoDeviceDiff = _.difference(oldVideoDeviceList, newVideoDeviceList);
      const audioDeviceDiff = _.difference(oldAudioDeviceList, newAudioDeviceList);

      if (oldAudioDeviceList.length > newAudioDeviceList.length) {
        constraints = {
          video: activeVideoInputDevice ? { deviceId: activeVideoInputDevice.deviceId } : false,
          audio: newDefaultAudioInput ? { deviceId: newDefaultAudioInput.deviceId } : false,
        };
      }

      if (oldAudioDeviceList.length < newAudioDeviceList.length) {
        setGetUserDeviceError(false);
        constraints = {
          video: activeVideoInputDevice ? { deviceId: activeVideoInputDevice.deviceId } : false,
          audio: newDefaultAudioInput ? { deviceId: newDefaultAudioInput.deviceId } : false,
        };
      }

      if (oldVideoDeviceList.length > newVideoDeviceList.length) {
        constraints = {
          video: newDefaultVideoInput ? { deviceId: newDefaultVideoInput.deviceId } : null,
          audio: activeAudioInputDevice ? { deviceId: activeAudioInputDevice.deviceId } : null,
        };
      }
      if (oldVideoDeviceList.length < newVideoDeviceList.length) {
        setGetUserDeviceError(false);
        constraints = {
          video: newDefaultVideoInput ? { deviceId: newDefaultVideoInput.deviceId } : null,
          audio: activeAudioInputDevice ? { deviceId: activeAudioInputDevice.deviceId } : null,
        };
      }

      const newMediaDevices = navigator.mediaDevices
        .getUserMedia(constraints)
        .then((mediaStream) => {
          const tracks = mediaStream.getTracks();

          setActiveAudioInputDevice(lodash.head(lodash.filter(tracks, { kind: 'audio' })));
          setActiveVideoInputDevice(lodash.head(lodash.filter(tracks, { kind: 'video' })));

          setAudioInputDeviceList(newAudioDeviceList);
          setVideoInputDeviceList(newVideoDeviceList);

          webcam.current.srcObject = mediaStream;
          setVideo(mediaStream);
          setDevicesInitialized(true);
        })
        .catch((err) => {
          console.log('getUserMedia error');
          console.error(`${err.name}: ${err.message}`);
          setGetUserDeviceError(true);
          setDevicesInitialized(true);
        })
        .finally(() => {
          setDevicesInitialized(true);
          setHasVideo(!!_.head(videoDeviceDiff));
          setHasAudio(!!_.head(audioDeviceDiff));
        });

      await toast.promise(newMediaDevices, {
        loading: 'Mise à jour des périphériques.',
        success: (
          <div>
            {_.head(videoDeviceDiff) && _.head(videoDeviceDiff).label}
            {_.head(audioDeviceDiff) && _.head(audioDeviceDiff).label}
          </div>
        ),
        error: 'Une erreur est survenue.',
      });

      setDeviceList(newDeviceList);
    };
  }, [deviceList, webcam, activeAudioInputDevice, video, activeVideoInputDevice]);

  return (
    <Container
      style={{
        height: 'auto',
      }}
      fluid
      className="text-center bg-default d-flex justify-content-center third-color"
    >
      <Container
        className="position-absolute d-flex flex-column"
        style={{
          top: '.5rem',
        }}
      >
        <HeaderNavbar hideMenu room={room} stopDevices={stopDevices} />
        <div>
          <Toaster />
        </div>
        <div
          className="row justify-content-center align-items-center"
          style={styles.containerCenter}
        >
          <div className="col-lg-8">
            <div
              ref={camWrapper}
              className="w-200 bg-dark mt-3 position-relative rounded"
              style={{
                height: 'auto',
                maxHeight: 'calc(100vh - 300px)',
              }}
            >
              {!!lab && !!lab.waiting_room_image && (
                <div
                  className="w-100 h-100 position-absolute absolute-full"
                  style={{
                    background: `url(${process.env.REACT_APP_API_URI_NODE}${lab.waiting_room_image.formats.large.url}) no-repeat center`,
                    backgroundSize: 'cover',
                  }}
                />
              )}
              {!!room && room.active_modules.includes('active_camera') && hasVideo && (
                <div
                  className="d-flex flex-column justify-content-center align-items-center position-absolute absolute-full"
                  style={{
                    zIndex: 1,
                    backgroundColor: '#212529CC',
                  }}
                >
                  <Spinner color="light" />
                </div>
              )}
              {!!room && room.active_modules.includes('active_camera') && (
                <video
                  className="position-absolute absolute-full"
                  ref={webcam}
                  style={{
                    objectFit: 'cover',
                    zIndex: 2,
                  }}
                  width="100%"
                  height="100%"
                  autoPlay
                  muted
                />
              )}
              <div
                className="d-flex w-100 justify-content-center mt-4 position-absolute"
                style={styles.containerButtonCall}
              >
                <Button
                  color="white"
                  className={classnames('p-0 d-flex justify-content-center align-items-center', {
                    'bg-danger border-danger text-white': !hasAudio,
                  })}
                  onClick={toggleAudio}
                  style={styles.button}
                >
                  {hasAudio ? <MicrophoneOn fill={Colors.GREY} /> : <MicrophoneOff fill="#FFF" />}
                </Button>
                {!!room && room.active_modules.includes('active_camera') && (
                  <Button
                    color="white"
                    className={classnames('p-0 d-flex justify-content-center align-items-center', {
                      'bg-danger border-danger text-white': !hasVideo,
                    })}
                    onClick={toggleVideo}
                    style={styles.button}
                  >
                    {hasVideo ? <CameraOn fill={Colors.GREY} /> : <CameraOff fill="#FFF" />}
                  </Button>
                )}
              </div>
            </div>
          </div>
          <div className="col">
            <div className="d-flex flex-column align-items-center justify-content-center mt-3 pb-3">
              {!devicesInitialized && (
                <div color="primary" className="font-weight-normal">
                  <h3 className="text-light mb-2" style={{ fontSize: 20 }}>
                    Préparation...
                  </h3>
                  <span className="text-light mb-2">Vous pourrez commencer dans un instant</span>
                  <br />
                  <br />
                  <span>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                      color="light"
                    />
                  </span>
                </div>
              )}

              {devicesInitialized && state.participant && asParticipant && asToken && (
                <>
                  <br />
                  <h3 className="text-light mb-2" style={{ fontSize: 20 }}>
                    Prêt à rejoindre la réunion ?
                  </h3>
                  <Button
                    color="primary"
                    style={{ width: '300px', marginLeft: 10 }}
                    className="mt-2 mb-3 font-weight-normal"
                    onClick={joinSession}
                  >
                    {t('Join meeting')}
                  </Button>

                  {!!room && room.participation && (
                    <>
                      <span className="text-light mb-2">{t('Join meeting as')}</span>
                      <EventSelectRole
                        style={{
                          width: 300,
                        }}
                        onChange={handleInputChange}
                        value={room.participation.role}
                      />
                    </>
                  )}
                </>
              )}

              {audioInputDeviceList.length !== 0 && (
                <div>
                  <span className="text-light mb-2">Périphérique de capture audio</span>
                  <Input
                    style={{
                      width: 300,
                    }}
                    type="select"
                    className="form-control-alternative mb-3"
                    onChange={onChangeAudioInput}
                  >
                    {audioInputDeviceList.map((device, key) => {
                      return (
                        <option key={key} value={device.deviceId}>
                          {device.label}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              )}

              {videoInputDeviceList.length !== 0 && (
                <div>
                  <span className="text-light mb-2">Périphérique de capture vidéo</span>
                  <Input
                    style={{
                      width: 300,
                    }}
                    type="select"
                    className="form-control-alternative mb-3"
                    onChange={onChangeVideoInput}
                  >
                    {videoInputDeviceList.map((device, key) => {
                      return (
                        <option key={key} value={device.deviceId}>
                          {device.label}
                        </option>
                      );
                    })}
                  </Input>
                </div>
              )}
              <br />

              {devicesInitialized && (
                <>
                  {activeAudioDevice ? (
                    <span style={{ width: 300, margin: 5 }}>
                      Sortie audio: {activeAudioDevice.label}
                    </span>
                  ) : (
                    <div>
                      <WarningAlert fill="#DBB941" style={styles.iconWarning} />
                      <span className="mb-2" style={styles.textColorWarning}>
                        Aucune sortie audio n'a été détecté
                      </span>
                    </div>
                  )}

                  {activeAudioInputDevice ? (
                    <span>Entrée audio: {activeAudioInputDevice.label}</span>
                  ) : (
                    <div>
                      <WarningAlert fill="#DBB941" style={styles.iconWarning} />
                      <span className="mb-2" style={styles.textColorWarning}>
                        Aucune entrée audio n'a été détectée
                      </span>
                    </div>
                  )}
                  {activeVideoInputDevice ? (
                    <span className="text-light mb-2">
                      Sortie video: {activeVideoInputDevice.label}
                    </span>
                  ) : (
                    <div>
                      <WarningAlert fill="#DBB941" style={styles.iconWarning} />
                      <span className="mb-2" style={styles.textColorWarning}>
                        Aucune caméra n'a été détectée
                      </span>
                    </div>
                  )}
                </>
              )}

              {!asParticipant && devicesInitialized && (
                <Alert color="danger">
                  Une erreur est survenue lors de la récupération des données. Veuillez quitter la
                  page et cliquer à nouveau sur votre lien d'invitation.
                </Alert>
              )}

              {!asToken && (
                <Alert color="danger">
                  Une erreur est survenue lors de la création de votre clé d'authentification.
                </Alert>
              )}

              {changeVideoOutputError && <Alert color="danger">{changeVideoOutputError}</Alert>}
              {changeAudioOutputError && <Alert color="danger">{changeAudioOutputError}</Alert>}
              {getUserDeviceError && (
                <Alert color="warning">
                  <div>
                    Pour cette expérience un micro ainsi qu'une webcam sont fortement conseillés.
                    Veuillez également les autoriser.
                  </div>
                  <div>-Webcam non détectée et/ou autorisée</div>
                  <div>-Micro non détectée et/ou autorisée</div>

                  <div />
                </Alert>
              )}
            </div>
          </div>
        </div>
      </Container>
    </Container>
  );
};

export default WaitingRoom;
const styles = {
  button: {
    borderRadius: '50%',
    width: 50,
    height: 50,
    minWidth: 50,
    minHeight: 50,
    maxWidth: 50,
    maxHeight: 50,
    fontSize: 18,
    color: 'grey',
    fontWeight: 'normal',
  },
  containerCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '80vh',
  },
  iconWarning: {
    width: '15px',
    height: '15px',
    marginRight: 5,
    marginBottom: 5,
  },
  textColorWarning: {
    color: '#DBB941',
  },
  containerButtonCall: {
    bottom: '1rem',
    zIndex: 10,
  },
};
