import React, { useCallback, useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { Col, Container, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import toast, { Toaster } from 'react-hot-toast';
import * as msal from '@azure/msal-browser';
import nodeApiHttp from '../../../utils/nodeApiHttp';
import HeaderNavbar from '../headers/HeaderNavbar';
import EventThumbnail from './EventThumbnail';

const GoogleCalendar = (callback, deps, props) => {
  const [t] = useTranslation('front');
  const { eventID, participantId } = callback.location.state;
  const [participant, setParticipant] = useState(null);
  const [event, setEvent] = useState(null);
  const [insertSuccess, setInsertSuccess] = useState(false);
  const [msInsertSuccess, setMsInsertSuccess] = useState(false);
  const [saveEventError, setSaveEventError] = useState('');

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const msalInstance = new msal.PublicClientApplication({
    auth: {
      clientId: process.env.REACT_APP_MS_CLIENT,
      redirectUri: process.env.REACT_APP_MS_REDIRECT_URI,
    },
  });

  const saveInCalendar = useCallback(async () => {
    const saveEvent = nodeApiHttp
      .post('/participants/calendar/add-event', { event, participant })
      .then(setInsertSuccess);

    await toast.promise(saveEvent, {
      loading: 'Enregitrement dans votre agenda ...',
      success: <b>Settings event success!</b>,
      error: <b>Une erreur est survenue lors de l'enregistrement.</b>,
    });
  }, [participant, event]);

  const authSuccess = useCallback(
    async (e) => {
      console.log(e);
      const getToken = nodeApiHttp
        .post(`/participants/create-google-token`, {
          email: participant.email,
          code: e.code,
        })
        .then(setParticipant);

      await toast.promise(getToken, {
        loading: 'Authentification ...',
        success: <b>Authentification réussi</b>,
        error: <b>Erreur d'authentification</b>,
      });
    },
    [participant]
  );

  const onFailure = useCallback((e) => {
    toast("Une erreur est survenue lors de l'identification.", {
      duration: 9000,
    });
  }, []);

  const login = useGoogleLogin({
    onSuccess: authSuccess,
    cancel_on_tap_outside: true,
    onError: onFailure,
    flow: 'auth-code',
    scope: 'https://www.googleapis.com/auth/calendar',
  });

  useEffect(async () => {
    const getParticipant = nodeApiHttp.get(`/participants/${participantId}`).then(setParticipant);
    await toast.promise(getParticipant, {
      loading: 'Récupération des données participant',
      success: <b>Authentification participant</b>,
      error: <b>Erreur d'authentification du participant</b>,
    });
  }, [participantId]);

  useEffect(async () => {
    const getEvent = nodeApiHttp.get(`/events/${eventID}`).then(setEvent);

    await toast.promise(getEvent, {
      loading: "Recherche de l'évènement",
      success: <b>Récupération de lévènement réussi</b>,
      error: <b>Erreur lors de la récupération de l'évènement.</b>,
    });
  }, [eventID]);

  const msLogin = useCallback(async () => {
    const loginRequest = {
      scopes: ['user.read', 'calendars.readWrite'],
      redirectUri: process.env.REACT_APP_MS_REDIRECT_URI,
    };
    try {
      const loginResponse = await msalInstance.loginPopup(loginRequest);

      const insertEventPromise = nodeApiHttp
        .post(`/participants/create-ms-calendar-event`, {
          eventId: eventID,
          accessToken: loginResponse.accessToken,
        })
        .then((res) => setMsInsertSuccess(true));

      await toast.promise(insertEventPromise, {
        loading: "Création de l'event dans votre calendrier Outlook",
        success: <b>Evènement créée</b>,
        error: <b>Erreur d'authentification du participant</b>,
      });
    } catch (err) {
      console.log('error');
      console.log(err);
      // handle error
    }
  }, [msalInstance, eventID]);

  return (
    <Container fluid className="h-100 text-center bg-default third-color">
      <div>
        <Toaster />
      </div>
      <Row
        className="justify-content-center mb-3 header pb-7 pt-7 position-relative first-color"
        style={{
          background: 'linear-gradient(87deg,#4c82ff 0, #4c82ff 100%)',
        }}
      >
        <Container
          className="position-absolute d-flex justify-content-between"
          style={{
            top: '.5rem',
          }}
        >
          <HeaderNavbar />
        </Container>
        <hr
          className="mb-0 mt-2"
          style={{
            width: '100vw',
            borderTop: '2px solid #FFFFFF22',
          }}
        />
        <Col className="mt-5">
          <h1 className="text-white mb-5">
            {!!participant && <span>Bonjour {participant.name}</span>}
          </h1>
          <p className="text-lead text-light">Ajouter cette évènement à votre calendrier</p>
        </Col>
        <div
          className="separator separator-bottom separator-skew zindex-100"
          style={{
            height: 80,
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            preserveAspectRatio="none"
            className="d-flex"
            version="1.1"
            viewBox="0 0 2560 100"
            x="0"
            y="0"
          >
            <polygon className="fill-default" points="2560 0 2560 100 0 100" />
          </svg>
        </div>
      </Row>
      <Container>
        <Col
          lg="5"
          md="7"
          className="mt--7"
          style={{
            margin: 'auto',
          }}
        >
          <Row>
            {event && (
              <EventThumbnail
                event={event}
                participant={participant}
                insertSuccess={insertSuccess}
                saveInCalendar={saveInCalendar}
                login={login}
                microsoftLoginCallback={msLogin}
                msInsertSuccess={msInsertSuccess}
              />
            )}
          </Row>
        </Col>
      </Container>
      {/*      <Footer /> */}
    </Container>
  );
};

export default GoogleCalendar;
