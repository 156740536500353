import React from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

const EventSelectRole = (props) => {
  const { onChange, value, style } = props;
  const [tCommon] = useTranslation();
  return (
    <Input
      type="select"
      id="input-role"
      className="form-control-alternative mb-3"
      required
      onChange={onChange}
      value={value}
      style={style}
      name="role"
    >
      <option value="participant">{tCommon('Participant')}</option>
      <option value="animator">{tCommon('Animator')}</option>
      <option value="observer">{tCommon('Observer')}</option>
      <option value="observer-animator">{tCommon('Observer Animator')}</option>
    </Input>
  );
};

export default EventSelectRole;

EventSelectRole.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
