import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Row } from 'reactstrap';

import logoVtopia from 'assets/img/logo-vtopia.svg';
import { SessionContext } from 'context/sessionContext';
import UserBanner from 'components/common/UserBanner';
import PharmacoReport from 'components/front/pharmacovigilance/PharmacoReport';
import RoomNavbarButtons from 'components/front/headers/RoomNavbarButtons';
import { useAuthContext } from '../../../context/authContext';
import 'App.css';

const Header = (props) => {
  const { isAnimator } = props;
  const { state } = useContext(SessionContext);
  const authContext = useAuthContext();

  return (
    <Row className="flex-grow-0 d-flex justify-content-between align-items-center p-3">
      <div className="d-flex">
        {/*
          src={
          state.labLogoUrl
            ? `${process.env.REACT_APP_API_BASE_URI}/${state.labLogoUrl}`
            : logoVtopia
          }
          */}
        {authContext.state.lab && (
          <div>
            <img
              style={{
                height: 35,
                marginRight: '10px',
                pointerEvents: 'all',
              }}
              src={
                authContext.state.lab.logo_image
                  ? `${process.env.REACT_APP_API_URI_NODE}${authContext.state.lab.logo_image.url}`
                  : logoVtopia
              }
              className="img-fluid ml-3"
              alt=""
            />

            <div
              className="badge"
              style={{
                display: 'none',
              }}
            >
              BETA
            </div>
          </div>
        )}
        {state.activeModules.includes('pharmacovigilance') && isAnimator && <PharmacoReport />}
      </div>
      <div className="text-white d-flex align-items-center">
        {state.activeModules.includes('share_document') && <RoomNavbarButtons />}
        <UserBanner />
      </div>
    </Row>
  );
};

export default Header;
Header.propTypes = {
  isAnimator: PropTypes.bool,
};
