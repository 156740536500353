import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { Button, Col, UncontrolledCollapse } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import SimpleBar from 'simplebar-react';

import { useSessionsContext, SET_POLLS } from 'context/sessionContext';
import http from 'utils/http';
import VotePoll from 'components/front/room/polls/VotePoll';
import PollsStats from 'components/front/room/polls/PollsStats';
import CreatePoll from 'components/front/room/polls/CreatePoll';

const Polls = (props) => {
  const { isAnimator, session, room, style } = props;
  const { state, dispatch } = useSessionsContext();
  const { polls } = state;

  const savedPolls = polls.filter((p) => !p.active);
  // const [pagination, setPagination] = useState(0);

  const [t] = useTranslation('front');
  const [addingPoll, setAddingPoll] = useState(false);

  const launchSelectedPolls = useCallback(async () => {
    const checkboxes = Array.from(document.querySelectorAll('input.poll-checkbox'));
    const selectedCheckboxes = checkboxes.filter((checkbox) => checkbox.checked);

    if (selectedCheckboxes.length === 0) {
      return;
    }

    try {
      await Promise.all(
        selectedCheckboxes.map(
          (checkbox) =>
            new Promise(async (resolve, reject) => {
              try {
                const response = await http.put(`polls/${checkbox.dataset.id}`, {
                  active: true,
                  opentok_session_id: room.opentok_session_id,
                  event_id: room.event_id,
                  room_id: room.id,
                });

                const updatedPolls = polls.map((p) => {
                  if (
                    selectedCheckboxes.some((box) => box.dataset.id.toString() === p.id.toString())
                  ) {
                    return { ...p, active: true };
                  }
                  return p;
                });

                dispatch({
                  type: SET_POLLS,
                  payload: updatedPolls,
                });

                resolve();
              } catch (error) {
                reject(error);
              }
            })
        )
      );
    } catch (error) {
      console.error(error);
    }
  }, [room, polls, dispatch]);

  return (
    <Col
      style={{
        display: 'flex',
        ...style,
      }}
      className="flex-column chat flex-grow-1 px-2 pt-3 pb-3 overflow-hidden"
    >
      {!isAnimator && polls.length > 0 && session && <VotePoll room={room} />}
      {isAnimator && (
        <>
          <div className="flex-grow-1 p-0 overflow-hidden">
            <SimpleBar className="h-100">
              {/* Adding a new poll */}
              {addingPoll && (
                <CreatePoll
                  onEditPoll={() => {}}
                  editPoll={() => {}}
                  room={room}
                  onPollCreated={() => setAddingPoll(false)}
                />
              )}
              {/* Stats */}
              {polls
                .filter((poll) => poll.active || poll.answers.some((a) => a !== 0))
                .map((poll) => (
                  <PollsStats
                    key={poll.id}
                    poll={poll}
                    openTokSessionId={room.opentok_session_id}
                  />
                ))}
            </SimpleBar>
          </div>
          <div className="flex-column flex-shrink-0">
            <hr className="my-3 mx--2" />
            {!addingPoll && (
              <>
                <span
                  className="mb-2 p-2 border border-primary shadow--hover w-100 text-left d-inline-flex justify-content-start align-items-center"
                  style={{
                    cursor: 'pointer',
                    borderRadius: 4,
                    fontSize: 11,
                  }}
                  role="button"
                  tabIndex={0}
                  onClick={() => setAddingPoll(true)}
                >
                  <span
                    style={{
                      borderRadius: '50%',
                      width: 20,
                      height: 20,
                      minWidth: 20,
                      minHeight: 20,
                      fontSize: 20,
                    }}
                    className="border border-primary p-1 d-inline-flex justify-content-center align-items-center font-weight-bold text-primary mr-2"
                  >
                    +
                  </span>{' '}
                  {t('Create a new poll')}
                </span>
              </>
            )}

            <UncontrolledCollapse toggler="#accordion-toggle">
              {/* {isAdmin
              ? (
                <Card className="bg-secondary shadow">
                  <CardHeader className="bg-white border-0" />
                  <CardBody>
                    <Card
                      style={{
                        margin: '-1.5rem',
                      }}
                      className="mb-5"
                    >
                      <CardBody className="p-0">
                        <Table className="align-items-center table-flush overflow-visible" responsive>
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">{tCommon('Title')}</th>
                              <th scope="col">{tCommon('Question')}</th>
                              <th scope="col" />
                            </tr>
                          </thead>
                          <tbody>
                            {adminPolls.slice(pagination * 4, 4 + pagination * 4).map((p, i) => (
                              <tr key={p.id}>
                                <th scope="row">
                                  <Media className="align-items-center">
                                    <Media>
                                      <span className="mb-0 text-sm">{p.title}</span>
                                    </Media>
                                  </Media>
                                </th>
                                <td>{p.question}</td>
                                <td>{tCommon(p.role)}</td>
                                <td className="text-right">
                                  <UncontrolledDropdown>
                                    <DropdownToggle
                                      className="btn-icon-only text-light"
                                      href="#"
                                      role="button"
                                      size="sm"
                                      color=""
                                      onClick={(e) => e.preventDefault()}
                                    >
                                      <i className="fas fa-ellipsis-v" />
                                    </DropdownToggle>
                                    <DropdownMenu className="dropdown-menu-arrow" right>
                                      <DropdownItem href="#" onClick={() => deletePoll(p.id)}>{tCommon('Delete')}</DropdownItem>

                                      <Link to={`/admin/polls/${p.id}`}>
                                        <DropdownItem>
                                          {tCommon('Edit')}
                                        </DropdownItem>

                                      </Link>

                                    </DropdownMenu>
                                  </UncontrolledDropdown>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                      </CardBody>
                      <CardFooter>
                        <nav aria-label="...">
                          <Pagination
                            className="pagination justify-content-end mb-0"
                            listClassName="justify-content-end mb-0"
                          >
                            <PaginationItem
                              className={classnames({
                                disabled: pagination <= 0,
                              })}
                            >
                              <PaginationLink
                                onClick={() => pagination > 0 && setPagination(pagination - 1)}
                              >
                                <i className="fas fa-angle-left" />
                                <span className="sr-only">{tCommon('Previous')}</span>
                              </PaginationLink>
                            </PaginationItem>
                            {adminPolls.filter((_, i) => i % 4 === 0).map((_, i) => (
                              <PaginationItem key={i} className={pagination === i ? 'active' : ''}>
                                <PaginationLink onClick={() => setPagination(i)}>{i + 1}</PaginationLink>
                              </PaginationItem>
                            ))}
                            <PaginationItem
                              className={classnames({
                                disabled: pagination >= ((polls.length / 4) | 0),
                              })}
                            >
                              <PaginationLink
                                onClick={() => pagination < ((polls.length / 4) | 0) && setPagination(pagination + 1)}
                              >
                                <i className="fas fa-angle-right" />
                                <span className="sr-only">{tCommon('Next')}</span>
                              </PaginationLink>
                            </PaginationItem>
                          </Pagination>
                        </nav>
                      </CardFooter>
                    </Card>
                  </CardBody>
                </Card>
              ) */}
              {/* : ( */}
              {isAnimator && (
                <div
                  className="border p-2 mb--1"
                  style={{
                    borderTopLeftRadius: 4,
                    borderTopRightRadius: 4,
                  }}
                >
                  {savedPolls.length > 0 ? (
                    <>
                      <Button
                        className="w-100 mb-3"
                        onClick={launchSelectedPolls}
                        color="primary"
                        style={{
                          fontSize: 11,
                        }}
                      >
                        {t('Start poll')}
                      </Button>
                      {savedPolls.map((poll) => (
                        <Checkbox
                          className="custom-control custom-checkbox mb-1"
                          key={`poll-${poll.id}`}
                        >
                          <input
                            className="custom-control-input poll-checkbox"
                            id={`poll-checkbox-${poll.id}`}
                            name={`poll-checkbox-${poll.id}`}
                            type="checkbox"
                            data-id={poll.id}
                          />
                          <label
                            className="custom-control-label"
                            htmlFor={`poll-checkbox-${poll.id}`}
                            style={{
                              fontSize: 11,
                            }}
                          >
                            {poll.title || poll.question}
                          </label>
                        </Checkbox>
                      ))}
                    </>
                  ) : (
                    <div
                      className="pb-2"
                      style={{
                        fontSize: 11,
                      }}
                    >
                      {t('No polls available')}
                    </div>
                  )}
                </div>
              )}
              {/* ) */}
              {/* } */}
            </UncontrolledCollapse>
            <Button
              color="white"
              id="accordion-toggle"
              className="mx-0 border text-left font-weight-normal d-inline-flex justify-content-between align-items-center w-100"
              style={{
                fontSize: 11,
                color: '#8898AA',
                // padding: 10,
                borderRadius: 4,
              }}
            >
              {t('Saved polls')}
              <i className="fa fa-angle-up" />
            </Button>
          </div>
        </>
      )}
    </Col>
  );
};

export default Polls;
Polls.propTypes = {
  session: PropTypes.object.isRequired,
  isAnimator: PropTypes.bool,
};

const Checkbox = styled.div`
  & .custom-control-label::before,
  & .custom-control-label::after {
    top: 0;
  }
`;
