import React, { useReducer, useEffect, useState, useCallback, useContext, useRef } from 'react';
import http from 'utils/http';

export const actions = Object.freeze({
  LOAD_LIST: 'LOAD_LIST',
  REFRESH_LIST: 'REFRESH_LIST',
  UPDATE_LIST: 'UPDATE_LIST',
  UPDATE_FILTERED_LIST: 'UPDATE_FILTERED_LIST',
  PRE_UPDATE_LIST: 'PRE_UPDATE_LIST',
  CLEAN_PRE_UPDATED_LIST: 'CLEAN_PRE_UPDATED_LIST',
  UPDATE_VALUE: 'UPDATE_VALUE',
  UPDATE_VALUE_ONLY: 'UPDATE_VALUE_ONLY',
  DELETE: 'DELETE',
  DELETE_MULTIPLE: 'DELETE_MULTIPLE',
  SEND_VALUE: 'SEND_VALUE',
  SEND_DELETE: 'SEND_DELETE',
  SEND_DELETE_MULTIPLE: 'SEND_DELETE_MULTIPLE',
});

const changes = Object.freeze({
  AFTER_LOAD: 'AFTER_LOAD',
  AFTER_SEND_VALUE: 'AFTER_SEND_VALUE',
  AFTER_SEND_DELETE: 'AFTER_SEND_DELETE',
  AFTER_SEND_DELETE_MULTIPLE: 'AFTER_SEND_DELETE_MULTIPLE',
  AFTER_UPDATE_LIST: 'AFTER_UPDATE_LIST',
  AFTER_UPDATE_FILTERED_LIST: 'AFTER_UPDATE_FILTERED_LIST',
  AFTER_PRE_UPDATE_LIST: 'AFTER_PRE_UPDATE_LIST',
  AFTER_CLEAN_PRE_UPDATED_LIST: 'AFTER_CLEAN_PRE_UPDATED_LIST',
  AFTER_UPDATE_VALUE: 'AFTER_UPDATE_VALUE',
  AFTER_DELETE: 'AFTER_DELETE',
  AFTER_DELETE_MULTIPLE: 'AFTER_DELETE_MULTIPLE',
  AFTER_REFRESH: 'AFTER_REFRESH',
});

export const ListContext = React.createContext({
  get error() {
    throw new Error('Please Make sure you are using a provider');
  },
});

export const useListContext = () => {
  const context = useContext(ListContext);
  if (!context) {
    return context.error;
  }
  return context;
};

/**
 * Returns the current displayed list by restoring an element with
 * its previous value from the fullList loaded in the background.
 * @param {*} list
 * @param {*} fullList
 * @param {*} id
 * @returns
 */
const restoreElement = (list, fullList, id) => {
  const prev = fullList.find((value) => value.id === id);
  return list.map((value) => {
    if (value.id === id) {
      return prev;
    }
    return value;
  });
};

/**
 * Returns the current displayed list by restoring multiple elements
 * with their previous value from the fullList loaded in the background.
 * @param {*} list
 * @param {*} fullList
 * @param {*} ids
 * @returns
 */
const restoreElements = (list, fullList, ids) => {
  const prevs = {};
  fullList.forEach((value) => {
    if (ids.indexOf(`${value.id}`) !== -1) {
      prevs[value.id] = value;
    }
  });
  return list.map((value) => {
    const prev = prevs[value.id];
    if (prev !== undefined) {
      return prev;
    }
    return value;
  });
};

/**
 * Fetch the list to be loaded.
 * @returns
 */
const onLoad = (resource) => {
  const { routes } = resource;
  return resource.http
    .get(routes.ON_LOAD)
    .then((response) => response)
    .catch((error) => {
      console.log(error);
      return [];
    });
};

/**
 * Calls the delete method to remove an element from the list on the
 * server side. No changes for the diplayed list on success, but sends and
 * action to update the fullList in background. Restores the previous element
 * within the displayed list on error.
 * @param {*} payload
 * @param {*} list
 */
const onDelete = (resource, payload, list, pagination) => {
  const { routes } = resource;
  const { _list: fullList, id } = payload;
  return resource.http
    .delete(`${routes.ON_DELETE}/${id}`)
    .then(() => ({
      list,
      change: {
        type: changes.AFTER_SEND_DELETE,
        payload: {
          // id: response.deleted_resource,
          id,
        },
      },
      pagination,
    }))
    .catch((error) => {
      console.log(error);
      return {
        list: restoreElement(list, fullList, id),
        change: {},
        pagination,
      };
    });
};

/**
 * Calls the delete method to remove multiple elements from the list on
 * the server side. No changes for the diplayed list on success, but sends and
 * action to update the fullList in background. Restores the previous elements
 * within the displayed list on error.
 * @param {*} payload
 * @param {*} list
 */
const onMultipleDelete = (resource, payload, list, pagination) => {
  const { routes } = resource;
  const { _list: fullList, ids } = payload;
  return resource.http
    .post(routes.ON_DELETE_MULTIPLE, {
      ids,
    })
    .then(() => ({
      list,
      change: {
        type: changes.AFTER_SEND_DELETE_MULTIPLE,
        payload: {
          // ids: response.deleted_resources,
          ids,
        },
      },
      pagination,
    }))
    .catch((error) => {
      console.log(error);
      return {
        list: restoreElements(list, fullList, ids),
        change: {},
        pagination,
      };
    });
};

/**
 * Calls the put method to update an element on the server side.
 * No changes for the diplayed list on success, but sends an action to update
 * the fullList in background. Restores the previous element within the
 * displayed list on error.
 * @param {*} payload
 * @param {*} list
 * @returns
 */
const onChangeValue = (resource, payload, list, pagination) => {
  const { routes } = resource;
  const { _list: fullList, id, value } = payload;
  return resource.http
    .put(`${routes.ON_CHANGE}/${id}`, {
      ...value,
    })
    .then((response) => ({
      list,
      change: {
        type: changes.AFTER_SEND_VALUE,
        payload: {
          id: response.id,
          value,
        },
      },
      pagination,
    }))
    .catch((error) => {
      console.log(error);
      return {
        list: restoreElement(list, fullList, id),
        change: {},
        pagination,
      };
    });
};

/**
 * Reducer managing the state of the displayed list.
 * @param {*} state
 * @param {*} action
 * @returns
 */
const reducer = (unresolved, action) =>
  new Promise((resolve) => {
    unresolved.then((state) => {
      const { resource, payload } = action;
      const { pagination } = state;
      switch (action.type) {
        case actions.REFRESH_LIST: {
          return resolve({
            list: state.list,
            change: {
              type: changes.AFTER_REFRESH,
            },
            pagination,
          });
        }

        case actions.LOAD_LIST: {
          const { _list: list, start: first, count } = {
            ...state.pagination,
            ...payload,
          };
          let start = first;
          if (list.length <= first) {
            start = first - count;
            if (start <= 0) {
              start = 0;
            }
          }
          return resolve({
            list: list.filter((value, index) => index >= start && index < count + start),
            change: {
              type: changes.AFTER_LOAD,
            },
            pagination: { start, count },
          });
        }

        case actions.PRE_UPDATE_LIST: {
          const { elements } = payload;
          return resolve({
            list: state.list,
            change: {
              type: changes.AFTER_PRE_UPDATE_LIST,
              payload: {
                elements,
              },
            },
            pagination,
          });
        }

        case actions.CLEAN_PRE_UPDATED_LIST: {
          return resolve({
            list: state.list.filter((element) => !element.tmp),
            change: {
              type: changes.AFTER_CLEAN_PRE_UPDATED_LIST,
            },
            pagination,
          });
        }

        case actions.UPDATE_LIST: {
          const { elements } = payload;
          return resolve({
            list: state.list,
            change: {
              type: changes.AFTER_UPDATE_LIST,
              payload: {
                elements,
              },
            },
            pagination,
          });
        }

        case actions.UPDATE_VALUE_ONLY: {
          const { list } = state;
          const { id, value } = payload;
          return resolve({
            list: list.map((element) => {
              if (id === element.id) {
                return { ...element, ...value };
              }
              return element;
            }),
            change: {
              type: changes.AFTER_SEND_VALUE,
              payload: {
                id,
                value,
              },
            },
            pagination,
          });
        }

        case actions.UPDATE_VALUE: {
          const { list } = state;
          const { id, value } = payload;
          return resolve({
            list: list.map((element) => {
              if (id === element.id) {
                return { ...element, ...value };
              }
              return element;
            }),
            change: {
              type: changes.AFTER_UPDATE_VALUE,
              payload: {
                id,
                value,
              },
            },
            pagination,
          });
        }

        case actions.DELETE: {
          const { list } = state;
          const { id } = payload;
          return resolve({
            list: list.map((value) => {
              if (id === value.id) {
                const update = {
                  ...value,
                };
                update.deleted = true;
                return update;
              }
              return value;
            }),
            change: {
              type: changes.AFTER_DELETE,
              payload: {
                id,
              },
            },
            pagination,
          });
        }

        case actions.DELETE_MULTIPLE: {
          const { list } = state;
          const { ids } = payload;
          return resolve({
            list: list.map((value) => {
              if (ids.indexOf(`${value.id}`) !== -1) {
                const update = {
                  ...value,
                };
                update.deleted = true;
                return update;
              }
              return value;
            }),
            change: {
              type: changes.AFTER_DELETE_MULTIPLE,
              payload: {
                ids,
              },
            },
            pagination,
          });
        }

        case actions.SEND_VALUE: {
          const { list } = state;
          return onChangeValue(resource, payload, list, pagination).then((data) => resolve(data));
        }

        case actions.SEND_DELETE: {
          const { list } = state;
          return onDelete(resource, payload, list, pagination).then((data) => resolve(data));
        }

        case actions.SEND_DELETE_MULTIPLE: {
          const { list } = state;
          return onMultipleDelete(resource, payload, list, pagination).then((data) =>
            resolve(data)
          );
        }

        default:
          return resolve(state);
      }
    });
  });

const initialState = {
  list: [],
  change: {},
  pagination: {
    start: 0,
    count: 5,
  },
};

export const ListProvider = ({ children, resource /* routes */ }) => {
  const [list, setList] = useState([]);
  const [status, setStatus] = useState('INIT');
  const [state, _dispatch] = useReducer(reducer, Promise.resolve(initialState));
  if (!resource.http) {
    resource.http = http;
  }
  /**
   * Reviews the dispatch function to carry the fullList in background as
   * an additionnal payload.
   * @param {*} data
   */
  const dispatch = useCallback(
    (data) => {
      _dispatch({
        type: data.type,
        resource,
        payload: {
          ...(data.payload || {}),
          _list: list,
        },
      });
    },
    [list, resource]
  );

  /**
   * Actions to update the fullList in background after an api call, or to
   * dispatch post-reducer actions in order to call a method over the api,
   * after changes.
   * @param {*} change
   */
  const applyChanges = useCallback(
    (change) => {
      const { type, payload } = change;
      switch (type) {
        case changes.AFTER_REFRESH: {
          setStatus('INIT');
          break;
        }
        case changes.AFTER_SEND_VALUE: {
          const clone = list.map((element) => {
            if (element.id === payload.id) {
              return { ...element, ...payload.value };
            }
            return element;
          });
          setList(() => clone);
          break;
        }

        case changes.AFTER_SEND_DELETE: {
          const clone = [...list];
          const index = clone.findIndex((element) => element.id === payload.id);
          if (index !== -1) {
            clone.splice(index, 1);
          }
          setList(() => clone);
          break;
        }

        case changes.AFTER_SEND_DELETE_MULTIPLE: {
          const clone = [...list];
          payload.ids.forEach((currId) => {
            const index = clone.findIndex((element) => `${element.id}` === currId);
            if (index !== -1) {
              clone.splice(index, 1);
            }
          });
          setList(() => clone);
          break;
        }

        case changes.AFTER_PRE_UPDATE_LIST: {
          const clone = [
            ...list,
            ...payload.elements.map((element) => ({
              ...element,
              tmp: true,
            })),
          ];
          setList(() => clone);
          setStatus('GET');
          break;
        }

        case changes.AFTER_CLEAN_PRE_UPDATED_LIST: {
          const clone = list.filter((element) => !element.tmp);
          setList(() => clone);
          setStatus('GET');
          break;
        }

        case changes.AFTER_UPDATE_LIST: {
          const clone = list.filter((element) => !element.tmp);
          payload.elements.forEach((element) => {
            clone.push({
              ...element,
            });
          });
          setList(() => clone);
          setStatus('GET');
          break;
        }

        case changes.AFTER_UPDATE_VALUE: //
          dispatch({
            type: actions.SEND_VALUE,
            payload: {
              id: payload.id,
              value: payload.value,
            },
          });
          break;

        case changes.AFTER_DELETE:
          dispatch({
            type: actions.SEND_DELETE,
            payload: {
              id: payload.id,
            },
          });
          break;

        case changes.AFTER_DELETE_MULTIPLE:
          dispatch({
            type: actions.SEND_DELETE_MULTIPLE,
            payload: {
              ids: payload.ids,
            },
          });
          break;

        default:
      }
    },
    [dispatch, list]
  );

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const previous = usePrevious({ list });

  useEffect(() => {
    if (previous?.list?.length && list.length < previous.list.length) {
      dispatch({
        type: actions.LOAD_LIST,
      });
    }
  }, [dispatch, previous, list]);

  useEffect(() => {
    state.then((value) => {
      const { change } = value;
      // console.log('Changes: ', change);
      if (change.type) {
        applyChanges(change);
        delete change.type; // Invalidates the previous change
      }
    });
  }, [state, list, applyChanges]);

  useEffect(() => {
    if (status === 'INIT') {
      onLoad(resource).then((data) => {
        setList(data);
        setStatus(() => 'GET');
      });
      setStatus(() => 'WAITING');
    } else if (status === 'GET') {
      if (list.length > 0) {
        dispatch({
          type: 'LOAD_LIST',
        });
        setStatus(() => 'LOAD');
      }
    }
  }, [dispatch, list, status, resource]);

  return (
    <ListContext.Provider
      value={{
        state,
        dispatch,
        listLength: list.length,
      }}
    >
      {children}
    </ListContext.Provider>
  );
};
